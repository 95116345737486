import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import React from 'react';
import CollaborationGroupsList from './CollaborationGroupsList';
import CollaborationGroupDetails from './CollaborationGroupDetails';
import SelectedCollaborationGroupIdProvider from './Contexts/SelectedCollaborationGroupIdContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
  },
  listContainer: {
    minHeight: '80vh',
    width: '30vw',
    background: '#F5F5F5',
    borderRadius: '1rem',
  },
  groupDetailsContainer: {
    minHeight: '80vh',
    width: '65vw',
    background: '#e8eef5',
    borderRadius: '1rem',
    marginLeft: '1rem',
  },
});

const CollaborationGroupsPage: React.FC = () => {
  const classes = useStyles();

  return (
    <SelectedCollaborationGroupIdProvider>
      <Box className={classes.container}>
        <Box className={classes.listContainer}>
          <CollaborationGroupsList />
        </Box>
        <Box className={classes.groupDetailsContainer}>
          <CollaborationGroupDetails />
        </Box>
      </Box>
    </SelectedCollaborationGroupIdProvider>
  );
};

export default CollaborationGroupsPage;
