import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface DateRangePickerProps {
  startDate: string;
  setStartDate: (startDate: string) => void;
  endDate: string;
  setEndDate: (endDate: string) => void;
}

const nullDateHandler = (date: Date | null): string => (date ?? moment().toDate()).toISOString();
const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="date-picker-container">
        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={(newDate: Date | null) => setStartDate(nullDateHandler(newDate))}
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={(newDate: Date | null) => setEndDate(nullDateHandler(newDate))}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
