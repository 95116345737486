import { useListCollaborationGroupQuery } from './Hooks/useCollaborationGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Box } from '@mui/material';
import SharedResourceGroupSelect from './SharedResourceGroupSelect';
import { useState } from 'react';
import SharedInteractionsTableWrapper from './SharedInteractionsTableWrapper';

const SharedMediaInteractionsPage: React.FC = () => {
  const { isFetching: isFetchingGroups, data: groupsData } = useListCollaborationGroupQuery();

  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  if (isFetchingGroups) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <SharedResourceGroupSelect
          selectedGroupId={selectedGroupId}
          groups={groupsData ?? []}
          onGroupSelect={(groupId) => setSelectedGroupId(groupId)}
        />
      </Box>
      <SharedInteractionsTableWrapper groupId={selectedGroupId} />
    </Box>
  );
};

export default SharedMediaInteractionsPage;
