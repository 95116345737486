import { CollaborationGroupSharedResourcesFiltersListItemsProps } from './Types/componentProps';
import { useContext, useMemo } from 'react';
import { ListItem, Box, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { DropdownTableContext } from '../../shared/contexts/DropdownTableContext';
import { collaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntries } from './Utils/constants';

interface FilterDisplayChipsProps {
  type: 'id' | 'name';
  filters: string[];
  dropDownTableFilterContext: { _id: string; name: string }[];
}

const FilterDisplayChips: React.FC<FilterDisplayChipsProps> = ({ type, filters, dropDownTableFilterContext }) => {
  const filteredDropdownTableContext = dropDownTableFilterContext.filter((filterContext) => {
    if (type === 'id') {
      return filters.includes(filterContext._id);
    }
    if (type === 'name') {
      return filters.includes(filterContext.name);
    }
    return false;
  });

  return (
    <>
      {filteredDropdownTableContext.map((filterContext) => (
        <Chip key={filterContext._id} variant="outlined" color="primary" label={filterContext.name} />
      ))}
    </>
  );
};

const CollaborationGroupSharedResourcesFiltersListItems: React.FC<
  CollaborationGroupSharedResourcesFiltersListItemsProps
> = ({ resourceType, filters }) => {
  const resourceFilters = useMemo(() => {
    return filters.filter((filterEl) => filterEl.resourceType === resourceType);
  }, [filters, resourceType]);

  const dropdownTableContext = useContext(DropdownTableContext);

  if (resourceFilters.length === 0) {
    return (
      <ListItem key="no-filter-list-item" data-cy="collaboration-group-details-interactions-filter-list-no-filter-item">
        <Typography>No resource filter set</Typography>
      </ListItem>
    );
  }

  return resourceFilters.map((resourceFilter) => {
    const { topic, dateFilter } = resourceFilter.criteria;
    const topicFilterDisplay = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: '125px' }}>
          <Typography>Topic:</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
          <Chip variant="outlined" color="primary" label={topic} />
        </Box>
      </Box>
    );

    const startDate = moment(dateFilter.start).tz(dateFilter.timezone).format('YYYY-MM-DD');
    const endDate = moment(dateFilter.end).tz(dateFilter.timezone).format('YYYY-MM-DD');
    const dateFilterDisplay = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: '125px' }}>
          <Typography>Date:</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
          <Chip variant="outlined" color="primary" label={`Start Date: ${startDate}`} />
          <Chip variant="outlined" color="primary" label={`End Date: ${endDate}`} />
        </Box>
      </Box>
    );

    const filterDisplays = collaborationGroupSharedResourcesFiltersListItemsFilterDisplayEntries.map(
      ({ title, filterCriteriaKey, dropdownTableContextFilterCriteriaKey }) => {
        const filterCriteria = resourceFilter.criteria[filterCriteriaKey];

        if (!(filterCriteria && filterCriteria.length > 0)) {
          return null;
        }

        let filterDisplayChips;
        if (filterCriteriaKey === 'units' || filterCriteriaKey === 'status') {
          filterDisplayChips = (
            <FilterDisplayChips
              type={'name'}
              filters={filterCriteria}
              dropDownTableFilterContext={dropdownTableContext[dropdownTableContextFilterCriteriaKey]}
            />
          );
        } else {
          filterDisplayChips = (
            <FilterDisplayChips
              type={'id'}
              filters={filterCriteria}
              dropDownTableFilterContext={dropdownTableContext[dropdownTableContextFilterCriteriaKey]}
            />
          );
        }

        return (
          <Box key={`filter-display-${title}`} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: '125px' }}>
              <Typography>{title}:</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '0.25rem' }}>{filterDisplayChips}</Box>
          </Box>
        );
      }
    );

    return (
      <ListItem
        key={`${resourceType}-${resourceFilter.tenant}`}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        data-cy="collaboration-group-details-interactions-filter-list-item"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          {dateFilterDisplay}
          {topicFilterDisplay}
          {filterDisplays}
        </Box>
      </ListItem>
    );
  });
};

export default CollaborationGroupSharedResourcesFiltersListItems;
