import { API } from '../constants/constants';

export interface GetUserBroadcastPopupResp {
  seen: boolean;
  content: string;
}

interface SaveBroadcastPopupReqBody {
  text: string;
}

export interface BroadcastPopupResp {
  seenCount: number;
  publishedContent: string;
  draftContent: string;
  publishedAt: Date;
}

export const getUserBroadcastPopup = async (): Promise<GetUserBroadcastPopupResp> => {
  const res = await API.get<GetUserBroadcastPopupResp>('/broadcast-popup');
  return res.data;
};

export const getAdminBroadcastPopup = async (): Promise<BroadcastPopupResp> => {
  const res = await API.get<BroadcastPopupResp>('/broadcast-popup/contents');
  return res.data;
};

export const saveBroadcastPopupDraft = async (data: SaveBroadcastPopupReqBody): Promise<BroadcastPopupResp> => {
  if (!data.text) {
    throw new Error('should not have empty broadcast popup draft to be saved');
  }
  const res = await API.post<BroadcastPopupResp>('/broadcast-popup/draft', data);
  return res.data;
};

export const publishBroadcastPopup = async (): Promise<BroadcastPopupResp> => {
  const res = await API.put<BroadcastPopupResp>('/broadcast-popup/publish');
  return res.data;
};
