import * as TermsOfServiceDialogActions from '../TermsOfServiceDialog/termsOfServiceDialogSlice';
import * as UserActions from '../Authorization/userSlice';
import { fetchTermsOfServiceDoc, fetchTermsOfServiceDocMeta } from '../../shared/functions/userFunctions';

export async function validateTermsOfServiceAgreement(agreedTermsOfServiceVersion, dispatch) {
  const { docName, version } = await fetchTermsOfServiceDocMeta();
  if (agreedTermsOfServiceVersion !== version) {
    dispatch(UserActions.setUserHasAgreedAgreedTermsOfService(false));
    const { htmlString } = await fetchTermsOfServiceDoc();
    dispatch(
      TermsOfServiceDialogActions.setTermsOfService({
        docName,
        version,
        htmlString,
      })
    );
    dispatch(TermsOfServiceDialogActions.openTermsOfServiceDialog());
  } else {
    dispatch(UserActions.setUserHasAgreedAgreedTermsOfService(true));
  }
}
