import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { useState } from 'react';

export default function LayoutWrapper() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Header sidebarOpen={sidebarOpen} onSetSidebarOpen={setSidebarOpen} />
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
      <Outlet />
    </>
  );
}
