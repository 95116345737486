import { configureStore } from '@reduxjs/toolkit';
import snackbarReducer from './components/AppSnackbar/snackbarSlice';
import userReducer from './components/Authorization/userSlice';
import termsOfServiceDialogReducer from './components/TermsOfServiceDialog/termsOfServiceDialogSlice';

export default configureStore({
  reducer: {
    snackbar: snackbarReducer,
    user: userReducer,
    termsOfServiceDialog: termsOfServiceDialogReducer,
  },
});
