import { Box, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { Select, MenuItem, Button, Chip } from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFilters } from './Context/FiltersContext';
import isFilterEmpty from '../../shared/functions/isFilterEmpty';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { mediaInteractionCategories, servicePageCategories } from '../../shared/constants/constants';

function FuzzySearch() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const existingFilters = useFilters();
  const [globalQuery, setglobalQuery] = useState(state?.queryString ? state.queryString : '');

  const categories =
    pathname === RouteInformation.mediaInteraction.path ? mediaInteractionCategories : servicePageCategories;
  const [category, setCategory] = useState(state?.category || categories[0]);

  const updateglobalQuery = (e) => {
    setglobalQuery(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // a debounced version of the search function
  const debouncedUpdateglobalQuery = debounce(updateglobalQuery, 50);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingRight: '2rem',
          marginTop: '1rem',
        }}
      >
        <Select
          disabled={!isFilterEmpty(existingFilters)}
          labelId="search-category"
          id="search-category-select"
          value={category}
          label="Category"
          onChange={handleCategoryChange}
          size="small"
          sx={{
            marginRight: '1rem',
          }}
          data-cy="search-category-select"
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category} data-cy={`category-${category}`}>
              {category}
            </MenuItem>
          ))}
        </Select>
        {/* <Tooltip title="Toggle to search through all time">
          <Switch
            checked={globalSearch}
            onChange={() => setglobalSearch(!globalSearch)}
            inputProps={{ 'aria-label': 'controlled' }}
          ></Switch>
        </Tooltip> */}

        <TextField
          size="medium"
          disabled={!isFilterEmpty(existingFilters)}
          placeholder="Search for key words..."
          onChange={debouncedUpdateglobalQuery}
          defaultValue={state?.queryString ? state.queryString : ''}
          sx={{
            marginRight: '3rem',
          }}
          variant="standard"
          data-cy="FuzzySearch-TextField"
        />
        {globalQuery && (
          <Link
            to={pathname}
            state={{
              queryString: globalQuery,
              category: category,
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              size="medium"
              sx={{
                marginLeft: '0.5rem',
              }}
            >
              <TravelExploreIcon />
              SEARCH ALL RECORDS
            </Button>
          </Link>
        )}
        {state?.queryString && (
          <Chip
            icon={<TravelExploreIcon />}
            label={`${category}: ${state.queryString}`}
            onDelete={() => {
              setglobalQuery('');
              navigate(pathname);
            }}
            sx={{
              marginLeft: '1rem',
            }}
            data-cy="FuzzySearch-QueryChip"
          />
        )}
        <br />
      </Box>
    </>
  );
}

export default FuzzySearch;
