import React from 'react';
import { TablePagination, TableContainer, Table, TableBody, Paper, TableRow } from '@mui/material';
import SharedContactsTableHeader from './SharedContactsTableHeader';
import { visibleSharedJournalistCols } from './Utils/constants';
import { getStrippedRow } from './Utils/functions';
import SharedJournalistsTableCell from './SharedJournalistsTableCell';
import { SharedJournalistsTableProps } from './Types/componentProps';

const SharedJournalistsTable = (props: SharedJournalistsTableProps) => {
  const { contactsData, page, pageSize, onPageChange, onPageSizeChange } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <SharedContactsTableHeader columns={visibleSharedJournalistCols} />
          <TableBody>
            {contactsData.contacts.map((contact, index) => (
              <TableRow key={contact.id} sx={{ backgroundColor: getStrippedRow(index) }}>
                {visibleSharedJournalistCols.map((col) => (
                  <SharedJournalistsTableCell key={col} col={col} contact={contact} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={contactsData.totalCount}
        onPageChange={(_event, page) => onPageChange(page)}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100, 150]}
        onRowsPerPageChange={(event) => onPageSizeChange(parseInt(event.target.value, 10))}
      />
    </>
  );
};

export default SharedJournalistsTable;
