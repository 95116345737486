import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ConfirmRespondGroupInvitationDialogProps } from './Types/componentProps';
import { LoadingButton } from '@mui/lab';
import { useRespondCollaborationGroupInvitationMutation } from './Hooks/useCollaborationGroupInvitation';
import { useQueryClient } from '@tanstack/react-query';

const ConfirmRespondGroupInvitationDialog: React.FC<ConfirmRespondGroupInvitationDialogProps> = ({
  toggleDialog,
  respondType,
  invitation,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const respondInvitationMutation = useRespondCollaborationGroupInvitationMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group/invitation/inbox'],
      });
    },
  });

  const dialogTitle = `Confirm ${respondType} invitation from ${invitation.sender.organizationName} (${invitation.sender.organizationCode})`;

  const getDialogContent = () => {
    if (respondType === 'accept') {
      return (
        <Alert color="info">
          By accepting this invitation, you will join the group as a collaborator. You can view shared services/media
          interactions/contacts. Admin users can add filters to share resources.
        </Alert>
      );
    } else {
      return (
        <Alert color="warning">
          By rejecting this invitation, you will not join the group. If you want to join the group in future, please
          contact the invitation sending Group Lead to resend an invitation.
        </Alert>
      );
    }
  };

  const handleConfirmClick = async () => {
    await respondInvitationMutation.mutateAsync({
      groupId: invitation.group._id,
      invitationId: invitation._id,
      data: {
        type: respondType,
      },
    });
    onClose();
  };

  return (
    <Dialog open={toggleDialog} data-cy="collaboration-invitation-inbox-confirm-respond-dialog">
      <DialogTitle data-cy="collaboration-invitation-inbox-confirm-respond-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose()}
          data-cy="collaboration-invitation-inbox-confirm-respond-dialog-actions-cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={respondInvitationMutation.isPending}
          onClick={() => handleConfirmClick()}
          data-cy="collaboration-invitation-inbox-confirm-respond-dialog-actions-confirm-btn"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRespondGroupInvitationDialog;
