import { API } from '../constants/constants';
export const getServicesReport = async (query: string) => {
  try {
    const response = await API.get(`/services/reports/filter?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    const error: Error = e as Error;
    return { error: 1, message: error.message };
  }
};

export const getIssues = async (query:string) => {
  try {
    const response = await API.get(`/issues/filter?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    const error: Error = e as Error;
    return { error: 1, message: error.message };
  }
};


export const sendEmail = async (query: any) => {
  try {
    const response = await API.post(`/sendmail`, query);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    const error: Error = e as Error;
    return { error: 1, message: error.message };
  }
};
