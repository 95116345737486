import { AutoTrackerKeys, IssueAndServiceSharedValues, IssueKeys } from '../constants/IssueAndServiceKeys';

export const processFromDBForAutoComplete = (object, keys, dropdownTable) => {
  const objectCopy = JSON.parse(JSON.stringify(object));

  Object.keys(objectCopy).forEach((key) => {
    if (keys[key] && object[key]) {
      objectCopy[keys[key]] = object[key];
      delete objectCopy[key];
    }
  });

  if (objectCopy[IssueAndServiceSharedValues.teamMembers]) {
    objectCopy[IssueAndServiceSharedValues.teamMembers] = dropdownTable['lead'].find((member) => {
      return member._id === objectCopy[IssueAndServiceSharedValues.teamMembers];
    });
  }

  return objectCopy;
};

export const processFromAIScrapeForAutoComplete = (object, message, keys, dropdownTable, user = null) => {
  const objectCopy = JSON.parse(JSON.stringify(object));

  // TODO find a better way to match these and handle the appropriate cases
  Object.keys(keys).forEach((key) => {
    if (keys[key] && object[keys[key]]) {
      if (key === 'date') {
        // Setting the field to this date at the current time
        const date = new Date(object[keys[key]]?.replace('-', '/'));
        const currentDate = new Date();
        date.setHours(
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds(),
          currentDate.getMilliseconds()
        );
        objectCopy[key] = date;
      } else if (key === 'topic') {
        objectCopy[key] = object[keys[key]];
      } else if (key === 'journalist') {
        const journalistName = object[keys[key]]?.name || object[keys[key]];
        objectCopy[key] =
          dropdownTable[key].find((value) => {
            const match = value.name === journalistName;
            if (object['Outlet']) {
              return !!match && objectCopy['outlet'] === value.Org;
            }
            return !!match;
          }) || null;
      } else if (key === 'outlet') {
        // TODO extract this
        const outletName = object[keys[key]]?.name || object[keys[key]];
        const match = dropdownTable[key].find((value) => {
          return value.name === outletName;
        })?._id;
        if (match) {
          objectCopy[key] = match;
        }
      } else if (key === 'dept' || key === 'units') {
        const nameValue = object[keys[key]]?.name || object[keys[key]];
        const match = dropdownTable[key].find((value) => {
          return value.name === nameValue;
        })?.name;
        if (match) {
          objectCopy[key] = [match];
        }
      } else if (key === 'status' || key === 'contactMethod') {
        objectCopy[key] =
          dropdownTable[key].find((value) => {
            const matches = value.name.toLowerCase().match(object[keys[key]].toLowerCase());
            return !!matches?.length;
          })?.name || null;
      } else if (key === 'type') {
        const typesArray = object[keys[key]].split(',').map((type) => type.trim());
        objectCopy[key] = typesArray
          .map((type) => {
            return (
              dropdownTable[key].find((value) => {
                const matches = value.name.toLowerCase().match(type.toLowerCase());
                return !!matches?.length;
              }) || null
            );
          })
          .filter((type) => type);
      } else if (key === 'expert') {
        const matches = object[keys[key]]
          ?.map((expert) => {
            return dropdownTable[key].find((value) => {
              return value.name === (expert.name || expert);
            });
          })
          .filter(Boolean);
        if (matches) {
          objectCopy[key] = matches;
        }
      } else {
        const match = dropdownTable[key].find((value) => {
          const matches = value.name.toLowerCase().match(object[keys[key]].toLowerCase());
          return !!matches?.length;
        });
        objectCopy[key] = match ? [match] : null;
      }
    }

    if (key === 'response') {
      objectCopy[key] = message;
    }
    delete objectCopy[keys[key]];
  });

  if (user) {
    const teamMember = dropdownTable['lead'].find((member) => member._id === user.id);
    if (Object.keys(keys).includes('journalist')) {
      objectCopy['lead'] = teamMember ? [dropdownTable['lead'].find((member) => member._id === user.id)] : null;
    } else {
      objectCopy['teamMember'] = teamMember ? [dropdownTable['lead'].find((member) => member._id === user.id)] : null;
    }
  }
  return objectCopy;
};

export const processFromResultForAIScrape = (result, fields, dropdownTable) => {
  Object.keys(result).forEach((key) => {
    switch (key) {
      case IssueKeys.contactMethod:
        result[key] = fields.contactMethod;
        break;
      case IssueAndServiceSharedValues.date:
        result[key] = fields.date.toLocaleDateString();
        break;
      case IssueAndServiceSharedValues.department: {
        const dept = dropdownTable['dept'].find((value) => value.name === fields.dept?.[0]);
        if (dept) {
          result[key] = dept;
        }
        break;
      }
      case IssueKeys.journalist: {
        const journalist = dropdownTable['journalist'].find((value) => value.name === fields.journalist?.name);
        if (journalist) {
          result[key] = journalist;
          result[AutoTrackerKeys.email] = journalist.email;
          result[AutoTrackerKeys.phone] = journalist.phone;
        }
        break;
      }
      case IssueKeys.outlet: {
        const outlet = dropdownTable['outlet'].find((value) => value._id === fields.journalist?.Org);
        if (outlet) {
          result[key] = outlet;
        }
        break;
      }
      case IssueAndServiceSharedValues.status:
        result[key] = fields.status;
        break;
      case IssueKeys.topic:
        result[key] = fields.topic;
        break;
      case IssueAndServiceSharedValues.type:
        result[key] = fields.type.map((t) => t.name).join(', ');
        break;
      case IssueAndServiceSharedValues.unit: {
        const unit = dropdownTable['units'].find((value) => value.name === fields.units?.[0]);
        if (unit) {
          result[key] = unit;
        }
        break;
      }
      case IssueAndServiceSharedValues.leadExpert: {
        result[key] = fields.expert
          ?.map((expert) => {
            return dropdownTable['expert'].find((value) => value.name === expert.name);
          })
          .filter(Boolean);
        break;
      }
      default:
        break;
    }
  });
  return result;
};

export const processCellsForAutoComplete = (object, dropdownTable, id) => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  if (objectCopy[IssueAndServiceSharedValues.teamMembers]) {
    objectCopy[IssueAndServiceSharedValues.teamMembers] = dropdownTable['lead'].find((member) => {
      return member._id === objectCopy[IssueAndServiceSharedValues.teamMembers];
    });
  }
  objectCopy._id = id;
  return objectCopy;
};
