import { createSlice } from '@reduxjs/toolkit';

export const termsOfServiceDialogSlice = createSlice({
  name: 'termsOfServiceDialog',
  initialState: {
    isOpen: false,
    docName: '',
    version: '',
    htmlString: '',
  },
  reducers: {
    openTermsOfServiceDialog: (state, _action) => {
      state.isOpen = true;
    },
    closeTermsOfServiceDialog: (state, _action) => {
      state.isOpen = false;
    },
    setTermsOfService: (state, action) => {
      state.docName = action.payload.docName;
      state.version = action.payload.version;
      state.htmlString = action.payload.htmlString;
    },
  },
});

export const { openTermsOfServiceDialog, closeTermsOfServiceDialog, setTermsOfService } =
  termsOfServiceDialogSlice.actions;

export default termsOfServiceDialogSlice.reducer;
