// Check if each value in the filter object is undefined or empty
function isFilterEmpty(filter) {
  for (const key in filter) {
    if (filter[key] !== undefined) {
      return false;
    }
  }
  return true;
}

export default isFilterEmpty;
