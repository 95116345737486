import { Box, Button, ListItem, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CollaborationGroupInvitationsListProps } from './Types/componentProps';
import { useResendCollaborationGroupInvitationMutation } from './Hooks/useCollaborationGroupInvitation';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import SendCollaborationGroupInvitationDialog from './SendCollaborationGroupInvitationDialog';
import CollaborationGroupInvitationsListItemsWrapper from './CollaborationGroupInvitationsListItemsWrapper';
import CollaborationGroupInvitationsListItem from './CollaborationGroupInvitationsListItem';
import { LoadingButton } from '@mui/lab';
import { CollaborationGroupInvitation } from './Types/apiData';
import ConfirmCancelCollaborationGroupInvitationDialog from './ConfirmCancelCollaborationGroupInvitationDialog';
import { listCollaborationGroupInvitations } from './Requests/CollaborationGroupInvitationRequests';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  listActionsItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listActionsItemContainer: {
    display: 'flex',
    gap: '0.8rem',
  },
});

const CollaborationGroupInvitationsList: React.FC<CollaborationGroupInvitationsListProps> = () => {
  const classes = useStyles();

  const { selectedGroupId } = useContext(SelectedCollaborationGroupIdContext);

  const queryClient = useQueryClient();

  const { isFetching: fetchInvitationLoading, data: invitations } = useQuery({
    queryKey: ['collaboration/group/invitations'],
    queryFn: () => listCollaborationGroupInvitations(selectedGroupId),
    enabled: selectedGroupId !== '',
    refetchOnWindowFocus: false,
  });

  const resendInvitationMutation = useResendCollaborationGroupInvitationMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/invitations'] });
    },
  });

  const [toggleSendInvitationDialog, setToggleSendInvitationDialog] = useState<boolean>(false);
  const [toggleConfirmCancelInvitationDialog, setToggleConfirmCancelInvitationDialog] = useState<boolean>(false);
  const [invitationToCancel, setInvitationToCancel] = useState<CollaborationGroupInvitation | null>(null);

  useEffect(() => {
    if (selectedGroupId !== '') {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/invitations'] });
    }
  }, [selectedGroupId, queryClient]);

  const handleCloseSendInvitationDialog = () => {
    setToggleSendInvitationDialog(false);
  };

  const handleResendInvitatonButtonClick = (invitationId: string) => {
    resendInvitationMutation.mutateAsync({
      groupId: selectedGroupId,
      invitationId,
    });
  };

  const handleCancelInvitationButtonClick = (invitation: CollaborationGroupInvitation) => {
    setInvitationToCancel(invitation);
    setToggleConfirmCancelInvitationDialog(true);
  };

  const renderListItemActions = (invitation: CollaborationGroupInvitation) => {
    if (invitation.status === 'accepted') {
      return null;
    }
    return (
      <>
        <LoadingButton
          data-cy="collaboration-group-details-invitations-list-invitations-actions-resend-btn"
          variant="outlined"
          loading={resendInvitationMutation.isPending}
          onClick={() => handleResendInvitatonButtonClick(invitation._id)}
        >
          Resend
        </LoadingButton>
        <Button
          data-cy="collaboration-group-details-invitations-list-invitations-actions-cancel-btn"
          variant="outlined"
          color="error"
          onClick={() => handleCancelInvitationButtonClick(invitation)}
        >
          Cancel
        </Button>
      </>
    );
  };

  return (
    <>
      <ListItem
        key="group-invitations-title"
        className={classes.listActionsItem}
        data-cy="collaboration-group-details-invitations-list-actions-list-item"
      >
        <Typography variant="h6">Manage Group Invitations</Typography>
        <Box className={classes.listActionsItemContainer}>
          <Button
            data-cy="collaboration-group-details-invitations-list-actions-invite-btn"
            variant="outlined"
            onClick={() => setToggleSendInvitationDialog(true)}
            disabled={fetchInvitationLoading}
          >
            Invite Collaborator
          </Button>
        </Box>
      </ListItem>
      <CollaborationGroupInvitationsListItemsWrapper
        isLoading={fetchInvitationLoading}
        invitations={invitations ? invitations : []}
      >
        {invitations?.map((invitation) => (
          <CollaborationGroupInvitationsListItem
            key={invitation._id}
            invitation={invitation}
            itemActions={renderListItemActions(invitation)}
          />
        ))}
      </CollaborationGroupInvitationsListItemsWrapper>
      <SendCollaborationGroupInvitationDialog
        groupId={selectedGroupId}
        toggleDialog={toggleSendInvitationDialog}
        invitations={invitations ? invitations : []}
        handleCancel={handleCloseSendInvitationDialog}
      />
      <ConfirmCancelCollaborationGroupInvitationDialog
        groupId={selectedGroupId}
        toggleDialog={toggleConfirmCancelInvitationDialog}
        invitation={invitationToCancel}
        onClose={() => {
          setInvitationToCancel(null);
          setToggleConfirmCancelInvitationDialog(false);
        }}
      />
    </>
  );
};

export default CollaborationGroupInvitationsList;
