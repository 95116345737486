import React from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { Autocomplete } from '@mui/material';
import IconDisplay from './IconDisplay';

function FilterField({filter, classes, filters, setFilters, options, textFiltersHelpText, filterIcon, multiple }) {
  return (
    <Box>
      <Typography className={classes.filterTitle}>{filter}</Typography>
      <Autocomplete
        id={`select-filter-${filter}`}
        options={options.sort((a, b) => -b?.firstLetter?.localeCompare(a?.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.name}
        value={filters[filter]}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onChange={(_, v) =>
          setFilters({
            ...filters,
            [filter]: v,
          })
        }
        multiple={multiple ? true : false}
        variant="outlined"
        classes={{ groupLabel: classes.groupLabel }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              helperText={textFiltersHelpText[filter]}
              id={`${filter}-filter-input`}
              InputProps={
                filterIcon[filter] && {
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start" fontSize="20px">
                        <IconDisplay filter={filter} filterIcon={filterIcon} />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }
              }
              className={classes.filterInput}
              FormHelperTextProps={{ className: classes.helpText }}
            />
          );
        }}
      />
    </Box>
  );
}

export default FilterField;
