import { useState } from 'react';
import './ClientAnalytics.css';
import AnalyticsTable from './AnalyticsTable/AnalyticsTable';
import { Spinner } from 'react-bootstrap';
import * as chartAPIRequests from '../../shared/requests/teamAnalyticsChartRequests';
import { chartTypeEnum } from './constants';
import moment from 'moment';
import { Chart } from 'chart.js';
import { useDropdownTable } from '../../shared/hooks/useDropdownTable';

function TeamAnalytics() {
  const { data } = useDropdownTable();
  const dropdownTable = data ?? 'loading';

  const now = moment().endOf('day');
  const defaultQuery = `endDate=${now.toISOString()}`;
  const defaultDateQuery = `year=${now.format('yyyy')}`;

  const [dateQuery, setDateQuery] = useState(defaultDateQuery);
  const [dateServiceQuery, setDateServiceQuery] = useState(defaultDateQuery);

  const [dateMI, setDateMI] = useState(now);
  const [dateService, setDateService] = useState(now);

  const changeYearMI = (newDate) => {
    setDateMI(newDate);
    setDateQuery(`year=${newDate.format('yyyy')}`);
  };

  const changeYearService = (newDate) => {
    setDateService(newDate);
    setDateServiceQuery(`year=${newDate.format('yyyy')}`);
  };

  const returnChartsArrayData = () => {
    return [
      {
        title: 'Total Media Interactions',
        plotly: 0,
        networkRequest: chartAPIRequests.getMediaInteractionsChart,
      },
      {
        title: 'Issue Response Media Interactions',
        plotly: 0,
        type: chartTypeEnum.barVertical,
        networkRequest: chartAPIRequests.getIssueResponseMIChart,
      },
      {
        title: 'Team Driven Media Interactions',
        plotly: 0,
        type: chartTypeEnum.barPercentage,
        networkRequest: chartAPIRequests.getTeamDrivenMIChart,
      },
      {
        title: 'Institutional Story Media Interactions',
        plotly: 0,
        type: chartTypeEnum.barVertical,
        networkRequest: chartAPIRequests.getInstitutionalStoryMIChart,
      },
      {
        title: 'Proactive Client Media Interactions',
        plotly: 0,
        type: chartTypeEnum.barVertical,
        networkRequest: chartAPIRequests.getProactiveMIChart,
      },
      {
        title: 'Proactive Media Relations Tactics',
        plotly: 0,
        networkRequest: chartAPIRequests.getProactiveMRChart,
      },
      {
        title: 'Total Reactive Services',
        plotly: 0,
        type: chartTypeEnum.barVertical,
        networkRequest: chartAPIRequests.getReactiveServicesChart,
      },
      {
        title: 'Proactive Services',
        plotly: 0,
        networkRequest: chartAPIRequests.getProactiveServicesChart,
      },
      {
        title: 'Issues Complexity',
        plotly: 0,
        type: chartTypeEnum.barVertical,
        networkRequest: chartAPIRequests.getIssueComplexityChart,
      },
      {
        title: `Media Interactions by Campaign (${dateMI.format('yyyy')})`,
        plotly: 0,
        type: chartTypeEnum.barWide,
        networkRequest: chartAPIRequests.getCampaignsMIChart,
      },
      {
        title: `Services by Campaign (${dateService.format('yyyy')})`,
        plotly: 0,
        type: chartTypeEnum.barWide,
        networkRequest: chartAPIRequests.getCampaignsServiceChart,
      },
    ];
  };

  const returnTablesDataArray = () => {
    return [];
  };
  window.addEventListener('beforeprint', () => {
    for (let id in Chart.instances) {
      Chart.instances[id].resize(600, 240);
    }
  });
  window.addEventListener('afterprint', () => {
    for (let id in Chart.instances) {
      Chart.instances[id].resize();
    }
  });

  return (
    <div className="analytics-wrapper">
      {dropdownTable !== 'loading' ? (
        <div className={'analytics-charts'}>
          <AnalyticsTable
            chartDataArray={returnChartsArrayData()}
            tableDataArray={returnTablesDataArray()}
            query={defaultQuery}
            dateMI={dateMI}
            changeYearMI={changeYearMI}
            dateService={dateService}
            changeYearService={changeYearService}
            dateQuery={dateQuery}
            dateServiceQuery={dateServiceQuery}
          ></AnalyticsTable>
        </div>
      ) : (
        <Spinner animation="border"></Spinner>
      )}
    </div>
  );
}

export default TeamAnalytics;
