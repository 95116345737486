import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  toggleArchiveDepartment,
  toggleArchiveUnit,
  toggleArchiveCampaign,
  toggleArchiveType,
} from '../adminPageRequest';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import { IssueAndServiceSharedValues } from '../../../shared/constants/IssueAndServiceKeys';

export const useArchiveItem = (enumItem, itemType) => {
  const dispatch = useDispatch();
  // use the itemType to get the corrsponding archiveMethod and queryKeys
  let archiveItemMethod;
  let archiveItemParam;
  let queryKeys;
  switch (itemType) {
    case IssueAndServiceSharedValues.department:
      archiveItemMethod = toggleArchiveDepartment;
      archiveItemParam = enumItem?.name;
      queryKeys = ['depts', 'adminPage'];
      break;
    case IssueAndServiceSharedValues.unit:
      archiveItemMethod = toggleArchiveUnit;
      archiveItemParam = enumItem?.name;
      queryKeys = ['units', 'adminPage'];
      break;
    case IssueAndServiceSharedValues.campaign:
      archiveItemMethod = toggleArchiveCampaign;
      archiveItemParam = enumItem?.name;
      queryKeys = ['campaigns', 'adminPage'];
      break;
    case IssueAndServiceSharedValues.type:
      archiveItemMethod = toggleArchiveType;
      archiveItemParam = enumItem?._id;
      queryKeys = ['types', 'adminPage'];
      break;
    default:
      archiveItemMethod = null;
      queryKeys = ['depts', 'adminPage'];
      break;
  }

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => archiveItemMethod(archiveItemParam),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys);
      dispatch(
        openSnackbar({
          message: `${itemType}: ${enumItem.name} ${enumItem.archived ? 'unarchived' : 'archived'}`,
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      console.log(error.message);
      dispatch(openSnackbar({ message: `${itemType}: ${enumItem.name} failed to be archived`, severity: 'error' }));
    },
  });
};
