export const cellColors = {
  healthThemeStyle: {
    background: '#e6c56c',
  },
  equityStyle: {
    background: '#d2a9f0',
  },
  sustainabilityStyle: {
    background: '#5cb5a6',
  },

  statusCompleted: {
    background: '#e1efd9',
  },
  statusParticipation: {
    background: '#8fd1eb',
  },
  statusProgress: {
    background: '#FFDFA0',
  },
  statusCancelled: {
    background: 'lightgrey',
  },
  reconciliationStyle: {
    background: '#59b7e3',
  },

  teachingAndLearningStyle: {
    background: '#f7c87c',
  },
  drivenYes: {
    background: '#36d67e',
  },
  typeStyle: {
    background: '#f8dee7',
  },
  complexity1: {
    background: '#fff5eb',
  },
  complexity2: {
    background: '#fee6ce',
  },
  complexity3: {
    background: '#fdd0a2',
  },
  complexity4: {
    background: '#fdae6b',
  },
  complexity5: {
    background: '#f7954f',
  },
  issue: {
    background: '#ff9797',
  },
  support: {
    background: '#e1efd9',
  },
};
