import { useEffect, useState } from 'react';
import { Grid, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  getCurrMonth,
  getNextMonth,
  getPrevMonth,
  monthYearToReadableString,
} from '../../shared/functions/dateProcessor';
import FilterListIcon from '@mui/icons-material/FilterList';
import BuildIcon from '@mui/icons-material/Build';
import FilterModal from './FilterModal';
import QueryAdder from '../QueryAdder/QueryAdder';
import NewIssuePopup from '../QueryAdder/NewIssuePopup';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import { API, getTabColorHeaderMain } from '../../shared/constants/constants';
import { needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import { useAtom } from 'jotai';

const useStyles = makeStyles(({ tabColor }) => ({
  button: {
    margin: '0 20px',
    minWidth: '100px',
  },
  newIssueButton: {
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
    minWidth: '190px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    padding: '0.5vh 1vh',
  },
  newIssueButtonMobile: {
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
    minWidth: '60px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    padding: '0.5vh 1vh',
  },
}));

export default function TrackerFilter(props) {
  const { state, pathname } = useLocation();
  const theme = useTheme();
  const { setMonthCallback, setDataCallback, currMonth, dropdownTable, eventHandler, inMediaInteraction } = props;
  const [needRefetchKeywordSearchedData, setNeedRefetchKeywordSearchedData] = useAtom(
    needRefetchKeywordSearchedDataAtom
  );
  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });
  const [monthlyView, setMonthlyView] = useState(true);
  const [filterTags, setFilterTags] = useState(state || {});
  const [filterModal, setFilterModal] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const nextMonth = getNextMonth(currMonth);
  const prevMonth = getPrevMonth(currMonth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  const isCurrentMonth = currMonth === getCurrMonth();

  const handleMonth = (month) => {
    setMonthCallback(month);
  };

  async function globalSearch(state, delayInMilliseconds) {
    if (state && Object.keys(state).length > 0) {
      setDataCallback(null);
      let domain;
      inMediaInteraction ? (domain = 'issues') : (domain = 'services');

      // There is a race condition that happens when you reload the table (by updating/deleting/adding issues/services) while
      // keyword filters are on. You can add a delay in that case to minimize occurances of that race condition.
      // Note: if delay is 0, we remove setTimeout immediately because setTimeout(()=>{}, 0) still has delay.
      if (delayInMilliseconds > 0) {
        setTimeout(async () => {
          await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
            .then((res) => {
              const filteredIssues = res.data.data || null;

              setDataCallback(filteredIssues);
            })
            .catch((err) => {
              alert(err);
              setDataCallback(null);
            });
        }, delayInMilliseconds);
      } else {
        await API.get(`/${domain}/fuzzySearch?queryString=${state.queryString}&category=${state.category}`)
          .then((res) => {
            const filteredIssues = res.data.data || null;

            setDataCallback(filteredIssues);
          })
          .catch((err) => {
            alert(err);
            setDataCallback(null);
          });
      }
    }
  }

  useEffect(() => {
    const existingTags = Object.keys(filterTags).filter((ft) => filterTags[ft]);

    if (state?.queryString) {
      globalSearch(state, 0);
      setMonthlyView(false);
    } else if (existingTags.length > 0) {
      setMonthlyView(false);
    } else {
      setMonthlyView(true);
    }
    //ignoring globalSearch in dep array
    //eslint-disable-next-line
  }, [state, filterTags]);

  useEffect(() => {
    if (state?.queryString && needRefetchKeywordSearchedData) {
      globalSearch(state, 1500);
      setMonthlyView(false);
    }
    setNeedRefetchKeywordSearchedData(false);
    //ignoring globalSearch, setNeedRefetchKeywordSearchedData in dep array
    //eslint-disable-next-line
  }, [state, needRefetchKeywordSearchedData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Box
          display="flex"
          flexDirection={isCurrentMonth ? 'row' : isMedium ? 'column' : isMobile ? 'column' : 'row'}
          justifyContent={isCurrentMonth ? 'start' : isMobile ? 'center' : ''}
          alignItems="center"
        >
          <Button
            onClick={() => handleMonth(prevMonth)}
            disabled={!monthlyView}
            className={classes.button}
            variant="outlined"
            color="primary"
            style={{ whiteSpace: 'nowrap', minWidth: '190px' }}
          >
            <Typography noWrap>&laquo; {monthYearToReadableString(prevMonth)}</Typography>
          </Button>
          <Button
            onClick={() => setFilterModal(true)}
            disabled={!dropdownTable || state?.queryString}
            color="primary"
            variant="outlined"
            className={classes.button}
            style={{ whiteSpace: 'nowrap', minWidth: '100px' }}
          >
            <FilterListIcon />
            {isMobile ? '' : <Typography>Filters</Typography>}
          </Button>
          {!isCurrentMonth && (
            <Button
              onClick={() => handleMonth(nextMonth)}
              disabled={!monthlyView}
              className={classes.button}
              variant="outlined"
              color="primary"
              style={{ whiteSpace: 'nowrap', minWidth: '190px' }}
            >
              <Typography noWrap>{monthYearToReadableString(nextMonth)} &raquo;</Typography>
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {eventHandler.type === 'ManagerAnalytics' ? null : (
              <Button
                variant="contained"
                className={isMobile ? classes.newIssueButtonMobile : classes.newIssueButton}
                onClick={() => setOpenForm(!openForm)}
                color="primary"
                data-cy="addNewService/Interaction-button-TrackerFilter"
              >
                <AddIcon />
                {isMobile ? (
                  ''
                ) : (
                  <Typography>
                    {eventHandler.type === 'MediaInteraction' ? 'New interaction' : 'New service'}
                  </Typography>
                )}
              </Button>
            )}
            <Button
              onClick={() => setInProgress(true)}
              disabled={!dropdownTable || state?.queryString}
              color="primary"
              variant="outlined"
              className={classes.button}
              style={{ whiteSpace: 'nowrap', minWidth: isMobile ? '100px' : '250px' }}
            >
              <BuildIcon style={{ marginRight: 10 }} />
              {isMobile ? <Typography>In Progress</Typography> : <Typography>Entries In Progress</Typography>}
            </Button>
          </Box>
          <NewIssuePopup openForm={openForm} setOpenForm={setOpenForm} title={eventHandler.newItemModalTitle}>
            <QueryAdder
              dropdownTable={dropdownTable}
              currMonth={currMonth}
              setOpenForm={setOpenForm}
              eventHandler={eventHandler}
            />
          </NewIssuePopup>
          <FilterModal
            open={filterModal}
            setModalOpen={setFilterModal}
            setDataCallback={setDataCallback}
            eventHandler={eventHandler}
            dropdownTable={{
              ...dropdownTable,
              unitHash: dropdownTable?.dropdowns?.unit,
            }}
            setMonthlyView={setMonthlyView}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            currMonth={currMonth}
            inProgress={inProgress}
            inProgressCallback={setInProgress}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
