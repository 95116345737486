import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { AddNewOrUpdateCollaborationGroupDialogProps } from './Types/componentProps';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreateCollaborationGroupMutation,
  useUpdateCollaborationGroupMutation,
} from './Hooks/useCollaborationGroup';
import { CreateOrUpdateCollaborationGroupReqData } from './Types/apiData';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useContext } from 'react';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  dialogContentContainer: {
    paddingTop: '0.5rem',
  },
  formTextField: {
    width: '100%',
  },
});

const AddNewCollaborationGroupDialog: React.FC<AddNewOrUpdateCollaborationGroupDialogProps> = ({
  type,
  toggleDialog,
  handleCloseDialog,
}) => {
  const classes = useStyles();

  const formSchema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
    },
  });

  const { selectedGroupId, dispatch: dispatchSelectedCollaborationGroupId } = useContext(
    SelectedCollaborationGroupIdContext
  );

  const queryClient = useQueryClient();
  const groupCreateMutation = useCreateCollaborationGroupMutation({
    onSuccessCallback: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/groups'],
      });
      dispatchSelectedCollaborationGroupId({ type: 'UPDATE_SELECT_GROUP_ID', payload: data.group._id });
      handleCancelDialog();
    },
  });

  const groupUpdateMutation = useUpdateCollaborationGroupMutation({
    onSuccessCallback: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
      queryClient.invalidateQueries({
        queryKey: ['collaboration/groups'],
      });
      dispatchSelectedCollaborationGroupId({ type: 'UPDATE_SELECT_GROUP_ID', payload: data._id });
      handleCancelDialog();
    },
  });

  const handleCancelDialog = () => {
    reset();
    handleCloseDialog();
  };

  const onSubmit: SubmitHandler<CreateOrUpdateCollaborationGroupReqData> = (data) => {
    if (type === 'create') {
      groupCreateMutation.mutateAsync(data);
    } else if (type === 'update') {
      groupUpdateMutation.mutateAsync({
        data,
        groupId: selectedGroupId,
      });
    }
  };

  let dialogTitle;
  let dialogActions;
  if (type === 'create') {
    dialogTitle = 'Add New Collaboration Group';
    dialogActions = (
      <DialogActions>
        <Button
          disabled={groupCreateMutation.isPending}
          onClick={() => handleCancelDialog()}
          data-cy="collaboration-groups-list-add-update-group-dialog-cancel-add-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={groupCreateMutation.isPending}
          data-cy="collaboration-groups-list-add-update-group-dialog-add-btn"
        >
          Add
        </LoadingButton>
      </DialogActions>
    );
  } else if (type === 'update') {
    dialogTitle = 'Update Collaboration Group';
    dialogActions = (
      <DialogActions>
        <Button
          disabled={groupUpdateMutation.isPending}
          onClick={() => handleCancelDialog()}
          data-cy="collaboration-groups-list-add-update-group-dialog-cancel-update-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={groupUpdateMutation.isPending}
          data-cy="collaboration-groups-list-add-update-group-dialog-update-btn"
        >
          Update
        </LoadingButton>
      </DialogActions>
    );
  }

  return (
    <Dialog open={toggleDialog} data-cy="collaboration-groups-list-add-update-group-dialog">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Stack className={classes.dialogContentContainer}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  data-cy="collaboration-groups-list-add-update-group-dialog-form-name"
                  className={classes.formTextField}
                  label="Group name"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        {dialogActions}
      </form>
    </Dialog>
  );
};

export default AddNewCollaborationGroupDialog;
