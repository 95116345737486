import {
  CollaborationGroupDetailsActionsProps,
  DisbandOrQuitCollaborationGroupDialogType,
} from './Types/componentProps';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import DisbandOrQuitCollaborationGroupDialog from './DisbandOrQuitCollaborationGroupDialog';
import UpdateCollaborationGroupDialog from './AddNewOrUpdateCollaborationGroupDialog';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  actionsContainers: {
    display: 'flex',
    gap: '1rem',
  },
});

const CollaborationGroupDetailsActions: React.FC<CollaborationGroupDetailsActionsProps> = ({ groupOwners }) => {
  const classes = useStyles();

  const user = useSelector<StoreState, UserState>((state) => state.user);
  const isGroupOwner = groupOwners.includes(user.userInfo.tenantId);
  const isLastGroupOwner = groupOwners.length === 1;

  const [toggleDisbandOrQuitDialog, setToggleDisbandOrQuitDialog] = useState<boolean>(false);
  const [disbandOrQuitDialogType, setDisbandOrQuitDialogType] =
    useState<DisbandOrQuitCollaborationGroupDialogType>('quit');
  const [toggleUpdateGroupDialog, setToggleUpdateGroupDialog] = useState<boolean>(false);

  const handleDisbandGroupButtonClick = (type: DisbandOrQuitCollaborationGroupDialogType) => {
    setDisbandOrQuitDialogType(type);
    setToggleDisbandOrQuitDialog(true);
  };

  const handleCloseUpdateGroupDialog = () => {
    setToggleUpdateGroupDialog(false);
  };

  if (isGroupOwner && isLastGroupOwner) {
    return (
      <Box className={classes.actionsContainers}>
        <Button
          data-cy="collaboration-group-details-edit-btn"
          variant="outlined"
          onClick={() => {
            setToggleUpdateGroupDialog(true);
          }}
        >
          Edit
        </Button>
        <UpdateCollaborationGroupDialog
          type="update"
          toggleDialog={toggleUpdateGroupDialog}
          handleCloseDialog={handleCloseUpdateGroupDialog}
        />
        <Button
          data-cy="collaboration-group-details-disband-btn"
          variant="contained"
          color="error"
          onClick={() => handleDisbandGroupButtonClick('disband')}
        >
          Disband Group
        </Button>
        <DisbandOrQuitCollaborationGroupDialog
          type={disbandOrQuitDialogType}
          toggleDialog={toggleDisbandOrQuitDialog}
          onDialogClose={() => setToggleDisbandOrQuitDialog(false)}
        />
      </Box>
    );
  } else if (isGroupOwner && !isLastGroupOwner) {
    return (
      <Box className={classes.actionsContainers}>
        <Button
          data-cy="collaboration-group-details-edit-btn"
          variant="outlined"
          onClick={() => {
            setToggleUpdateGroupDialog(true);
          }}
        >
          Edit
        </Button>
        <UpdateCollaborationGroupDialog
          type="update"
          toggleDialog={toggleUpdateGroupDialog}
          handleCloseDialog={handleCloseUpdateGroupDialog}
        />
        <Button
          data-cy="collaboration-group-details-disband-btn"
          variant="outlined"
          color="error"
          onClick={() => handleDisbandGroupButtonClick('disband')}
        >
          Disband Group
        </Button>
        <Button
          data-cy="collaboration-group-details-quit-btn"
          variant="contained"
          color="error"
          onClick={() => handleDisbandGroupButtonClick('quit')}
        >
          Quit Group
        </Button>
        <DisbandOrQuitCollaborationGroupDialog
          type={disbandOrQuitDialogType}
          toggleDialog={toggleDisbandOrQuitDialog}
          onDialogClose={() => setToggleDisbandOrQuitDialog(false)}
        />
      </Box>
    );
  } else {
    return (
      <>
        <Button
          data-cy="collaboration-group-details-quit-btn"
          variant="contained"
          color="error"
          onClick={() => handleDisbandGroupButtonClick('quit')}
        >
          Quit Group
        </Button>
        <DisbandOrQuitCollaborationGroupDialog
          type={disbandOrQuitDialogType}
          toggleDialog={toggleDisbandOrQuitDialog}
          onDialogClose={() => setToggleDisbandOrQuitDialog(false)}
        />
      </>
    );
  }
};

export default CollaborationGroupDetailsActions;
