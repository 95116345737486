import { Button } from '@mui/material';
import ToggleCollaborationGroupSharedContactsDialog from './ToggleCollaborationGroupSharedContactsDialog';
import { useState } from 'react';
import { shareContactType } from './Utils/constants';
import { ManageCollaborationGroupSharedJournalistActionsProps } from './Types/componentProps';

const ManageCollaborationGroupSharedJournalistActions = (
  props: ManageCollaborationGroupSharedJournalistActionsProps
) => {
  const { toggledOn } = props;
  const [openToggleSharedJournalistsDialog, setOpenToggleSharedJournalistsDialog] = useState<boolean>(false);

  const handleOpenDialog = () => setOpenToggleSharedJournalistsDialog(true);

  let actionButton;
  if (toggledOn) {
    actionButton = (
      <Button variant="contained" color="error" onClick={() => handleOpenDialog()}>
        Toggle Off
      </Button>
    );
  } else {
    actionButton = (
      <Button variant="outlined" color="primary" onClick={() => handleOpenDialog()}>
        Toggle On
      </Button>
    );
  }

  return (
    <>
      {actionButton}
      <ToggleCollaborationGroupSharedContactsDialog
        open={openToggleSharedJournalistsDialog}
        toggledOn={toggledOn}
        contactType={shareContactType.journalist}
        onClose={() => setOpenToggleSharedJournalistsDialog(false)}
      />
    </>
  );
};

export default ManageCollaborationGroupSharedJournalistActions;
