import { Box, ImageListItem, Switch, Typography } from '@mui/material';
import { TenantAddonControlFields } from '../../shared/constants/TenantFields';

function TenantAddonControlListItems({ isEditing, client, onClientAddonControlChange }) {
  return TenantAddonControlFields.map((field) => {
    let listItemContent = null;
    if (isEditing) {
      listItemContent = (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Switch checked={client[field.field]} onChange={(e) => onClientAddonControlChange(e, field)} />
          <Typography>{client[field.field] ? 'Activated' : 'Deactivated'}</Typography>
        </Box>
      );
    } else {
      listItemContent = <Typography>{client[field.field] ? 'Activated' : 'Deactivated'}</Typography>;
    }
    return (
      <ImageListItem key={`tenant-addon-control-${field.field}`}>
        <Typography variant="h6" fontWeight="bold">
          {field.description}
        </Typography>
        {listItemContent}
      </ImageListItem>
    );
  });
}

export default TenantAddonControlListItems;
