import moment from 'moment';
import { chartColors } from '../constants/constants';

export function prepareChartData(chartData) {
  const labels = moment.monthsShort(); //short month names from Jan to Dec
  const datasets = chartData.map((yearData, index) => ({
    label: `${yearData.year}`,
    data: Object.values(yearData.data),
    borderColor: chartColors[index],
    backgroundColor: chartColors[index],
  }));

  const data = {
    labels,
    datasets: datasets,
  };
  return data;
}

export function prepareChartCampaignData(chartData) {
  const labels = moment.monthsShort(); //short month names from Jan to Dec
  const datasets = chartData.map((campaignData, index) => {
    return {
      label: `${campaignData.campaign}`,
      data: Object.values(campaignData.data),
      borderColor: campaignData.campaignBackgroundColor
        ? campaignData.campaignBackgroundColor
        : chartColors[index % chartColors.length],
      backgroundColor: campaignData.campaignBackgroundColor
        ? campaignData.campaignBackgroundColor
        : chartColors[index % chartColors.length],
    };
  });

  const data = {
    labels,
    datasets: datasets,
  };
  return data;
}

export function setChartOptions(title) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${title}`,
      },
    },
  };
  return options;
}
function processManagerData(yearData) {
  //Example of yearData: { '2019': { Oct: [Array], Nov: [Array], Dec: [Array] } },
  const dataPerMonth = Object.values(yearData)['0']; //somehow the it comes with 0s as keys
  //console.log(Object.values(dataPerMonth));
  //console.log(typeof dataPerMonth);
  let populatedMonths = {
    Jan: getMonthData('Jan', dataPerMonth),
    Feb: getMonthData('Feb', dataPerMonth),
    Mar: getMonthData('Mar', dataPerMonth),
    Apr: getMonthData('Apr', dataPerMonth),
    May: getMonthData('May', dataPerMonth),
    Jun: getMonthData('Jun', dataPerMonth),
    Jul: getMonthData('Jul', dataPerMonth),
    Aug: getMonthData('Aug', dataPerMonth),
    Sep: getMonthData('Sep', dataPerMonth),
    Oct: getMonthData('Oct', dataPerMonth),
    Nov: getMonthData('Nov', dataPerMonth),
    Dec: getMonthData('Dec', dataPerMonth),
  };
  //console.log("MONTHS",populatedMonths)
  return populatedMonths;
}
function getMonthData(month, yearData) {
  // @EXAMPLE of yearData { Mar: [Array], Jan: [Array], Feb: [Array] } yearData[`${month}`

  if (Array.isArray(yearData[month]) && yearData[month].length > 0) {
    return yearData[month][0].issues; // month data is an array of length 1: Nov: [Array]
  } else {
    return 0; // if there is no data for this month, return  0 issues
  }
}

export function configManagerGraph(perYearData) {
  /**
   * @EXAMPLE of @PERYEARDATA
   * [
  { '2019': { Dec: [Array], Oct: [Array], Nov: [Array] } },
  { '2020': { Mar: [Array], Jan: [Array], Feb: [Array] } },
  { '2021': { Dec: [Array] } },
  { '2022': { Jan: [Array] } }
]
   */
  const labels = moment.monthsShort(); //short month names from Jan to Dec
  const datasets = perYearData.map((yearData, index) => ({
    // @EXAMPLE of yearData  { Mar: [Array], Jan: [Array], Feb: [Array] }

    label: `${Object.keys(yearData)[0]}`, //yearData)[0] to get the only key in the list
    data: Object.values(processManagerData(yearData)),
    borderColor: chartColors[index],
    backgroundColor: chartColors[index],
  }));

  const data = {
    labels,
    datasets: datasets,
  };
  return data;
}
/**
 * @EXAMPLE OF QUERY DATA:
 * [
  { '2019': { Nov: [Array], Dec: [Array], Oct: [Array] } },
  { '2020': { Feb: [Array], Jan: [Array], Mar: [Array] } },
  { '2021': { Dec: [Array] } }
]
 */
