import { API, API_DOMAIN } from '../constants/constants';

export async function agreeTermsOfService(userData) {
  const ret = await API.put(`/user/terms-of-service/${userData.id}/agree`, userData);
  return ret.data || null;
}

export async function fetchTermsOfServiceDocMeta() {
  const ret = await API.get(`/user/terms-of-service/meta`);
  return ret.data || null;
}

export async function fetchTermsOfServiceDoc() {
  const ret = await API.get(`/user/terms-of-service/doc`);
  return ret.data || null;
}

export async function verifyUserChangedEmail(userData) {
  const ret = await API.post(`/user/verify-email-change`, userData);
  return ret.data || null;
}

export async function updateUser(userData) {
  const ret = await API.put(`/user/info/${userData.id}`, userData);
  return ret.data || null;
}

export async function updateUserWithPrivilege(userData) {
  try {
    const ret = await API.put(`/user/withPrivilege/${userData.id}`, userData);
    return ret.data || null;
  } catch (err) {
    return err;
  }
}

export async function loginUser(userData) {
  const res = await fetch(`${API_DOMAIN}/user/login`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });

  const ret = await res.json();

  return ret;
}

export async function getUserMetaDataNonPIUserSettings(userSub) {
  const ret = await API.get(`/user-meta/${userSub}/non-pi`);

  return ret.data || null;
}

export async function updateUserMetaDataNonPIUserSettings(userSub, requestBody) {
  const ret = await API.put(`/user-meta/${userSub}/non-pi`, requestBody);

  return ret.data || null;
}
