export const rowOptions = Object.freeze({
  PROGRESS: 'In Progress',
  YES: 'Yes',
  NO: 'No',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  PARTICIPATION: 'Participation Ribbon',
  HEALTH_INNOVATION: 'Health Innovation and Community Well-being',
  SUSTAINABILITY: 'Sustainability and Climate Action',
  EQUITY: 'Equity & Anti-Racism',
  RECONCILIATION: 'Reconciliation and Indigenous Peoples',
  TEACHING_AND_LEARNING: 'Teaching, Learning and Research Excellence',
  TYPE: 'type',
  COMPLEXITY1: 1,
  COMPLEXITY2: 2,
  COMPLEXITY3: 3,
  COMPLEXITY4: 4,
  COMPLEXITY5: 5,
  ISSUE: "Issue",
  SUPPORT: "Support"
});
