import { useRef } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TableComponent from './TableComponent';
import ChartComponent from './ChartComponent';
import { Chart } from 'chart.js';
import Print from '@mui/icons-material/Print';
import { exportComponentAsJPEG } from 'react-component-export-image';

const useStyles = makeStyles(() => ({
  table: {
    maxHeight: '80vh',
    overflow: 'scroll',
    flexDirection: 'column',
    marginLeft: '1.5vw',
  },
  tableContainer: {
    maxHeight: '50vh',
    height: '100%',
    overflow: 'scroll',
    border: 'solid black',
    borderWidth: '1px',
    borderRadius: '1px',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    width: '90%',
  },
  tableHeader: {
    backgroundColor: '#002145',
    background: '#002145',
    height: '5vh',
    textAlign: 'left',
  },
  text: {
    paddingLeft: '5%',
    paddingTop: '1vh',
    color: '#ffffff',
    fontSize: '100%',
  },
  printButton: {
    margin: '25px',
  },
}));
function AnalyticsTable(props) {
  //Component for arranging in grid and rendering all the table/chart components
  const classes = useStyles();
  const {
    chartDataArray,
    tableDataArray,
    query,
    dateMI,
    changeYearMI,
    dateQuery,
    dateService,
    changeYearService,
    dateServiceQuery,
  } = props;
  const printRef = useRef();

  const printComponent = () => {
    exportComponentAsJPEG(printRef);
  };

  window.addEventListener('beforeprint', () => {
    for (let id in Chart.instances) {
      Chart.instances[id].resize(600, 240);
    }
  });
  window.addEventListener('afterprint', () => {
    for (let id in Chart.instances) {
      Chart.instances[id].resize();
    }
  });

  return (
    <div className={classes.outerContainer} ref={printRef}>
      <div className={classes.gridContainer}>
        <Grid container spacing={2} justifyContent="center" styles={classes.table}>
          {chartDataArray.map((chartData, i) => {
            return (
              <ChartComponent
                key={i}
                chartDataIn={chartData}
                query={query}
                dateMI={dateMI}
                changeYearMI={changeYearMI}
                dateService={dateService}
                changeYearService={changeYearService}
                dateQuery={dateQuery}
                dateServiceQuery={dateServiceQuery}
              ></ChartComponent>
            );
          })}
        </Grid>
        <Grid container spacing={2} justifyContent="center" styles={classes.table}>
          {tableDataArray.map((tableData, i) => {
            return (
              <TableComponent key={i} tableData={tableData} query={query}>
                {' '}
              </TableComponent>
            );
          })}
        </Grid>
        <div>
          <Button variant={'contained'} onClick={printComponent} className={classes.printButton}>
            <Typography>
              <Print />
              Print
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsTable;
