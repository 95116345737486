import React, { createContext, Dispatch, PropsWithChildren, useMemo, useReducer } from 'react';
import { SelectedCollaborationGroupIdAction } from './context-types';

export const SelectedCollaborationGroupIdReducer = (
  state: string,
  action: SelectedCollaborationGroupIdAction
): string => {
  if (action.type === 'UPDATE_SELECT_GROUP_ID') {
    return action.payload;
  } else {
    console.error('unknown selected collaboration group id action type', action.type);
    return state;
  }
};

export const SelectedCollaborationGroupIdContext = createContext<{
  selectedGroupId: string;
  dispatch: Dispatch<SelectedCollaborationGroupIdAction>;
}>({
  selectedGroupId: '',
  dispatch: () => {},
});

const SelectedCollaborationGroupIdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedGroupId, dispatch] = useReducer(SelectedCollaborationGroupIdReducer, '');
  const groupIdContextValue = useMemo(() => ({ selectedGroupId, dispatch }), [selectedGroupId]);

  return (
    <SelectedCollaborationGroupIdContext.Provider value={groupIdContextValue}>
      {children}
    </SelectedCollaborationGroupIdContext.Provider>
  );
};

export default SelectedCollaborationGroupIdProvider;
