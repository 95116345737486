import { Autocomplete, Box, Chip, InputAdornment, TextField, Typography } from '@mui/material';
import FilterModalFilterIcon from './FilterModalFilterIcon';
import { ReactElement, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

interface FilterModalSelectInputProps {
  groupTabColor: string;
  filterName: string;
  helperText?: string;
  icon: ReactElement;
  filterOptions: any[];
  value: string[];
  onChange: (value: any[]) => void;
  groupBy: (option: any) => any;
}

const useStyles = (tabColor: string) => {
  return makeStyles((styles) => ({
    groupLabel: {
      background: tabColor,
      color: styles.palette.primary.contrastText,
      fontWeight: 700,
    },
  }))();
};

const FilterModalSelectInput: React.FC<FilterModalSelectInputProps> = ({
  groupTabColor,
  filterName,
  helperText,
  icon,
  filterOptions,
  value,
  onChange,
  groupBy,
}) => {
  const classes = useStyles(groupTabColor);

  // use `any` type for now as all types of options has _id field, the lead(team member) has _id same as sub
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>(() => {
    return filterOptions.filter((option) => value.includes(option._id) || value.includes(option.name));
  });

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography fontSize={20} fontWeight={600} sx={{ marginTop: '0.5rem' }}>
        {filterName}
      </Typography>
      <Autocomplete
        classes={{ groupLabel: classes.groupLabel }}
        options={filterOptions}
        groupBy={groupBy}
        multiple
        getOptionLabel={(option) => option.name || ''}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option._id}>
            {option.name}
          </Box>
        )}
        value={autoCompleteValue}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />;
          })
        }
        onChange={(_, v) => {
          setAutoCompleteValue(v);
          onChange(v);
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            helperText={helperText ? helperText : ''}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <FilterModalFilterIcon filterName={filterName} filterIcon={icon} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default FilterModalSelectInput;
