import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useContext } from 'react';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCollaborationGroupSharedContacts } from './Requests/CollaborationGroupRequests';
import { ToggleCollaborationGroupSharedContactsDialogProps } from './Types/componentProps';

const ToggleCollaborationGroupSharedContactsDialog = (props: ToggleCollaborationGroupSharedContactsDialogProps) => {
  const { open, onClose, toggledOn, contactType } = props;

  const queryClient = useQueryClient();

  const { selectedGroupId } = useContext(SelectedCollaborationGroupIdContext);

  const toggleCollaborationGroupSharedContactsMutation = useMutation({
    mutationFn: updateCollaborationGroupSharedContacts,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
      onClose();
    },
  });

  const onSave = () => {
    const toggleQuery = toggledOn ? 'off' : 'on';

    toggleCollaborationGroupSharedContactsMutation.mutateAsync({
      groupId: selectedGroupId,
      contactType: contactType,
      toggle: toggleQuery,
    });
  };

  let dialog;
  if (toggledOn) {
    dialog = {
      title: 'Toggle Off Sharing Journalists',
      content: 'By toggling off, journalists of your organization is no longer available to group collaborators.',
    };
  } else {
    dialog = {
      title: 'Toggle On Sharing Journalists',
      content: 'By toggling on, all journalists of your organization will be shared to group collaborators.',
    };
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{dialog.title}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">{dialog.content}</Alert>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={toggleCollaborationGroupSharedContactsMutation.isPending}
          onClick={() => onSave()}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleCollaborationGroupSharedContactsDialog;
