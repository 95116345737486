import React from 'react';
import ChartContainer from './ChartContainer';
import { Typography } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
function NoDataComponent({ title }) {
  return (
    <ChartContainer>
      <Typography>{title}</Typography>
      <Typography>No Data, try using different filters.</Typography>
      <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>
    </ChartContainer>
  );
}

export default NoDataComponent;
