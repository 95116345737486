import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { useEffect, useState, useCallback, useRef } from 'react';

function fuzzySearch(rows, query, category) {
  let fuseOptionsKeys;
  if (category === 'Journalists' || category === 'Experts') {
    fuseOptionsKeys = ['name', 'Org.name', 'unitName', 'notes'];
  } else {
    fuseOptionsKeys = ['name'];
  }
  const options = {
    threshold: 0.2,
    ignoreLocation: true,
    keys: fuseOptionsKeys,
  };
  const fuse = new Fuse(rows, options);
  const results = fuse.search(query).map((result) => result.item);
  return results;
}

const ContactFuzzySearch = (props) => {
  const { contacts, setSearchResults, category } = props;
  const [textFieldValue, setTextFieldValue] = useState('');
  const debouncedHandleSearchRef = useRef(null);

  const handleSearch = useCallback(
    (keywords) => {
      if (keywords !== '') {
        const results = fuzzySearch(contacts, keywords, category);
        const sortedResults = [...results].sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        setSearchResults(sortedResults);
      } else {
        const sortedContacts = [...contacts].sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        setSearchResults(sortedContacts);
      }
    },
    [contacts, category, setSearchResults]
  );

  //set up and take down searching with debounce during component mount/unmount
  useEffect(() => {
    debouncedHandleSearchRef.current = debounce(handleSearch, 350);

    return () => {
      if (debouncedHandleSearchRef.current.cancel) {
        debouncedHandleSearchRef.current.cancel();
      }
    };
  }, [handleSearch]);

  const handleChange = (e) => {
    setTextFieldValue(e.target.value);
    debouncedHandleSearchRef.current(e.target.value);
  };

  //reset search if category changed
  useEffect(() => {
    setTextFieldValue('');
  }, [category]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}
    >
      <SearchIcon />
      <TextField
        variant="standard"
        placeholder={
          category === 'Journalists'
            ? ' Name/Outlet/Notes'
            : category === 'Experts'
            ? ' Name/Unit/Notes'
            : 'Search Outlets...' // default placeholder
        }
        value={textFieldValue}
        onChange={handleChange}
        data-cy="fuzzySearch-input-ContactFuzzySearch"
      />
    </Box>
  );
};

export default ContactFuzzySearch;
