import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';

interface VerificationModalProps {
  openVerificationModal: boolean;
  setOpenVerificationModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  modalDescription: string;
  handleYesClick: React.MouseEventHandler<HTMLButtonElement>;
}

const VerificationModal = (props: VerificationModalProps) => {
  const { openVerificationModal, setOpenVerificationModal, modalTitle, modalDescription, handleYesClick } = props;

  const handleClose = () => {
    setOpenVerificationModal(false);
  };

  return (
    <Dialog
      open={openVerificationModal}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{modalDescription}</Typography>
        <DialogActions>
          <br />
          <Button onClick={handleYesClick} variant="contained" aria-label="Verification-yes-button" data-cy="Verification-yes-button">
            Yes
          </Button>
          <Button onClick={() => setOpenVerificationModal(false)} variant="contained" data-cy="Verification-no-button">
            No
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default VerificationModal;
