import { forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Card, Grid } from '@mui/material';

const useStyles = makeStyles(() => ({
  filterButton: {
    maxWidth: '180px',
    maxHeight: '50px',
    position: 'sticky',
    top: '100px',
  },
  card: {
    border: 'solid #000',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: '#C0C0C0', //gray
    display: 'flex',
    justifyContent: 'center',
  },
  padding: {
    height: '33vh',
    width: '100%',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '4vh',
    paddingBottom: '4vh',
  },
  paddingSmall: {
    width: '100%',
    paddingLeft: '2.5vh',
    paddingRight: '2.5vh',
    paddingTop: '4.5vh',
    paddingBottom: '4.5vh',
  },
  gridItem: {
    position: 'relative',
    width: '100%',
  },
}));

function ChartContainer({ children, small, large }, ref) {
  const classes = useStyles();
  const divClassName = small ? classes.paddingSmall : classes.padding;
  const gridClassName = classes.gridItem;
  const cardClassName = classes.card;

  return (
    <Grid item lg={large ? 12 : 4} sm={large ? 12 : 6} className={gridClassName}>
      <Card className={cardClassName}>
        <div ref={ref} className={divClassName}>
          {children}
        </div>
      </Card>
    </Grid>
  );
}

export default forwardRef(ChartContainer);
