import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ConfirmCancelCollaborationGroupInvitationDialogProps } from './Types/componentProps';
import { LoadingButton } from '@mui/lab';
import { useCancelCollaborationGroupInvitationMutation } from './Hooks/useCollaborationGroupInvitation';
import { useQueryClient } from '@tanstack/react-query';

const ConfirmCancelCollaborationGroupInvitationDialog: React.FC<
  ConfirmCancelCollaborationGroupInvitationDialogProps
> = ({ groupId, toggleDialog, invitation, onClose }) => {
  const queryClient = useQueryClient();
  const cancelInvitationMutation = useCancelCollaborationGroupInvitationMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/invitations'] });
      onClose();
    },
  });

  const handleConfirmButtonClick = (invitationId: string) => {
    cancelInvitationMutation.mutateAsync({
      groupId,
      invitationId,
    });
  };

  if (!invitation) {
    return null;
  }

  const receiverName = `${invitation.receiver.organizationName} (${invitation.receiver.organizationCode})`;

  return (
    <Dialog open={toggleDialog}>
      <DialogTitle>Confirm cancellation of invitation for {receiverName}</DialogTitle>
      <DialogContent>
        <Alert color="warning">
          By cancelling this invitation, {receiverName} will no longer be able to join this group unless your group
          sends a new invitation. For rejected/expired invitation, simply click Resend button to resend the invitation
          to {receiverName}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          loading={cancelInvitationMutation.isPending}
          onClick={() => handleConfirmButtonClick(invitation._id)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancelCollaborationGroupInvitationDialog;
