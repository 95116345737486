import { useState, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { updateType, postNewTypeEnum } from './adminPageRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Tooltip,
  Zoom,
} from '@mui/material';
import { typesImportantForAnalytics } from '../../shared/constants/constants';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles({
  modalCard: {
    padding: '30px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
  },
  textField: {
    width: '100%',
  },
  fieldDivMiddle: {
    paddingTop: '1vh',
    paddingBottom: '1vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  fieldDivTop: {
    paddingTop: '2vh',
    paddingBottom: '1vh',
    borderTop: 'solid gray 1px',
    marginTop: '2vh',
    marginBottom: '1vh',
  },
  fieldDivBottom: {
    paddingTop: '1vh',
    paddingBottom: '2vh',
    borderBottom: 'solid gray 1px',
    marginTop: '1vh',
    marginBottom: '2vh',
  },
  addButton: {
    width: '6vw',
    height: '5vh',
  },
  cancelButton: {
    width: '6vw',
    height: '5vh',
    color: 'white',
    backgroundColor: 'red',
  },
  charCountText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

function AddEnumTypesModal({ open, setOpen, existingItems, editMode, setEditMode, selectedItem }) {
  // Component constants
  const typeNameCharLimit = 35;
  const typeCategoryCharLimit = 15;
  const typeBackgroundColorOptions = ['Red', 'Green', 'None'];
  const typeProactivityOptions = ['Proactive', 'Reactive'];
  const typePermissionsOptions = useMemo(() => {
    return ['User', 'Manager', 'Admin'];
  }, []); //will only run once

  const classes = useStyles();
  const [typeName, setTypeName] = useState('');
  const [typeCategory, setTypeCategory] = useState('');
  const [typeBackgroundColor, setTypeBackgroundColor] = useState('');
  const [typeProactivity, setTypeProactivity] = useState('');
  const [typePermissions, setTypePermissions] = useState([...typePermissionsOptions]);
  const [typeNameInputStatus, setTypeNameInputStatus] = useState({ error: false, message: '' });
  const [typeCategoryInputStatus, setTypeCategoryInputStatus] = useState({
    error: false,
    message: '',
  });
  const [typeBackgroundColorInputStatus, setTypeBackgroundColorInputStatus] = useState({
    error: false,
    message: '',
  });
  const [typeProactivityInputStatus, setTypeProactivityInputStatus] = useState({ error: false, message: '' });
  const dispatch = useDispatch();

  useEffect(() => {
    if (editMode) {
      setTypeName(selectedItem?.name || '');
      setTypeCategory(selectedItem?.type || '');
      setTypeBackgroundColor(selectedItem?.backgroundColor || '');
      setTypeProactivity(selectedItem?.proactivity || '');
      setTypePermissions(selectedItem?.permissions || [...typePermissionsOptions]);
    } else {
      setTypeName('');
      setTypeCategory('');
      setTypeBackgroundColor('');
      setTypeProactivity('');
      setTypePermissions([...typePermissionsOptions]);
    }
  }, [editMode, selectedItem, typePermissionsOptions]);

  const checkEmptyFieldsAndCharacterLength = () => {
    let pass = true;

    if (!typeName) {
      setTypeNameInputStatus({ error: true, message: '' });
      pass = false;
    } else {
      setTypeNameInputStatus({ error: false, message: '' });
    }

    if (!typeCategory) {
      setTypeCategoryInputStatus({ error: true, message: '' });
      pass = false;
    } else {
      setTypeCategoryInputStatus({ error: false, message: '' });
    }

    if (!typeBackgroundColor) {
      setTypeBackgroundColorInputStatus({ error: true, message: '' });
      pass = false;
    } else {
      setTypeBackgroundColorInputStatus({ error: false, message: '' });
    }

    if (!typeProactivity) {
      setTypeProactivityInputStatus({ error: true, message: '' });
      pass = false;
    } else {
      setTypeProactivityInputStatus({ error: false, message: '' });
    }

    return pass;
  };

  const checkDuplicates = () => {
    let numOfDuplicates = 0;
    if (editMode) {
      existingItems.forEach((item) => {
        if (item.name.toLowerCase() === typeName.toLowerCase() && item._id !== selectedItem._id) {
          numOfDuplicates += 1;
        }
      });
    } else {
      existingItems.forEach((item) => {
        if (item.name.toLowerCase() === typeName.toLowerCase()) {
          numOfDuplicates += 1;
        }
      });
    }

    if (numOfDuplicates > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${numOfDuplicates} type(s) with the same name already exists. Please check if you are trying to add a type that already exists.`,
        })
      );
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!checkEmptyFieldsAndCharacterLength()) return;

    if (!checkDuplicates()) return;

    if (editMode) {
      await updateType(selectedItem._id, {
        typeName: typeName,
        typeCategory: typeCategory,
        typeBackgroundColor: typeBackgroundColor,
        typeProactivity: typeProactivity,
        typePermissions: typePermissions,
      })
        .then((res) => {
          setOpen(false);
          setEditMode(false);
          dispatch(openSnackbar({ message: `Type updated successfully`, severity: 'success' }));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(openSnackbar({ message: `Failed to update type`, severity: 'error' }));
        });
    } else {
      await postNewTypeEnum({
        typeName: typeName,
        typeCategory: typeCategory,
        typeBackgroundColor: typeBackgroundColor,
        typeProactivity: typeProactivity,
        typePermissions: typePermissions,
      })
        .then((res) => {
          setOpen(false);
          dispatch(openSnackbar({ message: `Type added successfully`, severity: 'success' }));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(openSnackbar({ message: `Failed to add type`, severity: 'error' }));
        });
    }
  };

  const queryClient = useQueryClient();
  const itemMutation = useMutation({
    mutationFn: (e) => onSubmit(e),
    onSuccess: () => {
      queryClient.invalidateQueries(['types', 'adminPage']);
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        setEditMode(false);
        setOpen(false);
      }}
    >
      <Card className={classes.modalCard}>
        <Typography align="left" variant="h5">
          {editMode ? `Edit Type` : `Add Type`}
        </Typography>
        <form>
          <div className={classes.fieldDivTop}>
            <Tooltip
              title={<Typography variant={'body2'}>{`Name of Type`}</Typography>}
              TransitionComponent={Zoom}
              enterDelay={200}
              placement={'right'}
            >
              <TextField
                disabled={typesImportantForAnalytics.includes(typeName) && editMode}
                required
                value={typeName}
                onChange={(e) => {
                  if (e.target.value.length > typeNameCharLimit) {
                    setTypeName(e.target.value.slice(0, typeNameCharLimit));
                  } else {
                    setTypeName(e.target.value);
                  }
                }}
                className={classes.textField}
                id={'add-modal-type-name-input'}
                label={IssueAndServiceSharedValues.type}
                variant="outlined"
                error={typeNameInputStatus.error}
                helperText={
                  <Box>
                    {typeNameInputStatus.message ? `${typeNameInputStatus.message}` : ''}
                    <Box
                      className={classes.charCountText}
                    >{`${typeName.length}/${typeNameCharLimit} character(s)`}</Box>
                  </Box>
                }
              ></TextField>
            </Tooltip>
          </div>
          <div className={classes.fieldDivMiddle}>
            <Tooltip
              title={<Typography variant={'body2'}>{`Category of Type`}</Typography>}
              TransitionComponent={Zoom}
              enterDelay={200}
              placement={'right'}
            >
              <TextField
                required
                value={typeCategory}
                onChange={(e) => {
                  if (e.target.value.length > typeCategoryCharLimit) {
                    setTypeCategory(e.target.value.slice(0, typeCategoryCharLimit));
                  } else {
                    setTypeCategory(e.target.value);
                  }
                }}
                className={classes.textField}
                id={'add-modal-type-category-input'}
                label={'Category'}
                variant="outlined"
                helperText={
                  <Box>
                    {typeCategoryInputStatus.message ? `${typeCategoryInputStatus.message}` : ''}
                    <Box
                      className={classes.charCountText}
                    >{`${typeCategory.length}/${typeCategoryCharLimit} character(s)`}</Box>
                  </Box>
                }
                error={typeCategoryInputStatus.error}
              ></TextField>
            </Tooltip>
          </div>
          <div className={classes.fieldDivMiddle}>
            <Autocomplete
              disablePortal
              clearOnEscape
              openOnFocus
              autoHighlight
              autoSelect
              id={'add-modal-type-backgroundColor-input'}
              options={typeBackgroundColorOptions}
              value={typeBackgroundColor === '' ? null : typeBackgroundColor}
              renderInput={(params) => (
                <Tooltip
                  title={
                    <Typography variant={'body2'}>
                      {
                        'Background color of the Media Interaction/Service Log table cells that show this type. Note that the topmost type will have priority in determining the type cell color in Media Interaction/Service Log tables.'
                      }
                    </Typography>
                  }
                  TransitionComponent={Zoom}
                  enterDelay={200}
                  placement={'right'}
                >
                  <TextField
                    required
                    {...params}
                    className={classes.textField}
                    variant="outlined"
                    label={'Table Cell Background Color'}
                    error={typeBackgroundColorInputStatus.error}
                    helperText={typeBackgroundColorInputStatus.message}
                  />
                </Tooltip>
              )}
              onChange={(e, value) => {
                setTypeBackgroundColor(value);
              }}
              className={''}
            />
          </div>
          <div className={classes.fieldDivMiddle}>
            <Autocomplete
              disablePortal
              clearOnEscape
              openOnFocus
              autoHighlight
              autoSelect
              id={'add-modal-type-proactivity-input'}
              options={typeProactivityOptions}
              value={typeProactivity === '' ? null : typeProactivity}
              renderInput={(params) => (
                <Tooltip
                  title={
                    <Typography variant={'body2'}>{'Whether Type is created proactively or reactively'}</Typography>
                  }
                  TransitionComponent={Zoom}
                  enterDelay={200}
                  placement={'right'}
                >
                  <TextField
                    {...params}
                    required
                    className={classes.textField}
                    variant="outlined"
                    label={'Proactivity'}
                    error={typeProactivityInputStatus.error}
                    helperText={typeProactivityInputStatus.message}
                  />
                </Tooltip>
              )}
              onChange={(e, value) => {
                setTypeProactivity(value);
              }}
              className={''}
            />
          </div>
          <div className={classes.fieldDivBottom}>
            <FormControl variant="outlined">
              <Tooltip
                title={
                  <Typography
                    variant={'body2'}
                  >{`Determines the permission level of users required to see services with this type as an entry to the Service Log table. However, issues with this type in the Media Interactions table will not be affected. Note that for a user with a given permission to see a service in the Service Log table, ALL types contained in said service must have the given permission.`}</Typography>
                }
                TransitionComponent={Zoom}
                enterDelay={200}
                placement={'right'}
              >
                <FormLabel>Service Log Entry Availability Permissions</FormLabel>
              </Tooltip>
              <FormGroup>
                {typePermissionsOptions.map((permissionOption) => (
                  <FormControlLabel
                    control={<Checkbox checked={typePermissions.includes(permissionOption)} />}
                    value={permissionOption}
                    label={permissionOption}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newTypePermissions = [...typePermissions];
                        newTypePermissions.push(permissionOption);
                        setTypePermissions(newTypePermissions);
                      } else {
                        const newTypePermissions = typePermissions.filter((permission) => {
                          return permission !== permissionOption;
                        });
                        setTypePermissions(newTypePermissions);
                      }
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.cancelButton} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.addButton}
              onClick={(e) => itemMutation.mutate(e)}
              variant="contained"
              color="primary"
            >
              {editMode ? 'Edit' : 'Add'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}

export default AddEnumTypesModal;
