import React, { useContext, useEffect, useMemo } from 'react';
import { CollaborationGroupDetailsProps } from './Types/componentProps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Divider, LinearProgress, List, ListItem, Typography } from '@mui/material';
import CollaborationGroupInvitationsList from './CollaborationGroupInvitationsList';
import { getCollaborationGroupDetails } from './Requests/CollaborationGroupRequests';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import AdminPrivilegeProtected from '../../shared/protectedWrappers/AdminPrivilegeProtectedWrapper';
import GroupOwnerProtected from '../../shared/protectedWrappers/CollaborationGroupOwnerProtectedWrapper';
import CollaborationGroupDetailsActions from './CollaborationGroupDetailsActions';
import { makeStyles } from '@mui/styles';
import GroupCollaboratorsActions from './GroupCollaboratorsActions';
import ManageGroupShareMediaInteractionActions from './ManageGroupShareMediaInteractionActions';
import CollaborationGroupSharedResourcesFiltersListItems from './CollaborationGroupSharedResourcesFiltersListItems';
import ManageCollaborationGroupSharedJournalistActions from './ManageCollaborationGroupSharedJournalistActions';
import CollaborationGroupSharedContactToggleChip from './CollaborationGroupSharedContactToggleChip';
import { Box } from '@mui/system';
import { shareContactType } from './Utils/constants';

const useStyles = makeStyles({
  emptyGroupDetails: {
    marginTop: '1rem',
  },
  loadingProgress: {
    marginTop: '1rem',
  },
  list: {
    paddingTop: '1rem',
  },
  groupNameListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const CollaborationGroupDetails: React.FC<CollaborationGroupDetailsProps> = () => {
  const classes = useStyles();
  const { selectedGroupId } = useContext(SelectedCollaborationGroupIdContext);

  const queryClient = useQueryClient();
  const { isFetching, data: groupDetailsData } = useQuery({
    queryKey: ['collaboration/group-details'],
    queryFn: () => getCollaborationGroupDetails(selectedGroupId),
    enabled: selectedGroupId !== '',
    refetchOnWindowFocus: false,
  });

  const groupOwners = groupDetailsData ? groupDetailsData.owners.map((owner) => owner._id) : [];
  const sharedJournalistToggledOn = useMemo(() => {
    if (!groupDetailsData) {
      return false;
    }

    const sharedJournalistFilters = groupDetailsData.sharedContacts.filter(
      (contact) => contact.type === shareContactType.journalist
    );
    return sharedJournalistFilters.length !== 0;
  }, [groupDetailsData]);

  useEffect(() => {
    if (selectedGroupId !== '') {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
    }
  }, [selectedGroupId, queryClient]);

  if (!selectedGroupId) {
    return (
      <Typography data-cy="collaboration-group-details-empty-prompt" variant="h5" className={classes.emptyGroupDetails}>
        Click a group to manage
      </Typography>
    );
  } else if (isFetching || !groupDetailsData) {
    return <LinearProgress className={classes.loadingProgress} />;
  }

  return (
    <List className={classes.list}>
      <ListItem key="group-name" className={classes.groupNameListItem}>
        <Typography variant="h5" data-cy="collaboration-group-details-name">
          {groupDetailsData.name}
        </Typography>
        <AdminPrivilegeProtected>
          <CollaborationGroupDetailsActions groupOwners={groupOwners} />
        </AdminPrivilegeProtected>
      </ListItem>
      <ListItem key="group-owner-title">
        <Typography>
          Group <strong>Leads</strong>:
        </Typography>
      </ListItem>
      {groupDetailsData.owners.map((owner) => (
        <ListItem key={`group-owner-${owner._id}`}>
          <Typography data-cy="collaboration-group-details-owner">
            - {owner.organizationName} ({owner.organizationCode})
          </Typography>
        </ListItem>
      ))}
      <Divider key="group-info-divider" component="li" />
      <ListItem
        key="group-members-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography>
          Group <strong>Collaborators</strong>:
        </Typography>
        <AdminPrivilegeProtected>
          <GroupOwnerProtected groupOwners={groupOwners}>
            <GroupCollaboratorsActions group={groupDetailsData} />
          </GroupOwnerProtected>
        </AdminPrivilegeProtected>
      </ListItem>
      {groupDetailsData.tenants.map((tenant) => (
        <ListItem key={tenant._id}>
          <Typography data-cy="collaboration-group-details-member">
            - {tenant.organizationName} ({tenant.organizationCode})
          </Typography>
        </ListItem>
      ))}
      <Divider key="group-filter-divider-top" component="li" />
      <ListItem
        key="group-shared-resource-filter-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography>
          Manage <strong>Shared Interactions</strong> Filter:
        </Typography>
        <AdminPrivilegeProtected>
          <ManageGroupShareMediaInteractionActions
            sharedInteractionFilter={groupDetailsData.sharedResourceFilters.find(
              (filter) => filter.resourceType === 'issue'
            )}
          />
        </AdminPrivilegeProtected>
      </ListItem>
      <CollaborationGroupSharedResourcesFiltersListItems
        resourceType="issue"
        filters={groupDetailsData.sharedResourceFilters}
      />
      <Divider key="manage-group-shared-contact-divider" component="li" />
      <ListItem
        key="manage-group-shared-contact-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography>
            Share <strong>Journalists</strong> to group:
          </Typography>
          <CollaborationGroupSharedContactToggleChip toggledOn={sharedJournalistToggledOn} />
        </Box>
        <AdminPrivilegeProtected>
          <ManageCollaborationGroupSharedJournalistActions toggledOn={sharedJournalistToggledOn} />
        </AdminPrivilegeProtected>
      </ListItem>
      <Divider key="manage-group-membership-divider" component="li" />
      <AdminPrivilegeProtected>
        <GroupOwnerProtected groupOwners={groupOwners}>
          <CollaborationGroupInvitationsList />
        </GroupOwnerProtected>
      </AdminPrivilegeProtected>
    </List>
  );
};

export default CollaborationGroupDetails;
