import { Box, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  dateRange: {
    margin: 'auto',
    '&:focus-visible': {
      outlineColor: palette.primary.dark,
    },
  },
}));

interface FilterModalDateRangeInputsProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
}

const FilterModalDateRangeInputs: React.FC<FilterModalDateRangeInputsProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const classes = useStyles();

  const [start, setStart] = useState<Date | null>(startDate);
  const [end, setEnd] = useState<Date | null>(endDate);

  return (
    <Box>
      <Typography fontSize={20} fontWeight={600}>
        Date Range
      </Typography>
      <Box sx={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Typography fontSize={20} fontWeight={600}>
          From
        </Typography>
        <DatePicker
          selected={start}
          onChange={(date) => {
            setStart(date);
            if (date) {
              onStartDateChange(date);
            }
          }}
          className={classes.dateRange}
        />
        <Typography fontSize={20} fontWeight={600}>
          To
        </Typography>
        <DatePicker
          selected={end}
          onChange={(date) => {
            setEnd(date);
            if (date) {
              onEndDateChange(date);
            }
          }}
          className={classes.dateRange}
        />
      </Box>
    </Box>
  );
};

export default FilterModalDateRangeInputs;
