import { useQuery } from '@tanstack/react-query';
import { getPersonnelsByOrganization } from '../contactsRequest';

export const getJournalistsByOutletOptions = (outletId) => {
  return {
    queryKey: ['journalists', { outletId }],
    queryFn: () => getPersonnelsByOrganization(outletId),
  };
};

export const useJournalistsByOutlet = (outletId) => {
  return useQuery(getJournalistsByOutletOptions(outletId));
};
