import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { listCollaborationGroupSharedContacts } from './Requests/CollaborationGroupRequests';
import Spinner from 'react-bootstrap/Spinner';
import SharedJournalistsTable from './SharedJournalistsTable';
import { SharedJournalistsTableWrapperProps } from './Types/componentProps';
import SharedResourceGroupSelect from './SharedResourceGroupSelect';
import { Box } from '@mui/material';

const SharedJournalistsTableWrapper = (props: SharedJournalistsTableWrapperProps) => {
  const { groupId, groupsData, onSelectedGroupChange } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(50);

  const { isFetching, data: sharedJournalistsData } = useQuery({
    queryKey: ['collaboration/group/shared/journalists', groupId, page, pageSize],
    queryFn: () => listCollaborationGroupSharedContacts(groupId, 'journalist', page + 1, pageSize),
    enabled: groupId !== '',
    refetchOnWindowFocus: false,
  });

  const handleGroupSelect = (selectedGroupId: string) => {
    onSelectedGroupChange(selectedGroupId);
    setPage(0);
  };

  let sharedJournalistsTable;

  if (groupId === '') {
    sharedJournalistsTable = <div>Select a group to view Shared Journalists</div>;
  } else if (isFetching) {
    sharedJournalistsTable = (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  } else if (!sharedJournalistsData) {
    sharedJournalistsTable = <div>something went wrong, try re-select a group</div>;
  } else {
    sharedJournalistsTable = (
      <SharedJournalistsTable
        contactsData={sharedJournalistsData}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
        <SharedResourceGroupSelect
          selectedGroupId={groupId}
          groups={groupsData ?? []}
          onGroupSelect={(groupId) => handleGroupSelect(groupId)}
        />
      </Box>
      {sharedJournalistsTable}
    </>
  );
};

export default SharedJournalistsTableWrapper;
