import React, { useEffect, useState, useContext } from 'react';
import { getBackgroundColor, TrackerDataType, socketEvents } from '../../shared/constants/constants';
import moment from 'moment';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar-custom-styles.css';
import { useLocation } from 'react-router-dom';
import { getCurrMonth } from '../../shared/functions/dateProcessor';
import CalendarFilter from '../TrackerFilter/CalendarFilter';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import EventModal, { CalendarEvents, IssueEvent, ServiceEvent } from './EventModal';
import { useQuery } from '@tanstack/react-query';
import { emitMonthlyIssues, emitMonthlyServices } from './calendarPageRequest';
import { Spinner } from 'react-bootstrap';
import { DropdownTable, FlatIssue, FlatService, Org, UserMeta } from '../../shared/types';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import { isFlatIssue, isFlatService } from '../../shared/isTypes';

const localizer = momentLocalizer(moment);

interface CalendarViewProps {
  dropdownTable: DropdownTable;
}

enum ViewType {
  MEDIA_INTERACTION = TrackerDataType.MEDIA_INTERACTION,
  SERVICE = TrackerDataType.SERVICE,
  BOTH = TrackerDataType.COMBINED_INTERACTION_AND_SERVICE,
}

function CalendarView({ dropdownTable }: Readonly<CalendarViewProps>) {
  const [selectedView, setSelectedView] = useState<ViewType>(ViewType.SERVICE); // Initialize with 'service' as the default view
  const [currMonth, setCurrMonth] = useState<string>(getCurrMonth());
  const socket = useContext(SocketContext);
  const { state } = useLocation();
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const [formattedEvents, setFormattedEvents] = useState<CalendarEvents>([]);
  const [selectedEvent, setSelectedEvent] = useState<IssueEvent | ServiceEvent | null>(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [filteredInteractionsOrServices, setFilteredInteractionsOrServices] = useState<FlatIssue[] | FlatService[]>([]);
  const [filtersOn, setFiltersOn] = useState(false);

  useEffect(() => {
    setFiltersOn(false);
  }, [selectedView, setSelectedEvent]);

  const handleTabClick = (view: ViewType) => {
    setFiltersOn(false);

    setSelectedView(view);
  };

  const handleMonthChange = (newDate: Date) => {
    const newMonth = moment(newDate).format('YYYY-MM');
    setCurrMonth(newMonth);
  };

  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const tabButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
    transition: 'background-color 0.3s ease',
  };

  const activeTabButtonStyle = {
    backgroundColor: getBackgroundColor(selectedView),
    color: '#fff',
  };

  const handleSelectEvent = (event: IssueEvent | ServiceEvent) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  };

  const handleCloseEventModal = () => {
    setSelectedEvent(null);
    setIsEventModalOpen(false);
  };

  const handleDoubleClickEvent = (event: IssueEvent | ServiceEvent) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  };

  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth');
  } else {
    month = getCurrMonth();
  }

  const processData = (
    data: FlatIssue[] | FlatService[],
    isServiceView: boolean,
    dropdownTable: DropdownTable
  ): CalendarEvents => {
    if (data == null) {
      return [];
    }

    return data
      .map((item: FlatIssue | FlatService) => {
        if (!item?.cells) {
          console.error('Invalid item format');
          return null;
        }
        const startDate = new Date(item.cells.Date);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(item.cells.Date);
        endDate.setHours(0, 0, 0, 0);
        if (isServiceView && isFlatService(item)) {
          const teamMembers = item.cells[ServiceKeys.teamMember];
          const teamMemberNames = dropdownTable['lead']
            .filter((lead: UserMeta) => teamMembers.includes(lead._id))
            .map((lead: UserMeta) => lead.name);

          return {
            logtype: TrackerDataType.SERVICE,
            start: startDate,
            end: endDate,
            background: item.cells[ServiceKeys.actions],
            title: item.cells[ServiceKeys.service],
            lead: item.cells[ServiceKeys.unitLead].map((expert) => expert.name).join(', '),
            unit: item.cells[ServiceKeys.units].join(', '),
            campaign: item.cells[ServiceKeys.campaign].map((camp) => camp.name).join(', '),
            department:
              typeof item.cells[ServiceKeys.dept] === 'string'
                ? item.cells[ServiceKeys.dept]
                : item.cells[ServiceKeys.dept].join(', '),
            type: item.cells[ServiceKeys.type].map((type) => `${type.type}: ${type.name}`).join(', '),
            teamMembers: teamMemberNames.join(', '),
            complexity: item.cells[ServiceKeys.complexity] ? item.cells[ServiceKeys.complexity].toString() : '',
            diversity: item.cells[ServiceKeys.diversity] ?? '',
            status: item.cells[ServiceKeys.status],
            keyMessaging: item.cells[ServiceKeys.keyMessaging],
            colorEvento: 'var(--service-color)',
            comms: item.cells[ServiceKeys.file] ?? [],
            id: item.id,
          };
        } else if (isFlatIssue(item)) {
          const teamMembers = item.cells[IssueKeys.lead];
          const teamMemberNames = dropdownTable['lead']
            .filter((lead: UserMeta) => teamMembers.includes(lead._id))
            .map((lead: UserMeta) => lead.name);

          const outlett = item.cells[IssueKeys.outlet];
          const outlettName = dropdownTable['outlet']?.find((org: Org) => org['_id'] === outlett);
          const journalist = dropdownTable.orgAgents[outlett ?? '']?.find?.(
            (journ) => journ._id === item.cells[IssueKeys.journalist]
          );

          return {
            logtype: TrackerDataType.MEDIA_INTERACTION,
            start: startDate,
            end: endDate,
            title: item.cells[IssueKeys.topic],
            colorEvento: 'var(--media-color)',
            lead: item.cells[IssueKeys.expert].map((expert) => expert.name).join(', '),
            unit: item.cells[IssueKeys.units].join(', '),
            type: item.cells[ServiceKeys.type].map((type) => `${type.type}: ${type.name}`).join(', '),
            campaign: item.cells[IssueKeys.campaign].map((campaign) => campaign.name).join(', '),
            department:
              typeof item.cells[ServiceKeys.dept] === 'string'
                ? item.cells[ServiceKeys.dept]
                : item.cells[ServiceKeys.dept].join(', '),
            status: item.cells[IssueKeys.status],
            background: item.cells[IssueKeys.response] ?? '',
            teamMembers: teamMemberNames.join(', '),
            contact: item.cells[IssueKeys.contactMethod] ?? '',
            journalist: journalist?.name ?? '',
            keyMessaging: item.cells[IssueKeys.keyMessaging],
            outlet: outlett && outlettName ? outlettName.name : '',
            comms: item.cells[IssueKeys.file],
            id: item.id,
          };
        } else {
          return null;
        }
      })
      .filter((item: any): item is IssueEvent | ServiceEvent => item !== null);
  };

  const serviceQuery = useQuery({
    queryKey: ['services', currMonth],
    queryFn: () => emitMonthlyServices(socket, currMonth),
    enabled: selectedView === ViewType.SERVICE || selectedView === ViewType.BOTH,

    refetchInterval: selectedView === ViewType.SERVICE || selectedView === ViewType.BOTH ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  const mediaQuery = useQuery({
    queryKey: ['media', currMonth],
    queryFn: () => emitMonthlyIssues(socket, currMonth),
    enabled: selectedView === ViewType.MEDIA_INTERACTION || selectedView === ViewType.BOTH,
    refetchInterval: selectedView === ViewType.MEDIA_INTERACTION || selectedView === ViewType.BOTH ? 3000 : false,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    setFormattedEvents([]);
    if (dropdownTable) {
      if (selectedView === ViewType.MEDIA_INTERACTION && filtersOn) {
        const formattedMediaEvents = processData(filteredInteractionsOrServices, false, dropdownTable) as IssueEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedMediaEvents]);
      } else if (selectedView === ViewType.MEDIA_INTERACTION && mediaQuery.isSuccess && mediaQuery.data) {
        const formattedMediaEvents = processData(mediaQuery.data, false, dropdownTable) as IssueEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedMediaEvents]);
      }

      if (selectedView === ViewType.SERVICE && filtersOn) {
        const formattedServiceEvents = processData(
          filteredInteractionsOrServices,
          true,
          dropdownTable
        ) as ServiceEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedServiceEvents]);
      } else if (selectedView === ViewType.SERVICE && serviceQuery.isSuccess && serviceQuery.data) {
        const formattedServiceEvents = processData(serviceQuery.data, true, dropdownTable) as ServiceEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedServiceEvents]);
      }

      if (selectedView === ViewType.BOTH && serviceQuery.isSuccess && serviceQuery.data) {
        const formattedServiceEvents = processData(serviceQuery.data, true, dropdownTable) as ServiceEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedServiceEvents]);
      }

      if (selectedView === ViewType.BOTH && mediaQuery.isSuccess && mediaQuery.data) {
        const formattedMediaEvents = processData(mediaQuery.data, false, dropdownTable) as IssueEvent[];
        setFormattedEvents((formattedEvents: CalendarEvents) => [...formattedEvents, ...formattedMediaEvents]);
      }
    }
  }, [
    selectedView,
    currMonth,
    month,
    serviceQuery.data,
    mediaQuery.data,
    filteredInteractionsOrServices,
    filtersOn,
    dropdownTable,
    mediaQuery.isSuccess,
    serviceQuery.isSuccess,
  ]);

  useEffect(() => {
    sessionStorage.setItem('currMonth', currMonth);

    if (selectedView === ViewType.MEDIA_INTERACTION) {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_ISSUES, {
        month: currMonth,
        timezoneOffset: new Date(currMonth).getTimezoneOffset(),
      });
    }
    if (selectedView === ViewType.SERVICE) {
      socket.emit(socketEvents.EVENT_REQ_MONTHLY_SERVICES, {
        month: currMonth,
        timezoneOffset: new Date(currMonth).getTimezoneOffset(),
      });
    }
  }, [currMonth, socket, state, selectedView, setCurrMonth, month]);

  return (
    <div>
      {dropdownTable ? (
        <div>
          <div>
            {selectedView !== ViewType.BOTH && (
              <CalendarFilter
                setDataCallback={setFilteredInteractionsOrServices}
                currMonth={currMonth}
                dropdownTable={dropdownTable}
                onSelectEvent={handleSelectEvent}
                eventHandler={
                  selectedView === ViewType.MEDIA_INTERACTION ? mediaInteractionEventHandler : serviceLogEventHandler
                }
                inMediaInteraction={selectedView === ViewType.MEDIA_INTERACTION}
                typeView={selectedView}
                setFiltersOn={setFiltersOn}
                filtersOn={filtersOn}
              />
            )}
            <div style={buttonStyle} className="tabs">
              <button
                style={
                  selectedView === ViewType.SERVICE ? { ...tabButtonStyle, ...activeTabButtonStyle } : tabButtonStyle
                }
                onClick={() => handleTabClick(ViewType.SERVICE)}
              >
                Service Logs
              </button>
              <button
                style={
                  selectedView === ViewType.MEDIA_INTERACTION
                    ? { ...tabButtonStyle, ...activeTabButtonStyle }
                    : tabButtonStyle
                }
                onClick={() => handleTabClick(ViewType.MEDIA_INTERACTION)}
              >
                Media Interactions
              </button>
              <button
                style={selectedView === ViewType.BOTH ? { ...tabButtonStyle, ...activeTabButtonStyle } : tabButtonStyle}
                onClick={() => handleTabClick(ViewType.BOTH)}
              >
                All
              </button>
            </div>
          </div>
          <div>
            <div className="myCustomHeight">
              <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{
                  height: '40vw',
                  width: '100vw',
                  padding: '8px',
                }}
                events={formattedEvents}
                views={['month', 'week', 'day']}
                defaultView={'month'}
                eventPropGetter={(myEventsList) => {
                  const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : 'blue';
                  return { style: { backgroundColor } };
                }}
                onNavigate={(newDate) => handleMonthChange(newDate)}
                onDoubleClickEvent={handleDoubleClickEvent}
              />

              {isEventModalOpen && <EventModal event={selectedEvent} onClose={handleCloseEventModal} />}
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
}

export default CalendarView;
