import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { createUser } from './adminPageRequest';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import { SingleSelect } from '../Cells/CellTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles({
  modalCard: {
    padding: '30px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
  },
  textField: {
    width: '100%',
  },
  fieldDiv: {
    paddingTop: '2vh',
    paddingBottom: '2vh',
    borderTop: 'solid gray 1px',
    borderBottom: 'solid gray 1px',
    marginTop: '2vh',
    marginBottom: '2vh',
  },
  addButton: {
    width: '6vw',
    height: '5vh',
  },
  cancelButton: {
    width: '6vw',
    height: '5vh',
    color: 'white',
    backgroundColor: 'red',
  },
  fieldDivTop: {
    paddingTop: '2vh',
    paddingBottom: '1vh',
    borderTop: 'solid gray 1px',
    marginTop: '2vh',
    marginBottom: '1vh',
  },
  fieldDivBottom: {
    paddingTop: '1vh',
    paddingBottom: '2vh',
    borderBottom: 'solid gray 1px',
    marginTop: '1vh',
    marginBottom: '2vh',
  },
  fieldDivMiddle: {
    paddingTop: '1vh',
    paddingBottom: '1vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
});
function AddUserModal({ open, setOpen, editingUserPrivilege, callback }) {
  const classes = useStyles();
  const defaultValues = {
    username: '',
    name: '',
    email: '',
    privilege: 0,
  };
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    username: yup
      .string()
      .required()
      .matches(/^[a-zA-Z0-9.]+$/, 'Usernames may only include alphanumeric characters or periods'),
    name: yup.string().required(),
    email: yup.string().email().required(),
    privilege: yup.number().required(),
  });

  //get only user, manager, admin
  let privMapping = {};

  const privilegeLevelsForSelect = Object.keys(PrivilegeLevels)
    .map((level) => {
      privMapping[PrivilegeLevels[level].level] = PrivilegeLevels[level].title;
      if (PrivilegeLevels[level].level <= editingUserPrivilege)
        return {
          title: PrivilegeLevels[level].title,
          value: PrivilegeLevels[level].level,
        };
      return null;
    })
    .filter((level) => level !== null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const forSubmit = async (data) => {
    //create a new user
    createUser(data)
      .then((response) => {
        callback(response.user);
        dispatch(openSnackbar({ severity: 'success', message: 'User successfully created' }));
      })
      .catch((e) => {
        console.log(e.message);
        dispatch(openSnackbar({ severity: 'error', message: 'User failed to be created' }));
      });
    handleClose();
  };

  const queryClient = useQueryClient();
  const newUserMutation = useMutation({
    mutationFn: (data) => forSubmit(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['users', 'adminPage']);
    },
  });

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Card className={classes.modalCard}>
        <Typography align="left" variant="h5">
          Add User
        </Typography>
        <form onSubmit={handleSubmit((data) => newUserMutation.mutate(data))}>
          <div className={classes.fieldDivTop}>
            <Controller
              name="username"
              control={control}
              defaultValue={defaultValues.username}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Username"
                  variant="outlined"
                  className={classes.textField}
                  error={errors.username}
                  helperText={errors.username && errors.username.message}
                />
              )}
            />
          </div>
          <div className={classes.fieldDivMiddle}>
            <Controller
              name="name"
              control={control}
              defaultValue={defaultValues.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  className={classes.textField}
                  error={errors.name}
                  helperText={errors.name && errors.name.message}
                />
              )}
            />
          </div>
          <div className={classes.fieldDivMiddle}>
            <Controller
              name="email"
              control={control}
              defaultValue={defaultValues.email}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  error={errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </div>

          <div className={classes.fieldDivBottom}>
            <Controller
              name="privilege"
              control={control}
              defaultValue={defaultValues.privilege}
              render={({ field: { onChange, value } }) => (
                <SingleSelect
                  value={value}
                  items={privilegeLevelsForSelect}
                  label={'Account Type'}
                  callBack={(val) => {
                    onChange(val);
                  }}
                ></SingleSelect>
              )}
            />
          </div>

          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.cancelButton} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" className={classes.addButton} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}

export default AddUserModal;
