import { Chip } from '@mui/material';
import { CollaborationGroupSharedContactToggleChipProps } from './Types/componentProps';

const CollaborationGroupSharedContactToggleChip = (props: CollaborationGroupSharedContactToggleChipProps) => {
  const { toggledOn } = props;
  if (toggledOn) {
    return <Chip variant="outlined" color="success" label="ON" />;
  }
  return <Chip variant="outlined" color="error" label="OFF" />;
};

export default CollaborationGroupSharedContactToggleChip;
