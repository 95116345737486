import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { MetadataOptionProps, KeyMessagingProps, BackgroundResponseProps } from '../Interfaces';

interface CheckboxOptionProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  name: string;
}

// Parent Class
const CheckboxOption: React.FC<CheckboxOptionProps> = ({ checked, setChecked, label, name }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      className="checkbox"
      control={<Checkbox checked={checked} onChange={handleChange} name={name} />}
      label={label}
    />
  );
};

// Child Classes
const MetadataOption: React.FC<MetadataOptionProps> = ({ includeMetadata, setIncludeMetadata }) => (
  <CheckboxOption checked={includeMetadata} setChecked={setIncludeMetadata} label="Include Metadata" name="metadata" />
);

const KeyMessagingOption: React.FC<KeyMessagingProps> = ({ keyMessaging, setKeyMessaging }) => (
  <CheckboxOption
    checked={keyMessaging}
    setChecked={setKeyMessaging}
    label="Include Key Messaging"
    name="keyMessaging"
  />
);

const BackgroundResponseOption: React.FC<BackgroundResponseProps> = ({ backgroundResponse, setBackgroundResponse }) => (
  <CheckboxOption
    checked={backgroundResponse}
    setChecked={setBackgroundResponse}
    label="Include Background Response"
    name="backgroundResponse"
  />
);

export { MetadataOption, KeyMessagingOption, BackgroundResponseOption};
