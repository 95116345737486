import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FilteredTableDataRow from './FilteredTableDataRow';
import Paper from '@mui/material/Paper';
import { cellColors } from '../../shared/constants/commonCellColors';
import { IssueKeys } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles({
  table: {
    minWidth: 0,

    margin: '0 ',
  },
  tableCell: {
    maxWidth: '30%',
    padding: '0px 16px',
    borderBottom: 'black solid 1.5px',
    minHeight: '6vh',
    height: '6vh',
    maxHeight: '6vh',
  },
  tableTopicCell: {
    width: '50%',
    padding: '0px 16px',
    borderBottom: 'black solid 1.5px',
    minHeight: '6vh',
    height: '6vh',
    maxHeight: '6vh',
  },
  ...cellColors,
  headerBorder: {
    minHeight: '6vh',
    height: '6vh',
    maxHeight: '6vh',
  },
  container: {
    overflow: 'initial',
  },
});

export default function FilteredTable({ issues, cols, dropdownTable, type }) {
  const classes = useStyles();

  return issues?.length ? (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow className={classes.headerBorder}>
            {cols.map((col) => (
              <TableCell
                className={col === IssueKeys.topic ? classes.tableTopicCell : classes.tableCell}
                key={col}
                align="left"
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map((issue, index) => (
            <FilteredTableDataRow
              type={type}
              key={issue.id}
              issue={issue}
              columns={cols}
              dropdownTable={dropdownTable}
              isStripedRow={index % 2}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <h2>No data</h2>
  );
}
