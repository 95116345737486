import { useState, useEffect, useRef, useMemo, createRef } from 'react';
import { TagCloud, Tag, RendererFunction } from 'react-tagcloud';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';

import './WordCloud.css';

const useStyles = makeStyles(() => ({
  h5: {
    fontSize: '150%',
  },
  wordCloudContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    position: 'relative',
  },
  cloudWrapper: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

interface WordCloudProps {
  chartData: {
    y: string[];
    x: number[];
  }[];
  title: string;
  onAnimationComplete?: () => void; // Add callback prop for when animations complete
}

export function WordCloud({ chartData, title, onAnimationComplete = () => {} }: WordCloudProps) {
  const classes = useStyles();
  const wordCloudRef = useRef<HTMLDivElement | null>(null);
  const animationCountRef = useRef<number>(0);
  const [minSize, setMinSize] = useState<number>(30);
  const [maxSize, setMaxSize] = useState<number>(70);

  const words: Tag[] = useMemo(() => {
    const colorOptions: string[] = [
      'rgba(255, 99, 132, 0.7)',
      'rgba(255, 159, 64, 0.7)',
      'rgba(255, 205, 86, 0.7)',
      'rgba(75, 192, 192, 0.7)',
      'rgba(54, 162, 235, 0.7)',
      'rgba(153, 102, 255, 0.7)',
      'rgba(201, 203, 207, 0.7)',
    ];

    const data_y = chartData[0].y.map((i: string) => i.replace(/<br>/g, ' '));
    const data_x = chartData[0].x;

    const getRandomColor = (): string => {
      return colorOptions[Math.floor(Math.random() * colorOptions.length)];
    };

    const newWords = data_y.map((text, i) => ({
      value: text,
      count: data_x[i],
      color: getRandomColor(),
      index: i,
    }));

    return newWords.slice(0, 30); // Max 30 words
  }, [chartData]);

  const tagRef = useRef<any>(words.map(() => createRef<HTMLSpanElement>()));

  const customRenderer: RendererFunction = (tag, size, color) => {
    return (
      <Tooltip title={`Count: ${tag.count}`} key={tag.value}>
        <span
          ref={tag.index !== undefined ? tagRef.current[tag.index as keyof typeof tagRef.current] : undefined}
          className="animated-word"
          style={{
            fontSize: `${size}px`,
            margin: '0px 5px 0px 5px',
            color: color,
            fontFamily: 'Impact, sans-serif',
          }}
          onAnimationEnd={() => handleAnimationEnd(tag.index as keyof typeof tagRef.current)}
        >
          {tag.value}
        </span>
      </Tooltip>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (wordCloudRef.current) {
        const { offsetWidth: width, offsetHeight: height } = wordCloudRef.current;

        const minFontSize = Math.max(10, Math.min(width, height) * 0.05);
        const maxFontSize = Math.max(20, Math.min(width, height) * 0.15);

        setMinSize(minFontSize);
        setMaxSize(maxFontSize);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Reset count at the beginning of a new animation cycle
    animationCountRef.current = 0;
  }, [words]);

  // each tag animates separately. wait for all of them to end before firing a callback
  const handleAnimationEnd = (index: keyof typeof tagRef.current) => {
    animationCountRef.current += 1;

    // After an animation is finished, we want to remove the css class that triggers said animation.
    // This is due to html2canvas not working properly if the captured component has an "animation" css.
    tagRef.current[index]?.current?.classList.remove('animated-word');
    tagRef.current[index]?.current?.classList.add('animated-word-complete');

    // When all animations have completed, call the parent callback
    if (animationCountRef.current >= words.length && onAnimationComplete) {
      onAnimationComplete();
    }
  };

  return (
    <>
      <Typography className={classes.h5} variant="h5" align="center">
        {title}
      </Typography>
      <div className={classes.wordCloudContainer} ref={wordCloudRef}>
        <div className={classes.cloudWrapper}>
          <TagCloud minSize={minSize} maxSize={maxSize} tags={words} renderer={customRenderer} />
        </div>
      </div>
    </>
  );
}
