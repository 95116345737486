import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ProactivityState } from '../Interfaces'
import Typography from '@mui/material/Typography';

interface ProactivityOptionProps {
    proactivityState: ProactivityState;
    setProactivityState: React.Dispatch<React.SetStateAction<ProactivityState>>;
  }

const ProactivityOption: React.FC<ProactivityOptionProps> = ({proactivityState,setProactivityState}) => {
  

  const handleClick = (option: keyof ProactivityState) => {
    setProactivityState(prev => ({
      ...prev,
      [option]: !prev[option]
    }));
  };

  return (
    
    <Box className='proactivity-box'>
    <Typography className='proactivity-title'>
        Proactivity
      </Typography>
      <Stack className='proactivity-stack' direction="row" spacing={2}>
      
        <Chip
          label="Proactive"
          onClick={() => handleClick('proactive')}
          color={proactivityState.proactive ? 'primary' : 'default'}
          variant={proactivityState.proactive ? 'filled' : 'outlined'}
        />
        <Chip
          label="Reactive"
          onClick={() => handleClick('reactive')}
          color={proactivityState.reactive ? 'primary' : 'default'}
          variant={proactivityState.reactive ? 'filled' : 'outlined'}
        />
      </Stack>
    </Box>
  );
};

export default ProactivityOption;