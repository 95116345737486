import { useEffect, useState } from 'react';
import { CollaborationGroupsListProps } from './Types/componentProps';
import { ListCollaborationGroupAPIData } from './Types/apiData';
import { makeStyles } from '@mui/styles';
import { Button, InputAdornment, List, ListItem, TextField } from '@mui/material';
import { useCollaborationGroupFuzzySearch } from './Hooks/useCollaborationGroupFuzzySearch';
import SearchIcon from '@mui/icons-material/Search';
import { useListCollaborationGroupQuery } from './Hooks/useCollaborationGroup';
import AddNewCollaborationGroupDialog from './AddNewOrUpdateCollaborationGroupDialog';
import AdminPrivilegeProtected from '../../shared/protectedWrappers/AdminPrivilegeProtectedWrapper';
import CollaborationGroupsListItems from './CollaborationGroupsListItems';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
  },
  listHeader: {
    justifyContent: 'space-between',
    marginTop: '1rem',
    paddingTop: '10',
  },
  listHeaderTextField: {
    width: '70%',
    height: '100%',
  },
  listItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const CollaborationGroupsList: React.FC<CollaborationGroupsListProps> = () => {
  const classes = useStyles();

  const [groups, setGroups] = useState<ListCollaborationGroupAPIData[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<ListCollaborationGroupAPIData[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [toggleDialog, setToggleDialog] = useState<boolean>(false);

  const { isLoading, data, status } = useListCollaborationGroupQuery();

  const { handleSearch } = useCollaborationGroupFuzzySearch<ListCollaborationGroupAPIData>({
    items: groups,
    onSearchResultChange: (results) => setFilteredGroups(results),
    searchOptions: {
      keys: ['name'],
      threshold: 0.2,
    },
    debounceWait: 350,
  });

  useEffect(() => {
    if (status === 'success') {
      setGroups(data);
      setFilteredGroups(data);
    }
  }, [data, status]);

  const handleAddNewGroupButtonClick = () => {
    setToggleDialog(true);
  };

  const handleCloseDialog = () => {
    setToggleDialog(false);
  };

  return (
    <>
      <List className={classes.list}>
        <ListItem key="collaboration-groups-search-name-title" className={classes.listHeader}>
          <TextField
            className={classes.listHeaderTextField}
            data-cy="collaboration-groups-list-search-textfield"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search By Group Name"
            value={searchText}
            onChange={(e) => {
              const val = e.target.value;
              setSearchText(val);
              handleSearch(val);
            }}
          />
          <AdminPrivilegeProtected>
            <Button
              data-cy="collaboration-groups-list-add-group"
              variant="contained"
              onClick={() => handleAddNewGroupButtonClick()}
              startIcon={<AddIcon />}
            >
              Group
            </Button>
          </AdminPrivilegeProtected>
        </ListItem>
        <CollaborationGroupsListItems groups={filteredGroups} isLoading={isLoading} />
      </List>
      <AdminPrivilegeProtected>
        <AddNewCollaborationGroupDialog
          type="create"
          toggleDialog={toggleDialog}
          handleCloseDialog={handleCloseDialog}
        />
      </AdminPrivilegeProtected>
    </>
  );
};

export default CollaborationGroupsList;
