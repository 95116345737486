import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { Stack } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { API, genderOptions } from '../../../shared/constants/constants';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MergeIcon from '@mui/icons-material/Merge';
import ArchiveIcon from '@mui/icons-material/Archive';
import MergeContactPersonnelModal from './MergeContactPersonnelModal';
import { CONTACT_MAX_NOTES_LEN } from '../../../shared/constants/constants';
import AlertChangeOrgUnit from '../AlertChangeOrgUnit';
import { getExpertsByCaseInsensitiveNameAndUnit, getJournalistsByCaseInsensitiveNameAndOrg } from '../contactsRequest';
import { useArchiveContact } from '../Hooks/useArchiveContact';

const useStyles = makeStyles(({ palette, tabColor }) => ({
  cellInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  smCellInput: {
    width: 80,
  },
  smCellInputContainer: {
    maxWidth: 80,
  },
  groupLabel: {
    background: ({ tabColor }) => `${tabColor} !important`,
    color: `${palette.primary.contrastText} !important`,
    fontWeight: '700 !important',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '25rem',
    width: '30rem',
    overflow: 'hidden',
  },
  charCountText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const CombinedContactPersonnelModal = (props) => {
  // Props for adding a new contact
  const { openAddContact, setOpenAddContact, orgs, setRefreshContacts, category, units } = props;
  // Props for editing a contact on Contacts page
  const { isEditing, selectedContact } = props;
  // Props for entering from MediaInteractions/ServicePage
  const { isInContactPage, col, dialogValue, setDialogValue, value, setValue, callback, outlet } = props;
  // Props for entering from AutoTracker
  const { isInAutoTrackerPage } = props;
  let currentOrg;
  if (outlet) currentOrg = orgs.find((org) => org._id === outlet);

  // Prop for merging contacts
  const { contacts } = props;

  const [preFillOrgUnit, setPrefillOrgUnit] = React.useState(() => {
    if (!isEditing) return null;
    if (category === 'Journalists') return orgs.find((org) => org._id === selectedContact.Org._id);
    return units.find((unit) => unit._id === selectedContact.unit);
  });
  const [openMergeContact, setOpenMergeContact] = React.useState(false);
  const [changeWarning, setChangeWarning] = React.useState(false);

  const dispatch = useDispatch();

  const archiveContactMutation = useArchiveContact({
    contactId: selectedContact?._id,
    category: category,
    isArchiving: true,
    setRefetchContacts: setRefreshContacts,
  });

  let schema, url;
  if (category === 'Journalists') {
    url = '/journalist';
    schema = yup.object().shape({
      name: yup.string().required(),
      email: yup.string().email(),
      phone: yup.string().max(32),
      cell: yup.string().max(32),
      notes: yup.string().min(0).max(CONTACT_MAX_NOTES_LEN),
      Org: yup.string().nullable(),
    });
  } else {
    url = '/expert';
    schema = yup.object().shape({
      name: yup.string().required(),
      email: yup.string().email(),
      phone: yup.string().max(32),
      unit: yup.string().nullable(),
      language: yup.string(),
      gender: yup.string().nullable(),
      notes: yup.string().min(0).max(CONTACT_MAX_NOTES_LEN),
    });
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: null,
      phone: null,
      Org: null,
      unit: null,
      cell: null,
      notes: null,
      language: null,
      gender: null,
    },
  });
  const classes = useStyles();

  const getTitle = () => {
    const currentCategory = category.slice(0, -1);
    if (isEditing) return `Edit ${currentCategory}`;
    return `Add New ${currentCategory}`;
  };

  const checkDuplicates = async (data) => {
    let duplicateContacts;
    try {
      if (category === 'Journalists')
        duplicateContacts = await getJournalistsByCaseInsensitiveNameAndOrg(data.name, data.Org ? data.Org : '');
      else duplicateContacts = await getExpertsByCaseInsensitiveNameAndUnit(data.name, data.unit ? data.unit : '');
    } catch (err) {
      alert(err);
    }

    if (duplicateContacts.data?.length > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${duplicateContacts.data?.length} contact personnel(s) with the same Name and Organization/Unit already exists. Please check if you are trying to add a journalist or expert that already exists.`,
        })
      );
      return true;
    }
    return false;
  };

  const checkDuplicatesButExcludeSelected = async (data) => {
    let duplicateContacts;
    try {
      if (category === 'Journalists')
        duplicateContacts = await getJournalistsByCaseInsensitiveNameAndOrg(data.name, data.Org ? data.Org : '');
      else duplicateContacts = await getExpertsByCaseInsensitiveNameAndUnit(data.name, data.unit ? data.unit : '');
    } catch (err) {
      alert(err);
    }

    if (
      duplicateContacts.data?.filter((oneDuplicateContact) => {
        return oneDuplicateContact._id !== selectedContact._id;
      }).length > 0
    ) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${duplicateContacts.data?.length} contact personnel(s) with the same Name and Organization/Unit already exists. Please check if you are trying to add a journalist or expert that already exists.`,
        })
      );
      return true;
    }
    return false;
  };

  const handleArchive = () => {
    archiveContactMutation.mutateAsync();
  };

  const handleUpdate = async (data) => {
    if (await checkDuplicatesButExcludeSelected(data)) return;

    try {
      const response = await API.put(`${url}/${selectedContact._id}`, data);
      if (response.status !== 200) {
        alert(response.statusText);
      } else {
        dispatch(openSnackbar({ severity: 'success', message: 'Contact Personnel Updated!' }));
        setRefreshContacts(true);
      }
      handleClose();
      return response;
    } catch (e) {
      alert(`put failed - ${e}`);
    }
  };

  const handleClose = () => {
    reset();
    setOpenAddContact(false);
    setChangeWarning(false);
    setPrefillOrgUnit(null);
    if (!isInContactPage) {
      if (category === 'Experts')
        setDialogValue({
          name: '',
          phone: '',
          email: '',
          unit: '',
          language: '',
          gender: '',
          _id: '',
        });
      else
        setDialogValue({
          name: '',
          phone: '',
          cell: '',
          notes: '',
          email: '',
          Org: '',
          _id: '',
        });
    }
  };

  const forSubmit = async (data, category) => {
    if (isEditing) {
      await handleUpdate(data);
      return;
    }
    // first, check if the contact with the same name and Org already exists
    if (await checkDuplicates(data)) return;

    // if not, create a new contact
    API.post(url, data)
      .then((response) => {
        if (response.status !== 201) {
          alert(response.statusText);
        } else {
          dispatch(openSnackbar({ severity: 'success', message: 'Contact Personnel Created!' }));
          if (isInContactPage) setRefreshContacts(true);
        }
        return response;
      })
      .then((response) => {
        if (!isInContactPage && !isInAutoTrackerPage) {
          dialogValue._id = response.data.id;
          if (category === 'Experts') {
            dialogValue.Org = response.data.Org;
            value.push({ _id: response.data.id, name: response.data.name });
            callback(value, col);
          } else {
            const Org = (currentOrg ? currentOrg : preFillOrgUnit)?._id ?? '';
            dialogValue.Org = Org;
            setValue({ _id: response.data.id, name: response.data.name, Org });
            callback(dialogValue, col);
          }
        } else if (isInAutoTrackerPage) {
          callback(dialogValue, col);
        }
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        alert(`post failed - ${e}`);
      });
    reset();
    handleClose();
  };
  useEffect(() => {
    reset({
      name: selectedContact?.name,
      email: selectedContact?.email,
      phone: selectedContact?.phone,
    });
    if (selectedContact) {
      category === 'Journalists' && setPrefillOrgUnit(orgs.find((org) => org?._id === selectedContact.Org?._id));
      category === 'Experts' && setPrefillOrgUnit(units.find((unit) => unit._id === selectedContact.unit));
    }
  }, [selectedContact, category, orgs, units, reset]);

  useEffect(() => {
    if (!isInContactPage) {
      reset({
        name: dialogValue.name,
      });
    }
  }, [dialogValue?.name, isInContactPage, reset]);

  return (
    <Dialog
      open={openAddContact}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={(event) => {
          if (event) {
            if (typeof event.preventDefault === 'function') {
              event.preventDefault();
            }
            if (typeof event.stopPropagation === 'function') {
              event.stopPropagation();
            }
          }
          return handleSubmit((data) => forSubmit(data, category))(event);
        }}
        data-cy="form-CombinedContactPersonnelModal"
      >
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Stack
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1.5rem',
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue={() => {
                if (isEditing) return selectedContact.name;
                if (!isInContactPage) return dialogValue.name;
                return '';
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  className={classes.cellInput}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                  data-cy="name-input-CombinedContactPersonnelModal"
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              defaultValue={isEditing ? selectedContact.email : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  className={classes.cellInput}
                  error={errors.email}
                  helperText={errors.email && errors.email.message}
                  data-cy="email-input-CombinedContactPersonnelModal"
                />
              )}
            />

            <Controller
              name="phone"
              control={control}
              defaultValue={isEditing ? selectedContact.phone : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  variant="outlined"
                  className={classes.cellInput}
                  error={errors.phone}
                  helperText={errors.phone && errors.phone.message}
                  data-cy="phone-input-CombinedContactPersonnelModal"
                />
              )}
            />

            {category === 'Journalists' ? (
              <>
                <Controller
                  name="Org"
                  control={control}
                  defaultValue={currentOrg ? currentOrg._id : preFillOrgUnit?._id}
                  render={({ onChange, field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      clearOnEscape
                      openOnFocus
                      autoHighlight
                      options={orgs}
                      getOptionLabel={(option) => option.name}
                      value={currentOrg ? currentOrg : preFillOrgUnit}
                      onChange={(_, data) => {
                        setPrefillOrgUnit(data);
                        field.onChange(data ? data._id : data);
                        if (isEditing) setChangeWarning(true);
                      }}
                      data-cy="org-autoComplete-CombinedContactPersonnelModal"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Organization"
                          variant="outlined"
                          className={classes.cellInput}
                          error={errors.Org}
                          helperText={errors.Org && errors.Org.message}
                          data-cy="org-input-CombinedContactPersonnelModal"
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="cell"
                  control={control}
                  defaultValue={isEditing ? selectedContact.cell : ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Cell"
                      variant="outlined"
                      className={classes.cellInput}
                      error={errors.phone}
                      helperText={errors.phone && errors.phone.message}
                      data-cy="cell-input-CombinedContactPersonnelModal"
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={isEditing ? preFillOrgUnit?._id : ''}
                  render={({ onChange, field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      clearOnEscape
                      openOnFocus
                      autoHighlight
                      options={units}
                      getOptionLabel={(option) => option.name}
                      value={preFillOrgUnit}
                      onChange={(_, data) => {
                        setPrefillOrgUnit(data);
                        field.onChange(data ? data._id : data);
                        if (isEditing) setChangeWarning(true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Unit"
                          variant="outlined"
                          className={classes.cellInput}
                          error={errors.unit}
                          helperText={errors.unit && errors.unit.message}
                          data-cy="unit-input-CombinedContactPersonnelModal"
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="language"
                  control={control}
                  defaultValue={isEditing ? selectedContact.language : ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Language"
                      variant="outlined"
                      className={classes.cellInput}
                      error={errors.language}
                      helperText={errors.language && errors.language.message}
                      data-cy="language-input-CombinedContactPersonnelModal"
                    />
                  )}
                />
                <Controller
                  name="gender"
                  control={control}
                  defaultValue={isEditing ? selectedContact?.gender : ''}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      clearOnEscape
                      openOnFocus
                      autoHighlight
                      options={genderOptions}
                      getOptionLabel={(option) => option.name ?? option}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      onChange={(_, data) => {
                        field.onChange(data ? data.name : data);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          variant="outlined"
                          className={classes.cellInput}
                          error={errors.gender}
                          helperText={errors.gender && errors.gender.message}
                          data-cy="gender-input-CombinedContactPersonnelModal"
                        />
                      )}
                    />
                  )}
                />
              </>
            )}
            <br />
            <Controller
              name="notes"
              control={control}
              defaultValue={isEditing ? selectedContact.notes : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Notes"
                  multiline
                  minRows={2}
                  maxRows={5}
                  variant="outlined"
                  inputProps={{ maxLength: CONTACT_MAX_NOTES_LEN }}
                  helperText={
                    <Box className={classes.charCountText}>{`${
                      watch('notes').length
                    }/${CONTACT_MAX_NOTES_LEN} character(s)`}</Box>
                  }
                  className={classes.cellInput}
                  error={errors.notes}
                  data-cy="notes-input-CombinedContactPersonnelModal"
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-cy="cancel-button-CombinedContactPersonnelModal">
            Cancel
          </Button>
          {isEditing && (
            <Button
              onClick={() => setOpenMergeContact(true)}
              startIcon={<MergeIcon />}
              data-cy="merge-button-CombinedContactPersonnelModal"
            >
              Merge
            </Button>
          )}
          {isEditing && (
            <Button
              onClick={() => handleArchive()}
              startIcon={<ArchiveIcon />}
              data-cy="archive-button-CombinedContactPersonnelModal"
            >
              Archive
            </Button>
          )}
          {isEditing && (
            <Button type="submit" startIcon={<SyncAltIcon />} data-cy="update-button-CombinedContactPersonnelModal">
              Update
            </Button>
          )}
          {!isEditing && (
            <Button type="submit" startIcon={<AddCircleIcon />} data-cy="add-button-CombinedContactPersonnelModal">
              Add
            </Button>
          )}
        </DialogActions>
      </form>

      <MergeContactPersonnelModal
        openMergeContact={openMergeContact}
        setOpenMergeContact={setOpenMergeContact}
        selectedContact={selectedContact}
        category={category}
        contacts={contacts}
        orgs={orgs}
        units={units}
        setRefreshContacts={setRefreshContacts}
        setOpenAddContact={setOpenAddContact}
      />
      <AlertChangeOrgUnit open={changeWarning} setOpen={setChangeWarning} category={category} />
    </Dialog>
  );
};

export default CombinedContactPersonnelModal;
