export const enum AnalyticsFiltersEnum {
  campaigns = 'Campaigns',
  units = 'Units',
  startDate = 'Start Date',
  endDate = 'End Date',
  teamMembers = 'Team Members',
}

export const enum AnalyticsAdvancedFiltersEnum {
  status = 'Status',
  type = 'Type',
  dept = 'Department',
  outlet = 'Outlet',
}
