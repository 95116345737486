import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ManageCollaboratorsDialogProps } from './Types/componentProps';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  usePromoteCollaborationGroupOwnersMutation,
  useRemoveGroupCollaboratorsMutation,
} from './Hooks/useCollaborationGroup';
import { useQueryClient } from '@tanstack/react-query';

const ManageGroupCollaboratorsDialog: React.FC<ManageCollaboratorsDialogProps> = ({
  type,
  open,
  groupId,
  groupOwners,
  groupMembers,
  onClose,
}) => {
  const [candidates] = useState(() => {
    const groupOwnerIds = groupOwners.map((owner) => owner._id);
    return groupMembers.filter((member) => !groupOwnerIds.includes(member._id));
  });

  const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>([]);

  const queryClient = useQueryClient();

  const onMutationSuccessCallback = () => {
    queryClient.invalidateQueries({
      queryKey: ['collaboration/group-details'],
    });
    queryClient.invalidateQueries({
      queryKey: ['collaboration/groups'],
    });
    handleCloseDialog();
  };

  const promoteGroupOwnersMutation = usePromoteCollaborationGroupOwnersMutation({
    onSuccessCallback: onMutationSuccessCallback,
  });

  const removeGroupCollaboratorsMutation = useRemoveGroupCollaboratorsMutation({
    onSuccessCallback: onMutationSuccessCallback,
  });

  const handleCloseDialog = () => {
    setSelectedCandidateIds([]);
    onClose();
  };

  const handleToggleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, tenantId: string) => {
    if (e.target.checked) {
      setSelectedCandidateIds([...selectedCandidateIds, tenantId]);
    } else {
      setSelectedCandidateIds(selectedCandidateIds.filter((id) => id !== tenantId));
    }
  };

  const handleSave = () => {
    if (type === 'promote') {
      promoteGroupOwnersMutation.mutateAsync({
        groupId,
        data: {
          members: selectedCandidateIds,
        },
      });
    } else if (type === 'remove') {
      removeGroupCollaboratorsMutation.mutateAsync({
        groupId,
        data: {
          members: selectedCandidateIds,
        },
      });
    }
  };

  const renderDialogContent = () => {
    if (candidates.length === 0) {
      return <>No collaborator to {type}</>;
    } else {
      return (
        <FormGroup>
          {candidates.map((candidate) => (
            <FormControlLabel
              data-cy="manage-collaborators-dialog-checkbox"
              key={`{type}-candidate-${candidate._id}`}
              label={`${candidate.organizationName} (${candidate.organizationCode})`}
              control={<Checkbox onChange={(e) => handleToggleCheckBox(e, candidate._id)} />}
            />
          ))}
        </FormGroup>
      );
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle data-cy="manage-collaborators-dialog-title">
        {type === 'promote' ? 'Promote Collaborator(s) to Group Lead(s)' : 'Remove Collaborator(s) from Group'}
      </DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <Button
          data-cy="manage-collaborators-dialog-actions-cancel-btn"
          variant="outlined"
          onClick={() => handleCloseDialog()}
        >
          Cancel
        </Button>
        <LoadingButton
          data-cy="manage-collaborators-dialog-actions-save-btn"
          variant="contained"
          disabled={selectedCandidateIds.length === 0}
          loading={
            type === 'promote' ? promoteGroupOwnersMutation.isPending : removeGroupCollaboratorsMutation.isPending
          }
          onClick={() => handleSave()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ManageGroupCollaboratorsDialog;
