import { LoadingButton } from '@mui/lab';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDisbandCollaborationGroupMutation, useQuitCollaborationGroupMutation } from './Hooks/useCollaborationGroup';
import { DisbandOrQuitCollaborationGroupDialogProps } from './Types/componentProps';
import { useQueryClient } from '@tanstack/react-query';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';

const quitGroupDialogContent =
  'By quitting this group, you will not be able to access this group and all shared resources/contacts of yours will be removed from this group.';
const disbandGroupDialogContent =
  'By disbanding this group, all member collaborators of this group will not be able to access this group and all shared resources/contacts of this group will be removed.';

const DisbandOrQuitCollaborationGroupDialog: React.FC<DisbandOrQuitCollaborationGroupDialogProps> = ({
  type,
  toggleDialog,
  onDialogClose,
}) => {
  const queryClient = useQueryClient();

  const { selectedGroupId, dispatch: dispatchSelectedCollaborationGroupId } = useContext(
    SelectedCollaborationGroupIdContext
  );

  const [dialogContent, setDialogContent] = useState<string>('');

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['collaboration/groups'],
    });
    dispatchSelectedCollaborationGroupId({ type: 'UPDATE_SELECT_GROUP_ID', payload: '' });
  };

  const groupDisbandMutation = useDisbandCollaborationGroupMutation({
    onSuccessCallback: handleMutationSuccess,
  });

  const groupQuitMutation = useQuitCollaborationGroupMutation({
    onSuccessCallback: handleMutationSuccess,
  });

  useEffect(() => {
    if (type === 'disband') {
      setDialogContent(disbandGroupDialogContent);
    } else if (type === 'quit') {
      setDialogContent(quitGroupDialogContent);
    }
  }, [type]);

  const handleConfirm = async () => {
    if (selectedGroupId !== '') {
      if (type === 'disband') {
        await groupDisbandMutation.mutateAsync(selectedGroupId);
      } else if (type === 'quit') {
        await groupQuitMutation.mutateAsync(selectedGroupId);
      }
      onDialogClose();
    }
  };

  return (
    <Dialog open={toggleDialog} data-cy="collaboration-group-details-invitations-list-dialogs-disband-or-quit-dialog">
      <DialogTitle>Important Message</DialogTitle>
      <DialogContent data-cy="collaboration-group-details-invitations-list-dialogs-disband-or-quit-dialog-content">
        <Alert color="warning">{dialogContent}</Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onDialogClose()}
          data-cy="collaboration-group-details-invitations-list-dialogs-disband-or-quit-dialog-cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={groupDisbandMutation.isPending}
          onClick={() => handleConfirm()}
          data-cy="collaboration-group-details-invitations-list-dialogs-disband-or-quit-dialog-confirm-btn"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DisbandOrQuitCollaborationGroupDialog;
