import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar, Button, createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { closeSnackbar } from './snackbarSlice';
import { useContext } from 'react';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import { SocketContext } from '../../shared/contexts/SocketContext';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'green',
          color: 'white',
        },
        // standardError: {
        //   backgroundColor: 'red',
        //   color: 'white',
        // },
        standardWarning: {
          backgroundColor: 'goldenrod',
          color: 'white',
        },
        // standardInfo: {
        //   backgroundColor: 'blue',
        //   color: 'white',
        // },
      },
    },
  },
});

export default function AppSnackbar() {
  const socket = useContext(SocketContext);
  const eventMap = {
    deleteIssue: { label: 'UNDO', callback: MediaInteractionEventHandler(socket).undoDelete },
    deleteService: { label: 'UNDO', callback: ServiceLogEventHandler(socket).undoDelete },
  };
  const severities = ['info', 'warning', 'alert', 'success', 'error'];

  const dispatch = useDispatch();
  const { isOpen, message, severity, callbackData, eventType } = useSelector((state) => state.snackbar);

  const handleClose = (reason) => {
    if ('clickaway' === reason) return;

    dispatch(closeSnackbar());
  };

  const handleCallback = () => {
    try {
      eventMap[eventType]['callback'](callbackData);
    } catch (e) {
      alert('callback failed - ' + e);
    }
  };

  let label = null;
  if (eventMap.hasOwnProperty(eventType)) {
    label = eventMap[eventType]['label'];
  }

  let alertSeverity = 'info';
  if (severities.indexOf(severity) > -1) {
    alertSeverity = severity;
  } else {
    console.log('invalid severity provided');
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={isOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
        >
          <Alert onClose={handleClose} severity={alertSeverity}>
            {message}
            {label ? (
              <Button color="secondary" size="small" onClick={handleCallback}>
                {label}
              </Button>
            ) : (
              <></>
            )}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
