import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import OutletList from '../OutletList';
import { getOrganizations, mergeOrganizations } from '../contactsRequest';
import MergeIcon from '@mui/icons-material/MergeType';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';

function MergeOutletsModal(props) {
  const { openMergeOutlet, setOpenMergeOutlet, selectedOutlet, setRefreshContacts, setOpenEditOutlet } = props;
  const [outlets, setOutlets] = useState([]);
  const [outletsToBeMerged, setOutletsToBeMerged] = useState([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenMergeOutlet(false);
    setOpenEditOutlet(false);
  };

  const handleMerge = async () => {
    const res = await mergeOrganizations(outletsToBeMerged, selectedOutlet);
    if (res.status === 200) {
      dispatch(openSnackbar({ severity: 'success', message: 'Outlet Merge Successful!' }));
      handleClose();
      setRefreshContacts(true);
    } else {
      dispatch(openSnackbar({ severity: 'error', message: 'Outlet Merge Failed!' }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrganizations();
      setOutlets(response.data ?? []);
    };

    fetchData();
  }, []); // Assuming getOrganizations doesn't need to be in the dependency array

  return (
    <Dialog
      open={openMergeOutlet}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      data-cy="dialog-MergeOutletsModal"
    >
      <DialogTitle>Merge Contact</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {'Please select which outlet(s) to merge into:'}
          <strong> {selectedOutlet?.name}</strong>
        </Typography>
        <Typography variant="body1">
          Selected to become above:
          <strong>{outletsToBeMerged.map((contact) => contact.name).join()}</strong>
        </Typography>
        <Box
          sx={{
            height: '50vh',
          }}
        >
          <OutletList
            outlets={outlets}
            isMerging={true}
            selectedOutlet={selectedOutlet}
            outletsToBeMerged={outletsToBeMerged}
            setOutletsToBeMerged={setOutletsToBeMerged}
          />
        </Box>
        <DialogActions>
          <br></br>
          <Button onClick={handleClose} variant="contained" data-cy="cancel-button-MergeOutletsModal">
            Cancel
          </Button>
          <Button
            onClick={handleMerge}
            startIcon={<MergeIcon />}
            variant="contained"
            data-cy="merge-button-MergeOutletsModal"
          >
            Merge
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default MergeOutletsModal;
