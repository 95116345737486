import { SetupSharedMediaInteractionFilterDialogProps } from './Types/componentProps';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpsertCollaborationGroupSharedResourcesFilterMutation } from './Hooks/useCollaborationGroup';
import { useQueryClient } from '@tanstack/react-query';
import FilterModalDateRangeInputs from '../TrackerFilter/FilterModalDateRangeInputs';
import FilterModalTextInput from '../TrackerFilter/FilterModalTextInput';
import DescriptionIcon from '@mui/icons-material/Description';
import FilterModalSelectInput from '../TrackerFilter/FilterModalSelectInput';
import { useContext, useState } from 'react';
import {
  DropdownTableCampaign,
  DropdownTableContext,
  DropdownTableContextValueKey,
  DropdownTableExpert,
  DropdownTableJournalist,
  DropdownTableLead,
  DropdownTableOutlet,
  DropdownTableStatus,
  DropdownTableType,
  DropdownTableUnit,
} from '../../shared/contexts/DropdownTableContext';
import { sharedInteractionsAutoCompleteFilters } from './Utils/constants';
import moment from 'moment-timezone';
import { paletteColorsHeaderMain } from '../../shared/constants/constants';

interface DateFilterCriteria {
  timezone: string;
  start: Date;
  end: Date;
}

interface AutoCompleteFilterCriteria {
  outlets: string[];
  journalists: string[];
  experts: string[];
  units: string[];
  types: string[];
  status: string[];
  campaigns: string[];
  teamMembers: string[];
}

const SetupSharedMediaInteractionFilterDialog: React.FC<SetupSharedMediaInteractionFilterDialogProps> = ({
  open,
  onClose,
  groupId,
  sharedInteractionFilter,
}) => {
  const queryClient = useQueryClient();
  const sharedMediaInteractionUpsertMutation = useUpsertCollaborationGroupSharedResourcesFilterMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
      onClose();
    },
  });

  const dropdownTableContext = useContext(DropdownTableContext);

  const [topicFilterCriteria, setTopicFilterCriteria] = useState<string>(() => {
    if (!sharedInteractionFilter) {
      return '';
    }

    return sharedInteractionFilter.criteria.topic ? sharedInteractionFilter.criteria.topic : '';
  });

  const [dateFilterCriteria, setDateFilterCriteria] = useState<DateFilterCriteria>(() => {
    if (!sharedInteractionFilter) {
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month').toDate();
      const endOfYesterday = moment().endOf('day').toDate();
      return {
        timezone: moment.tz.guess(),
        start: startOfLastMonth,
        end: endOfYesterday,
      };
    }

    const { timezone, start, end } = sharedInteractionFilter.criteria.dateFilter;
    return {
      timezone,
      start: moment(start).tz(timezone).toDate(),
      end: moment(end).tz(timezone).toDate(),
    };
  });

  const [autoCompleteFilterCriteria, setAutoCompleteFilterCriteria] = useState<AutoCompleteFilterCriteria>(() => {
    if (!sharedInteractionFilter) {
      return {
        outlets: [],
        journalists: [],
        experts: [],
        units: [],
        types: [],
        status: [],
        campaigns: [],
        teamMembers: [],
      };
    }

    const { outlets, journalists, experts, units, types, status, campaigns, teamMembers } =
      sharedInteractionFilter.criteria;
    return {
      outlets: outlets ? outlets : [],
      journalists: journalists ? journalists : [],
      experts: experts ? experts : [],
      units: units ? units : [],
      types: types ? types : [],
      status: status ? status : [],
      campaigns: campaigns ? campaigns : [],
      teamMembers: teamMembers ? teamMembers : [],
    };
  });

  const onDateFilterCriteriaStartChange = (date: Date) => {
    setDateFilterCriteria({
      ...dateFilterCriteria,
      start: moment(date).tz(dateFilterCriteria.timezone).startOf('day').toDate(),
    });
  };

  const onDateFilterCriteriaEndChange = (date: Date) => {
    setDateFilterCriteria({
      ...dateFilterCriteria,
      end: moment(date).tz(dateFilterCriteria.timezone).endOf('day').toDate(),
    });
  };

  const getAutoCompleteFilterSortedOptions = (filterType: DropdownTableContextValueKey) => {
    if (filterType === 'type') {
      return dropdownTableContext[filterType].sort(
        (a, b) => -b.type.localeCompare(a.type) || -b.name.charAt(0).localeCompare(a.name.charAt(0))
      );
    }

    // exclude properties that does not have `name`
    if (filterType !== 'topic') {
      return dropdownTableContext[filterType].sort((a, b) => -b.name.charAt(0).localeCompare(a.name.charAt(0)));
    }

    console.error('unknown/unprocessable filter type', filterType);
    return [];
  };

  const getAutoCompleteFilterCriteriaProps = (filterType: DropdownTableContextValueKey) => {
    switch (filterType) {
      case 'outlet':
        return {
          value: autoCompleteFilterCriteria.outlets,
          onChange: (values: DropdownTableOutlet[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              outlets: values.map((value) => value._id),
            });
          },
        };
      case 'journalist':
        return {
          value: autoCompleteFilterCriteria.journalists,
          onChange: (values: DropdownTableJournalist[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              journalists: values.map((value) => value._id),
            });
          },
        };
      case 'expert':
        return {
          value: autoCompleteFilterCriteria.experts,
          onChange: (values: DropdownTableExpert[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              experts: values.map((value) => value._id),
            });
          },
        };
      case 'units':
        return {
          value: autoCompleteFilterCriteria.units,
          onChange: (values: DropdownTableUnit[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              units: values.map((value) => value.name),
            });
          },
        };
      case 'type':
        return {
          value: autoCompleteFilterCriteria.types,
          onChange: (values: DropdownTableType[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              types: values.map((value) => value._id),
            });
          },
        };
      case 'status':
        return {
          value: autoCompleteFilterCriteria.status,
          onChange: (values: DropdownTableStatus[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              status: values.map((value) => value.name),
            });
          },
        };
      case 'campaigns':
        return {
          value: autoCompleteFilterCriteria.campaigns,
          onChange: (values: DropdownTableCampaign[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              campaigns: values.map((value) => value._id),
            });
          },
        };
      case 'lead':
        return {
          value: autoCompleteFilterCriteria.teamMembers,
          onChange: (values: DropdownTableLead[]) => {
            setAutoCompleteFilterCriteria({
              ...autoCompleteFilterCriteria,
              teamMembers: values.map((value) => value.sub),
            });
          },
        };
      default:
        console.error('unknown autocomplete filter type', filterType);
        return {
          value: [],
          onChange: (value: any[]) => {},
        };
    }
  };

  const getAutoCompleteFilterGroupBy = (filterType: DropdownTableContextValueKey) => {
    if (filterType === 'type') {
      return (option: any) => option.type;
    }

    return (option: any) => option.name.charAt(0);
  };

  const onSave = () => {
    const { outlets, journalists, experts, units, types, status, campaigns, teamMembers } = autoCompleteFilterCriteria;

    sharedMediaInteractionUpsertMutation.mutateAsync({
      groupId: groupId,
      data: {
        resourceType: 'issue',
        resourceFields: ['all_issue_fields'],
        filterCriteria: {
          dateFilter: {
            timezone: dateFilterCriteria.timezone,
            start: moment(dateFilterCriteria.start).tz(dateFilterCriteria.timezone).toISOString(),
            end: moment(dateFilterCriteria.end).tz(dateFilterCriteria.timezone).toISOString(),
          },
          topic: topicFilterCriteria ? topicFilterCriteria : undefined,
          outlets: outlets.length === 0 ? undefined : outlets,
          journalists: journalists.length === 0 ? undefined : journalists,
          experts: experts.length === 0 ? undefined : experts,
          units: units.length === 0 ? undefined : units,
          types: types.length === 0 ? undefined : types,
          status: status.length === 0 ? undefined : status,
          campaigns: campaigns.length === 0 ? undefined : campaigns,
          teamMembers: teamMembers.length === 0 ? undefined : teamMembers,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>Set up shared Media Interactions Filter</DialogTitle>
      <DialogContent data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-content">
        <FilterModalDateRangeInputs
          startDate={dateFilterCriteria.start}
          endDate={dateFilterCriteria.end}
          onStartDateChange={onDateFilterCriteriaStartChange}
          onEndDateChange={onDateFilterCriteriaEndChange}
        />
        <FilterModalTextInput
          displayKey={'Topic'}
          filterName={'Topic'}
          icon={<DescriptionIcon />}
          value={topicFilterCriteria}
          onChange={(value) => setTopicFilterCriteria(value)}
        />
        {sharedInteractionsAutoCompleteFilters.map(({ filterType, filterName, helperText, icon }) => {
          const { value, onChange } = getAutoCompleteFilterCriteriaProps(filterType);
          return (
            <FilterModalSelectInput
              key={`autocomplete-filter-${filterType}`}
              groupTabColor={paletteColorsHeaderMain[0]}
              filterName={filterName}
              helperText={helperText}
              icon={icon}
              filterOptions={getAutoCompleteFilterSortedOptions(filterType)}
              value={value}
              onChange={onChange}
              groupBy={getAutoCompleteFilterGroupBy(filterType)}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose()}
          data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={sharedMediaInteractionUpsertMutation.isPending}
          onClick={() => onSave()}
          data-cy="collaboration-group-details-interactions-filter-setup-filter-dialog-save-btn"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SetupSharedMediaInteractionFilterDialog;
