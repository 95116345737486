import { Chip } from '@mui/material';
import { CollaborationGroupInvitationsListItemStatusChipProps } from './Types/componentProps';

const CollaborationGroupInvitationsListItemStatusChip: React.FC<
  CollaborationGroupInvitationsListItemStatusChipProps
> = ({ status, isExpired }) => {
  if (isExpired && status !== 'accepted') {
    return (
      <Chip
        label="Expired"
        color="warning"
        data-cy="collaboration-group-details-invitations-list-invitations-status-chip-expired"
      />
    );
  }
  switch (status) {
    case 'accepted':
      return (
        <Chip
          label="Accepted"
          color="success"
          data-cy="collaboration-group-details-invitations-list-invitations-status-chip-accepted"
        />
      );
    case 'pending':
      return (
        <Chip
          label="Pending"
          color="primary"
          data-cy="collaboration-group-details-invitations-list-invitations-status-chip-pending"
        />
      );
    case 'rejected':
      return (
        <Chip
          label="Rejected"
          color="error"
          data-cy="collaboration-group-details-invitations-list-invitations-status-chip-rejected"
        />
      );
    default:
      return null;
  }
};

export default CollaborationGroupInvitationsListItemStatusChip;
