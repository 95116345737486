import { Typography } from '@mui/material';
import { Button, Divider, Tooltip, IconButton, LinearProgress, Alert } from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemAvatar } from '@mui/material';
import { Avatar } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import TableViewIcon from '@mui/icons-material/TableView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import ContactsPageEditOutletModal from './Modal/ContactsPageEditOutletModal';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useArchiveContact } from './Hooks/useArchiveContact';
import { useJournalistsByOutlet } from './Hooks/useJournalistsByOutlet';

const OutletProfile = (props) => {
  const { selectedOutlet, setRefreshContacts } = props;
  const { data: journalistList, status, isLoading } = useJournalistsByOutlet(selectedOutlet._id);
  const [openEditOutlet, setOpenEditOutlet] = useState(false);
  const navigate = useNavigate();
  const dispath = useFiltersDispatch();

  const archiveContactMutation = useArchiveContact({
    contactId: selectedOutlet?._id,
    category: 'Outlets',
    isArchiving: false,
    setRefetchContacts: setRefreshContacts,
  });

  const todaysDate = new Date().toLocaleString();

  const mediaInteractionsFilterBasedOnSelectedOutlet = {
    outlet: [
      {
        firstLetter: selectedOutlet.name.split(' ')[0][0],
        name: selectedOutlet.name,
        _id: selectedOutlet._id,
      },
    ],
  };

  const JournalistHeaders = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Outlet', key: 'Org.name' },
    { label: 'Cell', key: 'cell' },
    { label: 'Notes', key: 'notes' },
  ];

  const handleUnarchiveButtonClick = async () => {
    await archiveContactMutation.mutateAsync();
  };

  let editOutletButton = (
    <Button
      onClick={() => {
        setOpenEditOutlet(true);
      }}
      data-cy="editContact-button-OutletProfile"
    >
      <EditOutlinedIcon />
    </Button>
  );

  if (selectedOutlet.archived) {
    editOutletButton = (
      <Tooltip>
        <IconButton size="small" onClick={() => handleUnarchiveButtonClick()} data-cy="unarchive-button-OutletProfile">
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (status === 'error') {
    return <Alert severity="error">Error fetching journalists of {selectedOutlet.name}</Alert>;
  }

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      >
        <ListItem key={'name'}>
          <ListItemAvatar>
            <Avatar>{selectedOutlet.name.split(' ')[0][0]}</Avatar>
          </ListItemAvatar>
          <Typography variant="h5">{selectedOutlet.name}</Typography>
          {editOutletButton}
        </ListItem>
        <Divider variant="insert" component="li"></Divider>
        {isLoading && <LinearProgress />}
        <ListItem
          key={'journalists'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '100%',
          }}
        >
          <Typography variant="h5">
            Journalists:
            {status === 'success' && (
              <CSVLink
                data={journalistList?.data}
                headers={JournalistHeaders}
                filename={`${selectedOutlet.name} - Journalists Export, ${todaysDate}.csv`}
              >
                <Tooltip title="Export the following journalists to a spreadsheet" placement="right">
                  <IconButton>
                    <DownloadOutlinedIcon color="action" />
                  </IconButton>
                </Tooltip>
              </CSVLink>
            )}
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              dispath({ type: 'setFilters', payload: mediaInteractionsFilterBasedOnSelectedOutlet });
              navigate(RouteInformation.mediaInteraction.path);
            }}
          >
            <TableViewIcon />
            Table view
          </Button>
        </ListItem>
        {status === 'success' &&
          journalistList?.data?.map((personnel) => (
            <ListItem key={personnel._id}>
              <Link
                to={RouteInformation.mediaInteraction.path}
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  const mediaInteractionsFilterBasedOnPersonnel = {
                    journalist: [
                      {
                        firstLetter: personnel.name.split(' ')[0][0],
                        name: personnel.name,
                        _id: personnel._id,
                      },
                    ],
                  };

                  // Set filters with selected contact and the personnels under selected contact
                  dispath({
                    type: 'setFilters',
                    payload: {
                      ...mediaInteractionsFilterBasedOnSelectedOutlet,
                      ...mediaInteractionsFilterBasedOnPersonnel,
                    },
                  });
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: 'inline',
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  {`${personnel.name} ${personnel.archived ? '(Archived)' : ''}`}
                </Typography>
              </Link>
            </ListItem>
          ))}
      </List>
      <ContactsPageEditOutletModal
        openModal={openEditOutlet}
        setOpenModal={setOpenEditOutlet}
        setRefreshContacts={setRefreshContacts}
        selectedOutlet={selectedOutlet}
      />
    </>
  );
};

export default OutletProfile;
