import { useState } from 'react';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import { QueryAdderCell } from './QueryAdderCell';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box } from '@mui/material';
import moment from 'moment';
import { diversityOptions, getTabColorHeaderMain, TrackerDataType } from '../../shared/constants/constants';
import { rowOptions } from '../../shared/constants/commonRowValues';
import { queryAdderMediaFields, queryAdderServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import ErrorBoundary from '../ErrorBoundary';
import { getServiceById } from '../../shared/requests/serviceNetworkRequests';
import { processFromDBForAutoComplete } from '../../shared/functions/autoCompleteProcessing';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import findOutletFromJournalist from '../../shared/functions/findOutletFromJournalist';
import { useSelector } from 'react-redux';
import { sendNotification } from '../../shared/requests/notifications';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px 5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > label': {
      width: '100%',
    },
    '& > div': {
      flex: '1 1 220',
    },
  },
  containerDrag: {
    border: 'dashed grey 4px',
    display: 'flex',
    backgroundColor: '#cbd5e1',
    flexWrap: 'wrap',
    gap: '10px 5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > label': {
      width: '100%',
    },
    '& > div': {
      flex: '1 1 220',
    },
  },
  adderButton: {
    width: '220px',
    height: '40px',
    fontSize: '16px',
    margin: '0 10px',
    fontWeight: 550,
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
  },
  buttonContainer: {
    display: 'block',
    margin: '15px auto',
    textAlign: 'center',
    flex: 'none !important',
    width: '100% !important',
  },
  textInput: {
    width: '100%',
  },
  spinner: {
    marginLeft: '60%',
    position: 'absolute',
  },
}));

const textInput = ['topic', 'response', 'service', 'keyMessaging', 'actions'];

export default function QueryAdder(props) {
  const { dropdownTable, setOpenForm, eventHandler, cells, issueId, linked, forAutoTracker, emailScrapeCallback } =
    props;
  const { pathname } = useLocation();

  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });
  const columns = Object.keys(
    eventHandler.type === TrackerDataType.MEDIA_INTERACTION ? queryAdderMediaFields : queryAdderServiceKeys
  );
  const defaultDate = new Date();
  let initialState = { date: defaultDate, status: rowOptions.PROGRESS };
  const currentUser = useSelector((state) => state.user.userInfo);

  const [isDragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);

  if (eventHandler.type === 'MediaInteraction') {
    initialState.status = rowOptions.PROGRESS;
  } else {
    initialState.diversity = diversityOptions[1]._id;
  }

  //importObject is an object with the following possible fields to autocomplete:
  //must prepare your object for use before using in autoComplete.
  // {
  //   Date: String representing date String,
  //   Topic: String with topic text,
  //   Service: String with service topic text,
  //   Linked Service: obj with {_id: String, name: String},
  //   Team Members: Array of Obj {_id: String, name: String},
  //   Team Member: Array of Obj {_id: String, name: String},
  //   Unit: Array of String,
  //   Campaign: Array of String,
  //   Type: Array of String,
  //   Status: String,
  //   Key Messaging: String,
  // }

  const autoPopulate = (importObject) => {
    const autoPopulateState = {};
    if (eventHandler.type === TrackerDataType.SERVICE) {
      autoPopulateState.linkedInteraction = [issueId];
    }
    const keys = eventHandler.type === 'MediaInteraction' ? ServiceKeys : IssueKeys;
    columns.forEach((column) => {
      let autoPopObj = null;
      switch (column) {
        case 'date':
          break;
        case 'topic':
          autoPopObj = importObject[ServiceKeys.service];
          break;
        case 'service':
          autoPopObj = importObject[IssueKeys.topic];
          break;
        case 'linkedService':
          const obj = {
            _id: importObject._id,
            name: importObject[ServiceKeys.service],
          };
          autoPopObj = [obj];

          break;
        case 'lead':
          // importObject[ServiceKeys.teamMember]
          //   ? (autoPopObj = [importObject[ServiceKeys.teamMember]])
          //   : (autoPopObj = null);
          autoPopObj = null;
          break;
        case 'expert':
          if (importObject[ServiceKeys.unitLead]?.[0]?.name) {
            autoPopObj = importObject[ServiceKeys.unitLead];
          } else {
            autoPopObj = [];
            importObject[ServiceKeys.unitLead]?.forEach((unitLeadId) => {
              const expertObject = dropdownTable['expert'].find((member) => member._id === unitLeadId);
              if (expertObject) {
                autoPopObj.push(expertObject);
              }
            });
          }
          break;
        case 'keyMessaging':
          break;
        case 'unitLead':
          autoPopObj = importObject[IssueKeys.expert];
          break;
        case 'teamMember':
          autoPopObj = [dropdownTable['lead'].find((member) => member._id === currentUser.id)];
          break;
        case 'file':
          break;
        default:
          if (IssueKeys[column]) {
            autoPopObj = importObject[keys[column]];
          }
          break;
      }
      if (autoPopObj) {
        autoPopulateState[column] = autoPopObj;
      }
    });

    return autoPopulateState;
  };

  if (linked) {
    initialState = { ...initialState, ...autoPopulate(cells) };
  }

  if (forAutoTracker) {
    initialState = { ...initialState, ...cells };
  }

  const [fields, setFields] = useState(initialState);
  const [errorCols, setErrorCols] = useState([]);
  const [loading, setLoading] = useState(false);

  const requiredInputs =
    eventHandler.type === TrackerDataType.MEDIA_INTERACTION
      ? [
          queryAdderMediaFields.topic,
          queryAdderMediaFields.date,
          queryAdderMediaFields.type,
          queryAdderMediaFields.units,
          queryAdderMediaFields.journalist,
          queryAdderMediaFields.status,
          queryAdderMediaFields.lead,
          queryAdderMediaFields.expert,
        ]
      : [
          queryAdderServiceKeys.date,
          queryAdderServiceKeys.service,
          queryAdderServiceKeys.type,
          queryAdderServiceKeys.units,
          queryAdderServiceKeys.teamMember,
          queryAdderServiceKeys.diversity,
          queryAdderServiceKeys.status,
          queryAdderServiceKeys.unitLead,
        ];

  const validateInput = () => {
    const invalidCols = [];
    requiredInputs.forEach((input) => {
      if (fields[input] === null || fields[input] === undefined || fields[input] === '') {
        invalidCols.push(input);
        return;
      }
      if (Array.isArray(fields[input]) && fields[input].length < 1) {
        invalidCols.push(input);
      }
    });
    setErrorCols(invalidCols);
    return invalidCols.length < 1;
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0] && isDragActive) {
      let newFiles = [...files] || [];
      const transferredFiles = e.dataTransfer.files;

      for (let i = 0; i < transferredFiles.length; i++) {
        newFiles.push(transferredFiles[i]);
      }

      setFiles(newFiles);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    fields['year'] = moment.utc(new Date(fields.date)).format('YYYY');
    fields['month'] = moment.utc(new Date(fields.date)).format('MMM');

    delete fields.outlet;
    try {
      if (validateInput()) {
        eventHandler.createNewItem(fields, files, ({ postId }) => {
          setOpenForm(false);

          if (eventHandler.type === TrackerDataType.MEDIA_INTERACTION) {
            sendNotification(
              fields.lead
                ?.map((teamMember) => teamMember._id)
                .filter((teamMemberId) => teamMemberId !== currentUser.id) ?? [],
              postId,
              fields.topic,
              true
            );
          } else {
            sendNotification(
              fields.teamMember
                ?.map((teamMember) => teamMember._id)
                .filter((teamMemberId) => teamMemberId !== currentUser.id) ?? [],
              postId,
              fields.service,
              false
            );
          }
          if (forAutoTracker) {
            emailScrapeCallback(fields);
          }
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (val, col) => {
    let newField;
    switch (col) {
      case 'journalist':
      case 'expert':
        newField = val;
        break;
      case 'outlet':
        newField = val?._id;
        break;
      case 'teamMember':
        newField = val;
        break;
      case 'topic':
      case 'response':
      case 'lead':
      case 'type':
      case 'date':
      case 'service':
      case 'unitLead':
      case 'keyMessaging':
      case 'actions':
      case 'linkedInteraction':
        newField = val;
        break;
      case 'linkedService':
        if (val.length > 0) {
          //since linkedServices can be multiple services, choose the one which was just added
          const linkedServ = dropdownTable['service'].find((service) => service._id === val[val.length - 1]._id);
          setLoading(true);
          getServiceById(linkedServ._id)
            .then((res) => {
              setLoading(false);
              const service = processFromDBForAutoComplete(res.data, ServiceKeys, dropdownTable);
              newField = val;
              setFields({ ...fields, ...autoPopulate(service), [col]: newField });
            })
            .catch(() => {
              setLoading(false);
            });
        }
        newField = val;
        break;
      case 'campaign':
        newField = val.map((campaign) => {
          return campaign;
        });
        break;
      case 'units':
        newField = val.map((unit) => {
          return unit;
        });
        break;
      case 'diversity':
        newField = val?._id;
        break;
      case 'complexity':
        newField = val?._id;
        break;
      case 'file':
        setFiles(val);
        break;
      case 'dept':
        newField = val.map((dept) => {
          return dept?.name;
        });
        break;
      default:
        newField = val?.name;
        break;
    }
    setFields((fields) => ({ ...fields, [col]: newField }));
    col === 'outlet' && setFields((fields) => ({ ...fields, journalist: null }));
    col === 'journalist' &&
      setFields((fields) => ({ ...fields, outlet: findOutletFromJournalist(newField, dropdownTable['outlet']) }));
  };
  //defaultMonth={props.currMonth} => Removed from form
  return (
    <ErrorBoundary>
      {loading && <Spinner animation="border" role="status" className={classes.spinner}></Spinner>}
      <form className={classes.container} onSubmit={onSubmitForm}>
        {columns.map((col) => {
          return !textInput.includes(col) ? (
            <QueryAdderCell
              key={col}
              classes={classes}
              col={col}
              dropdownTable={dropdownTable}
              fields={fields}
              callback={handleChange}
              defaultMonth={props.currMonth}
              required={requiredInputs.includes(col)}
              error={errorCols.includes(col)}
              eventHandler={eventHandler}
              files={files}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
              isDragActive={isDragActive}
            />
          ) : (
            <QueryAdderCell
              key={col}
              classes={classes}
              col={col}
              dropdownTable={dropdownTable}
              fields={fields}
              callback={handleChange}
              defaultMonth={props.currMonth}
              className={classes.textInput}
              required={requiredInputs.includes(col)}
              error={errorCols.includes(col)}
              eventHandler={eventHandler}
            />
          );
        })}
        <Box className={classes.buttonContainer}>
          <Button
            width={200}
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpenForm(false);
            }}
            className={classes.adderButton}
            data-cy="cancel-button-QueryAdder"
          >
            Cancel
          </Button>
          <Button
            width={200}
            color="secondary"
            variant="contained"
            onClick={onSubmitForm}
            type="submit"
            className={classes.adderButton}
            data-cy="submit-button-QueryAdder"
          >
            {eventHandler.type === TrackerDataType.SERVICE ? 'Add my service!' : 'Add my interaction!'}
          </Button>
        </Box>
      </form>
    </ErrorBoundary>
  );
}
