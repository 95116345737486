import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import UserListComponent from '../Admin/UserListComponent';
import Typography from '@mui/material/Typography';
import { getTenants } from './tenantsPageRequest';
import Button from '@mui/material/Button';
import { Spinner } from 'react-bootstrap';
import TenantCardComponent from './TenantCardComponent';
import AddTenantModal from './AddTenantModal';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import { useQuery } from '@tanstack/react-query';
import BroadcastPopupSetting from './BroadcastPopupSetting';

const useStyles = makeStyles({
  adminWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '3vh',
  },
  outer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileComponentWrapper: {
    marginLeft: '3vw',
  },
  deleteButton: {
    backgroundColor: '#ff604f',
    marginTop: '1vh',
    height: '8%',
    width: '70%',
    color: 'white',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  addClientButton: {
    marginTop: '2vh',
    marginBottom: '2vh',
  },
});
function TenantsPage({ user, editingUserPrivilege }) {
  const classes = useStyles();

  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [addClientOpen, setAddClientOpen] = useState(false);
  const tenantsQuery = useQuery({
    queryKey: ['tenants', 'tenantsPage'],
    queryFn: () => getTenants(),
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (tenantsQuery.data) setClients(tenantsQuery.data);
  }, [tenantsQuery.data]);

  const AddClientButton = () => {
    return (
      <Button
        className={classes.addClientButton}
        color="primary"
        variant="contained"
        onClick={() => setAddClientOpen(true)}
      >
        <AddIcon />
        Add Client
      </Button>
    );
  };
  const addClientToList = (client) => {
    setClients([...clients, client]);
    setSelectedClient(client);
  };

  const getAvatarLetter = (client) => {
    return client.organizationName.slice(0, 1).toUpperCase();
  };

  const getListItemTitle = (client) => {
    return client.organizationName;
  };
  const getListItemSubTitle = (client) => {
    return client.organizationCode;
  };
  if (tenantsQuery.isLoading) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (tenantsQuery.isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading tenants</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  return (
    <div className={classes.outer}>
      <Typography variant="h3">All Clients of BroadsightTracker</Typography>

      <div className={classes.adminWrapper}>
        <UserListComponent
          clickCallBack={setSelectedClient}
          user={selectedClient}
          users={clients}
          editingUserPrivilege={editingUserPrivilege}
          requiredEditingUserPrivilege={PrivilegeLevels.broadsightAdmin.level}
          addUserButton={AddClientButton}
          getAvatarLetter={getAvatarLetter}
          getListItemTitle={getListItemTitle}
          getListItemSubTitle={getListItemSubTitle}
        ></UserListComponent>

        {selectedClient && (
          <div className={classes.profileComponentWrapper}>
            <TenantCardComponent user={user} client={selectedClient}></TenantCardComponent>
          </div>
        )}
      </div>
      {/* {confirmationOpen && selectedUser && (
        <ConfirmationModal
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          headerText={`Delete ${selectedUser.username}`}
          text="Are you sure you want to delete this user?"
          callBack={onDelete}
        ></ConfirmationModal>
      )}
    {addWhiteListOpen && <AddUserModal open={addWhiteListOpen} setOpen={setAddWhiteListOpen}></AddUserModal>} */}
      {addClientOpen && (
        <AddTenantModal open={addClientOpen} setOpen={setAddClientOpen} callback={addClientToList}></AddTenantModal>
      )}

      <BroadcastPopupSetting />
    </div>
  );
}

export default TenantsPage;
