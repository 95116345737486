import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export function RadarChart({ chartData, title, ref }) {
  const labels = [];
  const values = [];

  chartData[0].forEach((i) => {
    labels.push(Object.values(i)[0]);
    values.push(Object.values(i)[1]);
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: values,
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${title}`,
      },
    },
    maintainAspectRatio: false,
  };

  return <Radar options={options} data={data} ref={ref} />;
}
