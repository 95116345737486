import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import { Box, Chip, Divider, LinearProgress, ListItem, ListItemButton, Typography } from '@mui/material';
import { CollaborationGroupsListItemsWrapperProps } from './Types/componentProps';
import { useContext } from 'react';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import { makeStyles } from '@mui/styles';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles({
  listItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const CollaborationGroupsListItems: React.FC<CollaborationGroupsListItemsWrapperProps> = ({ groups, isLoading }) => {
  const classes = useStyles();

  const user = useSelector<StoreState, UserState>((state) => state.user);
  const { selectedGroupId, dispatch: dispatchSelectedCollaborationGroupId } = useContext(
    SelectedCollaborationGroupIdContext
  );

  const queryClient = useQueryClient();

  const handleListItemClick = (groupId: string) => {
    if (selectedGroupId !== groupId) {
      dispatchSelectedCollaborationGroupId({ type: 'UPDATE_SELECT_GROUP_ID', payload: groupId });
    } else {
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group/invitations/candidates'],
      });
      queryClient.invalidateQueries({
        queryKey: ['collaboration/group-details'],
      });
      queryClient.invalidateQueries({
        queryKey: ['collaboration/groups'],
      });
    }
  };

  if (isLoading) {
    return <LinearProgress key="loading-collaboration-groups" />;
  }

  if (groups.length === 0) {
    return (
      <ListItem key="empty-collaboration-group-list-item" data-cy="collaboration-groups-list-empty-list-item">
        No collaboration group found.
      </ListItem>
    );
  }

  return groups.map((group) => {
    const groupMembers = group.tenants.map((tenant) => tenant.organizationCode).join(', ');
    let owningGroupIndicator = null;
    if (group.owners.includes(user.userInfo.tenantId)) {
      owningGroupIndicator = (
        <Chip label="Group Lead" color="primary" data-cy="collaboration-groups-list-groups-owner-chip" />
      );
    }

    return (
      <>
        <ListItem key={`group-${group._id}`} data-cy="collaboration-groups-list-groups-list-item">
          <ListItemButton onClick={() => handleListItemClick(group._id)}>
            <Box className={classes.listItemContainer}>
              <Box>
                <Typography variant="subtitle1">{group.name}</Typography>
                <Typography variant="body2">Members: {groupMembers}</Typography>
              </Box>
              {owningGroupIndicator}
            </Box>
          </ListItemButton>
        </ListItem>
        <Divider key={`group-list-divider-${group._id}`} component="li" />
      </>
    );
  });
};

export default CollaborationGroupsListItems;
