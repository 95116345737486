import { ResetSharedMediaInteractionFilterDialogProps } from './Types/componentProps';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRemoveCollaborationGroupSharedResourcesFilterMutation } from './Hooks/useCollaborationGroup';

const ResetSharedMediaInteractionFilterDialog: React.FC<ResetSharedMediaInteractionFilterDialogProps> = ({
  open,
  onClose,
  groupId,
}) => {
  const queryClient = useQueryClient();

  const removeCollaborationGroupSharedResourcesFilterMutation =
    useRemoveCollaborationGroupSharedResourcesFilterMutation({
      onSuccessCallback: () => {
        queryClient.invalidateQueries({
          queryKey: ['collaboration/group-details'],
        });
        onClose();
      },
    });

  const onReset = () => {
    removeCollaborationGroupSharedResourcesFilterMutation.mutateAsync({
      groupId: groupId,
      resourceType: 'issue',
    });
  };

  return (
    <Dialog open={open} data-cy="collaboration-group-details-interactions-filter-reset-filter-dialog">
      <DialogTitle>Reset shared Media Interactions Filter</DialogTitle>
      <DialogContent data-cy="collaboration-group-details-interactions-filter-reset-filter-dialog-content">
        <Alert color="warning">
          Reset Interactions Filter will remove the filter you set. All the shared Interactions from you will not be
          available for other group collaborators.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose()}
          data-cy="collaboration-group-details-interactions-filter-reset-filter-dialog-cancel-btn"
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={removeCollaborationGroupSharedResourcesFilterMutation.isPending}
          onClick={() => onReset()}
          data-cy="collaboration-group-details-interactions-filter-reset-filter-dialog-rest-btn"
        >
          Reset
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetSharedMediaInteractionFilterDialog;
