import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    padding: '30px',
    width: '80vw',
    minHeight: '90vh',
    height: '90vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
  },
  cameraButton: {
    position: 'absolute',
    top: '5%',
    right: '7%',
    zIndex: '1000',
  },
});
function EnlargedChartModal({ open, onClose, display }) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.content}>{display}</div>
    </Modal>
  );
}

export default EnlargedChartModal;
