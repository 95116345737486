import { EmailQueueEntry } from './Interfaces';
import { Table } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AIResultTableRow from './AIResultTableRow';
import { AutoTrackerIssueCols, AutoTrackerCols, TrackerDataType } from '../../shared/constants/constants';
import AutoTrackerTableHeader from './AutoTrackerTableHeader';

interface AIScrapeResultTableProps {
  row: EmailQueueEntry;
  eventHandler: any;
  dropdownTable: any;
  tabColor: string;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export function AIScrapeResultTable({
  row,
  eventHandler,
  dropdownTable,
  tabColor,
}: Readonly<AIScrapeResultTableProps>) {
  const classes = useStyles();

  return (
    <Table className={classes.tableContainer}>
      <AutoTrackerTableHeader
        columns={eventHandler.type === TrackerDataType.MEDIA_INTERACTION ? AutoTrackerIssueCols : AutoTrackerCols}
        tabColor={tabColor}
      />
      <AIResultTableRow
        row={row}
        columns={eventHandler.type === TrackerDataType.MEDIA_INTERACTION ? AutoTrackerIssueCols : AutoTrackerCols}
        dropdownTable={dropdownTable}
      />
    </Table>
  );
}
