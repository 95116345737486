import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Box,
  Collapse,
} from '@mui/material';
import { useSendNewCollaborationGroupInvitationMutation } from './Hooks/useCollaborationGroupInvitation';
import { useEffect, useMemo, useState } from 'react';
import {
  SendCollaborationGroupInvitationDialogProps,
  SendCollaborationGroupInvitationTenantCandidate,
} from './Types/componentProps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { listCollaborationGroupInvitationsCandidateTenants } from './Requests/CollaborationGroupInvitationRequests';
import TextField from '@mui/material/TextField';

const SendCollaborationGroupInvitationDialog: React.FC<SendCollaborationGroupInvitationDialogProps> = ({
  toggleDialog,
  invitations,
  groupId,
  handleCancel,
}) => {
  const { isFetching, data } = useQuery({
    queryKey: ['collaboration/group/invitations/candidates'],
    queryFn: () => listCollaborationGroupInvitationsCandidateTenants(groupId),
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const sendInvitationMutation = useSendNewCollaborationGroupInvitationMutation({
    onSuccessCallback: () => {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/invitations'] });
      handleCloseDialog();
    },
  });

  const invitationRecevierIds = useMemo(() => invitations.map((invitation) => invitation.receiver._id), [invitations]);

  const [tenantCandidates, setTenantCandidates] = useState<SendCollaborationGroupInvitationTenantCandidate[]>([]);

  useEffect(() => {
    if (!isFetching && data && data.length > 0) {
      setTenantCandidates(
        data.map((tenant) => ({
          selected: false,
          disabled: invitationRecevierIds.includes(tenant._id.toString()),
          id: tenant._id.toString(),
          message: '',
        }))
      );
    }
  }, [data, invitationRecevierIds, isFetching]);

  const hasTenantCandidateSelected = tenantCandidates.some((candidate) => candidate.selected);

  const handleSendInvitationButtonClick = () => {
    sendInvitationMutation.mutateAsync({
      groupId,
      data: {
        tenants: tenantCandidates
          .filter((tenant) => tenant.selected)
          .map((tenant) => ({
            id: tenant.id,
            message: tenant.message,
          })),
      },
    });
  };

  const handleCloseDialog = () => {
    setTenantCandidates(
      tenantCandidates.map((candidate) => ({
        ...candidate,
        selected: false,
        message: '',
      }))
    );
    handleCancel();
  };

  const handleToggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, candidateIndex: number) => {
    const newTenantCandidatesList = [...tenantCandidates];
    newTenantCandidatesList[candidateIndex].selected = e.target.checked;
    newTenantCandidatesList[candidateIndex].message = '';
    setTenantCandidates(newTenantCandidatesList);
  };

  const handleOptionalTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    candidateIndex: number
  ) => {
    const newTenantCandidatesList = [...tenantCandidates];
    newTenantCandidatesList[candidateIndex].message = e.target.value;
    setTenantCandidates(newTenantCandidatesList);
  };

  const renderDialogContent = () => {
    if (!isFetching && data && data.length === 0) {
      return <>No Candidate Found</>;
    } else if (!isFetching && data && data.length > 0 && tenantCandidates.length > 0) {
      return (
        <FormGroup>
          {data.map((tenant, index) => (
            <Box key={`invite-candidate-${tenant._id}`}>
              <FormControlLabel
                data-cy="collaboration-group-details-invitations-list-dialogs-invite-checkbox"
                label={`${tenant.organizationName} (${tenant.organizationCode})`}
                control={
                  <Checkbox
                    disabled={tenantCandidates[index].disabled}
                    onChange={(e) => handleToggleCheckbox(e, index)}
                  />
                }
              />
              <Box sx={{ padding: '0.25rem' }}>
                {tenantCandidates[index].selected && (
                  <Collapse in={tenantCandidates[index].selected}>
                    <TextField
                      data-cy="collaboration-group-details-invitations-list-dialogs-invite-optional-message-text-field"
                      sx={{ width: '100%' }}
                      label="(Optional) Invitation Message"
                      placeholder="(Optional) Invitation Message"
                      value={tenantCandidates[index].message}
                      onChange={(e) => handleOptionalTextChange(e, index)}
                    />
                  </Collapse>
                )}
              </Box>
            </Box>
          ))}
        </FormGroup>
      );
    } else {
      return <LinearProgress />;
    }
  };

  return (
    <Dialog fullWidth open={toggleDialog} data-cy="collaboration-group-details-invitations-list-dialogs-invite-dialog">
      <DialogTitle>Invite Collaborator(s)</DialogTitle>
      <DialogContent>{renderDialogContent()}</DialogContent>
      <DialogActions>
        <Button
          data-cy="collaboration-group-details-invitations-list-dialogs-invite-actions-cancel-btn"
          onClick={() => handleCloseDialog()}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          data-cy="collaboration-group-details-invitations-list-dialogs-invite-actions-send-btn"
          onClick={() => handleSendInvitationButtonClick()}
          variant="contained"
          disabled={!hasTenantCandidateSelected}
          loading={sendInvitationMutation.isPending}
        >
          Send Invitation(s)
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SendCollaborationGroupInvitationDialog;
