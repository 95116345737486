import { TableCell, Typography } from '@mui/material';
import React from 'react';
import { SharedJournalistsTableCellProps } from './Types/componentProps';
import { ListCollaborationGroupSharedContactsAPIDataContacts } from './Types/apiData';

const SharedJournalistsTableCell = (props: SharedJournalistsTableCellProps) => {
  const { col, contact } = props;
  const contactCol = col as keyof ListCollaborationGroupSharedContactsAPIDataContacts;

  if (col === 'Name') {
    return (
      <TableCell align="center">
        <Typography fontWeight={700}>{contact[contactCol]}</Typography>
      </TableCell>
    );
  }
  return <TableCell align="center">{contact[contactCol]}</TableCell>;
};

export default SharedJournalistsTableCell;
