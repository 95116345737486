import { useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilteredTable from '../../TrackerTable/FilteredTable';
import { Spinner } from 'react-bootstrap';
import ErrorComponent from './ErrorComponent';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EnlargedChartModal from '../EnlargedChartModal';
import { useLocation } from 'react-router-dom';
import { getTabColor } from '../../../shared/constants/constants';

const useStyles = makeStyles(({ tabColor }) => ({
  tableContainer: {
    maxHeight: '90%',
    overflow: 'scroll',
    border: 'solid black',
    borderWidth: '1px',
    borderRadius: '1px',
  },
  text: {
    paddingLeft: '5%',
    paddingTop: '1vh',
    color: '#ffffff',
    fontSize: '100%',
  },
  tableHeader: {
    backgroundColor: ({ tabColor }) => tabColor,
    background: '#002145',
    height: '5vh',
    textAlign: 'left',
  },
  fullScreenButton: {
    position: 'absolute',
    top: '3%',
    right: '1%',
    zIndex: '1000',
    color: 'white',
  },
  gridItem: {
    maxHeight: '50vh',
    position: 'relative',
    marginTop: '2.5vh',
  },
}));

function TableComponent({ tableData, query }) {
  //component for rendering the tables at bottom of analytics page
  const { pathname } = useLocation();

  const classes = useStyles({ tabColor: getTabColor(pathname) });
  const { columns, dropdownTable, networkRequest } = tableData;

  const [issuesState, setIssuesState] = useState('loading');
  const [open, setOpen] = useState(false);

  const buttonHandler = () => {
    setOpen(true);
  };
  useEffect(() => {
    setIssuesState('loading');
    networkRequest(query)
      .then((res) => {
        setIssuesState(res.data ? res.data : res);
      })
      .catch((e) => {
        setIssuesState(e);
      });
  }, [query, networkRequest]);

  const Container = ({ children }) => {
    return (
      <Grid item className={classes.gridItem} sm={6} lg={4}>
        <EnlargedChartModal open={open} onClose={() => setOpen(false)} display={children}></EnlargedChartModal>
        <IconButton className={classes.fullScreenButton} onClick={buttonHandler} size="large">
          <FullscreenIcon></FullscreenIcon>
        </IconButton>
        {children}
      </Grid>
    );
  };
  if (issuesState.error === 1) {
    return <ErrorComponent message={issuesState.message}></ErrorComponent>;
  } else if (issuesState !== 'loading' && dropdownTable !== 'loading') {
    return (
      <Container>
        <div className={classes.tableHeader}>
          <Typography className={classes.text} nowrap="true">
            {tableData.title}
          </Typography>
        </div>
        <div className={classes.tableContainer}>
          <FilteredTable type={tableData.type} issues={issuesState} cols={columns} dropdownTable={dropdownTable} />
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className={classes.tableHeader}>
          <Typography className={classes.text} nowrap="true">
            {tableData.title}
          </Typography>
        </div>
        <div>
          <Typography>Waiting for data</Typography>
          <Spinner animation="border" />
        </div>
      </Container>
    );
  }
}

export default TableComponent;
