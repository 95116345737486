import { Button, CardActions, TextField, ImageList, ImageListItem } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { UserProfileFields } from './UserProfileFields';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import { SingleSelect } from '../Cells/CellTypes';
import MFAModal from './MFAModal';
import { updateUserMetaDataNonPIUserSettings } from '../../shared/functions/userFunctions';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import { DEFAULT_IS_EMAIL_NOTIFICATIONS_ENABLED } from '../../shared/constants/constants';
import PropTypes from 'prop-types';
import VerifyNewUserProfileEmailDialog from './VerifyNewUserProfileEmailDialog';

const useStyles = makeStyles({
  cancelButton: {
    backgroundColor: 'red',
    color: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1vw',
  },
});
export default function ProfileComponent({
  user,
  setUser,
  editingUserPrivilege,
  updateUser,
  autoDisableNotifications = false,
  isOnProfilePage = false,
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const [openDialog, setOpenDialog] = useState(false);
  const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);

  const dispatch = useDispatch();

  const [isEmailNotificationsEnabled, setIsEmailNotificationsEnabled] = useState(
    userInfo.nonPIUserSettings?.isEmailNotificationOn ?? DEFAULT_IS_EMAIL_NOTIFICATIONS_ENABLED
  );

  useEffect(() => {
    setIsEmailNotificationsEnabled(
      userInfo.nonPIUserSettings?.isEmailNotificationOn ?? DEFAULT_IS_EMAIL_NOTIFICATIONS_ENABLED
    );
  }, [userInfo]);

  //get only user, manager, admin
  const privilegeLevelsForSelect = Object.values(PrivilegeLevels)
    .filter((level) => level.level !== undefined && level.level <= editingUserPrivilege)
    .map((level) => ({ title: level.title, value: level.level }));

  const updateSelfUserInfo = async () => {
    try {
      await updateUser(userInfo);
      if (user.email !== userInfo.email) {
        dispatch(
          openSnackbar({
            message: `Successfully updated profile, Please verify your new email`,
            severity: 'success',
          })
        );
        // proceed to verify email dialog
        setOpenDialog(true);
      } else {
        dispatch(
          openSnackbar({
            message: `Successfully updated profile`,
            severity: 'success',
          })
        );
        setUser();
      }
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbar({
          message: `Failed to update profile`,
          severity: 'error',
        })
      );
    }
  };

  const updateOtherUserInfo = async () => {
    try {
      const res = await updateUser(userInfo);
      if (res.username) {
        setUser(userInfo);
      } else {
        setUser(user);
        setUserInfo(user);
      }
      dispatch(
        openSnackbar({
          message: `Successfully updated profile`,
          severity: 'success',
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        openSnackbar({
          message: `Failed to update profile`,
          severity: 'error',
        })
      );
    }
  };

  const onSave = (e) => {
    if (isOnProfilePage) {
      updateSelfUserInfo();
    } else {
      updateOtherUserInfo();
    }
  };

  const onClickChangeEmailNotificationsSettingButton = async () => {
    try {
      await updateUserMetaDataNonPIUserSettings(userInfo.id, {
        nonPIUserSettings: { isEmailNotificationOn: !isEmailNotificationsEnabled },
      });
      setIsEmailNotificationsEnabled(!isEmailNotificationsEnabled);
      dispatch(
        openSnackbar({
          message: `Successfully ${isEmailNotificationsEnabled ? 'disabled' : 'enabled'} email notifications`,
          severity: 'success',
        })
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          message: `Failed to ${isEmailNotificationsEnabled ? 'disable' : 'enable'} email notifications`,
          severity: 'error',
        })
      );
    }
  };

  useEffect(() => {
    setIsEditing(false);
    setUserInfo(user);
  }, [user]);

  useEffect(() => {
    if (autoDisableNotifications) {
      if (isEmailNotificationsEnabled) {
        updateUserMetaDataNonPIUserSettings(userInfo.id, {
          nonPIUserSettings: { isEmailNotificationOn: false },
        })
          .then(() => {
            setIsEmailNotificationsEnabled(false);
            dispatch(
              openSnackbar({
                message: `Successfully disabled email notifications`,
                severity: 'success',
              })
            );
          })
          .catch((err) => {
            dispatch(
              openSnackbar({
                message: `Failed to ${isEmailNotificationsEnabled ? 'disable' : 'enable'} email notifications`,
                severity: 'error',
              })
            );
          });
      } else {
        dispatch(
          openSnackbar({
            message: `Email notifications has already been disabled`,
            severity: 'success',
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDisableNotifications]);

  return (
    userInfo && (
      <>
        <Card sx={{ minWidth: 500, maxWidth: 500 }}>
          <CardContent>
            <Typography variant="h4" component="div">
              {user.name}'s Profile
            </Typography>
            <CardActions style={{ justifyContent: 'center' }}>
              {isEditing ? (
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      setIsEditing(false);
                      onSave(e);
                    }}
                  >
                    SAVE
                  </Button>
                  {isOnProfilePage && (
                    <Button variant="outlined" onClick={() => setOpenDialog(true)}>
                      Verify Email
                    </Button>
                  )}
                  <Button
                    className={classes.cancelButton}
                    variant="contained"
                    onClick={(e) => {
                      setUserInfo(user);
                      setIsEditing(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              ) : (
                <>
                  <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
                    Edit Info
                  </Button>
                  <Button variant="outlined" color="primary" onClick={onClickChangeEmailNotificationsSettingButton}>
                    {`${isEmailNotificationsEnabled ? 'Disable' : 'Enable'} Email Notifications`}
                  </Button>
                </>
              )}
              {isOnProfilePage && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsMfaModalOpen(true)}
                  data-cy="configMfaButton-ProfileComponent"
                >
                  Configure MFA
                </Button>
              )}
            </CardActions>
            <ImageList cols={1} cellheight={100}>
              {UserProfileFields.map((field, i) => {
                if (
                  userInfo[field.field] ||
                  userInfo[field.field] === '' ||
                  (userInfo[field.field] === 0 && editingUserPrivilege > 0)
                ) {
                  let userInfoFieldComponent;

                  let isEditable = isEditing && field.editable && field.minClearance <= editingUserPrivilege;
                  const isPrivilegeField = field.field === 'privilege';
                  const isEmailField = field.field === 'email';

                  if (isOnProfilePage && isEmailField) {
                    isEditable = isEditing && field.editable && PrivilegeLevels.user.level <= editingUserPrivilege;
                  }

                  if (isEditable && isPrivilegeField) {
                    userInfoFieldComponent = (
                      <SingleSelect
                        callBack={(e) => {
                          setUserInfo({
                            ...userInfo,
                            [field.field]: e.target.value,
                          });
                        }}
                        items={privilegeLevelsForSelect}
                        value={userInfo[field.field]}
                        label={'Privilege Level'}
                        data-cy={field.dataCy}
                      />
                    );
                  } else if (isEditable) {
                    userInfoFieldComponent = (
                      <TextField
                        variant="standard"
                        value={userInfo[field.field]}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            [field.field]: e.target.value,
                          });
                        }}
                        data-cy={field.dataCy}
                      />
                    );
                  } else if (isPrivilegeField) {
                    userInfoFieldComponent = (
                      <Typography data-cy={field.dataCy}>
                        {privilegeLevelsForSelect[user[field.field]]?.title}
                      </Typography>
                    );
                  } else {
                    userInfoFieldComponent = <Typography data-cy={field.dataCy}>{user[field.field]}</Typography>;
                  }

                  return (
                    <ImageListItem key={i} cols={1}>
                      <Typography variant="h6" fontWeight="bold">
                        {field.label}
                      </Typography>
                      {userInfoFieldComponent}
                    </ImageListItem>
                  );
                } else {
                  return null;
                }
              })}
            </ImageList>
          </CardContent>
        </Card>
        {isOnProfilePage && (
          <VerifyNewUserProfileEmailDialog
            userId={user.id}
            openDialog={openDialog}
            setUser={setUser}
            handleDialogClose={() => setOpenDialog(false)}
          />
        )}
        {isOnProfilePage && (
          <MFAModal
            isModalOpen={isMfaModalOpen}
            setIsModalOpen={setIsMfaModalOpen}
            user={userInfo}
            setUserInfo={setUserInfo}
            data-cy="mfaModal-ProfileComponent"
          />
        )}
      </>
    )
  );
}

ProfileComponent.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  autoDisableNotifications: PropTypes.bool,
  isOnProfilePage: PropTypes.bool,
};
