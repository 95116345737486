import { Typography } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import TermsOfServiceDialog from '../TermsOfServiceDialog/TermsOfServiceDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

function PopupAgreementPage({ content = 'Verifying Acceptance of Legal Documents...' }) {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.hasAgreedTermsOfService) {
      const locFromPath = location?.state?.from?.pathname ?? '';
      const locQueryParams = location?.state?.from?.search ?? '';
      const locFullPath = locFromPath + locQueryParams;

      if (locFullPath) {
        navigate(locFullPath);
      } else {
        navigate(RouteInformation.serviceLog.path);
      }
    }
  }, [navigate, user, location]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner animation="border"></Spinner>
      <Typography>{content}</Typography>
      <TermsOfServiceDialog />
    </div>
  );
}

PopupAgreementPage.propTypes = {
  content: PropTypes.string,
};

export default PopupAgreementPage;
