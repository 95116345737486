import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import '../styles.css';

interface ComplexityPickerProps {
  minValue: number;
  setMinValue: React.Dispatch<React.SetStateAction<number>>;
  maxValue: number;
  setMaxValue: React.Dispatch<React.SetStateAction<number>>;
}

const ComplexityPicker: React.FC<ComplexityPickerProps> = ({ minValue, setMinValue, maxValue, setMaxValue }) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setMinValue(Array.isArray(newValue) ? newValue[0] : newValue);
    setMaxValue(Array.isArray(newValue) ? newValue[1] : newValue);
  };

  return (
    <Box className="box complexity">
      <Typography id="range-slider" className="sliderTitle" gutterBottom>
        Complexity Range
      </Typography>
      <Slider
        value={[minValue, maxValue]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        className="slider"
        min={1}
        max={5}
      />
    </Box>
  );
};

export default ComplexityPicker;
