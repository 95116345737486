import { SharedInteractionsTableWrapperProps } from './Types/componentProps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { listCollaborationGroupSharedResources } from './Requests/CollaborationGroupRequests';
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';
import SharedInteractionsTable from './SharedInteractionsTable';

const SharedInteractionsTableWrapper: React.FC<SharedInteractionsTableWrapperProps> = ({ groupId }) => {
  const { isFetching: isFetchingSharedIssues, data: sharedIssuesData } = useQuery({
    queryKey: ['collaboration/group/shared/interactions'],
    queryFn: () => listCollaborationGroupSharedResources(groupId, 'issue'),
    enabled: groupId !== '',
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (groupId !== '') {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/shared/interactions'] });
    }
  }, [queryClient, groupId]);

  if (isFetchingSharedIssues) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  if (groupId === '') {
    return <div>Select a group to view shared Interactions</div>;
  }

  if (!sharedIssuesData) {
    return <div>failed to fetch shared interactions, try refresh this page</div>;
  }

  if (sharedIssuesData.length === 0) {
    return (
      <div>
        No shared interactions found in this group. Try set up Interactions filter to this group or select another group
      </div>
    );
  }

  return <SharedInteractionsTable issues={sharedIssuesData} />;
};

export default SharedInteractionsTableWrapper;
