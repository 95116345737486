import {
  Box,
  Card,
  List,
  ListItem,
  Typography,
  Checkbox,
  Tooltip,
  checkboxClasses,
  ListSubheader,
} from '@mui/material';
import { IconButton } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import AdminListFuzzySearch from './AdminListFuzzySearch';
import { Fragment, useState, useEffect, useMemo } from 'react';
import { typeCategoriesImportantForAnalytics, typesImportantForAnalytics } from '../../shared/constants/constants';
import EnumListTypeItem from './EnumListTypeItem';

function EnumTypesListComponent({
  targetMergingItem,
  enumItems,
  addButton,
  setEnumModalOpen,
  setIsEditing,
  setSelectedEnum,
  isMerging,
  setIsMerging,
  setMergingItems,
  whatIsBeingMergedForTypes,
  setWhatIsBeingMergedForTypes,
}) {
  const [searchResults, setSearchResults] = useState(enumItems);
  const [categorizedSearchResults, setCategorizedSearchResults] = useState({});
  const [selectedItems, setSelectedItems] = useState(new Set()); // State for selected items to merge
  const additionalFuseOptionKeys = useMemo(() => ['type'], []);

  const handleCheckboxChange = (event, enumItem) => {
    const updatedSelectedItems = new Set(selectedItems);
    if (event.target.checked) {
      updatedSelectedItems.add(enumItem);
    } else {
      updatedSelectedItems.delete(enumItem);
    }
    setSelectedItems(updatedSelectedItems);
    setMergingItems([...updatedSelectedItems]);
  };

  useEffect(() => {
    const searchResultsMappedByCategory = {};
    searchResults?.forEach((result) => {
      if (result['type'] in searchResultsMappedByCategory) {
        searchResultsMappedByCategory[result['type']][result.archived ? 'archived' : 'unarchived'].push(result);
      } else {
        searchResultsMappedByCategory[result['type']] = { archived: [], unarchived: [] };
        searchResultsMappedByCategory[result['type']][result.archived ? 'archived' : 'unarchived'].push(result);
      }
    });

    setCategorizedSearchResults(searchResultsMappedByCategory);
  }, [searchResults]);

  return (
    <Box display="inline-block">
      <Card>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '0',
            margin: '0',
            maxHeight: '100%',
            overflow: 'auto',
            width: '30rem',
          }}
          disablePadding={true}
          padding={0}
        >
          <ListItem
            key={'searchbar'}
            sx={{
              justifyContent: 'space-between',
              marginTop: '1rem',
              paddingTop: '10',
            }}
          >
            <AdminListFuzzySearch
              items={enumItems}
              setSearchResults={setSearchResults}
              additionalFuseOptionKeys={additionalFuseOptionKeys}
              textFieldPlaceholder={'Search Names/Categories...'}
            />
            {!isMerging && addButton()}
          </ListItem>

          {categorizedSearchResults &&
            Object.keys(categorizedSearchResults)
              .sort((a, b) => {
                const categoryNameA = a.toLowerCase();
                const categoryNameB = b.toLowerCase();
                if (categoryNameA < categoryNameB) return -1;
                if (categoryNameA > categoryNameB) return 1;
                return 0;
              })
              .map((category, i) => {
                return (
                  <Fragment key={`category-header-${category}-${i}`}>
                    <ListItem
                      sx={{
                        alignContent: 'space-between',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'primary.main',
                        color: 'white',
                      }}
                    >
                      {typeCategoriesImportantForAnalytics.includes(category) ? (
                        <Tooltip
                          title="This type category is important for analytics. No editing restrictions are in place, but please be advised when moving types in and out of this category."
                          placement="bottom-start"
                        >
                          <Typography sx={{ color: 'yellow' }}>{category}</Typography>
                        </Tooltip>
                      ) : (
                        <Typography>{category}</Typography>
                      )}

                      {isMerging ? (
                        whatIsBeingMergedForTypes === 'Type Categories' && (
                          <Checkbox
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: 'white',
                              },
                            }}
                            checked={selectedItems.has(category)}
                            onChange={(event) => handleCheckboxChange(event, category)}
                            // targetMergingItem here is a string of the category name
                            disabled={targetMergingItem && category === targetMergingItem}
                          />
                        )
                      ) : (
                        <Tooltip title="Merge Type Categories">
                          <IconButton
                            sx={{ color: 'white' }}
                            size="small"
                            onClick={() => {
                              setSelectedEnum(category);
                              setWhatIsBeingMergedForTypes('Type Categories');
                              setIsMerging(true);
                            }}
                          >
                            <MergeIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>

                    {categorizedSearchResults[category]['unarchived'].map((enumItem, i) => {
                      return (
                        <EnumListTypeItem
                          targetMergingItem={targetMergingItem}
                          key={enumItem._id || `unarchived-${category}-${i}`}
                          enumItem={enumItem}
                          isMerging={isMerging}
                          selectedItems={selectedItems}
                          handleCheckboxChange={handleCheckboxChange}
                          setIsEditing={setIsEditing}
                          setSelectedEnum={setSelectedEnum}
                          setEnumModalOpen={setEnumModalOpen}
                          setIsMerging={setIsMerging}
                          typesImportantForAnalytics={typesImportantForAnalytics}
                          whatIsBeingMergedForTypes={whatIsBeingMergedForTypes}
                          setWhatIsBeingMergedForTypes={setWhatIsBeingMergedForTypes}
                        />
                      );
                    })}

                    {categorizedSearchResults[category]['archived'].length > 0 && (
                      <ListSubheader
                        sx={{
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                          width: '93%',
                          borderRadius: '10rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '1rem 1rem 1rem 1rem',
                          padding: '0',
                        }}
                      >
                        Archived
                      </ListSubheader>
                    )}

                    {categorizedSearchResults[category]['archived'].map((enumItem, i) => {
                      return (
                        <EnumListTypeItem
                          targetMergingItem={targetMergingItem}
                          key={enumItem._id || `archived-${category}-${i}`}
                          enumItem={enumItem}
                          isMerging={isMerging}
                          selectedItems={selectedItems}
                          handleCheckboxChange={handleCheckboxChange}
                          setIsEditing={setIsEditing}
                          setSelectedEnum={setSelectedEnum}
                          setEnumModalOpen={setEnumModalOpen}
                          setIsMerging={setIsMerging}
                          isArchivedItem={true}
                          typesImportantForAnalytics={typesImportantForAnalytics}
                          whatIsBeingMergedForTypes={whatIsBeingMergedForTypes}
                          setWhatIsBeingMergedForTypes={setWhatIsBeingMergedForTypes}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
        </List>
      </Card>
    </Box>
  );
}

export default EnumTypesListComponent;
