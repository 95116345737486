import { Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getForwardingAddress } from './emailScrapingRequests';

const ForwardingAddressInstructions = () => {
  const [address, setAddress] = useState('');

  useEffect(() => {
    getForwardingAddress().then((response) => {
      setAddress(response);
    });
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
      }}
    >
      <Typography variant="caption">Please forward your emails to {address}</Typography>
    </Box>
  );
};

export default ForwardingAddressInstructions;
