import { Dialog, Button, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../redux-types';
import DOMPurify from 'dompurify';
import * as UserActions from '../Authorization/userSlice';

const BroadcastPopupDialog: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userBroadcastPopup = useSelector((state: StoreState) => state.user.broadcastPopup);

  const [open, setOpen] = useState(true);

  const handleOK = () => {
    dispatch(
      UserActions.setUserBroadcastPopup({
        ...userBroadcastPopup,
        seen: true,
      })
    );
    navigate(RouteInformation.serviceLog.path, { replace: true });
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" data-cy="broadcast-popup-dialog">
      <DialogTitle>Announcement</DialogTitle>
      <DialogContent dividers>
        <DialogContentText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(userBroadcastPopup.content) }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleOK()} data-cy="broadcast-popup-dialog-ok-btn">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BroadcastPopupDialog;
