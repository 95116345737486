import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarPercentageChart({ chartData, title }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${title}`,
      },
    },
    scales: {
      y: {
        ticks: {
          format: {
            style: 'percent',
          },
        },
        display: true,
      },
    },
    maintainAspectRatio: false,
  };

  return <Bar options={options} data={chartData} />;
}
