import FilterField from './FilterField';
import { AnalyticsAdvancedFiltersEnum, AnalyticsFiltersEnum } from './analyticsEnums';
import { multipleFilters } from './constants';

interface SelectionInputFilterProps {
  inputFilters: AnalyticsFiltersEnum[] | AnalyticsAdvancedFiltersEnum[];
  [key: string]: any;
}

const SelectionInputFilter = (props: SelectionInputFilterProps) => {
  const {
    classes,
    inputFilters,
    dropdownTable,
    dropdownMapping,
    filters,
    setFilters,
    textFiltersHelpText,
    filterIcon,
  } = props;

  return inputFilters.map((filter) => {
    const options =
      (dropdownTable[dropdownMapping[filter]] || []).map((item: any) => ({
        ...item,
        firstLetter: item.name?.charAt(0),
      })) || [];
    return (
      <FilterField
        key={filter}
        filter={filter}
        setFilters={setFilters}
        filterIcon={filterIcon}
        filters={filters}
        classes={classes}
        options={options}
        textFiltersHelpText={textFiltersHelpText}
        multiple={multipleFilters.includes(filter)}
      />
    );
  });
};

export default SelectionInputFilter;
