export const isValidEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email);
};

export const isValidHexColorString = (hexColorString) => {
  return hexColorString.match(/^#[a-f0-9]{6}$/i) !== null;
};

// capitalize first letter of each word in a sentence
export function capitalize(str) {
  try {
    return str
      .split(/\s+/)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  } catch (e) {
    console.error(e);
    console.log('str was ' + str);
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
