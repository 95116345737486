import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ContactPersonnelsList from '../ContactPersonnelsList';
import { getExperts, getJournalist } from '../contactsRequest';
import MergeIcon from '@mui/icons-material/MergeType';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import { mergeExperts, mergeJournalists } from '../contactsRequest';

function MergeContactPersonnelModal(props) {
  const { orgs, units } = props;
  const { category } = props;
  const { openMergeContact, setOpenMergeContact, selectedContact, setRefreshContacts, setOpenAddContact } = props;
  const [contacts, setContacts] = useState([]);
  const [mergingContacts, setMergingContacts] = useState([]);

  const dispatch = useDispatch();

  const handleClose = (backToContactPage) => {
    setOpenMergeContact(false);
    if (backToContactPage) setOpenAddContact(false);
  };

  const handleMerge = async () => {
    let mergeContacts;
    category === 'Journalists' ? (mergeContacts = mergeJournalists) : (mergeContacts = mergeExperts);

    const res = await mergeContacts(mergingContacts, selectedContact);
    // dispatch(openSnackbar({ severity: 'success', message: 'Contact Merged!' }));
    if (res.status === 200) {
      dispatch(openSnackbar({ severity: 'success', message: 'Contact Personnel Merged!' }));
      handleClose(true);
      setRefreshContacts(true);
    } else {
      dispatch(openSnackbar({ severity: 'error', message: 'Contact Personnel Merge Failed!' }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    let getContacts;
    category === 'Journalists' ? (getContacts = getJournalist) : (getContacts = getExperts);

    getContacts().then((response) => {
      if (isMounted) {
        setContacts(response.data);
      }
    });

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [category]);

  return (
    <Dialog
      open={openMergeContact}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      data-cy="dialog-MergeContactPersonnelModal"
    >
      <DialogTitle>Merge Contact</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {'Please select which contact(s) to merge into:'}
          <strong> {selectedContact?.name}</strong>
        </Typography>
        <Typography variant="body1">
          Selected to become above:
          <strong>{mergingContacts.map((contact) => contact.name).join()}</strong>
        </Typography>
        <Box
          sx={{
            height: '50vh',
          }}
        >
          <ContactPersonnelsList
            data={contacts}
            orgs={orgs}
            category={category}
            units={units}
            isMerging={true}
            selectedContact={selectedContact}
            mergingContacts={mergingContacts}
            setMergingContacts={setMergingContacts}
          />
        </Box>
        <DialogActions>
          <br></br>
          <Button onClick={handleClose} variant="contained" data-cy="cancel-button-MergeContactPersonnelModal">
            Cancel
          </Button>
          <Button
            onClick={handleMerge}
            startIcon={<MergeIcon />}
            variant="contained"
            data-cy="merge-button-MergeContactPersonnelModal"
          >
            Merge
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default MergeContactPersonnelModal;
