import PropTypes from 'prop-types';
import { ARCHIVED_CONTACTS_HEADER_FLAG, filterContacts } from './utils';
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { FixedSizeList } from 'react-window';
import isSelectedContact from '../../shared/functions/isSelectedContact';

const ContactsVirtualListArchivedSubHeader = ({ style }) => {
  return (
    <ListItem style={style}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
          width: '93%',
          borderRadius: '10rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '1rem 1rem 1rem 1rem',
          padding: '0',
          height: '100%',
        }}
      >
        Archived
      </Box>
    </ListItem>
  );
};

ContactsVirtualListArchivedSubHeader.propTypes = {
  style: PropTypes.object.isRequired,
};

const ContactsVirtualList = (props) => {
  const {
    isOutletContact,
    contactsSize,
    searchResultItems,
    isMerging,
    setMergingContacts,
    mergingContacts,
    selectedContact,
    handleContactClick,
    getListItemAvatarProps,
    getListItemSecondaryText,
    height,
    itemSize,
    overscanCount,
  } = props;

  // when is merging, archived search results are ignored, which is appended to the end of array
  const fixedSizeListItems = useMemo(() => {
    const activeSearchResults = filterContacts(searchResultItems);
    const archivedSearchResults = filterContacts(searchResultItems, true);

    if (!isMerging && archivedSearchResults.length > 0) {
      return [...activeSearchResults, ARCHIVED_CONTACTS_HEADER_FLAG, ...archivedSearchResults];
    }
    return activeSearchResults;
  }, [searchResultItems, isMerging]);

  // list of checked contacts for merging
  const [checked, setChecked] = useState(Array(fixedSizeListItems.length).fill(false));

  const handleCheckBoxChange = (index) => {
    if (!checked[index]) {
      // add contact to mergingContacts
      setMergingContacts([...mergingContacts, fixedSizeListItems[index]]);
    } else {
      // remove contact from mergingContacts
      const newMergingContacts = mergingContacts.filter((contact) => contact._id !== fixedSizeListItems[index]._id);
      setMergingContacts(newMergingContacts);
    }
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
  };

  const renderVirtualListItems = ({ index, style }) => {
    if (fixedSizeListItems[index] === ARCHIVED_CONTACTS_HEADER_FLAG) {
      return <ContactsVirtualListArchivedSubHeader style={style} />;
    }

    let listItemSecondaryAction = null;
    let listItemToolTipTitle = '';
    let handleListItemClick = () => handleContactClick(fixedSizeListItems[index]);
    let listItemDisabled = false;
    if (isMerging) {
      listItemSecondaryAction = (
        <Checkbox
          edge="end"
          onChange={() => handleCheckBoxChange(index)}
          checked={checked[index] === true}
          disabled={isSelectedContact(selectedContact, fixedSizeListItems[index])}
        />
      );

      listItemToolTipTitle = `Email: ${fixedSizeListItems[index].email} Phone: ${fixedSizeListItems[index].phone}`;
      handleListItemClick = () => handleCheckBoxChange(index);
      listItemDisabled = isSelectedContact(selectedContact, fixedSizeListItems[index]);
    }

    // outlet does not have secondary text
    let listItemText = <ListItemText primary={fixedSizeListItems[index].name} />;
    if (!isOutletContact) {
      listItemText = (
        <ListItemText
          primary={fixedSizeListItems[index].name}
          secondary={getListItemSecondaryText(fixedSizeListItems[index])}
        />
      );
    }

    return (
      <ListItem style={style} key={fixedSizeListItems[index]._id} secondaryAction={listItemSecondaryAction}>
        <Tooltip title={listItemToolTipTitle}>
          <ListItemButton onClick={handleListItemClick} disabled={listItemDisabled}>
            <ListItemAvatar>
              <Avatar {...getListItemAvatarProps(fixedSizeListItems[index].name)} />
            </ListItemAvatar>
            {listItemText}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    );
  };

  useEffect(() => {
    setChecked(Array(fixedSizeListItems.length).fill(false));
    isMerging && setMergingContacts([]);
  }, [fixedSizeListItems.length, isMerging, setMergingContacts]);

  if (contactsSize <= 0) {
    return (
      <ListItem>
        <ListItemText primary="Loading..." />
      </ListItem>
    );
  }

  return (
    <FixedSizeList
      height={height}
      width={'100%'}
      itemSize={itemSize}
      itemCount={fixedSizeListItems.length}
      overscanCount={overscanCount}
    >
      {renderVirtualListItems}
    </FixedSizeList>
  );
};

export default ContactsVirtualList;

ContactsVirtualList.propTypes = {
  isOutletContact: PropTypes.bool,
  contactsSize: PropTypes.number.isRequired,
  searchResultItems: PropTypes.array.isRequired,
  isMerging: PropTypes.bool.isRequired,
  setMergingContacts: PropTypes.func.isRequired,
  mergingContacts: PropTypes.array.isRequired,
  selectedContact: PropTypes.object.isRequired,
  handleContactClick: PropTypes.func.isRequired,
  getListItemAvatarProps: PropTypes.func.isRequired,
  // (arg) => stringAvatar(arg)
  getListItemSecondaryText: PropTypes.func,
  // (arg) => getOrgRole(arg, category, units)
  height: PropTypes.number,
  itemSize: PropTypes.number,
  overscanCount: PropTypes.number,
};

ContactsVirtualList.defaultProps = {
  isOutletContact: false,
  height: 1000,
  itemSize: 60,
  overscanCount: 10,
  getListItemSecondaryText: () => {},
};
