export function getCurrMonth() {
  const date = new Date();
  return date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString();
}

export function getPrevMonth(currMonth) {
  let year = parseInt(currMonth.substring(0, 4), 10);
  let month = parseInt(currMonth.substr(5), 10);
  if (month === 1) {
    month = 12;
    --year;
  } else {
    --month;
  }
  return year.toString() + '-' + month.toString();
}

export function getNextMonth(currMonth) {
  let year = parseInt(currMonth.substring(0, 4), 10);
  let month = parseInt(currMonth.substr(5), 10);
  if (month === 12) {
    month = 1;
    ++year;
  } else {
    ++month;
  }
  return year.toString() + '-' + month.toString();
}

export function monthYearToReadableString(currMonth){
  const monthYear = currMonth.split("-");
  const year = monthYear[0];
  const monthNum = parseInt(monthYear[1]) - 1;
  const date = new Date(year, monthNum);
  return ` ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`
}
