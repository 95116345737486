/**
 * filter contacts by options
 * @param contacts - contacts data
 * @param archived - filter archived contacts if set to true, otherwise filter not archived contacts
 * @returns - filtered contacts
 */
export const filterContacts = (contacts, archived = false) => {
  if (archived) {
    return contacts
      .filter((contact) => contact.archived === true)
      .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
  }
  return contacts.filter((contact) => !contact.archived).sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
};

export const ARCHIVED_CONTACTS_HEADER_FLAG = 'archived_header';

export const countActiveContacts = (contacts) => {
  if (!contacts) {
    return 0;
  }
  return contacts.filter((contact) => !contact.archived).length;
};
