import { CollaborationGroupOwnerProtectedWrapperProps } from '../../components/Collaboration/Types/componentProps';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';

const CollaborationGroupOwnerProtectedWrapper: React.FC<CollaborationGroupOwnerProtectedWrapperProps> = ({
  children,
  groupOwners,
}) => {
  const user = useSelector<StoreState, UserState>((state) => state.user);
  const isGroupOwner = groupOwners.includes(user.userInfo.tenantId);
  if (isGroupOwner) {
    return children;
  }
  return null;
};

export default CollaborationGroupOwnerProtectedWrapper;
