import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as TermsOfServiceDialogActions from './termsOfServiceDialogSlice';
import * as UserActions from '../Authorization/userSlice';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { agreeTermsOfService } from '../../shared/functions/userFunctions';
import logout from '../Authorization/Logout';
import DOMPurify from 'dompurify';

export default function TermsOfServiceDialog() {
  const [isLoading, setIsLoading] = useState(false);

  const termsOfServiceDialog = useSelector((state) => state.termsOfServiceDialog);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const onAgree = async () => {
    setIsLoading(true);
    try {
      await agreeTermsOfService({
        id: user.userInfo.id,
        docName: termsOfServiceDialog.docName,
        version: termsOfServiceDialog.version,
      });
      setIsLoading(false);
      dispatch(TermsOfServiceDialogActions.closeTermsOfServiceDialog());

      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleClose = () => {
    logout(user.userInfo.username, () => {
      dispatch(TermsOfServiceDialogActions.closeTermsOfServiceDialog());
      localStorage.clear();
      sessionStorage.clear();
      dispatch(UserActions.userLogout());
    });
  };

  let dialogContent = <DialogContentText>Loading...</DialogContentText>;
  if (termsOfServiceDialog.htmlString) {
    dialogContent = (
      <DialogContentText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsOfServiceDialog.htmlString) }} />
    );
  }

  return (
    <Dialog scroll="paper" open={termsOfServiceDialog.isOpen} data-cy="terms-of-service-dialog">
      <DialogTitle>User Agreement</DialogTitle>
      <DialogContent dividers>{dialogContent}</DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton variant="contained" loading={isLoading} onClick={onAgree} data-cy="agree-button">
          Agree
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose} data-cy="logout-button">
          Decline & Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}
