import { Select, MenuItem } from '@mui/material';

const AdminCategorySelect = (props) => {
  const { category, handleCategoryChange } = props;
  return (
    <>
      <Select
        labelId="contact-category"
        id="contact-category-select"
        value={category}
        label="Category"
        onChange={handleCategoryChange}
        size="small"
      >
        <MenuItem value={'Users'}>Users</MenuItem>
        <MenuItem value={'Units'}>Units</MenuItem>
        <MenuItem value={'Departments'}>Departments</MenuItem>
        <MenuItem value={'Campaigns'}>Campaigns</MenuItem>
        <MenuItem value={'Types'}>Types</MenuItem>
      </Select>
    </>
  );
};

export default AdminCategorySelect;
