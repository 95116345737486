import { AnalyticsAdvancedFiltersEnum, AnalyticsFiltersEnum } from './analyticsEnums';

export const chartTypeEnum = {
  pie: 'pie',
  bar: 'bar',
  line: 'line',
  numberBox: 'numBox',
  topHits: 'topHits',
  barPercentage: 'barPercentage',
  barVertical: 'barVertical',
  barWide: 'barWide',
  wordCloud: 'wordCloud',
  ChartJSRadar: 'ChartJSRadar',
  ChartJSDoughnut: 'ChartJSDoughnut',
  ChartJSLine: 'ChartJSLine',
  ChartJSBar: 'ChartJSBar',
  ChartJSStackedBar: 'ChartJSStackedBar',
  PlotlyMonthLine: 'PlotlyMonthLine',
};

export const multipleFilters = [
  AnalyticsFiltersEnum.campaigns,
  AnalyticsFiltersEnum.teamMembers,
  AnalyticsFiltersEnum.units,
  AnalyticsAdvancedFiltersEnum.status,
  AnalyticsAdvancedFiltersEnum.type,
  AnalyticsAdvancedFiltersEnum.outlet,
  AnalyticsAdvancedFiltersEnum.dept,
];

export const textFiltersHelpText = {
  [AnalyticsFiltersEnum.campaigns]: 'Select a campaign',
  [AnalyticsFiltersEnum.units]: 'Search by Unit/Faculty/Area',
};
