import React from 'react';
import TypePicker from './TypePicker';
import { Type } from '../Interfaces';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';

interface ServiceTypePickerProps {
  serviceTypes: Type[];
  selectedServiceTypes: Type[];
  setSelectedServiceTypes: React.Dispatch<React.SetStateAction<Type[]>>;
}

const ServiceTypePicker: React.FC<ServiceTypePickerProps> = ({
  serviceTypes,
  selectedServiceTypes,
  setSelectedServiceTypes,
}) => {
  const dispatch = useDispatch();

  const handleEmptySelection = () => {
    dispatch(openSnackbar({ severity: 'error', message: 'In case you want to select Service Types, you must first select at least one Proactivity option!' }));
  };

  return (
    <TypePicker
      types={serviceTypes}
      selectedTypes={selectedServiceTypes}
      setSelectedTypes={setSelectedServiceTypes}
      label="Service Types"
      typeKey="_id"
      typeName="name"
      handleEmptySelection={handleEmptySelection}
    />
  );
};

export default ServiceTypePicker;