import { Dialog, Typography, Alert, AlertTitle } from '@mui/material';
import {
  ArchiveOrgWithJournalistsWarning,
  BoldArchiveOrgWthJournalistsWarning,
} from '../../shared/constants/constants';
import PropTypes from 'prop-types';

const AlertCannotArchiveOrg = (props) => {
  const { open, setOpen } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Alert severity="warning" onClose={() => setOpen(false)}>
        <AlertTitle>Cannot Archive Outlet</AlertTitle>
        <Typography variant="body2" paragraph={true}>
          {ArchiveOrgWithJournalistsWarning} — <strong>{BoldArchiveOrgWthJournalistsWarning}</strong>
        </Typography>
      </Alert>
    </Dialog>
  );
};
AlertCannotArchiveOrg.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AlertCannotArchiveOrg;
