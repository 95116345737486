import { createContext, useReducer, useContext } from 'react';

const FiltersContext = createContext(null);
const FiltersDispatchContext = createContext(null);

export function FiltersProvider({ children }) {
  const [filters, dispatch] = useReducer(filtersReducer, initialFilters);
  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters() must be used within a FiltersProvider');
  }
  return context;
}

export function useFiltersDispatch() {
  const context = useContext(FiltersDispatchContext);
  if (context === undefined) {
    throw new Error('useFiltersDispatch() must be used within a FiltersProvider');
  }
  return context;
}

const initialFilters = {};

function filtersReducer(state, action) {
  switch (action.type) {
    case 'setFilters': {
      return { ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
