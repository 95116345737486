import { useMutation } from '@tanstack/react-query';
import {
  cancelCollaborationGroupInvitation,
  resendCollaborationGroupInvitation,
  respondCollaborationGroupInvitation,
  sendNewCollaborationGroupInvitation,
} from '../Requests/CollaborationGroupInvitationRequests';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../AppSnackbar/snackbarSlice';
import {
  UseCancelCollaborationGroupInvitationMutationProps,
  UseResendCollaborationGroupInvitationMutationProps,
  UseRespondCollaborationGroupInvitationMutationProps,
  UseSendNewCollaborationGroupInvitationMutationProps,
} from '../Types/hooks';

export const mutationMsgs = {
  sendNewInvitation: {
    success: 'Invitation(s) sent successfully',
    error: 'Failed to send invitation(s)',
  },
  resendInvitation: {
    success: 'Invitation resent successfully',
    error: 'Failed to resend invitation',
  },
  cancelInvitation: {
    success: 'Invitation cancelled',
    error: 'Failed to cancel invitation',
  },
  respondInvitation: {
    success: 'Invitation responded',
    error: 'Failed to respond invitation',
  },
};

export const useSendNewCollaborationGroupInvitationMutation = ({
  onSuccessCallback,
}: UseSendNewCollaborationGroupInvitationMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: sendNewCollaborationGroupInvitation,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.sendNewInvitation.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group invitation mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.sendNewInvitation.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useResendCollaborationGroupInvitationMutation = ({
  onSuccessCallback,
}: UseResendCollaborationGroupInvitationMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: resendCollaborationGroupInvitation,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.resendInvitation.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group invitation mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.resendInvitation.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useCancelCollaborationGroupInvitationMutation = ({
  onSuccessCallback,
}: UseCancelCollaborationGroupInvitationMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: cancelCollaborationGroupInvitation,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.cancelInvitation.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group invitation mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.cancelInvitation.error,
          severity: 'error',
        })
      );
    },
  });
};

export const useRespondCollaborationGroupInvitationMutation = ({
  onSuccessCallback,
}: UseRespondCollaborationGroupInvitationMutationProps) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: respondCollaborationGroupInvitation,
    onSuccess: () => {
      dispatch(
        openSnackbar({
          message: mutationMsgs.respondInvitation.success,
          severity: 'success',
        })
      );
      onSuccessCallback();
    },
    onError: (error) => {
      console.error('collaboration group invitation mutation error', error);
      dispatch(
        openSnackbar({
          message: mutationMsgs.respondInvitation.error,
          severity: 'error',
        })
      );
    },
  });
};
