import React from 'react';
import TypePicker from './TypePicker';
import { UnitType } from '../Interfaces';

interface UnitTypePickerProps {
  unitTypes: UnitType[];
  selectedUnitTypes: UnitType[];
  setSelectedUnitTypes: React.Dispatch<React.SetStateAction<UnitType[]>>;
}

const UnitTypePicker: React.FC<UnitTypePickerProps> = ({
  unitTypes,
  selectedUnitTypes,
  setSelectedUnitTypes,
}) => {
  return (
    <TypePicker
      types={unitTypes}
      selectedTypes={selectedUnitTypes}
      setSelectedTypes={setSelectedUnitTypes}
      label="Unit Types"
      typeKey="_id"
      typeName="name"
    />
  );
};

export default UnitTypePicker;