import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarChart({ chartData, title, label }) {
  //const data = processData(chartData)//setChartData(chartData);

  const data = {
    labels: chartData[0].y.map((i) => i.replace('<br> ', '').replace('<br> ', '').replace('<br> ', '')), //temporary fix for <br> in chart.js change later backend wise
    datasets: [
      {
        label: label ? label : `#of ${title}`,
        data: chartData[0].x,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
        borderRadius: 6,
        hoverBackgroundColor: 'rgb(0, 0, 0, 0)',
      },
    ],
  };
  const options = {
    responsive: true,

    plugins: {
      title: {
        display: true,
        text: `${title}`,
      },
    },
    indexAxis: 'y', //set in case of horizontal bar chart
    maintainAspectRatio: false,
  };

  return <Bar options={options} data={data} />;
}
