import { useEffect, useState, useContext } from 'react';
import { Box, Modal, TextField, Button, Typography, Container, InputAdornment, Tooltip, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FaceIcon from '@mui/icons-material/Face';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StyleIcon from '@mui/icons-material/Style';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SchoolIcon from '@mui/icons-material/School';
import BuildIcon from '@mui/icons-material/Build';
import { Autocomplete } from '@mui/material';
import DatePicker from 'react-datepicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DnsIcon from '@mui/icons-material/Dns';
import FortIcon from '@mui/icons-material/Fort';
import { useLocation, useNavigationType } from 'react-router-dom';
import { DisplayKeys } from '../../shared/constants/IssueAndServiceKeys';
import { API, complexityOptions, getTabColorHeaderMain, TrackerDataType } from '../../shared/constants/constants';
import { Stack } from '@mui/material';
import { rowOptions } from '../../shared/constants/commonRowValues';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { useSelector } from 'react-redux';
import { useFilters, useFiltersDispatch } from './Context/FiltersContext';
import { needRefetchFilteredDataAtom } from '../../shared/atoms/filterAtoms';
import { useAtom } from 'jotai';

const useStyles = makeStyles(({ palette, tabColor }) => ({
  button: {
    margin: '0 20px',
  },
  content: {
    backgroundColor: 'white',
    padding: '20px 30px',
    maxWidth: '700px',
    minHeight: '600px',
    maxHeight: '90%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '25px',
    overflow: 'scroll',
  },
  filterContainer: {
    margin: '20px 0',
  },
  title: {
    fontSize: '26px',
    fontWeight: 700,
    textAlign: 'center',
  },
  filterTitle: {
    margin: '8px 0px',
    fontSize: '20px',
    fontWeight: 600,
  },
  filterInput: {
    width: '100%',
  },
  helpText: {
    fontSize: '16px',
    marginLeft: 0,
  },
  filterButton: {
    width: '110px',
    height: '40px',
    fontSize: '16px',
    margin: '0 10px',
    fontWeight: 550,
    '&.MuiButton-contained': {
      background: ({ tabColor }) => tabColor,
    },
  },
  buttonContainer: {
    display: 'block',
    margin: '15px auto',
    textAlign: 'center',
  },
  filterIcon: {
    margin: '0 10px',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  filterIconNested: {
    padding: '20px 0',
    margin: '2px',
    maxWidth: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupLabel: {
    background: ({ tabColor }) => tabColor,
    color: palette.primary.contrastText,
    fontWeight: 700,
  },
  dateRangeContainer: {
    display: 'inline-flex',
    width: '100%',
    '& > .react-datepicker-wrapper': {
      width: 'auto',
    },
    flexWrap: 'wrap',
  },
  dateRange: {
    padding: 8,
    margin: 'auto',
    '&:focus-visible': {
      outlineColor: palette.primary.dark,
    },
  },
  dateRangeText: {
    width: '100px',
  },
}));

const FiltersEnum = {
  outlet: 'outlet',
  journalist: 'journalist',
  topic: 'topic',
  expert: 'expert',
  units: 'units',
  startDate: 'Start Date',
  endDate: 'End Date',
  linkedService: 'linkedService',
  teamMember: 'Lead/Expert',
  service: 'service',
  complexity: 'complexity',
  type: 'type',
  campaigns: 'Campaigns',
  teamMembers: 'Team Members',
  status: 'status',
  linkedInteraction: 'linkedInteraction',
};

const dateInputFilters = [FiltersEnum.startDate, FiltersEnum.endDate];
const textInputFiltersMap = {
  MediaInteraction: [FiltersEnum.topic],
  ServiceLog: [FiltersEnum.service],
  ManagerAnalytics: [],
};
const selectInputFiltersMap = {
  MediaInteraction: [
    FiltersEnum.outlet,
    FiltersEnum.journalist,
    FiltersEnum.expert,
    FiltersEnum.units,
    FiltersEnum.linkedService,
    FiltersEnum.type,
    FiltersEnum.status,
    FiltersEnum.campaigns,
    FiltersEnum.teamMembers,
  ],
  ServiceLog: [
    FiltersEnum.units,
    FiltersEnum.teamMember,
    FiltersEnum.complexity,
    FiltersEnum.campaigns,
    FiltersEnum.type,
    FiltersEnum.status,
    FiltersEnum.teamMembers,
  ],
  ManagerAnalytics: [FiltersEnum.teamMembers],
};

const textFiltersHelpText = {
  [FiltersEnum.journalist]: "Type a journalist's name and see how we have responded to them in the past",
  [FiltersEnum.units]: 'Search by Unit/Faculty/Area',
};

const filterIconMap = {
  MediaInteraction: {
    [FiltersEnum.outlet]: <ApartmentIcon />,
    [FiltersEnum.expert]: <FaceIcon />,
    [FiltersEnum.topic]: <DescriptionIcon />,
    [FiltersEnum.journalist]: <AssignmentIndIcon />,
    [FiltersEnum.units]: <SchoolIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
    [FiltersEnum.linkedService]: <DnsIcon />,
    [FiltersEnum.status]: <BuildIcon />,
    [FiltersEnum.campaigns]: <FortIcon />,
    [FiltersEnum.type]: <StyleIcon />,
    [FiltersEnum.teamMembers]: <FaceIcon />,
    'id': <DescriptionIcon />,
  },
  ServiceLog: {
    [FiltersEnum.service]: <DescriptionIcon />,
    [FiltersEnum.teamMember]: <FaceIcon />,
    [FiltersEnum.units]: <SchoolIcon />,
    [FiltersEnum.complexity]: <FormatListNumberedIcon />,
    [FiltersEnum.type]: <StyleIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
    [FiltersEnum.status]: <BuildIcon />,
    [FiltersEnum.linkedInteraction]: <DnsIcon />,
    [FiltersEnum.campaigns]: <FortIcon />,
    [FiltersEnum.teamMembers]: <FaceIcon />,
    'id': <DescriptionIcon />,
  },
  ManagerAnalytics: {
    [FiltersEnum.teamMembers]: <FaceIcon />,
    [FiltersEnum.startDate]: <DateRangeIcon />,
    [FiltersEnum.endDate]: <DateRangeIcon />,
  },
};

export default function FilterModal(props) {
  const {
    open,
    setModalOpen,
    setMonthlyView,
    dropdownTable,
    setDataCallback,
    currMonth,
    eventHandler,
    inProgress,
    inProgressCallback,
    filterTags,
    setFilterTags,
  } = props;
  const { state, pathname } = useLocation();
  let initialState = state;
  const user = useSelector((state) => state.user.userInfo);

  const classes = useStyles({ tabColor: getTabColorHeaderMain(pathname) });
  if (state?.queryString) {
    initialState = {};
  }

  const filters = useFilters();
  const dispatch = useFiltersDispatch();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (initialState) dispatch({ type: 'setFilters', payload: initialState });
    //keep dep array empty to avoid render loops
    //eslint-disable-next-line
  }, []);

  const [unsubmittedFiltersSelectedUsingModal, setUnsubmittedFiltersSelectedUsingModal] = useState(filters);
  window.history.replaceState(null, pathname);
  const socket = useContext(SocketContext);
  const filterIcon = filterIconMap[eventHandler.type];
  const textInputFilters = textInputFiltersMap[eventHandler.type];
  const selectInputFilters = selectInputFiltersMap[eventHandler.type];
  const [needRefetchFilteredData, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);

  useEffect(() => {
    async function emitData() {
      let overwriteFilter = false;
      // This is to deal with blank screen bug that happens when we are at Media Interactions/Service Log with filters applied and
      // we hit the back button to return to Service Log/Media Interactions. The reason being that filter context does not
      // get reset by the back button.
      if (navigationType === 'POP') {
        // Filter context is unfortunately not updated instantly. So we need to do onSearch using empty object while waiting for
        // filter context to reset.
        overwriteFilter = true;
        dispatch({ type: 'setFilters', payload: {} });
      }

      if (filters) {
        await onSearch(overwriteFilter ? {} : filters);
      }
    }
    emitData();
    //ignoring filters, navigationType, onSearch in dep array
    //eslint-disable-next-line
  }, [socket, dispatch]);

  useEffect(() => {
    // To continously make sure unsubmittedFiltersSelectedUsingModal is equal to filters before modal is opened
    setUnsubmittedFiltersSelectedUsingModal(filters);

    syncFilterTags(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (inProgress) {
      const progressFilter = dropdownTable[FiltersEnum.status].find((option) => option.name === rowOptions.PROGRESS);
      const userNameFilter = dropdownTable['lead'].find((option) => option.username === user.username);

      const onGoingProgressFilter = filters[FiltersEnum.status]?.find((option) => option.name === rowOptions.PROGRESS);
      const onGoingUserFilter = filters[FiltersEnum.teamMembers]?.find((option) => option.username === user.username);

      if (!onGoingProgressFilter || !onGoingUserFilter) {
        onSearch({
          ...filters,
          [FiltersEnum.status]: [progressFilter],
          [FiltersEnum.teamMembers]: [userNameFilter],
        });
        dispatch({
          type: 'setFilters',
          payload: {
            ...filters,
            [FiltersEnum.status]: [progressFilter],
            [FiltersEnum.teamMembers]: [userNameFilter],
          },
        });
      }
      inProgressCallback(false);
    }
    //ignoring dropdownTable, filters, inProgressCallback, onSearch, user.username in dep array
    //eslint-disable-next-line
  }, [inProgress, dispatch]);

  // useEffect for refetching the data when source changes
  useEffect(() => {
    if (needRefetchFilteredData) {
      onSearch(filters).then(() => {
        setNeedRefetchFilteredData(false);
      });
    }
    //ignoring filters, onSearch, setNeedRefetchFilteredData in dep array
    //eslint-disable-next-line
  }, [needRefetchFilteredData]);

  const returnToMonthlyView = () => {
    setMonthlyView(true);

    if (eventHandler.type === TrackerDataType.MANAGER_ANALYTICS) {
      eventHandler.monthlyView(currMonth, setDataCallback);
    } else {
      eventHandler.monthlyView(currMonth);
    }
  };

  const syncFilterTags = (filtersToSynchFilterTagsWith) => {
    const filterTags = { ...filtersToSynchFilterTagsWith };
    dateInputFilters.forEach((df) => {
      if (filterTags[df]) {
        const offsetMs = filterTags[df].getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(filterTags[df].getTime() - offsetMs);
        filterTags[df] = `${df} ${dateLocal.toISOString().slice(0, 10)}`;
      }
    });
    setFilterTags(filterTags);
  };

  const onSearch = async (currFilters) => {
    setModalOpen(false);

    syncFilterTags(currFilters);

    const existingFilters = Object.keys(currFilters).filter(
      (f) => currFilters[f] && (!Array.isArray(currFilters[f]) || currFilters[f].length > 0)
    );

    if (existingFilters.length === 0) {
      returnToMonthlyView();
      return;
    }

    const groupFilterName = (f) => {
      return `${f.toLowerCase()}=${JSON.stringify(currFilters[f].map((value) => value.name))}`;
    };

    const groupFilterId = (f) => {
      return `${f.toLowerCase()}=${JSON.stringify(currFilters[f].map((value) => value._id))}`;
    };

    const query = existingFilters
      .map((f) => {
        if (textInputFilters.includes(f)) return `${f.toLowerCase()}=${encodeURIComponent(currFilters[f])}`;

        if (dateInputFilters.includes(f)) {
          if (f === FiltersEnum.endDate) {
            const date = new Date(currFilters[f]);
            date.setDate(date.getDate() + 1);
            return `${f.toLowerCase().replaceAll(' date', 'Date')}=${date.toISOString()}`;
          }
          return `${f.toLowerCase().replaceAll(' date', 'Date')}=${currFilters[f].toISOString()}`;
        }

        if (f === FiltersEnum.units) return groupFilterName(f);

        if (f === FiltersEnum.outlet) return groupFilterId(f);

        if (f === FiltersEnum.status) return groupFilterName(f);

        if (f === FiltersEnum.campaigns) return groupFilterId(f);

        if (f === FiltersEnum.linkedInteraction) return `${f.toLowerCase()}=${currFilters[f]._id}`;

        // Special filter (not accessible to user) to query for id
        if (f === 'id') return `${f.toLowerCase()}=${currFilters[f]}`;

        return groupFilterId(f);
      })
      .join('&');

    if (eventHandler.type === TrackerDataType.MANAGER_ANALYTICS) {
      eventHandler.getData(query, currMonth, setDataCallback);
    } else {
      const url = `/${eventHandler.type === TrackerDataType.SERVICE ? 'services' : 'issues'}/filter?${query}`;

      API.get(url)
        .then((res) => {
          const filteredIssues = res.data.data || null;
          setDataCallback(filteredIssues);
        })
        .catch((err) => {
          alert(err);
          setDataCallback(null);
        });
    }
  };

  const renderFilterTags = () => {
    const existingTags = Object.keys(filterTags).filter((ft) => filterTags[ft]);
    if (existingTags.length === 0) {
      return;
    }
    return (
      <Box className={classes.buttonContainer}>
        {existingTags
          .filter((tag) => selectInputFilters.find((element) => tag === element))
          .map((tagGroup) => {
            return (
              <Chip
                icon={renderFilterIcon(tagGroup)}
                label={
                  <Stack direction={'row'}>
                    {filterTags[tagGroup].map((tag) => {
                      return (
                        <Chip
                          label={tag.name || tag._id || tag}
                          key={tag._id}
                          variant="outlined"
                          onDelete={() => {
                            let filtersTagsInTagGroupExcludingToBeDeletedTag = filters[tagGroup].filter(
                              (element) => element.name !== tag.name
                            );

                            if (filtersTagsInTagGroupExcludingToBeDeletedTag.length === 0) {
                              filtersTagsInTagGroupExcludingToBeDeletedTag = undefined;
                            }

                            onSearch({
                              ...filters,
                              [tagGroup]: filtersTagsInTagGroupExcludingToBeDeletedTag,
                            });
                            dispatch({
                              type: 'setFilters',
                              payload: {
                                ...filters,
                                [tagGroup]: filtersTagsInTagGroupExcludingToBeDeletedTag,
                              },
                            });
                          }}
                          className={classes.filterIcon}
                        />
                      );
                    })}
                  </Stack>
                }
                key={tagGroup}
                variant="outlined"
                onDelete={() => {
                  onSearch({ ...filters, [tagGroup]: undefined });
                  dispatch({
                    type: 'setFilters',
                    payload: {
                      ...filters,
                      [tagGroup]: undefined,
                    },
                  });
                }}
                className={classes.filterIconNested}
                onClick={() => setModalOpen(true)}
              />
            );
          })}
        {existingTags
          .filter((tag) => !selectInputFilters.find((element) => tag === element))
          .map((tag) => {
            return (
              <Chip
                icon={renderFilterIcon(tag)}
                label={filterTags[tag].name || filterTags[tag]._id || filterTags[tag]}
                variant="outlined"
                key={tag}
                onDelete={() => {
                  onSearch({ ...filters, [tag]: undefined });
                  dispatch({
                    type: 'setFilters',
                    payload: {
                      ...filters,
                      [tag]: undefined,
                    },
                  });
                }}
                className={classes.filterIcon}
                onClick={() => setModalOpen(true)}
              />
            );
          })}
        <Button
          onClick={() => {
            setDataCallback(null);
            setFilterTags({});
            dispatch({
              type: 'setFilters',
              payload: {},
            });
            returnToMonthlyView();
          }}
          variant="outlined"
          color="secondary"
        >
          Clear All
        </Button>
      </Box>
    );
  };

  const renderDateRange = () => {
    return (
      <>
        <Typography className={classes.filterTitle}>Date Range</Typography>
        <Box className={classes.dateRangeContainer}>
          <Typography className={`${classes.filterTitle} ${classes.dateRangeText}`} align="left">
            From
          </Typography>
          <DatePicker
            selected={unsubmittedFiltersSelectedUsingModal[FiltersEnum.startDate]}
            onChange={(date) =>
              setUnsubmittedFiltersSelectedUsingModal({
                ...unsubmittedFiltersSelectedUsingModal,
                [FiltersEnum.startDate]: date,
              })
            }
            selectsStart
            startDate={unsubmittedFiltersSelectedUsingModal[FiltersEnum.startDate]}
            endDate={unsubmittedFiltersSelectedUsingModal[FiltersEnum.endDate]}
            className={classes.dateRange}
          />
          <Typography className={`${classes.filterTitle} ${classes.dateRangeText}`} align="center">
            To
          </Typography>
          <DatePicker
            selected={unsubmittedFiltersSelectedUsingModal[FiltersEnum.endDate]}
            onChange={(date) =>
              setUnsubmittedFiltersSelectedUsingModal({
                ...unsubmittedFiltersSelectedUsingModal,
                [FiltersEnum.endDate]: date,
              })
            }
            selectsEnd
            startDate={unsubmittedFiltersSelectedUsingModal[FiltersEnum.startDate]}
            endDate={unsubmittedFiltersSelectedUsingModal[FiltersEnum.endDate]}
            minDate={unsubmittedFiltersSelectedUsingModal[FiltersEnum.startDate]}
            className={classes.dateRange}
          />
        </Box>
      </>
    );
  };

  const renderFilterIcon = (filter) => {
    return (
      <Tooltip title={<Typography fontSize={22}>{filter}</Typography>} arrow={true}>
        {filterIcon[filter]}
      </Tooltip>
    );
  };

  const renderTextInputFilter = () => {
    return textInputFilters.length ? (
      textInputFilters.map((filter) => (
        <Box key={filter}>
          <Typography className={classes.filterTitle}>{DisplayKeys[filter] || filter}</Typography>
          <TextField
            helperText={textFiltersHelpText[filter]}
            key={filter}
            id={`${filter}-filter-input`}
            size="small"
            InputProps={
              filterIcon[filter] && {
                startAdornment: (
                  <InputAdornment position="start" fontSize="20px">
                    {renderFilterIcon(filter)}
                  </InputAdornment>
                ),
              }
            }
            variant="outlined"
            className={classes.filterInput}
            value={unsubmittedFiltersSelectedUsingModal[filter] || ''}
            onChange={(event) =>
              setUnsubmittedFiltersSelectedUsingModal({
                ...unsubmittedFiltersSelectedUsingModal,
                [filter]: event.target.value,
              })
            }
            FormHelperTextProps={{ className: classes.helpText }}
          />
        </Box>
      ))
    ) : (
      <></>
    );
  };

  const renderSelectInputFilter = () => {
    const filterKeyMap = {
      [FiltersEnum.teamMember]: 'expert',
      [FiltersEnum.teamMembers]: 'lead',
      [FiltersEnum.linkedService]: 'service',
      [FiltersEnum.status]: 'status',
      [FiltersEnum.campaigns]: 'campaigns',
    };

    return selectInputFilters.map((filter) => {
      const key = filterKeyMap[filter] || filter;
      let options = [];
      let groupBy = (option) => option.firstLetter;
      if (key === FiltersEnum.complexity) {
        options = complexityOptions;
        groupBy = undefined;
      } else {
        options =
          (dropdownTable[key] || [])
            .filter((item) => !item.archived)
            .map((item) => ({
              ...item,
              firstLetter: item.name?.charAt(0),
            })) || [];
        if (key === FiltersEnum.type) {
          options = options.sort(
            (a, b) => -b?.type?.localeCompare(a?.type) || -b?.firstLetter?.localeCompare(a?.firstLetter)
          );
          groupBy = (option) => option.type;
        } else {
          options = options.sort((a, b) => -b?.firstLetter?.localeCompare(a?.firstLetter));
        }
      }
      return (
        <Box key={filter}>
          <Typography className={classes.filterTitle}>{DisplayKeys[filter] || filter}</Typography>
          <Autocomplete
            id={`select-filter-${filter}`}
            options={options}
            groupBy={groupBy}
            multiple={true}
            getOptionLabel={(option) => option.name || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option._id}>
                {option.name}
              </Box>
            )}
            value={unsubmittedFiltersSelectedUsingModal[filter] || []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />;
              })
            }
            onChange={(_, v) => {
              setUnsubmittedFiltersSelectedUsingModal({ ...unsubmittedFiltersSelectedUsingModal, [filter]: v });
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            variant="outlined"
            classes={{ groupLabel: classes.groupLabel }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                helperText={textFiltersHelpText[filter]}
                size="small"
                id={`${filter}-filter-input`}
                InputProps={
                  filterIcon[filter] && {
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start" fontSize="20px">
                          {renderFilterIcon(filter)}
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }
                }
                className={classes.filterInput}
                FormHelperTextProps={{ className: classes.helpText }}
              />
            )}
          />
        </Box>
      );
    });
  };

  const renderModalButtons = () => {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.filterButton}
          variant="outlined"
          color="primary"
          onClick={() => {
            setUnsubmittedFiltersSelectedUsingModal(filters);
            setModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.filterButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch({
              type: 'setFilters',
              payload: {
                ...unsubmittedFiltersSelectedUsingModal,
              },
            });
            onSearch(unsubmittedFiltersSelectedUsingModal);
          }}
        >
          Search
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Modal
        disableEscapeKeyDown
        open={open}
        onClose={(_, reason) => {
          // Closing filter modal with backdrop click will have same behavior as clicking cancel button
          if (reason === 'backdropClick') {
            setUnsubmittedFiltersSelectedUsingModal(filters);
          }
          setModalOpen(false);
        }}
        aria-labelledby="filter-modal-title"
        aria-describedby="tracker-filters"
      >
        <Container className={classes.content}>
          <Typography className={classes.title} color="primary">
            Tracker Filters
          </Typography>
          <Box className={classes.filterContainer}>
            {renderDateRange()}
            {renderTextInputFilter()}
            {renderSelectInputFilter()}
            {renderModalButtons()}
          </Box>
        </Container>
      </Modal>
      {renderFilterTags()}
    </>
  );
}
