import { Box, Card, CardContent, Collapse, TableCell, TableRow } from '@mui/material';
import {
  visibleShareIssueCols,
  visibleShareIssueDetailedCols,
  visibleShareIssueDetailedWideCols,
} from './Utils/constants';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { paletteColorsHeaderMain, TrackerDataType } from '../../shared/constants/constants';
import CellViewer from '../Cells/CellViewer';
import { SharedInteractionsCollapsedDataRowProps } from './Types/componentProps';
import { IssueKeys } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = (tabColor: string) => {
  return makeStyles(({ palette }) => ({
    hiddenCellCard: {
      flex: 1,
      alignSelf: 'flex-start',
      margin: '10px',
      textAlign: 'center',
      border: `1px solid ${palette.secondary.dark}`,
    },
    cardContent: {
      padding: '0 !important',
      borderRadius: '10%',
    },
    cardName: {
      background: tabColor,
      padding: 8,
      color: palette.primary.contrastText,
      fontWeight: 800,
    },
    collapseWrapperInner: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      width: '100%',
    },
    colContainer: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '50%',
      flexDirection: 'row',
    },
    wideColContainer: {
      flex: 1,
      display: 'flex',
      width: '50%',
      flexBasis: '15vw',
      flexDirection: 'row',
    },
  }))();
};

const SharedInteractionsCollapsedDataRow = (props: SharedInteractionsCollapsedDataRowProps) => {
  const { collapsed, issueCells } = props;

  const classes = useStyles(paletteColorsHeaderMain[0]);

  const renderDetailedViewCards = (col: IssueKeys) => (
    <Card className={classes.hiddenCellCard}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardName}>{col}</Box>
        <Box sx={{ padding: 6, paddingBottom: 10, minHeight: '5vh' }}>
          <CellViewer
            type={TrackerDataType.MEDIA_INTERACTION}
            cells={issueCells}
            index={col}
            callback={() => {}}
            dropdownTable={null}
            openForm={() => {}}
            classes={classes}
            id={null}
            handleDrag={() => {}}
            handleDrop={() => {}}
            isDragActive={() => {}}
          />
        </Box>
      </CardContent>
    </Card>
  );

  if (!collapsed) {
    return null;
  }

  return (
    <TableRow sx={{ display: collapsed ? 'table-row' : 'none', padding: '4px' }}>
      <TableCell colSpan={visibleShareIssueCols.length + 2}>
        <Collapse in={collapsed} timeout={250} classes={{ wrapperInner: classes.collapseWrapperInner }}>
          <Box className={classes.colContainer}>{visibleShareIssueDetailedCols.map(renderDetailedViewCards)}</Box>
          <Box className={classes.wideColContainer}>
            {visibleShareIssueDetailedWideCols.map(renderDetailedViewCards)}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default SharedInteractionsCollapsedDataRow;
