import React, { useContext, useState } from 'react';
import { AIScrapeResult, EmailQueueEntry } from './Interfaces';
import { Typography, Table, Paper, TableContainer, TableBody } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmailEntryRow from './EmailEntryRow';
import {
  API,
  AutoTrackerInteractionHeaderCols,
  AutoTrackerServiceHeaderCols,
  getBackgroundColor,
  RequiredAutoTrackerIssueKeys,
  RequiredAutoTrackerServiceKeys,
  TrackerDataType,
} from '../../shared/constants/constants';
import NewIssuePopup from '../QueryAdder/NewIssuePopup';
import { SocketContext } from '../../shared/contexts/SocketContext';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import QueryAdder from '../QueryAdder/QueryAdder';
import { addContact, deleteEmail, getEmails, saveFixedEmail } from './emailScrapingRequests';
import {
  processFromAIScrapeForAutoComplete,
  processFromResultForAIScrape,
} from '../../shared/functions/autoCompleteProcessing';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AutoTrackerTableHeader from './AutoTrackerTableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import { validateEntry, validatePotentialEntry } from './helpers';
import moment from 'moment/moment';
import { sendNotification } from '../../shared/requests/notifications';
import ForwardingAddressInstructions from './ForwardingAddressInstructions';

interface EmailScrapingPageInterface {
  dropdownTable: any;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: '10px',
    width: '90%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '10px',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const interactionsEmail = 'interaction';
const servicesEmail = 'service';

//TODO finish support for services
function AutoTrackerPage(props: Readonly<EmailScrapingPageInterface>) {
  const { dropdownTable } = props;
  const socket = useContext(SocketContext);
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);
  const serviceEventHandler = ServiceLogEventHandler(socket);
  const [selectedView] = useState(mediaInteractionEventHandler.type);
  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const [cells, setCells] = useState({});
  const [selectedEmail, setSelectedEmail] = useState<EmailQueueEntry | null>(null);
  const currentUser = useSelector((state: any) => state.user.userInfo);
  const dispatch = useDispatch();

  const emailsQuery = useQuery({
    queryKey: ['emails', 'emailScrapingPage'],
    queryFn: () => getEmails(),
    refetchInterval: 5 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const queryClient = useQueryClient();
  const handleDelete = useMutation({
    mutationFn: (email: EmailQueueEntry) => deleteEmail(email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', 'emailScrapingPage'] });
      if (!selectedEmail) {
        dispatch(openSnackbar({ severity: 'success', message: `Entry deleted successfully` }));
      } else {
        setSelectedEmail(null);
      }
    },
    onError: () => {
      dispatch(openSnackbar({ severity: 'error', message: `There was an error deleting the AutoTracker entry` }));
    },
  });

  const queryDeleteCallback = async (
    fields: any,
    email?: EmailQueueEntry,
    updatedDropdownTable: any = dropdownTable
  ) => {
    const emailToDelete = email || selectedEmail;
    if (emailToDelete) {
      const result: AIScrapeResult = {
        Contact: '',
        Date: '',
        Department: undefined,
        Journalist: { name: '', _id: '' },
        'Journalist Email': '',
        'Journalist Phone': '',
        Outlet: { name: '', _id: '' },
        'Lead/Expert': undefined,
        Status: '',
        Topic: '',
        Type: '',
        Unit: undefined,
      };
      const parsedFixedResult = processFromResultForAIScrape(result, fields, updatedDropdownTable);
      try {
        await saveFixedEmail(emailToDelete, parsedFixedResult);
        dispatch(openSnackbar({ severity: 'success', message: `Entry added successfully` }));
        handleDelete.mutate(emailToDelete);
      } catch (error: any) {
        dispatch(openSnackbar({ severity: 'error', message: error.message }));
      }
    }
  };

  if (emailsQuery.isLoading) {
    return <div className={classes.spinnerContainer}>Loading...</div>;
  } else if (emailsQuery.isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading emails</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  async function handleManualAddClick(email: EmailQueueEntry) {
    if (email.aiScrapeResult) {
      setCells(
        processFromAIScrapeForAutoComplete(
          email.aiScrapeResult,
          email.text,
          selectedView === TrackerDataType.MEDIA_INTERACTION ? IssueKeys : ServiceKeys,
          dropdownTable
        )
      );
    } else {
      setCells({});
    }
    setSelectedEmail(email);
    setOpenForm(true);
  }

  async function handleAutomaticAddClick(email: EmailQueueEntry) {
    const requiredColumns =
      selectedView === TrackerDataType.MEDIA_INTERACTION
        ? RequiredAutoTrackerIssueKeys
        : RequiredAutoTrackerServiceKeys;
    const columnsToBeAdded = validatePotentialEntry(email, dropdownTable, requiredColumns);
    let updatedDropdownTable = dropdownTable;
    if (columnsToBeAdded?.length > 0 && email.aiScrapeResult) {
      dispatch(
        openSnackbar({
          severity: 'info',
          message: `Adding missing fields, please wait.`,
        })
      );
      try {
        for (const column of columnsToBeAdded) {
          const contactRequests = [];
          if (column !== IssueKeys.journalist) {
            contactRequests.push(addContact(column, email.aiScrapeResult, updatedDropdownTable));
          }
          await Promise.all(contactRequests);
        }
        updatedDropdownTable = (await API.get('dropdown-table'))?.data;
        if (columnsToBeAdded.includes(IssueKeys.journalist)) {
          await addContact(IssueKeys.journalist, email.aiScrapeResult, updatedDropdownTable);
          updatedDropdownTable = (await API.get('dropdown-table'))?.data;
        }
        await queryClient.invalidateQueries({ queryKey: ['dropdown-table'] });
      } catch (error) {
        dispatch(
          openSnackbar({
            severity: 'error',
            message: `There was a problem adding missing fields.`,
          })
        );
      }
    }
    const isValidEntry = validateEntry(email, updatedDropdownTable, requiredColumns);
    if (isValidEntry) {
      const fields = processFromAIScrapeForAutoComplete(
        email.aiScrapeResult,
        email.text,
        selectedView === TrackerDataType.MEDIA_INTERACTION ? IssueKeys : ServiceKeys,
        updatedDropdownTable,
        currentUser
      );
      fields['year'] = moment.utc(new Date(fields.date)).format('YYYY');
      fields['month'] = moment.utc(new Date(fields.date)).format('MMM');

      delete fields.outlet;
      try {
        await mediaInteractionEventHandler.createNewItem(fields, null, ({ postId }: { postId: any }) => {
          sendNotification(
            (mediaInteractionEventHandler.type === TrackerDataType.MEDIA_INTERACTION ? fields.lead : fields.teamMember)
              ?.map((teamMember: any) => teamMember._id)
              .filter((teamMemberId: any) => teamMemberId !== currentUser.id) ?? [],
            postId,
            mediaInteractionEventHandler.type === TrackerDataType.MEDIA_INTERACTION ? fields.topic : fields.service,
            mediaInteractionEventHandler.type === TrackerDataType.MEDIA_INTERACTION
          );
          setSelectedEmail(email);
          queryDeleteCallback(fields, email, updatedDropdownTable);
        });
      } catch (e: any) {
        console.log(e.message);
      }
    } else {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: `Entry needs to be added manually, some required fields are missing`,
        })
      );
    }
  }
  return (
    <div style={{ width: '100%' }}>
      {dropdownTable && emailsQuery.isSuccess ? (
        <div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <AutoTrackerTableHeader
                tabColor={getBackgroundColor(selectedView)}
                columns={
                  selectedView === TrackerDataType.MEDIA_INTERACTION
                    ? AutoTrackerInteractionHeaderCols
                    : AutoTrackerServiceHeaderCols
                }
              />
              <TableBody>
                {emailsQuery.data.parsedEmails
                  .filter((entry: EmailQueueEntry) =>
                    entry.destination.includes(
                      selectedView === TrackerDataType.MEDIA_INTERACTION ? interactionsEmail : servicesEmail
                    )
                  )
                  .map((entry: EmailQueueEntry) => {
                    return (
                      <EmailEntryRow
                        row={entry}
                        key={entry._id}
                        dropdownTable={dropdownTable}
                        handleManualAddClick={handleManualAddClick}
                        handleAutomaticAddClick={handleAutomaticAddClick}
                        handleDelete={handleDelete}
                        columns={
                          selectedView === TrackerDataType.MEDIA_INTERACTION
                            ? AutoTrackerInteractionHeaderCols
                            : AutoTrackerServiceHeaderCols
                        }
                        eventHandler={mediaInteractionEventHandler}
                        tabColor={getBackgroundColor(selectedView)}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <NewIssuePopup
            openForm={openForm}
            setOpenForm={setOpenForm}
            title={mediaInteractionEventHandler.newItemModalTitle}
          >
            <QueryAdder
              dropdownTable={dropdownTable}
              setOpenForm={setOpenForm}
              eventHandler={
                selectedView === TrackerDataType.MEDIA_INTERACTION ? mediaInteractionEventHandler : serviceEventHandler
              }
              cells={cells}
              forAutoTracker={true}
              emailScrapeCallback={queryDeleteCallback}
            />
          </NewIssuePopup>
          <ForwardingAddressInstructions />
        </div>
      ) : (
        <div className={classes.spinnerContainer}>Loading...</div>
      )}
    </div>
  );
}

export default AutoTrackerPage;
