import { Typography, Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { verifyUserChangedEmail } from '../../shared/functions/userFunctions';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  cancelButton: {
    backgroundColor: 'red',
    color: 'white',
  },
  dialogFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
    marginTop: '0.8rem',
  },
  dialogInputContainer: {
    display: 'flex',
    flexDirection: 'column',
  }
});

export default function VerifyNewUserProfileEmailDialog({
  userId,
  openDialog,
  setUser,
  handleDialogClose,
}) {
  const classes = useStyles();
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const closeDialog = () => {
    setVerificationCode('');
    setIsLoading(false);
    handleDialogClose();
  };

  const onVerify = () => {
    setIsLoading(true);
    verifyUserChangedEmail({
      id: userId,
      code: verificationCode,
    }).then(() => {
      setIsLoading(false);
      dispatch(
        openSnackbar({
          message: `Successfully verified email`,
          severity: 'success',
        })
      );
      closeDialog();
      setUser();
    }).catch(err => {
      setIsLoading(false);
      dispatch(
        openSnackbar({
          message: `Failed to verify email, try click Save button to send a new code`,
          severity: 'error',
        })
      );
      console.error(err);
    });
  };

  return (
    <Dialog
      open={openDialog}
    >
      <DialogTitle>Verify new email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You must verify your new email to apply changes.
        </DialogContentText>
        <DialogContentText>
          Use the <strong>Verification Code</strong> received in your email to verify.
        </DialogContentText>
        <DialogContentText>
          If you do not receive a code, close this dialog and click on Save button to try again.
        </DialogContentText>
        <Box className={classes.dialogFormContainer}>
          <div className={classes.dialogInputContainer}>
            <Typography variant="h6" fontWeight="bold">
              Confirmation Code
            </Typography>
            <TextField
              variant="standard"
              placeholder='Confirmation Code'
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          className={classes.cancelButton}
          variant="contained"
          disabled={isLoading}
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={!verificationCode}
          onClick={onVerify}
        >
          Verify
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

VerifyNewUserProfileEmailDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  setUser: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
}
