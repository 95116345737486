import { Tooltip, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface FilterModalFilterIconProps {
  filterName: string;
  filterIcon: ReactElement;
}
const FilterModalFilterIcon: React.FC<FilterModalFilterIconProps> = ({ filterName, filterIcon }) => {
  return (
    <Tooltip title={<Typography fontSize={22}>{filterName}</Typography>} arrow={true}>
      {filterIcon}
    </Tooltip>
  );
};

export default FilterModalFilterIcon;
