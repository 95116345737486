import { useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { IconButton } from '@mui/material';
import ToolTip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';

export default function CsvExporter({ rows, dropdowns, flattenerCallback, filename }) {
  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();
  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current === false) {
      csvLink.current.link.click();
    }

    if (initialLoad.current === true) {
      initialLoad.current = false;
    }
  }, [csvData]);

  return (
    <>
      <ToolTip title="Export data to CSV">
        <div>
          <IconButton
            onClick={() => {
              const formattedRows = flattenerCallback(rows, dropdowns);
              setCsvData(formattedRows);
            }}
            size="large"
            style={{ position: 'relative', paddingTop: '20px', bottom: '-10px' }}
          >
            {<DownloadIcon />}
          </IconButton>
        </div>
      </ToolTip>
      <CSVLink
        filename={Date.now() + '-' + filename + '.csv'}
        className="hidden"
        data={csvData}
        ref={csvLink}
        separator={','}
      />
    </>
  );
}

CsvExporter.propTypes = {
  rows: PropTypes.array.isRequired,
  dropdowns: PropTypes.object.isRequired,
  flattenerCallback: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
};
