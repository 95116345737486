import { IconButton, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { AddCircleOutline, AddCircle, Delete } from '@mui/icons-material';
import { EmailQueueEntry } from './Interfaces';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

interface EmailEntryEditorCellInterface {
  className: string;
  row: EmailQueueEntry;
  handleManualAddClick: (email: EmailQueueEntry) => void;
  handleAutomaticAddClick: (email: EmailQueueEntry) => void;
  handleDelete: UseMutationResult<AxiosResponse<any, any>, Error, EmailQueueEntry, unknown>;
}

export function EmailEntryEditorCell(props: Readonly<EmailEntryEditorCellInterface>) {
  const { className, row, handleManualAddClick, handleAutomaticAddClick, handleDelete } = props;

  return (
    <TableCell className={className}>
      <>
        <Tooltip title={'Add automatically'} placement="right">
          <IconButton aria-label="Add automatically" size="small" onClick={() => handleAutomaticAddClick(row)}>
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Add manually'} placement="right">
          <IconButton aria-label="Add manually" size="small" onClick={() => handleManualAddClick(row)}>
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Delete'} placement="right">
          <IconButton aria-label="Delete" size="small" onClick={() => handleDelete.mutate(row)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </>
    </TableCell>
  );
}
