import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
  modalCard: {
    padding: '30px',
    width: '20vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
  },
  confirmButton: {
    backgroundColor: 'red',
    width: '6vw',
    height: '5vh',
    color: 'white',
  },
  cancelButton: {
    width: '6vw',
    height: '5vh',
  },
});
function ConfirmationModal({ callBack, open, setOpen, text, headerText, confirmButtonText }) {
  const classes = useStyles();

  // Handle modal close
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal data-cy="confirmationModal" open={open} onClose={handleClose}>
      <Card className={classes.modalCard}>
        <Typography align="left" variant="h4" onClick={handleClose}>
          {' '}
          {/* Make headerText clickable to close */}
          {headerText}
        </Typography>
        <Typography align="left">{text}</Typography>
        <div className={classes.buttonContainer}>
          <Button
            data-cy="button-cancel-confirmationModal"
            variant="contained"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.confirmButton}
            onClick={() => {
              handleClose();
              callBack();
            }}
            variant="primary"
          >
            {confirmButtonText}
          </Button>
        </div>
      </Card>
    </Modal>
  );
}

export default ConfirmationModal;
