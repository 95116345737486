export const getStrippedRow = (index: number) => {
  if (index % 2) {
    return '#f0f2f5';
  }
  return '';
};

export const getSharedContactAvatarLetter = (name: string) => {
  let tokens = name.split(' ') || null;
  return tokens
    ? `${tokens[0].charAt(0).toUpperCase()}${tokens[1].charAt(0).toUpperCase()}`
    : `${name.charAt(0).toUpperCase()}`;
};
