import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface ReportTypeOptionProps {
    reportType: 'Service' | 'MediaInteraction';
    setReportType: React.Dispatch<React.SetStateAction<'Service' | 'MediaInteraction'>>;
}

const ReportTypeOption: React.FC<ReportTypeOptionProps> = ({ reportType, setReportType }) => {
    const handleClick = (option: 'Service' | 'MediaInteraction') => {
        setReportType(option);
    };

    return (
        <Box className='proactivity-box'>
            <Typography className='report-type-title'>
                Report Type
            </Typography>
            <Stack className='proactivity-stack' direction="row" spacing={2}>
                <Chip
                    className="report-type-chip"
                    label="Services Report"
                    onClick={() => handleClick('Service')}
                    color={reportType === 'Service' ? 'primary' : 'default'}
                    variant={reportType === 'Service' ? 'filled' : 'outlined'}
                />
                <Chip
                    className="report-type-chip"
                    label="Media Interactions Report"
                    onClick={() => handleClick('MediaInteraction')}
                    color={reportType === 'MediaInteraction' ? 'primary' : 'default'}
                    variant={reportType === 'MediaInteraction' ? 'filled' : 'outlined'}
                />
            </Stack>
        </Box>
    );
};

export default ReportTypeOption;