import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ChartContainer from './ChartContainer';
import { Typography } from '@mui/material';
function ErrorComponent({ message }) {
  //component for rendering an error message, expects type
  // {
  //  error: boolean whether response was an error or not,
  //  message: string the error message we wish to display
  // }
  return (
    <ChartContainer>
      <Typography>Error displaying your data</Typography>
      <Typography>{message}</Typography>
      <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>
    </ChartContainer>
  );
}

export default ErrorComponent;
