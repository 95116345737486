import { TableCell, TableRow } from '@mui/material';
import { EmailQueueEntry } from './Interfaces';
import AIResultTableCell from './AIResultTableCell';

interface AIResultTableRowProps {
  row: EmailQueueEntry;
  columns: string[];
  dropdownTable: any;
}

export default function AIResultTableRow({ row, columns, dropdownTable }: Readonly<AIResultTableRowProps>) {
  return (
    <TableRow>
      <TableCell />
      <TableCell />
      {columns.map((i) => (
        <AIResultTableCell key={i} fieldType={i} row={row} dropdownTable={dropdownTable} />
      ))}
    </TableRow>
  );
}
