import { TableHead, TableRow, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { getTabColorHeaderMain } from '../../shared/constants/constants';
import { IssueKeys, ServiceKeys } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles(({ palette, tabColor }) => ({
  tableHeaderCell: {
    textAlign: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 700,
    width: '8%',
    backgroundColor: ({ tabColor }) => tabColor,
  },
  tableIconCell: {
    width: '10px',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0,
  },
  wide1Col: {
    width: '25%',
  },
  wide2Col: {
    width: '40%',
  },
}));

// TODO: Add unit test to make sure they are correct subarrays from constants.js
// Also tests to make sure the same column isn't in Media and Service at some time
const wide1ColsMap = {
  MediaInteraction: [IssueKeys.campaign],
  ServiceLog: [ServiceKeys.campaign],
};

const wide2ColsMap = {
  MediaInteraction: [IssueKeys.topic],
  ServiceLog: [ServiceKeys.service],
};

export default function TrackerTableHeaderRow({ columns, type, tabColor }) {
  const { pathname } = useLocation();
  const classes = useStyles({ tabColor: tabColor ?? getTabColorHeaderMain(pathname) });

  return (
    <TableHead>
      <TableRow>
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`}></TableCell>
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`}></TableCell>
        {columns.map((col) => (
          <TableCell
            key={col}
            className={`${classes.tableHeaderCell} ${
              wide1ColsMap[type].includes(col)
                ? classes.wide1Col
                : wide2ColsMap[type].includes(col)
                ? classes.wide2Col
                : ''
            }`}
          >
            {col}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
