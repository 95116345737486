// Please keep this in sync with issueKeys.js (for server)

export enum IssueAndServiceSharedValues {
  date = 'Date',
  type = 'Type',
  unit = 'Unit',
  department = 'Department',
  leadExpert = 'Lead/Expert',
  campaign = 'Campaign',
  backgroundResponse = 'Background/Response',
  status = 'Status',
  teamMembers = 'Team Members',
  keyMessaging = 'Key Messaging',
}

// For mapping issues to flattened issues
export enum IssueKeys {
  date = IssueAndServiceSharedValues.date,
  linkedService = 'Linked Service',
  type = IssueAndServiceSharedValues.type,
  contactMethod = 'Contact',
  topic = 'Topic',
  fieldAddons = 'Field Addons',
  units = IssueAndServiceSharedValues.unit,
  dept = IssueAndServiceSharedValues.department,
  expert = IssueAndServiceSharedValues.leadExpert,
  campaign = IssueAndServiceSharedValues.campaign,
  outlet = 'Outlet',
  journalist = 'Journalist',
  tone = 'Tone',
  response = IssueAndServiceSharedValues.backgroundResponse,
  status = IssueAndServiceSharedValues.status,
  lead = IssueAndServiceSharedValues.teamMembers,
  keyMessaging = IssueAndServiceSharedValues.keyMessaging,
  file = 'Story Link',
}

// For mapping services to flattened services
export enum ServiceKeys {
  date = IssueAndServiceSharedValues.date,
  linkedInteraction = 'Media Interactions',
  type = IssueAndServiceSharedValues.type,
  service = 'Service',
  units = IssueAndServiceSharedValues.unit,
  dept = IssueAndServiceSharedValues.department,
  unitLead = IssueAndServiceSharedValues.leadExpert,
  campaign = IssueAndServiceSharedValues.campaign,
  complexity = 'Complexity',
  diversity = 'Diversity',
  actions = IssueAndServiceSharedValues.backgroundResponse,
  status = IssueAndServiceSharedValues.status,
  teamMember = IssueAndServiceSharedValues.teamMembers,
  keyMessaging = IssueAndServiceSharedValues.keyMessaging,
  file = 'Comms Material',
}

export const queryAdderMediaFields = {
  date: 'date',
  linkedService: 'linkedService',
  type: 'type',
  contactMethod: 'contactMethod',
  topic: 'topic',
  units: 'units',
  dept: 'department',
  expert: 'expert',
  campaign: 'campaign',
  outlet: 'outlet',
  journalist: 'journalist',
  tone: 'tone',
  response: 'response',
  status: 'status',
  lead: 'lead',
  keyMessaging: 'keyMessaging',
  file: 'file',
};

export const queryAdderServiceKeys = {
  date: 'date',
  type: 'type',
  service: 'service',
  units: 'units',
  dept: 'dept',
  unitLead: 'unitLead',
  campaign: 'campaign',
  complexity: 'complexity',
  diversity: 'diversity',
  actions: 'actions',
  status: 'status',
  teamMember: 'teamMember',
  keyMessaging: 'keyMessaging',
  linkedInteraction: 'linkedInteraction',
  file: 'file',
};

// For custom analytics' 'Media Interactions In Progress' chart
export const inProgressKeys = {
  topic: IssueKeys.topic,
  outlet: 'Media Outlet',
  // we map this to IssueKeys.lead instead of IssueAndServiceSharedValues.teamMembers to emphasize the fact that
  // inProgressKeys in the context of issues only
  teamMember: IssueKeys.lead,
  timeStamp: 'Time Stamp',
};

export const TextInputKeys = [
  IssueKeys.topic,
  IssueAndServiceSharedValues.backgroundResponse,
  ServiceKeys.service,
  IssueAndServiceSharedValues.keyMessaging,
];

export const DisplayKeys = {
  ...IssueKeys,
  ...ServiceKeys,
};

export const AutoTrackerKeys = {
  subject: 'Subject',
  sender: 'Sender',
};
