const PrivilegeLevels = {
  user: {
    level: 0,
    title: 'User',
  },
  manager: {
    level: 1,
    title: 'Manager',
  },
  admin: {
    level: 2,
    title: 'Admin',
  },
  broadsightAdmin: {
    level: 3,
    title: 'broadsightAdmin',
  },
};

const PrivilegeLevelToTitle = {};
Object.keys(PrivilegeLevels).forEach((title) => {
  if (title === 'broadsightAdmin') {
    PrivilegeLevelToTitle[PrivilegeLevels[title].level] = title;
  } else {
    PrivilegeLevelToTitle[PrivilegeLevels[title].level] = title.charAt(0).toUpperCase() + title.slice(1);
  }
});

module.exports = { PrivilegeLevels, PrivilegeLevelToTitle };
