import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { useEffect, useState, useCallback } from 'react';

function fuzzySearch(rows, query, additionalFuseOptionKeys) {
  const fuseOptionsKeys = ['name'].concat(additionalFuseOptionKeys);
  const options = {
    threshold: 0.2,
    ignoreLocation: true,
    keys: fuseOptionsKeys,
  };
  const fuse = new Fuse(rows, options);
  const results = fuse.search(query).map((result) => result.item);
  return results;
}

const AdminListFuzzySearch = (props) => {
  const { items, setSearchResults, additionalFuseOptionKeys = [], textFieldPlaceholder } = props;
  const [textFieldValue, setTextFieldValue] = useState('');

  const additionalKeysString = JSON.stringify(additionalFuseOptionKeys);

  useEffect(() => {
    const sortedArray =
      items?.sort((a, b) => {
        const itemA = a.name.toLowerCase();
        const itemB = b.name.toLowerCase();
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0;
      }) ?? [];
    if (textFieldValue) {
      const results = fuzzySearch(sortedArray, textFieldValue, additionalKeysString);
      setSearchResults(results);
    } else {
      setSearchResults(sortedArray);
    }
  }, [items, additionalKeysString, setSearchResults, textFieldValue]);

  const handleSearch = useCallback(
    (e) => {
      const debouncedFunction = debounce(() => {
        const keywords = e.target.value;
        if (keywords !== '') {
          const results = fuzzySearch(items, keywords, additionalKeysString);
          setSearchResults(results);
        } else {
          setSearchResults(items);
        }
      }, 350);

      debouncedFunction();

      // Cleanup
      return () => {
        debouncedFunction.cancel();
      };
    },
    [items, setSearchResults, additionalKeysString]
  );

  const handleChange = (e) => {
    setTextFieldValue(e.target.value);
    handleSearch(e);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}
    >
      <SearchIcon />
      <TextField
        variant="standard"
        placeholder={textFieldPlaceholder}
        value={textFieldValue}
        onChange={handleChange}
        data-cy='admin-fuzzy-search'
      />
    </Box>
  );
};

export default AdminListFuzzySearch;
