import { API } from '../../../shared/constants/constants';
import {
  CancelCollaborationGroupInvitationReq,
  CollaborationGroupCandidateTenant,
  CollaborationGroupInvitation,
  ResendCollaborationGroupInvitationReq,
  RespondCollaborationGroupInvitationReq,
  SendNewCollaborationGroupInvitationAPIData,
  SendNewCollaborationGroupInvitationReq,
} from '../Types/apiData';

export const listCollaborationGroupInvitations = async (groupId: string) => {
  const res = await API.get<CollaborationGroupInvitation[]>(`/collaboration/group-membership/${groupId}/invitations`);
  return res.data;
};

export const listCollaborationGroupInvitationsCandidateTenants = async (groupId: string) => {
  const res = await API.get<CollaborationGroupCandidateTenant[]>(
    `/collaboration/group-membership/${groupId}/invitations/candidates`
  );
  return res.data;
};

export const sendNewCollaborationGroupInvitation = async ({
  groupId,
  data,
}: SendNewCollaborationGroupInvitationReq) => {
  const res = await API.post<SendNewCollaborationGroupInvitationAPIData>(
    `/collaboration/group-membership/${groupId}/invite`,
    data
  );
  return res.data;
};

export const resendCollaborationGroupInvitation = async ({
  groupId,
  invitationId,
}: ResendCollaborationGroupInvitationReq) => {
  const res = await API.put(`/collaboration/group-membership/${groupId}/invitations/${invitationId}/resend-invite`);
  return res.data;
};

export const cancelCollaborationGroupInvitation = async ({
  groupId,
  invitationId,
}: CancelCollaborationGroupInvitationReq) => {
  const res = await API.delete(`/collaboration/group-membership/${groupId}/invitations/${invitationId}/cancel-invite`);
  return res.data;
};

export const listCollaborationGroupInvitationInbox = async () => {
  const res = await API.get<CollaborationGroupInvitation[]>('/collaboration/group-membership/invitations/inbox');
  return res.data;
};

export const respondCollaborationGroupInvitation = async ({
  groupId,
  invitationId,
  data,
}: RespondCollaborationGroupInvitationReq) => {
  const res = await API.put(
    `/collaboration/group-membership/${groupId}/invitations/${invitationId}/respond-invite`,
    data
  );
  return res.data;
};
