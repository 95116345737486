import { ListItem, Typography, Checkbox, Stack, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import MergeIcon from '@mui/icons-material/Merge';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useArchiveItem } from './Hooks/useArchiveItem';
import isTargetMergingItem from '../../shared/functions/isSelectedContact';

const EnumListItem = ({
  targetMergingItem,
  enumItem,
  itemType,
  isMerging,
  selectedItems,
  handleCheckboxChange,
  setIsEditing,
  setSelectedEnum,
  setEnumModalOpen,
  setIsMerging,
  isArchivedItem,
}) => {
  const archiveItemMutation = useArchiveItem(enumItem, itemType);

  const handleArchiveClick = async () => {
    await archiveItemMutation.mutateAsync();
  };

  const shouldBeDisabled = isTargetMergingItem(targetMergingItem, enumItem);

  return (
    <ListItem
      divider={true}
      sx={{
        alignContent: 'space-between',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: enumItem.backgroundColor,
      }}
      disabled={shouldBeDisabled}
    >
      <Typography>{enumItem.name}</Typography>
      {isMerging ? (
        <Checkbox checked={selectedItems.has(enumItem)} onChange={(event) => handleCheckboxChange(event, enumItem)} disabled={shouldBeDisabled} />
      ) : (
        <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', marginLeft: '5%' }}>
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={() => {
                setIsEditing(true);
                setSelectedEnum(enumItem);
                setEnumModalOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Merge">
            <IconButton
              size="small"
              onClick={() => {
                setSelectedEnum(enumItem);
                setIsMerging(true);
              }}
            >
              <MergeIcon />
            </IconButton>
          </Tooltip>
          {isArchivedItem ? (
            <Tooltip title="Unarchive">
              <IconButton size="small" onClick={handleArchiveClick}>
                <UnarchiveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Archive">
              <IconButton size="small" onClick={handleArchiveClick}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
    </ListItem>
  );
};

export default EnumListItem;
