import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import EnumListComponent from './EnumListComponent';
import MergeIcon from '@mui/icons-material/MergeType';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { mergeUnits, mergeDepts, mergeCampaigns } from './adminPageRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

function MergeAdminModal(props) {
  const { itemType, openMergeAdmin, setOpenMergeAdmin, selectedItem, enumItems } = props;
  const [mergingItems, setMergingItems] = useState([]);

  let mergeItemsMethod;
  let queryKeys;

  switch (itemType) {
    case IssueAndServiceSharedValues.department:
      mergeItemsMethod = mergeDepts;
      queryKeys = ['depts', 'adminPage'];
      break;
    case IssueAndServiceSharedValues.unit:
      mergeItemsMethod = mergeUnits;
      queryKeys = ['units', 'adminPage'];
      break;
    case IssueAndServiceSharedValues.campaign:
      mergeItemsMethod = mergeCampaigns;
      queryKeys = ['campaigns', 'adminPage'];
      break;
    default:
      break;
  }

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mergeItems = useMutation({
    mutationFn: (e) => handleMerge(e),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys);
    },
  });

  const handleClose = () => {
    setOpenMergeAdmin(false);
  };

  const handleMerge = async (e) => {
    e.preventDefault();

    await mergeItemsMethod(mergingItems, selectedItem)
      .then((res) => {
        dispatch(openSnackbar({ severity: 'success', message: `${itemType} Merged!` }));
        handleClose();
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(openSnackbar({ severity: 'error', message: `${itemType} Merge Failed!` }));
      });
  };

  return (
    <Dialog
      open={openMergeAdmin}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <DialogTitle>Merge {itemType}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {`Please select which ${itemType?.toLowerCase()}(s) to merge into:`}
          <strong> {selectedItem?.name}</strong>
        </Typography>
        <Typography variant="body1">
          Selected to become above:
          <strong>{mergingItems.map((item) => item.name).join()}</strong>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            height: '50vh',
            overflow: 'auto',
            marginLeft: '1rem',
          }}
        >
          <EnumListComponent
            enumItems={enumItems}
            isMerging={true}
            targetMergingItem={selectedItem}
            mergingItems={mergingItems}
            setMergingItems={setMergingItems}
          />
        </Box>
        <DialogActions>
          <br />
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={(e) => mergeItems.mutate(e)} startIcon={<MergeIcon />} variant="contained">
            Merge
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default MergeAdminModal;
