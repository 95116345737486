import { Box, Stack, Typography } from '@mui/material';
import ContactPersonnelProfile from './ContactPersonnelProfile';
import ContactPersonnelsList from './ContactPersonnelsList';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { getExperts, getJournalist, getOrganizations } from './contactsRequest';
import ContactCategorySelect from './ContactCategorySelect';
import OutletList from './OutletList';
import OutletProfile from './OutletProfile';
import { useUnits } from './Hooks/useUnits';

const useStyles = makeStyles((theme) => ({
  contactPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0',
    padding: '0',
  },
  contactHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: '8vw',
    marginBottom: '0',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    paddingTop: '0rem',
    marginLeft: '0vw',
  },
  contactsListContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '80vh',
    width: '30vw',
    background: '#F5F5F5',
    borderRadius: '1rem',
    margin: '2rem',
    marginLeft: '8vw',
    marginTop: '1rem',
  },
  contactsProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '80vh',
    width: '50vw',
    background: '#e8eef5',
    borderRadius: '1rem',
    margin: '2rem',
    marginLeft: '10',
    marginTop: '1rem',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ContactsPage = () => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [refreshContacts, setRefreshContacts] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [category, setCategory] = useState('Journalists');
  const isContactPersonnels = category === 'Journalists' || category === 'Experts';

  const { data: units, isLoading, isError } = useUnits();

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    setIsLoadingList(true);
    let getContacts;
    switch (category) {
      case 'Journalists':
        getContacts = getJournalist;
        break;
      case 'Experts':
        getContacts = getExperts;
        break;
      case 'Outlets':
        getContacts = getOrganizations;
        break;
      default:
        console.error('invalid category provided in contacts page');
        break;
    }
    getContacts()
      .then((response) => {
        const sortedResponseData = response.data.sort((a, b) => {
          return a.name.trim().localeCompare(b.name.trim());
        });
        setContacts(sortedResponseData);
        setSelectedContact(sortedResponseData[0]);
      })
      .catch((err) => {
        console.log('Error getting contacts: ', err);
        console.log(err);
      });
    getOrganizations()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((err) => {
        console.log('Error getting organizations: ', err);
        console.log(err);
      });
    setRefreshContacts(false);
  }, [refreshContacts, category]);

  if (isLoading) return <div className={classes.spinnerContainer}>Loading...</div>;

  if (isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading users or units</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  let contactsList = null;
  let contactDetails = null;
  if (isContactPersonnels) {
    contactsList = (
      <ContactPersonnelsList
        data={contacts}
        handleContactClick={handleContactClick}
        orgs={orgs}
        setRefreshContacts={setRefreshContacts}
        category={category}
        isLoadingList={isLoadingList}
        setIsLoadingList={setIsLoadingList}
        units={units}
      />
    );
    contactDetails = (
      <Box className={classes.contactsProfileContainer}>
        <ContactPersonnelProfile
          selectedContact={selectedContact}
          category={category}
          units={units}
          orgs={orgs}
          setRefreshContacts={setRefreshContacts}
        />
      </Box>
    );
  } else {
    contactsList = (
      <OutletList
        category={category}
        outlets={contacts}
        handleContactClick={handleContactClick}
        setRefreshContacts={setRefreshContacts}
        isLoadingList={isLoadingList}
        setIsLoadingList={setIsLoadingList}
      />
    );
    contactDetails = (
      <Box className={classes.contactsProfileContainer}>
        <OutletProfile selectedOutlet={selectedContact} setRefreshContacts={setRefreshContacts} />
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" className={classes.contactPageContainer}>
        <Box className={classes.contactHeader}>
          <ContactCategorySelect category={category} handleCategoryChange={handleCategoryChange} />
        </Box>
        <Box className={classes.outerContainer}>
          <Box className={classes.contactsListContainer}>{contactsList}</Box>
          {selectedContact && !isLoadingList && contactDetails}
        </Box>
      </Stack>
    </>
  );
};

export default ContactsPage;
