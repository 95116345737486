import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { getUserMetaDataNonPIUserSettings, loginUser } from '../../shared/functions/userFunctions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from './userSlice';
import jwt_decode from 'jwt-decode';
import { refreshTokenHandler } from './refreshToken';
import refreshCloudfrontCookies from '../../shared/functions/refreshCloudfrontCookies';
import { tokenRefreshTime, cloudfrontRefreshTime, userPrivilegeMap } from '../../shared/constants/constants';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { validateTermsOfServiceAgreement } from './validateTermsOfServiceAgreement';
import { getAddonPageAccessControl } from '../../shared/requests/addonPageAccessControlRequests';
import { getUserBroadcastPopup } from '../../shared/requests/broadcastPopupRequests';

function LoginLoading({ socketAuthenticated }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  //submit login call to api
  useEffect(() => {
    const loginAsync = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');

      dispatch(UserActions.setIsFetching());

      try {
        const resp = await loginUser({ code: code });

        if (resp) {
          //update user info in redux if successful api call
          const tokens = JSON.parse(resp.tokens);
          const id_token = jwt_decode(tokens.id_token);

          sessionStorage.setItem('id_token', tokens.id_token);
          sessionStorage.setItem('access_token', tokens.access_token);
          sessionStorage.setItem('refresh_token', tokens.refresh_token);

          const { nonPIUserSettings } = await getUserMetaDataNonPIUserSettings(id_token.sub);

          const termsOfServiceAgreed = id_token['custom:termsOfServiceAgreed'] || null;
          const userTenantId = id_token['custom:tenantId'] || '';
          const addonPageAccessControls = await getAddonPageAccessControl();
          const userBroadcastPopup = await getUserBroadcastPopup();

          dispatch(
            UserActions.setUserInfo({
              username: id_token['cognito:username'],
              email: id_token.email,
              id: id_token.sub,
              privilege: userPrivilegeMap[id_token['cognito:groups'][0]] || 0,
              name: id_token.name,
              avatar: id_token.picture,
              nonPIUserSettings: nonPIUserSettings,
              termsOfServiceAgreed: termsOfServiceAgreed,
              tenantId: userTenantId,
              phone: id_token.phone_number || 'Not Configured',
            })
          );
          dispatch(UserActions.setIsAuthenticated());
          dispatch(UserActions.setUserAddonPageAccessControls({ ...addonPageAccessControls }));
          dispatch(UserActions.setUserBroadcastPopup({ ...userBroadcastPopup }));

          setTimeout(refreshTokenHandler, tokenRefreshTime); //start refresh token timer
          setTimeout(refreshCloudfrontCookies, cloudfrontRefreshTime);

          await validateTermsOfServiceAgreement(termsOfServiceAgreed, dispatch);
        } else {
          //fail to login. redirect to home page.
          console.error(resp);
          window.location.replace(process.env.REACT_APP_COGNITO_URL);
        }
      } catch (err) {
        console.error(err);
        window.location.replace(process.env.REACT_APP_COGNITO_URL);
      }
    };

    loginAsync();
  }, [dispatch]);

  useEffect(() => {
    if (user.isAuthenticated && socketAuthenticated) {
      const redirectUrl = localStorage.getItem('redirectUrlAfterLogin');

      if (redirectUrl) {
        localStorage.removeItem('redirectUrlAfterLogin');
        navigate(redirectUrl);
      } else {
        navigate(RouteInformation.serviceLog.path);
      }
    }
  }, [user, socketAuthenticated, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner animation="border"></Spinner>
      <Typography>Please wait, logging you in to Broadsight Tracker</Typography>
    </div>
  );
}

export default LoginLoading;
