import { useListCollaborationGroupQuery } from './Hooks/useCollaborationGroup';
import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Box } from '@mui/material';
import SharedJournalistsTableWrapper from './SharedJournalistsTableWrapper';

const SharedJournalistsPage = () => {
  const { isFetching: isFetchingGroups, data: groupsData } = useListCollaborationGroupQuery();

  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  if (isFetchingGroups) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  if (!groupsData) {
    console.error('groups data is missing');
    return (
      <div className="spinner-container">
        <div>Failed to fetch groups data, try refresh this page</div>
      </div>
    );
  }

  return (
    <Box>
      <SharedJournalistsTableWrapper
        groupId={selectedGroupId}
        groupsData={groupsData}
        onSelectedGroupChange={(groupId) => setSelectedGroupId(groupId)}
      />
    </Box>
  );
};

export default SharedJournalistsPage;
