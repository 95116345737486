import { LinearProgress, ListItem } from '@mui/material';
import { CollaborationGroupInvitationsListItemsWrapperProps } from './Types/componentProps';

const CollaborationGroupInvitationsListItemsWrapper: React.FC<
  React.PropsWithChildren<CollaborationGroupInvitationsListItemsWrapperProps>
> = ({ isLoading, invitations, children }) => {
  if (!isLoading && invitations.length > 0) {
    return <>{children}</>;
  } else if (!isLoading && invitations.length === 0) {
    return (
      <ListItem
        key="no-active-collaboration-group-invitaitons"
        data-cy="collaboration-group-details-invitations-list-invitations-empty-list-item"
      >
        No invitation found
      </ListItem>
    );
  } else {
    return <LinearProgress key="group-invitations-pending" />;
  }
};

export default CollaborationGroupInvitationsListItemsWrapper;
