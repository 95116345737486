import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import FilterModalFilterIcon from './FilterModalFilterIcon';
import { ReactElement } from 'react';

interface FilterModalTextInputsProps {
  displayKey: string;
  helperText?: string;
  filterName: string;
  icon: ReactElement;
  value: string;
  onChange: (value: string) => void;
}

const FilterModalTextInput: React.FC<FilterModalTextInputsProps> = ({
  filterName,
  displayKey,
  helperText,
  icon,
  value,
  onChange,
}) => {
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography fontSize={20} fontWeight={600} sx={{ marginBottom: '0.5rem' }}>
        {displayKey}
      </Typography>
      <TextField
        sx={{ width: '100%' }}
        helperText={helperText ? helperText : ''}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterModalFilterIcon filterName={filterName} filterIcon={icon} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </Box>
  );
};

export default FilterModalTextInput;
