import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    isOpen: false,
    severity: 'info',
    message: '',
    callbackData: null,
    eventType: null,
  },
  reducers: {
    openSnackbar: (state, action) => {
      state.isOpen = true;
      state.severity = action.payload.severity || 'info';
      state.message = action.payload.message || '';
      state.callbackData = action.payload.callbackData || null;
      state.eventType = action.payload.eventType || null;
    },
    closeSnackbar: (state, action) => {
      state.isOpen = false;
      state.severity = 'info';
      state.message = null;
      state.callbackData = null;
      state.eventType = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
