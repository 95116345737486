import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { isValidEmail } from '../../shared/functions/misc';
import { postNewTenant } from './tenantsPageRequest';

const useStyles = makeStyles({
  modalCard: {
    padding: '30px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerContainer: {
    paddingBottom: '.5vh',
    borderBottom: '1px gray solid',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
  },
  textField: {
    width: '100%',
    // marginTop: '2vh',
  },
  fieldDiv: {
    paddingTop: '1vh',
    paddingBottom: '1vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  addButton: {
    width: '6vw',
    height: '5vh',
  },
  cancelButton: {
    width: '6vw',
    height: '5vh',
    color: 'white',
    backgroundColor: 'red',
  },
});
function AddTenantModal({ callback, open, setOpen }) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgCode, setOrgCode] = useState('');
  const [noOfSeats, setNoOfSeats] = useState(1);
  const [emailError, setEmailError] = useState({ error: false, message: '' });
  const [nameError, setNameError] = useState({ error: false, message: '' });
  const [codeError, setCodeError] = useState({ error: false, message: '' });

  const handleChange = (setter) => {
    return (e) => {
      setter(e.target.value);
    };
  };
  const validateForm = () => {
    const validEmail = isValidEmail(email);
    const validName = orgName !== '';
    const validOrgCode = orgCode !== '';

    setEmailError({ error: !validEmail, message: '' });
    setNameError({ error: !validName, message: '' });
    setCodeError({ error: !validOrgCode, message: '' });

    return validEmail && validName && validOrgCode;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const newTenant = {
        ownerEmail: email,
        organizationCode: orgCode,
        organizationName: orgName,
        noOfSeats: noOfSeats,
      };
      postNewTenant(newTenant)
        .then((response) => {
          callback(response.data);
          setOpen(false);
        })
        .catch((err) => {
          const response = err.response.data;
          setEmailError({ error: Boolean(response.email), message: response.email });
          setNameError({ error: Boolean(response.organizationName), message: response.organizationName });
        });
    }
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Card className={classes.modalCard}>
        <div className={classes.headerContainer}>
          <Typography align="left" variant="h5">
            Add a new client
          </Typography>
        </div>
        <form>
          <div className={classes.fieldDiv}>
            <TextField
              required
              type="text"
              value={orgName}
              onChange={handleChange(setOrgName)}
              className={classes.textField}
              id="orgName"
              label="Organization Name"
              variant="outlined"
              error={nameError.error}
              helperText={nameError.message}
            ></TextField>
          </div>
          <div className={classes.fieldDiv}>
            <TextField
              required
              type="text"
              value={orgCode}
              onChange={handleChange(setOrgCode)}
              className={classes.textField}
              id="orgCode"
              label="Organization Code"
              variant="outlined"
              error={codeError.error}
              helperText={codeError.message}
            ></TextField>
          </div>
          <div className={classes.fieldDiv}>
            <TextField
              required
              type="email"
              value={email}
              onChange={handleChange(setEmail)}
              className={classes.textField}
              id="email"
              label="Organization Owner Email"
              variant="outlined"
              error={emailError.error}
              helperText={emailError.message}
            ></TextField>
          </div>
          <div className={classes.fieldDiv}>
            <TextField
              required
              type="number"
              value={noOfSeats}
              onChange={handleChange(setNoOfSeats)}
              className={classes.textField}
              InputProps={{ inputProps: { min: 1 } }}
              id="noOfSeats"
              label="Number of Seats"
              variant="outlined"
            ></TextField>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.cancelButton} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" className={classes.addButton} onClick={onSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}

export default AddTenantModal;
