import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(() => ({
  rootContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  outerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  thisMonthContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '55%',
  },
  bigNumber: {
    float: 'right',
  },
  greenIcon: {
    color: 'green',
    fontSize: '150px !important',
    maxHeight: '3vw',
    maxWidth: '3vw',
    bottom: '-10%',
  },
  redIcon: {
    color: 'red',
    fontSize: '150px !important',
    maxHeight: '3vw',
    maxWidth: '3vw',
    bottom: '-20%',
  },
  removePadding: {
    maxHeight: '3vw',
    maxWidth: '3vw',
    overflow: 'hidden',
  },
  divider: {
    borderLeft: '2px',
    width: '2px !important',
    height: '90% !important',
    float: 'right',
    marginLeft: '3vw',
    marginRight: '3vw',
  },
  percentage: {
    marginRight: '0vw',
    alignItems: 'flex-start',
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

//This functional component accepts the prop -data- with the following schema:
// {
//thisMonth: Number   ;the count for this month (# of issues, # of services, etc.)
//historical: Number ;the average from past time periods of the same range.
//title: String  ;this is the title that goes in the top of the NumberBox
//subTitle: ;this is the subtitle that goes below this month's count.
// }

function NumberBox({ data }) {
  const classes = useStyles();
  const { thisMonth, historical, title, subTitle } = data;
  const percentChange = Math.round((thisMonth / (historical ? historical : 1)) * 100 - 100);
  return (
    <div className={classes.rootContainer}>
      <Typography style={{ fontSize: '1.2rem' }}>{title}</Typography>
      <div className={classes.outerContainer}>
        <div className={classes.thisMonthContainer}>
          <h1 className={classes.bigNumber}>{thisMonth}</h1>
          <Typography style={{ fontSize: '1.0rem', textAlign: 'right' }}>{subTitle}</Typography>
        </div>

        <hr className={classes.divider}></hr>
        <div className={classes.percentage}>
          <div className={classes.arrowContainer}>
            <h1 style={{}}>{`${percentChange}%`}</h1>
            {thisMonth > historical ? (
              <span className={classes.removePadding}>
                {'    '}
                <ExpandLessIcon className={classes.greenIcon}></ExpandLessIcon>
              </span>
            ) : (
              <span className={classes.removePadding}>
                {' '}
                <ExpandMoreIcon className={classes.redIcon}></ExpandMoreIcon>{' '}
              </span>
            )}
          </div>
          <Typography style={{ fontSize: '1.0rem', textAlign: 'left' }}>vs. previous years</Typography>
        </div>
      </div>
    </div>
  );
}

export default NumberBox;
