import { API } from '../../shared/constants/constants';

export const duplicateSeriveLog = async (id) => {
  await API.post(`/service/${id}/duplicate`);
};

export const duplicateMediaInteraction = async (id) => {
  await API.post(`/issue/${id}/duplicate`);
};

export const getFlatIssueById = async (id) => {
  return await API.get(`issue/${id}/flat`);
};

export const getFlatServiceById = async (id) => {
  return await API.get(`service/${id}/flat`);
};
