import { API } from '../../shared/constants/constants';

export const getUsers = async () => {
  try {
    const res = await API.get(`/users`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting users');
  }
};

export const disableUser = async (userId) => {
  try {
    const res = await API.put(`/user/disable/${userId}`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when disabling user');
  }
};

export const enableUser = async (userId) => {
  try {
    const res = await API.put(`/user/enable/${userId}`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when enabling user');
  }
};

export const resendPassword = async (user) => {
  try {
    const res = await API.put(`/user/resendPassword/${user}`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when resetting user password');
  }
};

export const createUser = async (data) => {
  try {
    const res = await API.post(`/user`, data);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when creating user');
  }
};

export const getUnits = async () => {
  try {
    const res = await API.get(`/table/units`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting units');
  }
};

export const getDepartments = async () => {
  try {
    const res = await API.get(`/depts`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting departments');
  }
};

export const getCampaigns = async () => {
  try {
    const res = await API.get(`/table/campaigns`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting campaigns');
  }
};

export const getTypes = async () => {
  try {
    const res = await API.get(`/table/types`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting type');
  }
};

export const getNoOfTenantSeatsRemaining = async () => {
  try {
    const res = await API.get('/tenant/tenantSeatsRemain');
    return res.data;
  } catch (e) {
    return e;
  }
};

// creat a new unit
export const postNewUnitEnum = async (unitObject) => {
  try {
    const res = await API.post(`table/unit`, {
      name: unitObject.name,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when adding unit');
  }
};

export const postNewTypeEnum = async (newTypeObject) => {
  try {
    const res = await API.post(`/table/type`, {
      name: newTypeObject.typeName,
      type: newTypeObject.typeCategory,
      backgroundColor: newTypeObject.typeBackgroundColor,
      proactivity: newTypeObject.typeProactivity,
      permissions: newTypeObject.typePermissions,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when adding type');
  }
};

export const postNewDepartmentEnum = async (deptObject) => {
  try {
    const res = await API.post(`/dept`, {
      name: deptObject.name,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when adding department');
  }
};

export const postNewCampaignEnum = async (campaignObject) => {
  try {
    const res = await API.post(`/table/campaign`, {
      name: campaignObject.name,
      backgroundColor: campaignObject.backgroundColor ?? '',
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when adding campaign');
  }
};

export const updateType = async (typeId, newTypeObject) => {
  try {
    const res = await API.put(`/table/type/${typeId}`, {
      name: newTypeObject.typeName,
      type: newTypeObject.typeCategory,
      backgroundColor: newTypeObject.typeBackgroundColor,
      proactivity: newTypeObject.typeProactivity,
      permissions: newTypeObject.typePermissions,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when updating type');
  }
};

export const updateDepartment = async (id, newDeptObject) => {
  try {
    const res = await API.put(`/dept/${id}`, { name: newDeptObject.name });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when updating department');
  }
};

export const updateUnit = async (unitId, newUnitObject) => {
  try {
    const res = await API.put(`/table/unit/${unitId}`, { name: newUnitObject.name });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when updating unit');
  }
};

export const updateCampaign = async (campaignId, newCampaignObject) => {
  try {
    const res = await API.put(`/table/campaign/${campaignId}`, {
      name: newCampaignObject.name,
      backgroundColor: newCampaignObject.backgroundColor ?? '',
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when updating campaign');
  }
};

export const mergeUnits = async (mergingUnits, targetUnit) => {
  try {
    const res = await API.put('/table/mergeUnits', { mergingUnits: mergingUnits, targetUnit: targetUnit });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when merging units');
  }
};

export const mergeDepts = async (mergingDepts, targetDept) => {
  try {
    const res = await API.put('/depts/merge', { mergingDepts: mergingDepts, targetDept: targetDept });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when merging departments');
  }
};

export const mergeTypes = async (mergingTypes, targetType) => {
  try {
    const res = await API.put('/table/mergeTypes', { mergingTypes: mergingTypes, targetType: targetType });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when merging types');
  }
};

export const mergeTypeCategories = async (mergingTypeCategories, targetTypeCategory) => {
  try {
    const res = await API.put('/table/mergeTypeCategories', {
      mergingTypeCategories: mergingTypeCategories,
      targetTypeCategory: targetTypeCategory,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when merging types categories');
  }
};

export const mergeCampaigns = async (mergeCampaigns, targetCampaign) => {
  try {
    const res = await API.put('/table/mergeCampaigns', {
      mergingCampaigns: mergeCampaigns,
      targetCampaign: targetCampaign,
    });
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when merging campaigns');
  }
};

export const toggleArchiveDepartment = async (deptName) => {
  try {
    const res = await API.put(`/dept/${deptName}/toggle-archive`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Toggle archive department failed');
  }
};

export const toggleArchiveType = async (typeId) => {
  try {
    const res = await API.put(`/table/type/${typeId}/toggle-archive`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Toggle archive type failed');
  }
};

export const toggleArchiveUnit = async (unitName) => {
  try {
    const res = await API.put(`/table/unit/${unitName}/toggle-archive`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Toggle archive unit failed');
  }
};

export const toggleArchiveCampaign = async (campaignName) => {
  try {
    const res = await API.put(`/table/campaign/${campaignName}/toggle-archive`);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Toggle archive campaign failed');
  }
};

export const adminReinitUserSMSMFA = async (userId) => {
  try {
    const res = await API.patch(`/user/${userId}/admin-clearmfa`, {});
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when resetting user MFA config');
  }
};
