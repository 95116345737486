import { API } from '../../shared/constants/constants';
export const getTenants = async () => {
  try {
    const res = await API.get(`tenant/allTenants?getTenantTrackingInformation=true&getAddonControls=true`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const postNewTenant = async (tenant) => {
  return API.post(`tenant`, tenant);
};

export const switchTenant = async (user, tenant) => {
  try {
    const res = await API.put(`user/tenantId/${user.id}`, { tenantId: tenant.id });
    if (res.status !== 201) {
      return { severity: 'error', message: 'Client switch unsuccessful' };
    } else {
      return { severity: 'success', message: 'Client Switch Successful, Please Refresh Page!' };
    }
  } catch (e) {
    return { severity: 'error', message: e.message };
  }
};

export async function updateTenantOwner(tenant, organizationOwner) {
  try {
    const res = await API.put(`tenant/owner/${tenant._id}`, { organizationOwner });
    return res.data || null;
  } catch (e) {
    return e;
  }
}

export async function updateTenantAddonControl(tenant, addonControl) {
  try {
    const res = await API.put(`/tenant/${tenant._id}/addon-control`, { ...addonControl });
    return res.data || null;
  } catch (e) {
    return e;
  }
}
