import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';

export const UserProfileFields = [
  {
    field: 'username',
    label: 'Username',
    editable: false,
    minClearance: PrivilegeLevels.user.level,
    dataCy: 'field-username-profileComponent',
  },
  {
    field: 'email',
    label: 'Email',
    editable: true,
    minClearance: PrivilegeLevels.admin.level,
    dataCy: 'field-email-profileComponent',
  },
  {
    field: 'name',
    label: 'Name',
    editable: true,
    minClearance: PrivilegeLevels.user.level,
    dataCy: 'field-name-profileComponent',
  },
  {
    field: 'privilege',
    label: 'Account Type',
    editable: true,
    minClearance: 1,
    mapping: PrivilegeLevels,
    dataCy: 'field-privilege-profileComponent',
  },
  {
    field: 'phone',
    label: 'Phone Number',
    editable: false,
    minClearance: 0,
    dataCy: 'field-phone-profileComponent',
  },
  {
    field: 'mfaEnabled',
    label: 'MFA Status',
    editable: false,
    minClearance: 2,
    dataCy: 'field-mfaStatus-profileComponent',
  },
  {
    field: 'mfaSettings',
    label: 'MFA Methods',
    editable: false,
    minClearance: 2,
    dataCy: 'field-mfaMethods-profileComponent',
  },
  {
    field: 'enabled',
    label: 'Enabled',
    editable: false,
    minClearance: PrivilegeLevels.user.level,
    dataCy: 'field-userStatus-profileComponent',
  },
];
