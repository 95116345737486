import * as XLSX from 'xlsx';
import { chartTypeEnum } from '../components/Analytics/constants';
import moment from 'moment/moment';

const downloadData = (data, type, title) => {
  let downloadSheet = false;
  let worksheet;
  let convertedData;
  let fileName = `${title.replace(/ /g, '_')}.xlsx`;
  const workbook = XLSX.utils.book_new();

  if (type === chartTypeEnum.ChartJSBar) {
    downloadSheet = true;
    convertedData = chartJSBarToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  } else if (type === chartTypeEnum.ChartJSDoughnut) {
    downloadSheet = true;
    convertedData = chartJSDoughnutToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  } else if (type === chartTypeEnum.ChartJSStackedBar) {
    downloadSheet = true;
    convertedData = chartStackedBarsToExcelConversion(data);
    const header = dateHeaderSort(convertedData);
    worksheet = XLSX.utils.json_to_sheet(convertedData, { header });
  } else if (type === chartTypeEnum.line) {
    downloadSheet = true;
    convertedData = chartLineToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  } else if (type === chartTypeEnum.PlotlyMonthLine) {
    downloadSheet = true;
    convertedData = chartMonthLineToExcelConversion(data);
    const options = getMonthLineOptions(convertedData);
    worksheet = XLSX.utils.json_to_sheet(convertedData, options);
  } else if (type === chartTypeEnum.topHits) {
    downloadSheet = true;
    convertedData = chartTopHitsToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  } else if (type === chartTypeEnum.wordCloud) {
    downloadSheet = true;
    convertedData = chartJSBarToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  } else if (type === chartTypeEnum.numberBox) {
    downloadSheet = true;
    convertedData = numBoxToExcelConversion(data);
    worksheet = XLSX.utils.json_to_sheet(convertedData);
  }

  if (downloadSheet) {
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Page 1');
    XLSX.writeFile(workbook, fileName, { compression: true });
  }
};

const chartJSBarToExcelConversion = (data) => {
  const x = data[0].x;
  const y = data[0].y;

  return y.map((value, index) => {
    return { Label: value, Count: x[index] };
  });
};

const chartJSDoughnutToExcelConversion = (data) => {
  const labels = data[0].labels;
  const values = data[0].values;
  return values.map((value, index) => {
    return { Label: labels[index], Count: value };
  });
};

const chartStackedBarsToExcelConversion = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  if (data[0].hasOwnProperty('name')) {
    return data.map((value) => {
      const entry = { Label: value.name };
      value.x.forEach((date, index) => {
        entry[date] = value.y[index];
      });
      return entry;
    });
  }
  if (data[0].hasOwnProperty('label')) {
    return data.map((value) => {
      const entry = { Label: value.label };
      Object.entries(value.data).forEach(([key, value]) => {
        entry[key] = value;
      });

      return entry;
    });
  }
};

const chartLineToExcelConversion = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((value) => {
    const entry = { Label: value.name };
    value.x.forEach((date, index) => {
      const dateStr = moment.utc(date).format('MMM YYYY');
      entry[dateStr] = value.y[index];
    });

    return entry;
  });
};

const chartMonthLineToExcelConversion = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((value) => {
    const entry = { Label: value.name };
    value.x.forEach((date, index) => {
      entry[date] = value.y[index];
    });

    return entry;
  });
};

const chartTopHitsToExcelConversion = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  return data[0].map((value) => {
    return { Label: value._id, Count: value.count };
  });
};

const numBoxToExcelConversion = (data) => {
  const x = data[0].x;
  const y = data[0].y;

  return x.map((value, index) => {
    return { Label: value, Count: y[index] };
  });
};

const dateHeaderSort = (data) => {
  const headerSet = new Set();
  data.forEach((value) => {
    Object.keys(value).forEach((key) => {
      headerSet.add(key);
    });
  });

  return Array.from(headerSet).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (isNaN(dateB)) {
      return true;
    }
    if (isNaN(dateA)) {
      return false;
    }
    return dateA - dateB;
  });
};

const getMonthLineOptions = (data) => {
  if (data?.length >= 2) {
    const header = ['Label', ...moment.monthsShort()];
    return { header };
  } else {
    return {};
  }
};

export default downloadData;
