import { SharedInteractionsTableProps } from './Types/componentProps';
import { Paper, TableContainer, TableBody } from '@mui/material';
import TrackerTableHeaderRow from '../TrackerTable/TrackerTableHeaderRow';
import React, { useRef, useState } from 'react';
import Table from '@mui/material/Table';
import { visibleShareIssueCols } from './Utils/constants';
import { getBackgroundColor, TrackerDataType } from '../../shared/constants/constants';
import makeStyles from '@mui/styles/makeStyles';
import { cellColors } from '../../shared/constants/commonCellColors';
import TablePagination from '@mui/material/TablePagination';
import SharedInteractionsDataRow from './SharedInteractionsDataRow';

const { MEDIA_INTERACTION } = TrackerDataType;
const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    borderRadius: '10px',
    width: '100%',
    border: `2px solid #B1B9C9`,
    margin: '0 auto',
    maxHeight: '75vh',
    marginTop: '1rem',
  },
  table: {
    minWidth: 0,
    margin: 0,
  },
  tableCell: {
    maxWidth: 210,
  },
  ...cellColors,
  input: {
    width: 130,
    height: 40,
  },
  cell: {
    maxWidth: 150,
  },
  label: {
    minWidth: 150,
  },
  dataCell: {
    padding: '6px',
    textAlign: 'center',
    height: '109px',
  },
  pagination: {
    marginRight: '2%',
  },
}));

const SharedInteractionsTable = (props: SharedInteractionsTableProps) => {
  const { issues } = props;
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const ref = useRef<HTMLDivElement>(null);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TrackerTableHeaderRow
            columns={visibleShareIssueCols}
            type={MEDIA_INTERACTION}
            tabColor={getBackgroundColor(MEDIA_INTERACTION)}
          />
          <TableBody>
            {issues.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((issue, index) => (
              <SharedInteractionsDataRow key={issue.id} issue={issue} rowIndex={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
        component="div"
        count={issues.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pagination}
      />
    </>
  );
};

export default SharedInteractionsTable;
