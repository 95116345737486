import { Card, CardHeader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import Spinner from 'react-bootstrap/Spinner';
import { getNotificationsForCurrentUser, Notification } from '../../shared/requests/notifications';
import './notifications-custom-styling.css';
import NotificationComponent from './NotificationComponent';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';

const useStyles = makeStyles({
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5F5F5',
    height: '87vh',
    width: '35vw',
    paddingBottom: '50px',
  },
  notificationsContainerHeader: {
    backgroundColor: 'white',
    width: '100%',
    marginBottom: '50px',
  },
  notificationsPageWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.5vh',
  },
});

interface NotificationsPageProps {
  user: any;
  dropdownTable: any;
}
function NotificationsPage(props: Readonly<NotificationsPageProps>) {
  const classes = useStyles();

  const { user, dropdownTable } = props;
  const dispatch = useDispatch();

  const {
    data: notifications,
    isLoading: isNotificationsLoading,
    isError: isNotificationsLoadError,
  } = useQuery({
    queryKey: ['notifications', 'notificationsPage'],
    queryFn: () => getNotificationsForCurrentUser(true),
  });

  useEffect(() => {
    if (isNotificationsLoadError) {
      dispatch(openSnackbar({ message: `Notifications failed to be fetched`, severity: 'error' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationsLoadError]);

  if (isNotificationsLoading || !user || !dropdownTable) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" />
      </div>
    );
  }

  const teamMembersIdToInfoMap: Record<string, any> = {};
  const teamMembers = dropdownTable?.lead ?? [];

  teamMembers.forEach((teamMember: any) => {
    teamMembersIdToInfoMap[teamMember._id] = teamMember;
  });

  const renderNotifications = (prop: ListChildComponentProps) => {
    const { data, index, style } = prop;
    const notification = data[index] as Notification;

    return (
      <NotificationComponent
        sender={teamMembersIdToInfoMap[notification.sender]}
        style={style}
        notification={notification}
        index={index}
      />
    );
  };

  return (
    <div className={classes.notificationsPageWrapper}>
      <Card variant="outlined" className={classes.notificationsContainer}>
        <CardHeader title="Notifications" className={classes.notificationsContainerHeader} />
        <FixedSizeList
          itemKey={(index) => index}
          itemData={notifications ?? []}
          itemCount={notifications?.length ?? 0}
          itemSize={170}
          height={1100}
          className="notifications-list no-scrollbars"
          width="30vw"
        >
          {renderNotifications}
        </FixedSizeList>
      </Card>
    </div>
  );
}

export default NotificationsPage;
