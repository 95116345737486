import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import UserListComponent from './UserListComponent';
import ProfileComponent from '../Profile/ProfileComponent';
import Typography from '@mui/material/Typography';
import {
  getUsers,
  getUnits,
  getCampaigns,
  getDepartments,
  getNoOfTenantSeatsRemaining,
  getTypes,
  resendPassword,
  disableUser,
  enableUser,
  adminReinitUserSMSMFA,
} from './adminPageRequest';
import Button from '@mui/material/Button';
import ConfirmationModal from './ConfirmationModal';
import AddUserModal from './AddUserModal';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import EnumListContainer from './EnumListContainer';
import { useQuery } from '@tanstack/react-query';
import { Stack, Box } from '@mui/material';
import AdminCategorySelect from './AdminCategorySelect';
import AddIcon from '@mui/icons-material/Add';
import { PrivilegeLevels } from '../../shared/constants/PrivilegeLevels';
import { updateUserWithPrivilege } from '../../shared/functions/userFunctions';
//import {sendResetPasswordLink} from './emailServiceAdapter'

const useStyles = makeStyles({
  adminPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    width: '80%',
    minHeight: '85vh',
    marginLeft: '5%',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '1rem',
    marginLeft: '5rem',
    marginTop: '1rem',
    background: '#F5F5F5',
    borderRadius: '1rem',
  },
  adminWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.5vh',
  },
  adminHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: '5rem',
    marginBottom: '0',
  },
  listContainer: {
    width: '100%',
    height: '100%',
    padding: '1rem',
    marginLeft: '5rem',
    marginTop: '1rem',
    background: '#F5F5F5',
    borderRadius: '1rem',
  },
  profileComponentWrapper: {
    marginLeft: '3vw',
  },
  disableButton: {
    backgroundColor: '#ff604f',
    marginTop: '1vh',
    height: '8%',
    width: '70%',
    color: 'white',
  },
  enableButton: {
    backgroundColor: 'green',
    marginTop: '1vh',
    height: '8%',
    width: '70%',
    color: 'white',
  },
  resetButton: {
    backgroundColor: '#58a5ff',
    marginTop: '1vh',
    height: '8%',
    width: '70%',
    color: 'white',
  },
  spinnerContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  addUserButton: {
    marginTop: '2vh',
    marginBottom: '2vh',
  },
});
function AdminPage({ editingUserPrivilege, dropdownTable }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [seatsRemaining, setSeatsRemaining] = useState(0);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationReset, setConfirmationReset] = useState(false);
  const [confirmationResetMFA, setConfirmationResetMFA] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [enumModalOpen, setEnumModalOpen] = useState(false);
  const [category, setCategory] = useState('Users');
  const [isEditing, setIsEditing] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [selectedEnum, setSelectedEnum] = useState(null);

  const usersQuery = useQuery({
    queryKey: ['users', 'adminPage'],
    queryFn: () => getUsers(),
  });

  const typesQuery = useQuery({
    queryKey: ['types', 'adminPage'],
    queryFn: () => getTypes(),
  });

  const unitsQuery = useQuery({
    queryKey: ['units', 'adminPage'],
    queryFn: () => getUnits(),
  });

  const deptsQuery = useQuery({
    queryKey: ['depts', 'adminPage'],
    queryFn: () => getDepartments(),
  });

  const campaignsQuery = useQuery({
    queryKey: ['campaigns', 'adminPage'],
    queryFn: () => getCampaigns(),
  });

  const tenantSeatsQuery = useQuery({
    queryKey: ['tenantSeats', 'adminPage'],
    queryFn: () => getNoOfTenantSeatsRemaining(),
  });

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const updateUserList = (user) => {
    const index = users.findIndex((userIt) => {
      return userIt._id === user._id;
    });

    if (index > -1) {
      const tempUsers = users;
      tempUsers[index] = user;
      setUsers(tempUsers);
    }
  };

  const addUserToList = (user) => {
    setUsers([...users, user]);
    setSelectedUser(user);
    if (seatsRemaining !== false) {
      setSeatsRemaining(seatsRemaining - 1);
    }
  };

  const getAvatarLetter = (user) => {
    let nameTokens = user.name.split(' ') || null;
    return nameTokens
      ? `${nameTokens[0].charAt(0).toUpperCase()}${nameTokens[1].charAt(0).toUpperCase()}`
      : `${user.username.charAt(0).toUpperCase()}`;
  };

  const getListItemSubTitle = (user) => {
    return user.name || '';
  };
  const getListItemTitle = (user) => {
    return user.username;
  };

  const replaceStaleUser = (user) => {
    const indexOfUser = users.findIndex((oldUser) => {
      return user._id === oldUser._id;
    });

    if (indexOfUser < 0) {
      throw new Error(`Could not find user ${user.id}`);
    }

    const newUsersArray = users;
    newUsersArray[indexOfUser] = user;

    setUsers(newUsersArray);
  };

  const hasAdminPrivilege = () => {
    return editingUserPrivilege >= PrivilegeLevels.admin.level;
  };

  const onReset = async () => {
    resendPassword(selectedUser._id)
      .then(() => {
        dispatch(openSnackbar({ severity: 'warning', message: `sent password reset successfully` }));
      })
      .catch((err) => {
        console.error(err);
        dispatch(openSnackbar({ severity: 'error', message: `failed to send password reset` }));
      });
  };

  const onDisable = () => {
    disableUser(selectedUser._id)
      .then((user) => {
        setConfirmationOpen(false);
        setConfirmationReset(false);
        replaceStaleUser(user);
        setSelectedUser(user);
        // AWS cognito calls are slow + expensive, so we just update the # of seats
        // the user sees on the client side.
        if (seatsRemaining !== false) {
          setSeatsRemaining(seatsRemaining + 1);
        }
        dispatch(openSnackbar({ severity: 'warning', message: `user ${user.username} disabled!` }));
      })
      .catch((err) => {
        console.log(err);
        setConfirmationOpen(false);
        setConfirmationReset(false);
        dispatch(openSnackbar({ severity: 'error', message: `user ${selectedUser.username} couldn't be disabled!` }));
      });
  };

  const onEnable = () => {
    enableUser(selectedUser._id)
      .then((user) => {
        setConfirmationOpen(false);
        replaceStaleUser(user);
        setSelectedUser(user);
        // AWS cognito calls are slow + expensive, so we just update the # of seats
        // the user sees on the client side.
        if (seatsRemaining !== false) {
          setSeatsRemaining(seatsRemaining - 1);
        }
        dispatch(openSnackbar({ severity: 'warning', message: `user ${user.username} enabled!` }));
      })
      .catch((err) => {
        console.log(err);
        setConfirmationOpen(false);
        dispatch(openSnackbar({ severity: 'error', message: `user ${selectedUser.username} couldn't be enabled!` }));
      });
  };

  const onResetMFA = () => {
    adminReinitUserSMSMFA(selectedUser.id)
      .then(() => {
        selectedUser.phone = '';
        setConfirmationResetMFA(false);
        replaceStaleUser(selectedUser);
        dispatch(openSnackbar({ severity: 'warning', message: `user ${selectedUser.username}'s MFA settings reset!` }));
      })
      .catch((err) => {
        console.log(err);
        setConfirmationOpen(false);
        setConfirmationReset(false);
        dispatch(
          openSnackbar({
            severity: 'error',
            message: `user ${selectedUser.username}'s MFA settings couldn't be reset!`,
          })
        );
      });
  };

  const disableAddUserButton =
    tenantSeatsQuery.isLoading || tenantSeatsQuery.isError || (seatsRemaining !== false && seatsRemaining <= 0);
  const AddUserButton = () => {
    return (
      <Button
        className={classes.addUserButton}
        color="primary"
        variant="contained"
        onClick={() => setAddUserOpen(true)}
        disabled={disableAddUserButton}
      >
        <AddIcon />
        Add User
      </Button>
    );
  };

  const AddEnumButton = () => {
    return (
      <Button
        className={classes.addUserButton}
        color="primary"
        variant="contained"
        onClick={() => {
          setEnumModalOpen(true);
          setIsEditing(false);
        }}
      >
        <AddIcon />
        Add {category.slice(0, -1)}
      </Button>
    );
  };

  // const executeFunction = async (parameter) => {
  //   try {
  //     const response = await fetch('/api/executeFunction', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ parameter }),
  //     });

  //     const data = await response.json();
  //     console.log(data.result); // Result returned by the backend function
  //   } catch (error) {
  //     console.error('Error executing function:', error);
  //   }
  // };

  // // Example usage of executeFunction with a parameter
  // const handleExecute = async (parameter) => {
  //   const parameterValue = parameter;

  //   try {
  //     await executeFunction(parameterValue);
  //   } catch (error) {
  //     console.error('Error executing function:', error);
  //   }
  // };

  // This is a temporary fix when upgrading to react-query v5 because onSuccess() is deprecated in useQuery.
  // Check out why it's strongly NOT encouraged anymore: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#state-syncing
  useEffect(() => {
    if (usersQuery.data) setUsers(usersQuery.data.data);
    if (tenantSeatsQuery.data) setSeatsRemaining(tenantSeatsQuery.data.noOfTenantSeatsRemaining);
  }, [usersQuery.data, tenantSeatsQuery.data]);

  if (
    usersQuery.isLoading &&
    unitsQuery.isLoading &&
    deptsQuery.isLoading &&
    campaignsQuery.isLoading &&
    typesQuery.isLoading
  )
    return <div className={classes.spinnerContainer}>Loading...</div>;
  if (usersQuery.isError || unitsQuery.isError || deptsQuery.isError || campaignsQuery.isError || typesQuery.isError) {
    return (
      <div className={classes.spinnerContainer}>
        <Typography variant="h3">Error loading users or units</Typography>
        <Typography variant="h5">Please refresh the page</Typography>
      </div>
    );
  }

  return (
    <Stack direction="column" className={classes.adminPageContainer}>
      <Box className={classes.adminHeader}>
        <AdminCategorySelect category={category} handleCategoryChange={handleCategoryChange} />
      </Box>
      {category === 'Users' && (
        <Box className={classes.outerContainer}>
          <Stack className={classes.adminWrapper}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">Users in your organization</Typography>
              {!tenantSeatsQuery.isLoading && !tenantSeatsQuery.isError && seatsRemaining !== false && (
                <Typography>Seats remaining: {seatsRemaining}</Typography>
              )}
              <UserListComponent
                clickCallBack={setSelectedUser}
                user={selectedUser}
                users={users}
                permissionEditable={true}
                addUserButton={AddUserButton}
                getAvatarLetter={getAvatarLetter}
                getListItemTitle={getListItemTitle}
                getListItemSubTitle={getListItemSubTitle}
                editingUserPrivilege={editingUserPrivilege}
                requiredEditingUserPrivilege={PrivilegeLevels.admin.level}
              ></UserListComponent>
            </div>
            {selectedUser && (
              <div className={classes.profileComponentWrapper}>
                <ProfileComponent
                  user={selectedUser}
                  setUser={(user) => {
                    if (selectedUser === user) {
                      dispatch(openSnackbar({ severity: 'error', message: `User wasn't updated!` }));
                    } else {
                      dispatch(
                        openSnackbar({
                          severity: 'warning',
                          message: `User updated! User will have to click reset password before first logging in.`,
                        })
                      );
                    }
                    setSelectedUser(user);
                    updateUserList(user);
                  }}
                  editingUserPrivilege={editingUserPrivilege}
                  updateUser={updateUserWithPrivilege}
                ></ProfileComponent>
                <Button
                  variant="contained"
                  onClick={() => setConfirmationOpen(true)}
                  className={selectedUser.enabled === 'True' ? classes.disableButton : classes.enableButton}
                  disabled={selectedUser.enabled === 'False' && disableAddUserButton}
                >
                  {selectedUser.enabled === 'True' ? 'Disable User' : 'Enable User'}
                </Button>
                {hasAdminPrivilege() && (
                  <Button
                    variant="contained"
                    data-cy="button-resetUserMFA-AdminPage"
                    onClick={() => setConfirmationResetMFA(true)}
                    className={selectedUser.mfaEnabled === 'Enabled' ? classes.disableButton : classes.enableButton}
                    disabled={selectedUser.mfaEnabled === 'Disabled'}
                  >
                    {selectedUser.mfaEnabled === 'Enabled' ? 'Reset MFA' : 'MFA Not Configured'}
                  </Button>
                )}

                {hasAdminPrivilege() && selectedUser.status === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => setConfirmationReset(true)}
                    className={classes.resetButton}
                  >
                    Resend Invitation Email
                  </Button>
                )}
              </div>
            )}
          </Stack>
          {confirmationOpen && selectedUser && (
            <ConfirmationModal
              open={confirmationOpen}
              setOpen={setConfirmationOpen}
              headerText={(selectedUser.enabled === 'True' ? 'Disable ' : 'Enable ') + selectedUser.username}
              text={`Are you sure you want to ${selectedUser.enabled === 'True' ? 'disable' : 'enable'} this user?`}
              callBack={selectedUser.enabled === 'True' ? onDisable : onEnable}
              confirmButtonText={selectedUser.enabled === 'True' ? 'Disable' : 'Enable'}
            ></ConfirmationModal>
          )}
          {confirmationResetMFA && selectedUser && (
            <ConfirmationModal
              open={confirmationResetMFA}
              setOpen={setConfirmationResetMFA}
              headerText={'Reset MFA settings for ' + selectedUser.username}
              text={`Are you sure you want to reset this user's MFA configuration?`}
              callBack={onResetMFA}
              confirmButtonText={'Reset MFA'}
            ></ConfirmationModal>
          )}
          {confirmationReset && selectedUser && (
            <ConfirmationModal
              open={confirmationReset}
              editingUserPrivilege={editingUserPrivilege}
              setOpen={setConfirmationReset}
              headerText={' '}
              text={
                'Please confirm you want to resend the Broadsight Tracker invitation email for this user. If they do not receive the email, please double-check their email address is correct, and ask them to check their spam or trash folders.'
              }
              callBack={onReset}
              confirmButtonText={selectedUser.enabled === 'True' ? 'Resend Invitation' : 'Cancel'}
            ></ConfirmationModal>
          )}
          {addUserOpen && (
            <AddUserModal
              open={addUserOpen}
              editingUserPrivilege={editingUserPrivilege}
              setOpen={setAddUserOpen}
              callback={addUserToList}
            ></AddUserModal>
          )}
        </Box>
      )}

      {category === 'Units' && (
        <EnumListContainer
          title="Unit/Faculty List"
          itemType="Unit"
          enumItems={unitsQuery.data}
          addButton={AddEnumButton}
          enumModalOpen={enumModalOpen}
          setEnumModalOpen={setEnumModalOpen}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
          selectedEnum={selectedEnum}
          setSelectedEnum={setSelectedEnum}
          style={classes.listContainer}
        />
      )}

      {category === 'Types' && (
        <EnumListContainer
          title="Types List"
          itemType="Type"
          enumItems={typesQuery.data}
          addButton={AddEnumButton}
          enumModalOpen={enumModalOpen}
          setEnumModalOpen={setEnumModalOpen}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
          selectedEnum={selectedEnum}
          setSelectedEnum={setSelectedEnum}
          style={classes.listContainer}
        />
      )}

      {category === 'Departments' && (
        <EnumListContainer
          title="Departments List"
          itemType="Department"
          enumItems={deptsQuery.data.data}
          addButton={AddEnumButton}
          enumModalOpen={enumModalOpen}
          setEnumModalOpen={setEnumModalOpen}
          isEditing={isEditing}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
          setIsEditing={setIsEditing}
          selectedEnum={selectedEnum}
          setSelectedEnum={setSelectedEnum}
          style={classes.listContainer}
        />
      )}

      {category === 'Campaigns' && (
        <EnumListContainer
          title="Campaign List"
          itemType="Campaign"
          enumItems={campaignsQuery.data}
          addButton={AddEnumButton}
          enumModalOpen={enumModalOpen}
          setEnumModalOpen={setEnumModalOpen}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
          selectedEnum={selectedEnum}
          setSelectedEnum={setSelectedEnum}
          style={classes.listContainer}
        />
      )}
    </Stack>
  );
}

export default AdminPage;
