import { Input } from '@mui/material';
import { Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import {
  ComboBox,
  ComboBoxCreatableJournalist,
  ComboBoxCreatableExpert,
  ComboBoxCreatableOutlet,
  ComboBoxObjInput,
  MultiSelect,
  FreeSolo,
  UploadCell,
  SingleSelectLinkedService,
  ComboBoxCreatableDepartment,
} from './CellTypes';
import {
  DisplayKeys,
  IssueAndServiceSharedValues,
  IssueKeys,
  ServiceKeys,
} from '../../shared/constants/IssueAndServiceKeys';
import ErrorBoundary from '../ErrorBoundary';
import { complexityOptions, diversityOptions, mediaToneOptions } from '../../shared/constants/constants';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

export default function CellSelector(props) {
  const {
    id,
    cells,
    col,
    className,
    required,
    error,
    dropdownTable,
    classes,
    callBack,
    type,
    handleDrag,
    handleDrop,
    isDragActive,
  } = props;
  let options = [];
  let cell = (
    <Input
      align="left"
      className={classes.input}
      defaultValue={cells[col]}
      onChange={(e) => callBack(e.target.value, col)}
      label={DisplayKeys[col] || col}
    />
  );

  //TODO change to use proper keys after backend flattening removed
  switch (col) {
    case IssueKeys.contactMethod:
      cell = (
        <ComboBox
          options={dropdownTable['contactMethod']}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="contact-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.date:
      cell = (
        <DatePicker
          selected={new Date(cells[col] || '')}
          onChange={(date) => {
            callBack(date.toISOString(), col);
          }}
          data-cy="datePicker-CellSelector"
        />
      );
      break;
    case IssueKeys.topic:
      cell = (
        <label htmlFor={DisplayKeys[col] || col} className={classes.label}>
          {DisplayKeys[col] || col}
          <FreeSolo
            list={dropdownTable['topic']}
            callback={callBack}
            label={col}
            value={cells[col]}
            data-cy="topic-input-CellSelector"
          />
        </label>
      );
      break;
    case IssueAndServiceSharedValues.department:
      cell = (
        <ComboBoxCreatableDepartment
          options={dropdownTable['dept'].filter((dept) => cells.Department === dept.name || !dept.archived)}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="department-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.campaign:
      cell = (
        <MultiSelect
          options={dropdownTable['campaigns'].filter(
            (campaign) =>
              cells[col].map((cellCampaigns) => cellCampaigns.name).includes(campaign.name) || !campaign.archived
          )}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="campaign-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.status:
      cell = (
        <ComboBox
          options={dropdownTable['status']}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="status-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.unit:
      cell = (
        <MultiSelect
          options={dropdownTable['units']
            .filter((unit) => cells[col].map((cellUnits) => cellUnits).includes(unit.name) || !unit.archived)
            .map((unit) => unit.name)}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="unit-select-CellSelector"
        />
      );
      break;
    case 'Expert Required':
    case 'expert':
    case 'unitLead':
    case IssueAndServiceSharedValues.leadExpert:
      cell = (
        <ComboBoxCreatableExpert
          units={dropdownTable['units']}
          options={dropdownTable['expert'].filter(
            (expert) => cells[col].map((cellUnit) => cellUnit._id).includes(expert._id) || !expert.archived
          )}
          col={col}
          callback={callBack}
          val={cells[col]}
          setVal={(v) => (cells[col] = v)}
          className={className}
          required={required}
          error={error}
          data-cy="lead/expert-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.teamMembers:
      if (!Array.isArray(cells[col])) {
        cell = <ComboBoxObjInput options={dropdownTable['lead']} col={col} callback={callBack} val={cells[col]} />;
      } else {
        if (cells[col] && cells[col].length && typeof cells[col][0] === 'string') {
          cells[col] = cells[col].map((member) => {
            return dropdownTable['lead'].find((lead) => lead._id === member);
          });
        }
        cell = (
          <MultiSelect
            options={dropdownTable['lead']}
            col={col}
            callback={callBack}
            val={cells[col]}
            data-cy="teamMember-select-CellSelector"
          />
        );
      }

      break;
    case IssueKeys.outlet:
      cell = (
        <ComboBoxCreatableOutlet
          options={dropdownTable['outlet'].filter((outlet) => cells[col] === outlet._id || !outlet.archived)}
          // options={dropdownTable['outlet']}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="outlet-select-CellSelector"
        />
      );
      break;
    case IssueKeys.journalist:
      if (cells[IssueKeys.outlet]) {
        options = dropdownTable['orgAgents'][cells[IssueKeys.outlet]]?.filter(
          (journalist) => journalist.Org === cells[IssueKeys.outlet] || !journalist.archived
        );
      } else {
        options = dropdownTable['journalist'].filter(
          (journalist) => cells[col] === journalist._id || !journalist.archived
        );
      }

      cell = (
        <ComboBoxCreatableJournalist
          orgs={dropdownTable['outlet']}
          options={options}
          col={col}
          callback={callBack}
          val={cells[col]}
          outlet={cells[IssueKeys.outlet]}
          data-cy="journalist-select-CellSelector"
        />
      );
      break;
    case IssueAndServiceSharedValues.keyMessaging:
      cell = (
        <RichTextEditor
          defaultValue={''}
          value={cells[col]}
          onChange={(value) => {
            callBack(value, col);
          }}
          dataCy={'keyMessaging-input-CellSelector'}
          readOnly={false}
        />
      );
      break;
    case IssueAndServiceSharedValues.backgroundResponse:
      cell = (
        <RichTextEditor
          defaultValue={''}
          value={cells[col]}
          onChange={(value) => {
            callBack(value, col);
          }}
          dataCy={'backgroundResponse-input-CellSelector'}
          readOnly={false}
        />
      );
      break;
    case IssueAndServiceSharedValues.type:
      cell = (
        <MultiSelect
          options={dropdownTable['type'].filter(
            (type) => cells[col].map((cellTypes) => cellTypes.name).includes(type.name) || !type.archived
          )}
          col={col}
          callback={callBack}
          val={cells[col]}
          groupBy={(option) => option.type}
          data-cy="type-select-CellSelector"
        />
      );
      break;

    case IssueKeys.linkedService:
      cell = (
        <SingleSelectLinkedService
          options={dropdownTable['service']}
          col={col}
          callback={callBack}
          val={cells[col] && cells[col].length > 0 ? cells[col][0] : ''}
          data-cy="linkedService-select-CellSelector"
        />
      );
      break;
    // case 'Collaborator Access':
    //   cell = <ComboBox options={dropdownTable['collaboratorAccess']} col={col} callback={callBack} val={cells[col]} />;
    //   break;
    case ServiceKeys.complexity:
      cell = (
        <ComboBox
          options={complexityOptions}
          col={col}
          callback={callBack}
          val={cells[col]}
          data-cy="complexity-slect-CellSelector"
          isConstant
        />
      );
      break;
    case ServiceKeys.diversity:
      cell = (
        <ComboBox
          options={diversityOptions}
          col={col}
          callback={callBack}
          val={cells[col]}
          required={required}
          error={error}
          data-cy="diversity-comboBox-CellSelector"
          isConstant
        />
      );
      break;
    case ServiceKeys.linkedInteraction:
      cell = (
        <div>
          <Typography>Link existing Media Interactions to this service via the Media Interaction page</Typography>
          {/* <CellViewer cells={cells} index={col} dropdownTable={dropdownTable} /> */}
        </div>
      );
      break;
    case IssueKeys.file:
      cell = (
        <UploadCell
          id={id}
          cellName={col}
          files={cells[col]}
          callback={callBack}
          type={type}
          data-cy="storyLink-upload-CellSelector"
        />
      );
      break;
    case ServiceKeys.file:
      cell = (
        <UploadCell
          id={id}
          cellName={col}
          files={cells[col]}
          callback={callBack}
          type={type}
          data-cy="commsMaterial-upload-CellSelector"
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          isDragActive={isDragActive}
        />
      );
      break;
    case IssueKeys.tone:
      cell = (
        <ComboBox
          options={mediaToneOptions}
          col={col}
          callback={callBack}
          val={cells[col]}
          required={required}
          error={error}
          data-cy="tone-comboBox-CellSelector"
          isConstant
        />
      );
      break;
    default:
      //TODO copy default case from queryAdder once issue flattening is fixed
      break;
  }

  return <ErrorBoundary>{cell}</ErrorBoundary>;
}
