import { createContext, useReducer, useContext } from 'react';

const FilteredIssuesContext = createContext(null);
const FilteredIssuesDispatchContext = createContext(null);

export function FilteredIssuesProvider({ children }) {
  const [filteredIssues, dispatch] = useReducer(filteredIssuesReducer, initialFilteredIssues);

  return (
    <FilteredIssuesContext.Provider value={filteredIssues}>
      <FilteredIssuesDispatchContext.Provider value={dispatch}>
        {children}
      </FilteredIssuesDispatchContext.Provider>
    </FilteredIssuesContext.Provider>
  );
}

export function useFilteredIssues() {
    const context = useContext(FilteredIssuesContext);
    if (context === undefined) {
      throw new Error('useFilteredIssues must be used within a FilteredIssuesProvider');
    }
    return context;
  }
  
  export function useFilteredIssuesDispatch() {
    const context = useContext(FilteredIssuesDispatchContext);
    if (context === undefined) {
      throw new Error('useFilteredIssuesDispatch must be used within a FilteredIssuesProvider');
    }
    return context;
  }

  const initialFilteredIssues = [];

function filteredIssuesReducer(state, action) {
  switch (action.type) {
    case 'setFilteredIssues': {
      return action.payload; 
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
