import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface TextInputProps {
  options: string[];
  selectedTopics: string[];
  setSelectedTopics: React.Dispatch<React.SetStateAction<string[]>>;
  reportType: 'Service' | 'MediaInteraction';
}

const TextInput: React.FC<TextInputProps> = ({ selectedTopics, setSelectedTopics, options,reportType }) => {
  return (
    <Box sx={{ width: '100%', margin: '0 auto', paddingTop: 2, paddingBottom:3}}>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option: string) => option}
        value={selectedTopics}
        onChange={(event: React.SyntheticEvent, newValue: string[] | null) => {
          setSelectedTopics(newValue || []);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={reportType === 'Service' ? 'Select Services' : 'Select Topics'}
            variant="outlined"
            fullWidth
          />
        )}
      />
    </Box>
  );
}

export default TextInput;