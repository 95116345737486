import { TableRow } from '@mui/material';
import FilteredTableDataCell from './FilteredTableDataCell';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  '@keyframes loadIssuse': {
    '0%': { backgroundColor: '#a9d5a9' },
    '100%': { backgroundColor: 'white' },
  },

  dataCell: {
    padding: '6px',
    textAlign: 'left',
    border: `1px solid ${palette.secondary.dark}`,
  },
  iconCell: {
    borderRight: 'none',
    borderLeft: 'none',
    '&> button': {
      padding: '4px',
    },
  },

  stripedRow: {
    backgroundColor: palette.primary.light,
  },
}));

export default function FilteredTableDataRow({ type, issue, dropdownTable, columns, isStripedRow }) {
  const classes = useStyles();

  return (
    <>
      <TableRow className={isStripedRow ? classes.stripedRow : ''} key={issue.id}>
        {columns.map((key) => (
          <FilteredTableDataCell
            type={type}
            key={key}
            data={issue.cells}
            index={key}
            dropdownTable={dropdownTable}
            className={classes.dataCell}
          />
        ))}
      </TableRow>
    </>
  );
}
