import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultBackgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(150, 75, 0, 0.2)',
  'rgba(255, 205, 86, 0.2)',
  'rgba(201, 203, 207, 0.2)',
];

const defaultBorderColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgba(150, 75, 0)',
  'rgb(255, 205, 86)',
  'rgb(201, 203, 207)',
];

const getBackgroundHexColorWithReducedOpacity = (backgroundHexColor) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundHexColor);
  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.2)` : null;
};

export function DoughnutChart({ chartData, title }) {
  //const data = processData(chartData)//setChartData(chartData);
  const data = {
    labels: chartData[0].labels,
    datasets: [
      {
        data: chartData[0].values,
        backgroundColor: chartData[0].labels?.map((label, index) => {
          return chartData[0].labelToColorMapping[label]
            ? getBackgroundHexColorWithReducedOpacity(chartData[0].labelToColorMapping[label]) ??
                chartData[0].labelToColorMapping[label]
            : defaultBackgroundColors[index % defaultBackgroundColors.length];
        }),
        borderColor: chartData[0].labels?.map((label, index) => {
          return chartData[0].labelToColorMapping[label]
            ? chartData[0].labelToColorMapping[label]
            : defaultBorderColors[index % defaultBorderColors.length];
        }),
        borderWidth: 1,
        hoverBackgroundColor: 'rgb(0, 0, 0, 0)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${title}`,
      },
    },
    maintainAspectRatio: false,
  };

  return <Doughnut options={options} data={data} />;
}
