import { Box, Button, Divider, List, ListItem } from '@mui/material';
import {
  CollaborationGroupInvitationInboxPageProps,
  ConfirmCancelCollaborationGroupInvitationDialogRespondType,
} from './Types/componentProps';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import CollaborationGroupInvitationsListItem from './CollaborationGroupInvitationsListItem';
import ConfirmRespondGroupInvitationDialog from './ConfirmRespondGroupInvitationDialog';
import { CollaborationGroupInvitation } from './Types/apiData';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  listContainer: {
    minHeight: '80vh',
    width: '65vw',
    background: '#e8eef5',
    borderRadius: '1rem',
  },
  listTitleItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  listTitleItemDivider: {
    marginBottom: '1rem',
  },
});

const CollaborationGroupInvitationInboxPage: React.FC<CollaborationGroupInvitationInboxPageProps> = ({
  invitations,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitationToRespond, setInvitationToRespond] = useState<CollaborationGroupInvitation | null>(null);
  const [respondType, setRespondType] = useState<ConfirmCancelCollaborationGroupInvitationDialogRespondType>('accept');
  const [toggleDialog, setToggleDialog] = useState<boolean>(false);

  const handleRefreshButtonClick = async () => {
    setIsLoading(true);
    await queryClient.invalidateQueries({
      queryKey: ['collaboration/group/invitation/inbox'],
    });
    setIsLoading(false);
  };

  const handleConfirmRespondGroupInvitationDialogClose = () => {
    setToggleDialog(false);
    setInvitationToRespond(null);
  };

  const handleRespondButtonClick = (
    type: ConfirmCancelCollaborationGroupInvitationDialogRespondType,
    invitation: CollaborationGroupInvitation
  ) => {
    setRespondType(type);
    setInvitationToRespond(invitation);
    setToggleDialog(true);
  };

  return (
    <>
      <Box className={classes.pageContainer}>
        <Box className={classes.listContainer}>
          <List>
            <ListItem
              key="collaboration-group-invitation-inbox-title"
              className={classes.listTitleItem}
              data-cy="collaboration-invitation-inbox-header-list-item"
            >
              Group Invitations Inbox
              <LoadingButton
                data-cy="collaboration-invitation-inbox-header-refresh-btn"
                variant="outlined"
                loading={isLoading}
                onClick={() => handleRefreshButtonClick()}
              >
                Refresh
              </LoadingButton>
            </ListItem>
            <Divider
              className={classes.listTitleItemDivider}
              key="collaboration-group-invitation-inbox-title-divider"
              component="li"
            />
            {invitations.map((invitation) => (
              <CollaborationGroupInvitationsListItem
                key={invitation._id}
                isInbox
                invitation={invitation}
                itemActions={
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => handleRespondButtonClick('accept', invitation)}
                      data-cy="collaboration-invitation-inbox-invitations-accept-btn"
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRespondButtonClick('reject', invitation)}
                      data-cy="collaboration-invitation-inbox-invitations-reject-btn"
                    >
                      Reject
                    </Button>
                  </>
                }
              />
            ))}
          </List>
        </Box>
      </Box>
      {invitationToRespond && (
        <ConfirmRespondGroupInvitationDialog
          toggleDialog={toggleDialog}
          respondType={respondType}
          invitation={invitationToRespond}
          onClose={handleConfirmRespondGroupInvitationDialogClose}
        />
      )}
    </>
  );
};

export default CollaborationGroupInvitationInboxPage;
