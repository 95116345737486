import { useMemo } from 'react';
import { UseCollaborationGroupFuzzySearchProps } from '../Types/hooks';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';

export const useCollaborationGroupFuzzySearch = <T extends Object>({
  items,
  onSearchResultChange,
  searchOptions,
  debounceWait,
}: UseCollaborationGroupFuzzySearchProps<T>) => {
  const fuse = useMemo(() => {
    return new Fuse(items, searchOptions);
  }, [items, searchOptions]);

  const handleSearch = debounce((value: string) => {
    const results = value ? fuse.search(value).map((result) => result.item) : items;
    onSearchResultChange(results);
  }, debounceWait);

  return {
    handleSearch,
  };
};
