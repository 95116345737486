import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Button, LinearProgress, Tooltip, IconButton, Typography } from '@mui/material';
import ContactFuzzySearch from './ContactFuzzySearch';
import ContactsPageAddOutletModal from './Modal/ContactsPageAddOutletModal';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import ContactsVirtualList from './ContactsVirtualList';
import { useJournalists } from './Hooks/useJournalists';
import CotactsExportPreview from './ContactsExportPreview';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// generate a random color for each contact
function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}`,
  };
}

function getJournalistsByOutlets(outlets, journalists) {
  // Create a set of outlet IDs
  const outletIds = new Set(outlets.map((outlet) => outlet?._id));

  if (!Array.isArray(journalists.data)) {
    console.error('journalists is not an array:', journalists);
    return [];
  }

  // Filter the journalists to only include those who belong to the given outlets
  return journalists.data?.filter((journalist) => {
    return outletIds.has(journalist?.Org?._id);
  });
}

const OutletList = (props) => {
  const { handleContactClick, isLoadingList, setIsLoadingList, setRefreshContacts, outlets, category } = props;

  // Props from MergeOutletsModal
  const { isMerging, outletsToBeMerged, setOutletsToBeMerged, selectedOutlet } = props;

  const [searchResults, setSearchResults] = useState(outlets);
  const [query, setQuery] = useState('');
  const [openAddOutlet, setOpenAddOutlet] = useState(false);
  const [isExportEnabled, setIsExportEnabled] = useState(false);

  const {
    data: journalists,
    isLoading: isLoadingJournalists,
    isSuccess: isSuccessJournalists,
    isError: isErrorJournalists,
  } = useJournalists();

  const ExportButton = () => (
    <Tooltip title="Preview and Export all journalists(if any) of the current outlets in the list">
      <IconButton onClick={() => setIsExportEnabled(true)}>
        <DownloadOutlinedIcon color="action" />
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    setSearchResults(outlets);
    outlets.length > 0 && setIsLoadingList && setIsLoadingList(false);
  }, [outlets, setIsLoadingList]);

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '0',
          margin: '0',
          maxHeight: '100%',
          overflow: 'auto',
          width: '35rem',
        }}
      >
        <ListItem
          sx={{
            justifyContent: 'space-between',
            marginTop: '1rem',
            paddingTop: '10',
          }}
        >
          <ContactFuzzySearch
            contacts={outlets}
            setSearchResults={setSearchResults}
            query={query}
            setQuery={setQuery}
            category={category}
          />
          {isExportEnabled ? (
            <>
              {isLoadingJournalists && <CircularProgress />}
              {isErrorJournalists && <Typography>Error fetching journalists</Typography>}
              {isSuccessJournalists && (
                <CotactsExportPreview
                  category={category}
                  previewData={getJournalistsByOutlets(searchResults, journalists)}
                  autoOpen
                  onPreviewClose={() => setIsExportEnabled(false)}
                />
              )}
            </>
          ) : (
            <ExportButton />
          )}
          {!isMerging && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddOutlet(true);
              }}
              data-cy="addContact-button-OutletList"
            >
              <AddIcon />
              Add Outlet
            </Button>
          )}
        </ListItem>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {isLoadingList ? <LinearProgress /> : null}
          <ContactsVirtualList
            isOutletContact={true}
            contactsSize={outlets ? outlets.length : 0}
            searchResultItems={searchResults}
            isMerging={isMerging}
            setMergingContacts={setOutletsToBeMerged}
            mergingContacts={outletsToBeMerged}
            selectedContact={selectedOutlet}
            handleContactClick={handleContactClick}
            getListItemAvatarProps={(name) => stringAvatar(name)}
          />
        </Box>
      </List>
      <ContactsPageAddOutletModal
        openModal={openAddOutlet}
        setOpenModal={setOpenAddOutlet}
        setRefreshContacts={setRefreshContacts}
      />
    </>
  );
};

export default OutletList;
