import { IssueKeys, ServiceKeys } from './constants/IssueAndServiceKeys';
import { FlatIssue, FlatService } from './types';

export function isFlatIssue(obj: any): obj is FlatIssue {
  try {
    return obj?.cells[IssueKeys.topic] !== undefined;
  } catch (ignore) {
    return false;
  }
}

export function isFlatService(obj: any): obj is FlatService {
  try {
    return obj?.cells[ServiceKeys.service] !== undefined;
  } catch (ignore) {
    return false;
  }
}
