import { chartTypeEnum } from '../constants';
import { WordCloud } from '../WordCloud';
import { RadarChart } from '../RadarChart';
import { DoughnutChart } from '../DoughnutChart';
import { BarChart } from '../SimpleBar';
import { StackedBarChart } from '../StackedBarChart';
import { BarPlot, LinePlot, MonthLinePlot, PieChart } from '../PlotlyChart';
import NumberBox from '../chartComponents/NumberBox';
import TopHitsBox from '../chartComponents/TopHitsBox';
import { BarPercentageChart } from '../BarPercentageChart';
import { prepareChartCampaignData, prepareChartData } from '../../../shared/functions/chartDataProcessor';
import { BarVerticalChart } from '../BarVerticalChart';
import { BarWideChart } from '../BarWideChart';
import { LineChart } from '../LineChart';
import { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { IconButton } from '@mui/material';
import html2canvas from 'html2canvas';

function ChartContent({ chartDataParam, query, open }) {
  const useStyles = makeStyles(() => ({
    chartDiv: {
      width: '100%',
      height: '100%',
      padding: '0.5vh',
    },
    cameraButton: {
      position: 'absolute',
      top: open ? '5%' : '10%',
      right: '7%',
      zIndex: '1000',
    },
  }));

  const classes = useStyles();

  const chartRef = useRef();

  // wordcloud needs a bit of time to finish rendering before calling
  // html2canvas
  const printChart = async () => {
    const canvas = await html2canvas(chartRef.current, {});

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'image.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const cameraButton = (
    <IconButton className={classes.cameraButton} onClick={printChart}>
      <PhotoCameraIcon />
    </IconButton>
  );

  let chart;
  switch (chartDataParam.type) {
    case chartTypeEnum.wordCloud:
      chart = <WordCloud chartData={chartDataParam.chartData} title={chartDataParam.title}></WordCloud>;
      break;
    case chartTypeEnum.ChartJSRadar:
      chart = <RadarChart chartData={chartDataParam.chartData} title={chartDataParam.title}></RadarChart>;
      break;
    case chartTypeEnum.ChartJSDoughnut:
      chart = <DoughnutChart chartData={chartDataParam.chartData} title={chartDataParam.title}></DoughnutChart>;
      break;
    case chartTypeEnum.ChartJSBar:
      chart = (
        <BarChart
          chartData={chartDataParam.chartData}
          title={chartDataParam.title}
          label={chartDataParam.label}
        ></BarChart>
      );
      break;
    case chartTypeEnum.ChartJSStackedBar:
      chart = (
        <StackedBarChart
          chartData={chartDataParam.chartData}
          title={chartDataParam.title}
          query={query}
        ></StackedBarChart>
      );
      break;
    case chartTypeEnum.pie:
      chart = (
        <PieChart
          chartData={chartDataParam.chartData}
          title={chartDataParam.title}
          type={chartDataParam.type}
        ></PieChart>
      );
      break;
    case chartTypeEnum.numberBox:
      const nboxData = {
        thisMonth: chartDataParam.chartData[0].y[1],
        historical: chartDataParam.chartData[0].y[0],
        title: chartDataParam.title,
        subTitle: chartDataParam.subTitle,
      };
      chart = <NumberBox data={nboxData}></NumberBox>;
      break;
    case chartTypeEnum.bar:
      chart = (
        <BarPlot chartData={chartDataParam.chartData} title={chartDataParam.title} type={chartDataParam.type}></BarPlot>
      );
      break;
    case chartTypeEnum.topHits:
      chart = (
        <TopHitsBox
          chartData={chartDataParam.chartData}
          title={chartDataParam.title}
          type={chartDataParam.type}
        ></TopHitsBox>
      );
      break;
    case chartTypeEnum.barPercentage:
      chart = (
        <BarPercentageChart
          chartData={prepareChartData(chartDataParam.chartData)}
          title={chartDataParam.title}
        ></BarPercentageChart>
      );
      break;
    case chartTypeEnum.barVertical:
      chart = (
        <BarVerticalChart
          chartData={prepareChartData(chartDataParam.chartData)}
          title={chartDataParam.title}
        ></BarVerticalChart>
      );
      break;
    case chartTypeEnum.barWide:
      chart = (
        <BarWideChart
          chartData={prepareChartCampaignData(chartDataParam.chartData)}
          title={chartDataParam.title}
        ></BarWideChart>
      );
      break;
    case chartTypeEnum.PlotlyMonthLine:
      chart = <MonthLinePlot chartData={chartDataParam.chartData} title={chartDataParam.title}></MonthLinePlot>;
      break;

    case chartTypeEnum.line:
      chart = <LinePlot chartData={chartDataParam.chartData} title={chartDataParam.title}></LinePlot>;
      break;
    default:
      chart = (
        <LineChart chartData={prepareChartData(chartDataParam.chartData)} title={chartDataParam.title}></LineChart>
      );
      break;
  }

  return (
    <div className={classes.chartDiv} ref={chartRef}>
      {cameraButton}
      {chart}
    </div>
  );
}

export default ChartContent;
