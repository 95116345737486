import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { IssueKeys } from '../../shared/constants/IssueAndServiceKeys';

interface AutoTrackerTableHeaderProps {
  tabColor: string;
  columns: string[];
}

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: 700,
    width: '20%',
    backgroundColor: ({ tabColor }: { tabColor: string }) => tabColor,
  },
  tableIconCell: {
    width: '10px',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0,
  },
  wideCol: {
    width: '40%',
  },
}));

export default function AutoTrackerTableHeader(props: Readonly<AutoTrackerTableHeaderProps>): JSX.Element {
  const { tabColor, columns } = props;
  const classes = useStyles({ tabColor });

  return (
    <TableHead>
      <TableRow>
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`} />
        <TableCell className={`${classes.tableHeaderCell} ${classes.tableIconCell}`} />
        {columns.map((value) => (
          <TableCell
            className={`${classes.tableHeaderCell} ${value === IssueKeys.topic ? classes.wideCol : ''}`}
            key={value}
            align="left"
          >
            {value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
