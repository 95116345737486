import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
    borderRadius: '25px',
    width: 550,
    overflow: 'visible',
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
  },
});

function NewIssuePopup({ title, children, openForm, setOpenForm }) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        classes={{ paper: classes.content }}
        disableEnforceFocus
      >
        <DialogTitle>
          <Typography align="center" className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default NewIssuePopup;
