import { Button, Card, CardActions, CardContent, ImageList, ImageListItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { switchTenant, updateTenantAddonControl, updateTenantOwner } from './tenantsPageRequest';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useEffect, useState } from 'react';
import { tenantFields } from '../../shared/constants/TenantFields';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TenantAddonControlListItems from './TenantAddonControlListItems';
import { setUserAddonPageAccessControls } from '../Authorization/userSlice';

const useStyles = makeStyles({
  item: {
    padding: 0,
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignText: 'left',
  },
  button: {
    marginBottom: '2vh',
  },
});

function TenantCardComponent({ user, client }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editingClientInfo, setEditingClientInfo] = useState(client);
  const queryClient = useQueryClient();
  const editTenant = useMutation({
    mutationFn: (e) => handleEditMutation(e),
    onSuccess: () => {
      dispatch(openSnackbar({ severity: 'success', message: 'Client updated!' }));
      queryClient.invalidateQueries(['tenants', 'tenantsPage']);
    },
    onError: () => {
      dispatch(openSnackbar({ severity: 'error', message: `Client wasn't updated!` }));
    },
  });

  const handleEditMutation = async (e) => {
    e.preventDefault();

    setIsEditing(false);
    await updateTenantOwner(client, editingClientInfo.organizationOwner);
    await updateTenantAddonControl(client, {
      collaborationActivated: editingClientInfo.collaborationActivated,
      engagementLogActivated: editingClientInfo.engagementLogActivated,
      trackerAIActivated: editingClientInfo.trackerAIActivated,
    });
    dispatch(
      setUserAddonPageAccessControls({
        collaborationActivated: editingClientInfo.collaborationActivated,
        engagementLogActivated: editingClientInfo.engagementLogActivated,
        trackerAIActivated: editingClientInfo.trackerAIActivated,
      })
    );
  };

  const SwitchTenantButton = () => {
    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => {
          switchTenant(user, client).then((res) => {
            if (res.severity === 'success') {
              window.location.reload();
            } else {
              dispatch(openSnackbar({ severity: 'error', message: 'You are not permitted to use this feature' }));
            }
          });
        }}
      >
        Switch To Client - authorized users only
      </Button>
    );
  };

  useEffect(() => {
    setIsEditing(false);
    setEditingClientInfo(client);
  }, [client]);

  return (
    <Card>
      <CardContent className={classes.outerContainer}>
        <Typography style={{ marginBottom: '2vh' }} variant="h4">
          {client.organizationName}
        </Typography>
        <SwitchTenantButton />
        <CardActions style={{ justifyContent: 'center' }}>
          {isEditing ? (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  editTenant.mutate(e);
                }}
              >
                SAVE
              </Button>
              <Button
                className={classes.cancelButton}
                variant="contained"
                onClick={() => {
                  setEditingClientInfo(client);
                  setIsEditing(false);
                }}
              >
                CANCEL
              </Button>
            </div>
          ) : (
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Edit Info
            </Button>
          )}
        </CardActions>
        <ImageList cols={1} cellheight={100}>
          {tenantFields.map((field, i) => {
            return (
              <ImageListItem key={i} id={client._id} cols={1}>
                <Typography variant="h6" fontWeight="bold">
                  {field.description}
                </Typography>
                {isEditing && field.editable ? (
                  <TextField
                    variant="standard"
                    value={editingClientInfo[field.field]}
                    onChange={(e) => {
                      setEditingClientInfo({
                        ...editingClientInfo,
                        [field.field]: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <Typography>{editingClientInfo[field.field]?.toString()}</Typography>
                )}
              </ImageListItem>
            );
          })}
          <TenantAddonControlListItems
            isEditing={isEditing}
            client={editingClientInfo}
            onClientAddonControlChange={(e, field) => {
              setEditingClientInfo({
                ...editingClientInfo,
                [field.field]: e.target.checked,
              });
            }}
          />
        </ImageList>
      </CardContent>
    </Card>
  );
}

export default TenantCardComponent;
