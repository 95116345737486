export const tenantFields = [
  {
    field: 'organizationName',
    description: 'Organization Name',
    editable: false,
  },
  {
    field: 'organizationOwner',
    description: 'Organization Owner Email',
    editable: true,
  },
  {
    field: 'organizationCode',
    description: 'Organization Code',
    editable: false,
  },
  {
    field: 'noOfSeats',
    description: 'Number of Seats',
    editable: false,
  },
  {
    field: 'dateCreated',
    description: 'Date Created',
    editable: false,
  },
  {
    field: 'activeSubscription',
    description: 'Active Subscription',
    editable: false,
  },
  {
    field: 'totalMediaInteractionsCreatedLastWeek',
    description: 'Media Interactions Added In Last 7 Days',
    editable: false,
  },
  {
    field: 'totalMediaInteractionsCreated',
    description: 'Total Media Interactions Added',
    editable: false,
  },
  {
    field: 'totalServicesCreatedLastWeek',
    description: 'Services Added In Last 7 Days',
    editable: false,
  },
  {
    field: 'totalServicesCreated',
    description: 'Total Services Added',
    editable: false,
  },
];

export const TenantAddonControlFields = [
  { field: 'collaborationActivated', description: 'Collaboration Addon Control' },
  { field: 'engagementLogActivated', description: 'Engagement Log Addon Control' },
  { field: 'trackerAIActivated', description: 'AutoTracker Addon Control' },
];
