import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

export function LineChart({ chartData, title }) {
  const options = {
    responsive: true,

    tension: 0.3,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${title}`,
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return <Line options={options} data={chartData} />;
}
