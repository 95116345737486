   import {
  Typography,
  Tooltip
} from '@mui/material'; 
    const IconDisplay = ({filter,filterIcon}) => {
    return (
      <Tooltip title={<Typography fontSize={22}>{filter}</Typography>} arrow={true}>
        {filterIcon[filter]}
      </Tooltip>
    );
  };

  export default IconDisplay;