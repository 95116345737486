import { API } from '../../shared/constants/constants';
import { tokenRefreshTime } from '../../shared/constants/constants';

export function refreshToken() {
  return new Promise((resolve, reject) => {
    console.log('refreshing token!');

    API.post('/user/refresh', {
      refresh_token: sessionStorage.getItem('refresh_token'),
    })
      .then((response) => {
        const tokens = JSON.parse(response.data.tokens) || null;

        if (tokens) {
          sessionStorage.setItem('id_token', tokens.id_token);
          sessionStorage.setItem('access_token', tokens.access_token);
          resolve(true); // Resolve with true if successful
        } else {
          sessionStorage.removeItem('access_token');
          sessionStorage.removeItem('id_token');
          sessionStorage.removeItem('refresh_token');
          resolve(false); // Resolve with false if unsuccessful
        }
      })
      .catch((err) => {
        console.error('Authorization error. Please try again.');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('refresh_token');
        resolve(false);
      });
  });
}

export async function refreshTokenHandler() {
  const revalidated = await refreshToken();

  if (revalidated) {
    // Call the function every 45 minutes to get a new token
    setTimeout(refreshTokenHandler, tokenRefreshTime);
  } else {
    window.location.replace(process.env.REACT_APP_COGNITO_URL);
  }
}
