import { Avatar } from '@mui/material';

const generateColor = (input) => {
  let inputHash = [...input].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${inputHash % 360}, 95%, 35%)`;
};

function CustomAvatar({ user, className = '' }) {
  let imgSrc = user.avatar ?? '';

  if (!(user && user.name)) {
    return (
      <Avatar className={className} alt={'?'} style={{ backgroundColor: generateColor('?') }}>
        ?
      </Avatar>
    );
  }

  let nameTokens = user.name.split(' ');
  if (nameTokens.length >= 2) {
    const initials = `${nameTokens[0].charAt(0).toUpperCase()}${nameTokens[nameTokens.length - 1]
      .charAt(0)
      .toUpperCase()}`;
    return (
      <Avatar
        alt={`${user.name}`}
        children={initials}
        src={imgSrc}
        className={className}
        style={{ backgroundColor: generateColor(user.username) }}
      />
    );
  } else if (nameTokens.length === 1) {
    const initials = `${nameTokens[0].charAt(0).toUpperCase()}`;
    return (
      <Avatar
        alt={`${user.name}`}
        children={initials}
        src={imgSrc}
        className={className}
        style={{ backgroundColor: generateColor(user.username) }}
      />
    );
  } else if (user.username) {
    const initials = `${user.username.charAt(0).toUpperCase()}${user.username
      .charAt(user.username.length - 1)
      .toUpperCase()}`;
    return (
      <Avatar
        alt={user.username}
        children={initials}
        src={imgSrc}
        className={className}
        style={{ backgroundColor: generateColor(user.username) }}
      />
    );
  } else {
    return (
      <Avatar alt={'?'} src={imgSrc} className={className} style={{ backgroundColor: generateColor('red') }}>
        ?
      </Avatar>
    );
  }
}

export default CustomAvatar;
