import { API } from '../constants/constants';

export async function getAddonPageAccessControl() {
  try {
    const res = await API.get('/tenant/addon-control');
    return res.data || null;
  } catch (err) {
    return err;
  }
}
