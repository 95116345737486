import { IconButton, Tooltip, Typography } from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Button, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemAvatar } from '@mui/material';
import { Avatar } from '@mui/material';
import { getRelatedIssuesByContactPersonnelID } from './contactsRequest';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TableViewIcon from '@mui/icons-material/TableView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CombinedContactPersonnelModal from './Modal/CombinedContactPersonnelModal';
import { useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { capitalize } from '../../shared/functions/misc';
import { RouteInformation } from '../../shared/constants/RouteInformation';
import { useArchiveContact } from './Hooks/useArchiveContact';

// Lookup units using unit id
const lookupUnit = (unitId, units) => {
  const unit = units.find((unit) => unit._id === unitId);
  return unit ? unit.name : '❗Outdated - Please update/Merge this contact.';
};

// get related issues using contact id
const relatedIssues = async (category, contactId) => {
  const issues = await getRelatedIssuesByContactPersonnelID(category, contactId).then((res) => {
    return res;
  });
  return issues;
};

const ContactPersonnelProfile = (props) => {
  const { selectedContact, category, units } = props;

  const [issues, setIssues] = useState([]);
  const [openEditContact, setOpenEditContact] = useState(false);
  const navigate = useNavigate();
  const dispath = useFiltersDispatch();

  const unarchiveContactMutation = useArchiveContact({
    contactId: selectedContact?._id,
    category: category,
    isArchiving: false,
    setRefetchContacts: props.setRefreshContacts,
  });

  let selectContactRecord;
  category === 'Journalists'
    ? (selectContactRecord = {
        journalist: [
          {
            firstLetter: selectedContact.name.split(' ')[0][0],
            name: selectedContact.name,
            _id: selectedContact._id,
          },
        ],
      })
    : (selectContactRecord = {
        expert: [
          {
            firstLetter: selectedContact.name.split(' ')[0][0],
            name: selectedContact.name,
            _id: selectedContact._id,
          },
        ],
      });

  const handleUnarchiveButtonClick = () => {
    unarchiveContactMutation.mutateAsync();
  };

  let editContactButton = (
    <Button
      onClick={() => {
        setOpenEditContact(true);
      }}
      data-cy="editContact-button-ContactPersonnelProfile"
    >
      <EditOutlinedIcon />
    </Button>
  );

  if (selectedContact.archived) {
    editContactButton = (
      <Tooltip title="Unarchive">
        <IconButton
          size="small"
          onClick={() => handleUnarchiveButtonClick()}
          data-cy="unarchive-button-ContactPersonnelProfile"
        >
          <UnarchiveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  useEffect(() => {
    const getIssues = async () => {
      const issues = await relatedIssues(category, selectedContact._id);
      if (issues) setIssues(issues.data ? issues.data : []);
    };

    if (selectedContact._id) {
      getIssues();
    } else {
      console.log('No contact personnel selected');
    }
  }, [selectedContact, category, setIssues]);

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          overflow: 'auto',
          minWidth: '100%',
        }}
      >
        <ListItem key={'name'}>
          <ListItemAvatar>
            <Avatar>{selectedContact.name.split(' ')[0][0]}</Avatar>
          </ListItemAvatar>
          <Typography variant="h5">{selectedContact.name}</Typography>
          {editContactButton}
        </ListItem>
        <ListItem key={'outlet_unit'}>
          <Typography variant="h6">
            {category === 'Journalists' ? 'Outlet: ' : 'Unit: '}
            {category === 'Journalists' && selectedContact.Org
              ? capitalize(selectedContact.Org.name)
              : capitalize(selectedContact.unit ? lookupUnit(selectedContact.unit, units) : 'N/A')}
          </Typography>
        </ListItem>
        <ListItem key={'role'}>
          <Typography variant="h6">Role: {capitalize(`${category.slice(0, -1)}`)}</Typography>
        </ListItem>
        <ListItem key={'email'}>
          <Typography variant="h6">Email: {selectedContact.email}</Typography>
        </ListItem>
        <ListItem key={'phone'}>
          <Typography variant="h6">Phone: {selectedContact.phone}</Typography>
        </ListItem>
        {category === 'Journalists' && selectedContact.cell && (
          <ListItem key={'cell'}>
            <Typography variant="h6">Cell: {selectedContact.cell}</Typography>
          </ListItem>
        )}
        {category === 'Experts' && selectedContact.language && (
          <ListItem key={'language'}>
            <Typography variant="h6">Language: {selectedContact.language}</Typography>
          </ListItem>
        )}
        {category === 'Experts' && selectedContact.gender && (
          <ListItem key={'gender'}>
            <Typography variant="h6">Gender: {selectedContact.gender}</Typography>
          </ListItem>
        )}
        {selectedContact.notes && (
          <ListItem key={'notes'}>
            <Typography variant="h6">Notes: {selectedContact.notes}</Typography>
          </ListItem>
        )}
        <Divider variant="insert" component="li"></Divider>
        <ListItem
          key={'topics'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '100%',
          }}
        >
          <Typography variant="h5">Covered Topics:</Typography>

          <Button
            variant="contained"
            onClick={() => {
              dispath({ type: 'setFilters', payload: selectContactRecord });
              navigate(RouteInformation.mediaInteraction.path);
            }}
            data-cy="tableView-button-ContactPersonnelProfile"
          >
            <TableViewIcon />
            Table view
          </Button>
        </ListItem>
        {issues.map((issue) => (
          <ListItem key={issue.id}>
            <Link
              to={RouteInformation.mediaInteraction.path}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                // Set filters with selected contact and the specific topic
                dispath({
                  type: 'setFilters',
                  payload: {
                    ...selectContactRecord,
                    topic: issue.cells.Topic,
                  },
                });
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: 'inline',
                }}
                style={{ textDecoration: 'underline' }}
              >{`${issue.cells.Topic}`}</Typography>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                }}
              >
                {`${new Date(issue.cells.Date).toLocaleDateString('en-us', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })} - `}
                {issue.cells.Status}
              </Typography>
            </Link>
          </ListItem>
        ))}
      </List>
      <CombinedContactPersonnelModal
        openAddContact={openEditContact}
        setOpenAddContact={setOpenEditContact}
        orgs={props.orgs}
        units={units}
        setRefreshContacts={props.setRefreshContacts}
        category={category}
        isEditing={openEditContact}
        selectedContact={selectedContact}
        isInContactPage={true}
      />
    </>
  );
};

export default ContactPersonnelProfile;
