import { API } from '../../shared/constants/constants';

interface FormData {
  userId: string;
  password?: string;
  phone?: string;
}

interface MFAResponse {
  data: any;
  status?: number;
}

export const getUserMFAInfo = async (userId: string): Promise<MFAResponse> => {
  try {
    const res = await API.get(`/user/${userId}/mfaSettings`, {});
    return res.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when getting MFA info');
  }
};

export const configUserMFA = async (formData: FormData): Promise<MFAResponse> => {
  try {
    const res = await API.patch(`/user/${formData.userId}/configmfa`, {
      password: formData.password,
      phone: formData.phone,
    });
    return res.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when updating MFA info');
  }
};

export const validateUserMFACode = async (userId: string, session: string, code: string): Promise<MFAResponse> => {
  try {
    const res = await API.post(`/user/${userId}/validatemfa`, { session, code });
    return res.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when validating MFA code');
  }
};

export const disableUserSMSMFA = async (formData: FormData): Promise<boolean> => {
  try {
    const res = await API.patch(`/user/${formData.userId}/disablemfa`, {});
    return res.status === 200;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when validating MFA code');
  }
};

export const reInitUserSMSMFA = async (formData: FormData): Promise<MFAResponse> => {
  try {
    const res = await API.patch(`/user/${formData.userId}/clearmfa`, {});
    return res.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message ?? 'Error occurred when resetting user MFA config');
  }
};
