import { GroupCollaboratorsActionsProps, ManageCollaboratorsDialogType } from './Types/componentProps';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import ManageGroupCollaboratorsDialog from './ManageGroupCollaboratorsDialog';

const GroupCollaboratorsActions: React.FC<GroupCollaboratorsActionsProps> = ({ group }) => {
  const shouldDisableActions = group.tenants.length === 1;

  const [toggleManageGroupCollaboratorDialog, setToggleManageGroupCollaboratorDialog] = useState(false);
  const [manageGroupCollaboratorType, setManageGroupCollaboratorType] =
    useState<ManageCollaboratorsDialogType>('promote');

  const handleOpenDialog = (type: ManageCollaboratorsDialogType) => {
    setManageGroupCollaboratorType(type);
    setToggleManageGroupCollaboratorDialog(true);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Button
          data-cy="promote-group-lead-toggle-btn"
          variant="outlined"
          size="small"
          disabled={shouldDisableActions}
          onClick={() => handleOpenDialog('promote')}
        >
          Promote Group Lead
        </Button>
        <Button
          data-cy="remove-group-collaborator-toggle-btn"
          variant="contained"
          size="small"
          color="error"
          disabled={shouldDisableActions}
          onClick={() => handleOpenDialog('remove')}
        >
          Remove Collaborator
        </Button>
      </Box>
      <ManageGroupCollaboratorsDialog
        type={manageGroupCollaboratorType}
        open={toggleManageGroupCollaboratorDialog}
        groupId={group._id}
        groupOwners={group.owners}
        groupMembers={group.tenants}
        onClose={() => {
          setToggleManageGroupCollaboratorDialog(false);
        }}
      />
    </>
  );
};

export default GroupCollaboratorsActions;
