import { Box, Typography } from '@mui/material';
import EnumListComponent from './EnumListComponent';
import AddEnumModal from './AddEnumModal';
import MergeAdminModal from './MergeAdminModal';
import AddEnumTypesModal from './AddEnumTypesModal';
import EnumTypesListComponent from './EnumTypesListComponent';
import MergeTypesAdminModal from './MergeTypesAdminModal';
import { useState } from 'react';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const listContainerStyles = {
  justifyContent: 'space-between',
  height: '80vh',
  width: '100%',
  padding: '1rem',
  marginLeft: '5rem',
  marginTop: '1rem',
  background: '#F5F5F5',
  borderRadius: '1rem',
  overflow: 'scroll',
};

const EnumListContainer = ({
  title,
  itemType,
  enumItems,
  addButton,
  enumModalOpen,
  setEnumModalOpen,
  isEditing,
  setIsEditing,
  isMerging,
  setIsMerging,
  selectedEnum,
  setSelectedEnum,
}) => {
  const [whatIsBeingMergedForTypes, setWhatIsBeingMergedForTypes] = useState('Types'); // Type Categories or Types
  return (
    <Box sx={listContainerStyles}>
      <Typography variant="h5">{title}</Typography>
      <br />
      {itemType === IssueAndServiceSharedValues.type ? (
        <EnumTypesListComponent
          enumItems={enumItems}
          itemType={itemType}
          addButton={addButton}
          setEnumModalOpen={setEnumModalOpen}
          setIsEditing={setIsEditing}
          setSelectedEnum={setSelectedEnum}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
          whatIsBeingMergedForTypes={whatIsBeingMergedForTypes}
          setWhatIsBeingMergedForTypes={setWhatIsBeingMergedForTypes}
        />
      ) : (
        <EnumListComponent
          enumItems={enumItems}
          itemType={itemType}
          addButton={addButton}
          setEnumModalOpen={setEnumModalOpen}
          setIsEditing={setIsEditing}
          setSelectedEnum={setSelectedEnum}
          isMerging={isMerging}
          setIsMerging={setIsMerging}
        />
      )}

      {enumModalOpen &&
        (itemType === IssueAndServiceSharedValues.type ? (
          <AddEnumTypesModal
            open={enumModalOpen}
            setOpen={setEnumModalOpen}
            existingItems={enumItems}
            editMode={isEditing}
            setEditMode={setIsEditing}
            selectedItem={selectedEnum}
          />
        ) : (
          <AddEnumModal
            open={enumModalOpen}
            setOpen={setEnumModalOpen}
            itemType={itemType}
            existingItems={enumItems}
            editMode={isEditing}
            setEditMode={setIsEditing}
            selectedItem={selectedEnum}
          />
        ))}
      {isMerging &&
        (itemType === IssueAndServiceSharedValues.type ? (
          <MergeTypesAdminModal
            openMergeAdmin={isMerging}
            setOpenMergeAdmin={setIsMerging}
            selectedItem={selectedEnum}
            enumItems={enumItems}
            whatIsBeingMergedForTypes={whatIsBeingMergedForTypes}
            setWhatIsBeingMergedForTypes={setWhatIsBeingMergedForTypes}
          />
        ) : (
          <MergeAdminModal
            itemType={itemType}
            openMergeAdmin={isMerging}
            setOpenMergeAdmin={setIsMerging}
            selectedItem={selectedEnum}
            enumItems={enumItems}
          />
        ))}
    </Box>
  );
};

export default EnumListContainer;
