import { RouteInformation } from '../shared/constants/RouteInformation';

function validateAddonControl(property, userAddonControlStoreState) {
  switch (property) {
    case 'collaboration':
      return userAddonControlStoreState.collaborationActivated;
    case 'engagement':
      return userAddonControlStoreState.engagementLogActivated;
    case 'trackerAI':
      return userAddonControlStoreState.trackerAIActivated;
    default:
      console.error(`unknown addon control property`, property);
      return false;
  }
}

export function getRouteAddonControlIfExists(route, userAddonControlStoreState) {
  if (!RouteInformation[route].hasOwnProperty('addonControl')) {
    return true;
  }

  return validateAddonControl(RouteInformation[route].addonControl, userAddonControlStoreState);
}

export function getSidebarItemAddonControlIfExists(sidebarItem, userAddonControlStoreState) {
  if (!sidebarItem.hasOwnProperty('addonControl')) {
    return true;
  }

  return validateAddonControl(sidebarItem.addonControl, userAddonControlStoreState);
}
