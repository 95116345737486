import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import MergeIcon from '@mui/icons-material/MergeType';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { mergeTypeCategories, mergeTypes } from './adminPageRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import EnumTypesListComponent from './EnumTypesListComponent';

function MergeTypesAdminModal(props) {
  const {
    openMergeAdmin,
    setOpenMergeAdmin,
    selectedItem,
    enumItems,
    whatIsBeingMergedForTypes,
    setWhatIsBeingMergedForTypes,
  } = props;
  const [mergingItems, setMergingItems] = useState([]);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mergeItems = useMutation({
    mutationFn: (e) => handleMerge(e),
    onSuccess: () => {
      queryClient.invalidateQueries(['types', 'adminPage']);
    },
  });

  const handleClose = () => {
    setOpenMergeAdmin(false);
  };

  const handleMerge = async (e) => {
    e.preventDefault();

    let mergeFunction = whatIsBeingMergedForTypes === 'Types' ? mergeTypes : mergeTypeCategories;

    await mergeFunction(mergingItems, selectedItem)
      .then((res) => {
        dispatch(openSnackbar({ severity: 'success', message: `Types Merged!` }));
        handleClose();
      })
      .catch((err) => {
        console.log(err.message);
        dispatch(openSnackbar({ severity: 'error', message: `Type Merge Failed!` }));
      });
  };

  return (
    <Dialog
      open={openMergeAdmin}
      onClose={handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <DialogTitle>Merge Types</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {'Please select which type(s) to merge into:'}
          <strong> {selectedItem?.name ?? selectedItem}</strong>
        </Typography>
        <Typography variant="body1">
          Selected to become above:
          <strong>{mergingItems.map((item) => item.name ?? item).join()}</strong>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            height: '50vh',
            overflow: 'auto',
            marginLeft: '1rem',
          }}
        >
          <EnumTypesListComponent
            enumItems={enumItems}
            isMerging={true}
            targetMergingItem={selectedItem}
            mergingItems={mergingItems}
            setMergingItems={setMergingItems}
            whatIsBeingMergedForTypes={whatIsBeingMergedForTypes}
            setWhatIsBeingMergedForTypes={setWhatIsBeingMergedForTypes}
          />
        </Box>
        <DialogActions>
          <br />
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button onClick={(e) => mergeItems.mutate(e)} startIcon={<MergeIcon />} variant="contained">
            Merge
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default MergeTypesAdminModal;
