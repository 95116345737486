import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import SetupSharedMediaInteractionFilterDialog from './SetupSharedMediaInteractionFilterDialog';
import { SelectedCollaborationGroupIdContext } from './Contexts/SelectedCollaborationGroupIdContext';
import ResetSharedMediaInteractionFilterDialog from './ResetSharedMediaInteractionFilterDialog';
import { ManageGroupShareMediaInteractionActionsProps } from './Types/componentProps';

const ManageGroupShareMediaInteractionActions: React.FC<ManageGroupShareMediaInteractionActionsProps> = ({
  sharedInteractionFilter,
}) => {
  const { selectedGroupId } = useContext(SelectedCollaborationGroupIdContext);

  const [toggleSetupFilterDialog, setToggleSetupFilterDialog] = React.useState(false);
  const [toggleResetFilterDialog, setToggleResetFilterDialog] = React.useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setToggleSetupFilterDialog(true)}
          data-cy="collaboration-group-details-interactions-filter-setup-filter-toggle-btn"
        >
          {sharedInteractionFilter ? 'Update Filter' : 'Setup Filter'}
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => setToggleResetFilterDialog(true)}
          data-cy="collaboration-group-details-interactions-filter-reset-filter-toggle-btn"
          color="error"
        >
          Reset Filter
        </Button>
      </Box>
      <SetupSharedMediaInteractionFilterDialog
        open={toggleSetupFilterDialog}
        onClose={() => setToggleSetupFilterDialog(false)}
        groupId={selectedGroupId}
        sharedInteractionFilter={sharedInteractionFilter}
      />
      <ResetSharedMediaInteractionFilterDialog
        open={toggleResetFilterDialog}
        onClose={() => setToggleResetFilterDialog(false)}
        groupId={selectedGroupId}
      />
    </>
  );
};

export default ManageGroupShareMediaInteractionActions;
