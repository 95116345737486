import React, { useEffect, useState } from 'react';
import { CardHeader, Container, Button } from '@mui/material';
import DateRangePicker from './UserInput/DateRangePicker';
import ServiceTypePicker from './UserInput/ServiceTypePicker';
import ReportTypeOption from './UserInput/ReportType';
import { BackgroundResponseOption, KeyMessagingOption, MetadataOption } from './UserInput/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './styles.css';
import moment from 'moment/moment';
import { getIssues, getServicesReport, sendEmail } from '../../shared/requests/reportRequests';
import {
  filterTypesByProactivity,
  removeApplicableFields,
  responseToArray,
  filterReportProactivity,
  encodeReportQuery,
  generateEmailConfig,
  handleEmailResponse,
  triggerErrorDispatch,
} from './Utils';
import ComplexityPicker from './UserInput/ComplexityPicker';
import EmailInput from './UserInput/EmailInput';
import UnitTypePicker from './UserInput/UnitTypePicker';
import { EmailConfig, ReportMetadata, Type, ProactivityState, Report, UnitType } from './Interfaces';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import ProactivityOption from './UserInput/ProactivityOption';
import { Spinner } from 'react-bootstrap';
import TextInput from './UserInput/TextInput';

interface ReportsPageProps {
  dropdownTable: any;
}

const ReportsPage: React.FC<ReportsPageProps> = ({ dropdownTable }) => {
  const now = moment().endOf('day').toDate().toISOString();
  const past = moment().startOf('day').subtract(6, 'days').toDate().toISOString();

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<string>(past);
  const [reportType, setReportType] = useState<'Service' | 'MediaInteraction'>('Service');
  const [endDate, setEndDate] = useState<string>(now);
  const [minComplexity, setMinComplexity] = useState<number>(1);
  const [maxComplexity, setMaxComplexity] = useState<number>(5);
  const [email, setEmail] = useState<string>('');
  const [includeKeyMessaging, setIncludeKeyMessaging] = useState(false);
  const [includeBackgroundResponse, setIncludeBackgroundResponse] = useState(false);
  const [includeMetadata, setIncludeMetadata] = useState(false);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Type[]>([]);
  const [topics, setTopics] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [serviceTypes, setServiceTypes] = useState<Type[]>([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<UnitType[]>([]);
  const [unitTypes, setUnitTypes] = useState<UnitType[]>([]);
  const [proactivityState, setProactivityState] = useState<ProactivityState>({
    proactive: false,
    reactive: false,
  });

  function generateReportMetadata(): ReportMetadata {
    return {
      reportType: reportType,
      startDate: startDate,
      endDate: endDate,
      minComplexity: minComplexity,
      maxComplexity: maxComplexity,
      keyMessaging: includeKeyMessaging,
      backgroundResponse: includeBackgroundResponse,
      proactivity: proactivityState,
      selectedServiceTypes: selectedServiceTypes,
    };
  }

  async function mailHandler(e: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();

    // trigger error dispatch if necessary
    if (triggerErrorDispatch(dispatch, proactivityState, email)) return;

    const query = encodeReportQuery({
      startDate,
      endDate,
      minComplexity,
      maxComplexity,
      includeKeyMessaging,
      selectedServiceTypes,
      selectedUnitTypes,
      selectedTopics,
    });

    try {
      let responseArray: Report[];
      let res: any;

      if (reportType === 'Service') {
        res = await getServicesReport(query);
      } else {
        res = await getIssues(query);
      }

      responseArray = responseToArray(res.data, dropdownTable);

      responseArray = removeApplicableFields(responseArray, includeBackgroundResponse, includeKeyMessaging);

      const reports: Report[] = filterReportProactivity(responseArray, proactivityState);

      const emailConfig: EmailConfig = generateEmailConfig(
        email,
        reportType,
        reports,
        generateReportMetadata(),
        includeMetadata
      );

      const emailResponse = await sendEmail(emailConfig);

      handleEmailResponse(emailResponse, email, dispatch);
    } catch (error) {
      dispatch(openSnackbar({ severity: 'error', message: 'Email failed to send!' }));
    }
  }

  useEffect(() => {
    if (dropdownTable) {
      setServiceTypes(dropdownTable.type);
      setUnitTypes(dropdownTable.units);
    }
  }, [dropdownTable]);

  useEffect(() => {
    if (dropdownTable) {
      setServiceTypes(dropdownTable.type.filter((type: Type) => filterTypesByProactivity(type, proactivityState)));
      setTopics(dropdownTable.topic);
    }

    //ignoring dropdownTable.type in dep array
    //eslint-disable-next-line
  }, [proactivityState]);

  return (
    <Container>
      {dropdownTable ? (
        <div className="card-container">
          <Card variant="outlined">
            <CardHeader className="header" title="Report Generation" />
            <CardContent className="card-content">
              <ReportTypeOption reportType={reportType} setReportType={setReportType} />
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <ComplexityPicker
                minValue={minComplexity}
                setMinValue={setMinComplexity}
                maxValue={maxComplexity}
                setMaxValue={setMaxComplexity}
              />
              <TextInput
                options={topics}
                selectedTopics={selectedTopics}
                setSelectedTopics={setSelectedTopics}
                reportType={reportType}
              />
              <ProactivityOption proactivityState={proactivityState} setProactivityState={setProactivityState} />
              <ServiceTypePicker
                serviceTypes={serviceTypes}
                selectedServiceTypes={selectedServiceTypes}
                setSelectedServiceTypes={setSelectedServiceTypes}
              />
              <UnitTypePicker
                unitTypes={unitTypes}
                selectedUnitTypes={selectedUnitTypes}
                setSelectedUnitTypes={setSelectedUnitTypes}
              />
              <EmailInput email={email} setEmail={setEmail} />
              <Container>
                <BackgroundResponseOption
                  backgroundResponse={includeBackgroundResponse}
                  setBackgroundResponse={setIncludeBackgroundResponse}
                />
                <KeyMessagingOption keyMessaging={includeKeyMessaging} setKeyMessaging={setIncludeKeyMessaging} />
                <MetadataOption includeMetadata={includeMetadata} setIncludeMetadata={setIncludeMetadata} />
              </Container>
            </CardContent>
            <Button variant="contained" color="primary" className="send-button" onClick={mailHandler}>
              Send
            </Button>
          </Card>
        </div>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </Container>
  );
};

export default ReportsPage;
