import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, SelectChangeEvent } from '@mui/material';
import { sortTypesAlphabetically } from '../Utils';
import '../styles.css';

interface TypePickerProps<T> {
  types: T[];
  selectedTypes: T[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<T[]>>;
  label: string;
  typeKey: keyof T;
  typeName: keyof T;
  handleEmptySelection?: () => void;
}

const TypePicker = <T extends { [key: string]: any }>({
  types,
  selectedTypes,
  setSelectedTypes,
  label,
  typeKey,
  typeName,
  handleEmptySelection,
}: TypePickerProps<T>) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const selected = types.filter((type) => value.includes(type[typeKey]));
    setSelectedTypes(selected);
  };

  // Use the utility function to sort the types array alphabetically by the typeName property
  const sortedTypes = sortTypesAlphabetically(types, typeName);

  return (
    <Box component="form" className="box" noValidate sx={{ mt: 1 }}>
      <FormControl fullWidth>
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          multiple
          value={selectedTypes.map((type) => type[typeKey])}
          input={<OutlinedInput label={label} />}
          onChange={handleChange}
          renderValue={(selectedIds) =>
            selectedIds.map((id) => types.find((type) => type[typeKey] === id)?.[typeName]).join(', ')
          }
          onClick={() => {
            if (types.length === 0 && handleEmptySelection) {
              handleEmptySelection();
            }
          }}
        >
          {sortedTypes.map((type) => {
            const isSelected = selectedTypes.some((selected) => selected[typeKey] === type[typeKey]);
            return (
              <MenuItem
                key={type[typeKey]}
                value={type[typeKey]}
                className={isSelected ? 'selected-item' : ''}
              >
                {type[typeName]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TypePicker;