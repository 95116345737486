import { Grid, Box, Modal, Button, Typography, Container } from '@mui/material';
import { FileTableWithDragAndDrop } from '../Cells/CellTypes';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { ServiceOrIssueStatus, TrackerDataType } from '../../shared/constants/constants';
import { File } from '../../shared/types';

const increasedFontSize = '1.1rem';

export interface ServiceEvent {
  logtype: TrackerDataType.SERVICE;
  start: Date;
  end: Date;
  background: string;
  title: string;
  lead: string;
  unit: string;
  campaign: string;
  department: string;
  type: string;
  teamMembers: string;
  complexity: string;
  diversity: string;
  status: ServiceOrIssueStatus;
  keyMessaging: string;
  colorEvento: string;
  comms: File[];
  id: string;
}

export interface IssueEvent {
  logtype: TrackerDataType.MEDIA_INTERACTION;
  start: Date;
  end: Date;
  background: string;
  title: string;
  lead: string;
  unit: string;
  campaign: string;
  department: string;
  type: string;
  teamMembers: string;
  contact: string;
  journalist: string;
  status: ServiceOrIssueStatus;
  keyMessaging: string;
  outlet: string;
  colorEvento: string;
  comms: File[];
  id: string;
}

export type CalendarEvents = IssueEvent[] | ServiceEvent[] | (IssueEvent | ServiceEvent)[];

interface EventModalProps {
  event: IssueEvent | ServiceEvent | null;
  onClose: () => void;
}

const EventModal = ({ event, onClose }: EventModalProps) => {
  const handleClose = () => {
    onClose();
  };

  const formatDateString = (date: Date) => {
    return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
  };

  const isServiceEvent = event?.logtype === TrackerDataType.SERVICE;
  const isInteractionEvent = event?.logtype === TrackerDataType.MEDIA_INTERACTION;

  return (
    <Modal
      disableEscapeKeyDown
      open={event !== null}
      onClose={() => handleClose()}
      aria-labelledby="filter-modal-title"
      aria-describedby="tracker-filters"
    >
      <Container
        maxWidth="sm"
        style={{
          outline: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          marginTop: '8px',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '25px',
            m: 'auto',
            p: 3,
            overflow: 'auto',
            maxHeight: 'calc(100vh - 96px)', // Adjust for viewport height minus some margin
            maxWidth: '90%',
            minWidth: '600px',
            top: '50%',
            left: '50%',
          }}
        >
          <Typography variant="h4" component="div" id="filter-modal-title" style={{ marginBottom: '16px' }}>
            {isInteractionEvent && 'Media Interaction Details'}
            {isServiceEvent && 'Service Log Details'}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Name:</strong> {event?.title}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Date:</strong> {event?.end ? formatDateString(event.end) : ''}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Lead:</strong> {event?.lead}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Type:</strong> {event?.type}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Team Members:</strong> {event?.teamMembers}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Campaign:</strong> {event?.campaign}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Unit:</strong> {event?.unit}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Department:</strong> {event?.department}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Background:</strong>
            <RichTextEditor
              defaultValue={''}
              value={event?.background ?? ''}
              readOnly={true}
              quillStyle={{ border: '1px black solid', padding: '6px 6px 10px', minHeight: '5vh' }}
            />
          </Typography>
          {isServiceEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Complexity:</strong> {event?.complexity}
            </Typography>
          )}
          {isServiceEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Diversity:</strong> {event?.diversity}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Contact:</strong> {event?.contact}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Journalist:</strong> {event?.journalist}
            </Typography>
          )}
          {isInteractionEvent && (
            <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
              <strong>Outlet:</strong> {event?.outlet}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Status:</strong> {event?.status}
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            <strong>Key Messaging:</strong>
            <RichTextEditor
              defaultValue={''}
              value={event?.keyMessaging ?? ''}
              readOnly={true}
              quillStyle={{ border: '1px black solid', padding: '6px 6px 10px', minHeight: '5vh' }}
            />
          </Typography>
          <Typography variant="body1" gutterBottom style={{ marginBottom: '8px', fontSize: increasedFontSize }}>
            {isInteractionEvent && <strong>Story Link:</strong>}
            {isServiceEvent && <strong>Comms Material:</strong>}{' '}
            <FileTableWithDragAndDrop
              key={event?.id}
              files={event?.comms}
              col={undefined}
              onDelete={undefined}
              handleDrag={undefined}
              handleDrop={undefined}
              isDragActive={false}
            />
          </Typography>

          <Grid container style={{ marginTop: 16 }}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button onClick={onClose} variant="contained" color="primary">
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Modal>
  );
};

export default EventModal;
