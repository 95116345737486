import { AIScrapeResult, EmailQueueEntry } from './Interfaces';
import { IssueAndServiceSharedValues, IssueKeys } from '../../shared/constants/IssueAndServiceKeys';

export function validateEntry(entry: EmailQueueEntry, dropdownTable: any, requiredColumns: string[]): boolean {
  const aiScrapeResult = entry.aiScrapeResult;
  if (!aiScrapeResult) {
    return false;
  }
  for (const column of requiredColumns) {
    if (!isAlreadyInTable(aiScrapeResult, dropdownTable, column)) {
      return false;
    }
  }
  return true;
}

function isAlreadyInTable(aiScrapeResult: AIScrapeResult, dropdownTable: any, column: string): boolean {
  switch (column) {
    case IssueAndServiceSharedValues.date: {
      const date = new Date(aiScrapeResult.Date);
      return !(!aiScrapeResult.Date || !date);
    }
    case IssueAndServiceSharedValues.type: {
      return !!aiScrapeResult.Type;
    }
    case IssueKeys.topic: {
      return !!aiScrapeResult.Topic;
    }
    case IssueAndServiceSharedValues.unit: {
      const unitValue = aiScrapeResult.Unit?.name ?? (aiScrapeResult.Unit as unknown as string);
      return !!dropdownTable['units'].find((value: any) => value?.name === unitValue);
    }
    case IssueAndServiceSharedValues.leadExpert: {
      try {
        return (
          aiScrapeResult['Lead/Expert']
            ?.map((expert) => {
              const expertValue = expert?.name ?? expert;
              return !!dropdownTable['expert'].find((value: any) => value?.name === expertValue);
            })
            .every(Boolean) || false
        );
      } catch (e) {
        const expertValue = aiScrapeResult['Lead/Expert'] as unknown as string;
        return !!dropdownTable['expert'].find((value: any) => value?.name === expertValue);
      }
    }
    case IssueKeys.journalist: {
      const journalistValue = aiScrapeResult.Journalist?.name ?? (aiScrapeResult.Journalist as unknown as string);
      let contactInTable = !!dropdownTable['journalist'].find((value: any) => value?.name === journalistValue);

      return contactInTable && checkOutletAndJournalist(aiScrapeResult, dropdownTable, journalistValue);
    }
    case IssueAndServiceSharedValues.status: {
      return !!dropdownTable['status'].find(
        (value: any) => value.name.toLowerCase() === aiScrapeResult.Status.toLowerCase()
      );
    }
    case IssueKeys.outlet: {
      const outletValue = aiScrapeResult.Outlet?.name ?? (aiScrapeResult.Outlet as unknown as string);
      if (outletValue) {
        return !!dropdownTable['outlet'].find((value: any) => value?.name === outletValue);
      }
      return true;
    }
    default:
      return false;
  }
}

export function validatePotentialEntry(
  entry: EmailQueueEntry,
  dropdownTable: any,
  requiredColumns: string[]
): string[] {
  const aiScrapeResult = entry.aiScrapeResult;
  if (!aiScrapeResult) {
    return [];
  }
  const requiredColumnsMissing = [];
  for (const column of requiredColumns) {
    if (!isAlreadyInTable(aiScrapeResult, dropdownTable, column)) {
      if (canBeAdded(aiScrapeResult, column)) {
        requiredColumnsMissing.push(column);
      } else {
        // If a single one of these can't be added then we can let the user know they need to do it manually
        return [];
      }
    }
  }
  return requiredColumnsMissing;
}

function canBeAdded(aiScrapeResult: AIScrapeResult, column: string): boolean {
  switch (column) {
    case IssueAndServiceSharedValues.unit: {
      const unitValue = aiScrapeResult.Unit?.name ?? (aiScrapeResult.Unit as unknown as string);
      return !!unitValue;
    }
    case IssueAndServiceSharedValues.leadExpert: {
      try {
        return (
          aiScrapeResult['Lead/Expert']
            ?.map((expert) => {
              return expert?.name ?? expert;
            })
            .every(Boolean) || false
        );
      } catch (e) {
        const expertValue = aiScrapeResult['Lead/Expert'] as unknown as string;
        return !!expertValue;
      }
    }
    case IssueKeys.journalist: {
      const journalistValue = aiScrapeResult.Journalist?.name ?? (aiScrapeResult.Journalist as unknown as string);
      return !!journalistValue;
    }
    case IssueKeys.outlet: {
      const outletValue = aiScrapeResult.Outlet?.name ?? (aiScrapeResult.Outlet as unknown as string);
      return !!outletValue;
    }
    // Other columns must already be included
    default:
      return false;
  }
}

const checkOutletAndJournalist = (aiScrapeResult: AIScrapeResult, dropdownTable: any, journalistValue: string) => {
  if (aiScrapeResult.Outlet) {
    const outletId = dropdownTable['outlet'].find(
      (value: any) => value?.name === (aiScrapeResult.Outlet?.name ?? aiScrapeResult.Outlet)
    )?._id;
    if (outletId) {
      return !!dropdownTable['orgAgents'][outletId]?.find((value: any) => value?.name === journalistValue);
    } else {
      return false;
    }
  }
  return true;
};
