import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { cellColors } from '../../shared/constants/commonCellColors';
import CellViewer from '../Cells/CellViewer';
import stylizeCell from '../../shared/functions/cellStyling';

const useStyles = makeStyles(() => ({
  tableCell: {
    maxWidth: 1000,
  },
  ...cellColors,
  input: {
    width: 130,
    height: 40,
  },
}));

export default function FilteredTableDataCell({ data, index, dropdownTable, type }) {
  const classes = useStyles();

  return (
    <TableCell className={[classes.tableCell, stylizeCell(data[index], classes, index)].join(' ')} align="left">
      <CellViewer cells={data} index={index} dropdownTable={dropdownTable} type={type} />
    </TableCell>
  );
}
