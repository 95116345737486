import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from 'react-bootstrap/Spinner';
import { visibleServiceLogCols, socketEvents } from '../../shared/constants/constants';
import ServiceLogEventHandler from '../../shared/eventHandler/ServiceLogEventHandler';
import MediaInteractionEventHandler from '../../shared/eventHandler/MediaInteractionEventHandler';
import TrackerTable from '../TrackerTable/TrackerTable';
import TrackerFilter from '../TrackerFilter/TrackerFilter';
import { getCurrMonth } from '../../shared/functions/dateProcessor';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { SocketContext } from '../../shared/contexts/SocketContext';
import { useFilters, useFiltersDispatch } from '../TrackerFilter/Context/FiltersContext';
import { useAtom } from 'jotai';
import { needRefetchFilteredDataAtom, needRefetchKeywordSearchedDataAtom } from '../../shared/atoms/filterAtoms';
import isFilterEmpty from '../../shared/functions/isFilterEmpty';

const useStyles = makeStyles({
  tableContainer: {
    width: 'calc(100% - 60px)',
    margin: '0 auto',
    marginBottom: 30,
  },
  filterContainer: {
    margin: '0 auto',
  },
});

export const ServiceLogPage = ({ dropdownTable, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filtersDispatch = useFiltersDispatch();
  const { state } = useLocation();
  let month;
  if (sessionStorage.getItem('currMonth')) {
    month = sessionStorage.getItem('currMonth');
  } else {
    month = getCurrMonth();
  }

  const [data, setData] = useState(null);
  const [currMonth, setMonth] = useState(month);
  const existingFilters = useFilters();
  const [, setNeedRefetchFilteredData] = useAtom(needRefetchFilteredDataAtom);
  const [, setNeedRefetchKeywordSearchedData] = useAtom(needRefetchKeywordSearchedDataAtom);

  const socket = useContext(SocketContext);
  const serviceLogEventHandler = ServiceLogEventHandler(socket);
  const mediaInteractionEventHandler = MediaInteractionEventHandler(socket);

  const serviceParams = useParams();

  // Filter service based on url
  useEffect(() => {
    if (serviceParams.serviceId) {
      filtersDispatch({
        type: 'setFilters',
        payload: {
          id: serviceParams.serviceId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceParams]);

  useEffect(() => {
    socket.on(socketEvents.EVENT_MONTHLY_SERVICES, (data) => {
      if (isFilterEmpty(existingFilters) && !state?.queryString) {
        setData(data);
      } else {
        setNeedRefetchFilteredData(true);
      }
    });

    socket.on(socketEvents.EVENT_SERVICE_ADDED, ({ monthFilter, snackbarObject }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else if (monthFilter === month) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: month,
          timezoneOffset: new Date(month).getTimezoneOffset(),
        });
      }
      if (snackbarObject) {
        dispatch(openSnackbar(snackbarObject));
      }
    });

    socket.on(socketEvents.EVENT_SERVICE_DELETED, ({ monthFilter }) => {
      let month = sessionStorage.getItem('currMonth');
      if (!isFilterEmpty(existingFilters)) {
        setNeedRefetchFilteredData(true);
      } else if (state?.queryString) {
        setNeedRefetchKeywordSearchedData(true);
      } else if (monthFilter === month) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: month,
          timezoneOffset: new Date(month).getTimezoneOffset(),
        });
      }
    });

    socket.on(socketEvents.EVENT_CONFIRM_DELETE, (doc) => {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: 'service deleted!',
          callbackData: doc,
          eventType: 'deleteService',
        })
      );
    });

    socket.on(socketEvents.EVENT_ERROR, (error) => {
      alert(error);
    });

    // cleanup function to remove all event listeners when the value of dependencies change, avoid redundant listeners
    return () => {
      socket.off(socketEvents.EVENT_MONTHLY_SERVICES);
      socket.off(socketEvents.EVENT_SERVICE_ADDED);
      socket.off(socketEvents.EVENT_SERVICE_DELETED);
      socket.off(socketEvents.EVENT_CONFIRM_DELETE);
      socket.off(socketEvents.EVENT_ERROR);
    };
  }, [socket, dispatch, existingFilters, setNeedRefetchFilteredData, setNeedRefetchKeywordSearchedData, state]);

  useEffect(() => {
    setData(null);
    sessionStorage.setItem('currMonth', currMonth);
    if (!state) {
      if (isFilterEmpty(existingFilters)) {
        socket.emit(socketEvents.EVENT_MONTHLY_SERVICES, {
          month: currMonth,
          timezoneOffset: new Date(currMonth).getTimezoneOffset(),
        });
      }
    }
  }, [currMonth, existingFilters, socket, state]);

  return (
    <Box className={classes.tableContainer}>
      <Box>
        <TrackerFilter
          setMonthCallback={setMonth}
          setDataCallback={setData}
          currMonth={currMonth}
          dropdownTable={dropdownTable}
          eventHandler={serviceLogEventHandler}
          className={classes.filterContainer}
          user={user}
        />
      </Box>
      {data && dropdownTable ? (
        <>
          <TrackerTable
            rows={data}
            dropdownTable={dropdownTable}
            visibleCols={visibleServiceLogCols}
            eventHandler={serviceLogEventHandler}
            linkedEventHandler={mediaInteractionEventHandler}
            user={user}
          />
        </>
      ) : (
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      )}
    </Box>
  );
};
