import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SharedResourceGroupSelectProps } from './Types/componentProps';
import { useQueryClient } from '@tanstack/react-query';

const SharedResourceGroupSelect = (props: SharedResourceGroupSelectProps) => {
  const { selectedGroupId, groups, onGroupSelect } = props;
  const queryClient = useQueryClient();

  const handleMenuItemClick = (groupId: string) => {
    onGroupSelect(groupId);
    if (groupId === selectedGroupId) {
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/shared/interactions'] });
      queryClient.invalidateQueries({ queryKey: ['collaboration/group/shared/journalists'] });
    }
  };

  return (
    <FormControl sx={{ width: '20vw' }} size="small">
      <InputLabel>Group</InputLabel>
      <Select label="Group" data-cy="collaboration-shared-resource-group-select">
        {groups.map((group) => (
          <MenuItem
            key={`shared-resource-group-select-item-${group._id}`}
            value={group._id}
            onClick={() => handleMenuItemClick(group._id)}
            data-cy="collaboration-shared-resource-group-select-items"
          >
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SharedResourceGroupSelect;
