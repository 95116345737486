import ProfileComponent from './ProfileComponent';
import { updateUser } from '../../shared/functions/userFunctions';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from '../Authorization/userSlice';
import { refreshToken } from '../Authorization/refreshToken';
import { getUserMFAInfo } from './profilePageRequest';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

function ProfilePage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userInfo);

  const mfaQuery = useQuery({
    queryKey: ['mfa', 'profilePage'],
    queryFn: () => getUserMFAInfo(userData.id),
  });

  useEffect(() => {
    if (!mfaQuery.isLoading && mfaQuery.data) {
      const updatedUserData = {
        ...userData,
        ...mfaQuery.data,
      };
      dispatch(UserActions.setUserInfo(updatedUserData));
    }
    //userData shouldn't be added to this or will get render loop
    //eslint-disable-next-line
  }, [mfaQuery.isLoading, mfaQuery.data, dispatch]);

  const setUser = async () => {
    // refresh token to update user profile
    dispatch(UserActions.setIsFetching());
    refreshToken();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75vh',
        width: '100vw',
      }}
    >
      <ProfileComponent
        user={userData}
        isOnProfilePage={true}
        setUser={setUser}
        editingUserPrivilege={userData.privilege}
        updateUser={updateUser}
        autoDisableNotifications={searchParams.get('autoDisableNotifications') === 'true'}
      ></ProfileComponent>
    </div>
  );
}

export default ProfilePage;
