import { Select, MenuItem } from '@mui/material';

const ContactCategorySelect = (props) => {
  const { category, handleCategoryChange } = props;
  return (
    <>
      <Select
        labelId="contact-category"
        id="contact-category-select"
        value={category}
        label="Category"
        onChange={handleCategoryChange}
        size="small"
        data-cy="category-select-ContactCategorySelect"
      >
        <MenuItem value={'Journalists'}>Journalists</MenuItem>
        <MenuItem value={'Experts'}>Experts</MenuItem>
        <MenuItem value={'Outlets'}>Outlets</MenuItem>
      </Select>
    </>
  );
};

export default ContactCategorySelect;
