import { API } from '../constants/constants';

export const getInteractionsTable = async (query) => {
  try {
    const response = await API.get(`/issues/media/filter?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getServicesTable = async (query) => {
  try {
    const response = await API.get(`/services/filter?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getMediaInteractionsChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getIssueFilteredMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/issues/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getIssueResponseMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/responses/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getProactiveServicesChart = async (query) => {
  try {
    const response = await API.get(`/media/services/proactive/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getProactiveMRChart = async (query) => {
  try {
    const response = await API.get(`/media/services/tactic/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getReactiveServicesChart = async (query) => {
  try {
    const response = await API.get(`/media/services/reactive/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTeamDrivenMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/team/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getInstitutionalStoryMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/institutional/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getProactiveMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/proactive/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getCampaignsMIChart = async (query) => {
  try {
    const response = await API.get(`/media/interactions/campaigns/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getCampaignsServiceChart = async (query) => {
  try {
    const response = await API.get(`/media/services/campaigns/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getIssueComplexityChart = async (query) => {
  try {
    const response = await API.get(`/media/services/complexity/?${query}`);
    if (response.status === 204) {
      return 'noData';
    }
    return response.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getInteractionsByMember = async (query) => {
  try {
    const res = await API.get(`/custom/media/interactions/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getServicesByMember = async (query) => {
  try {
    const res = await API.get(`/custom/media/services/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getInProgressInteraction = async (query) => {
  try {
    const res = await API.get(`/custom/media/interactions/progress/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data.issues;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getApplesData = async (query) => {
  try {
    const res = await API.get(`/custom/media/apples/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getPvrData = async (query) => {
  try {
    const res = await API.get(`/custom/media/pvr/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getComplexityChartData = async (query) => {
  try {
    const res = await API.get(`/custom/media/complexity/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getCampaignsChartData = async (query) => {
  try {
    const res = await API.get(`/custom/media/campaigns/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTopMediaHits = async (query) => {
  try {
    const res = await API.get(`custom/media/topMediaHits/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTopServiceHits = async (query) => {
  try {
    const res = await API.get(`custom/media/topServiceHits/filter?${query}`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTopMediaTopics = async (query) => {
  try {
    const res = await API.get(`custom/media/topMediaTopics/filter?${query}&limit=5`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTopProactiveTopics = async (query) => {
  try {
    const res = await API.get(`/custom/media/topProactiveTopics/filter?${query}&limit=5`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getMoreMediaTopics = async (query) => {
  try {
    const res = await API.get(`/custom/media/topMediaTopics/filter?${query}&limit=20`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};
export const get50MediaTopics = async (query) => {
  try {
    const res = await API.get(`/custom/media/topMediaTopics/filter?${query}&limit=30`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};
export const getMoreProactiveTopics = async (query) => {
  try {
    const res = await API.get(`/custom/media/topProactiveTopics/filter?${query}&limit=20`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const get50ProactiveTopics = async (query) => {
  try {
    const res = await API.get(`/custom/media/topProactiveTopics/filter?${query}&limit=50`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getExperts = async (query) => {
  try {
    const res = await API.get(`/custom/media/topExperts/filter?${query}&limit=150`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getInteractionsByType = async (query) => {
  try {
    const res = await API.get(`/custom/media/mediaInteractionsByType/filter?${query}&limit=150`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getTopComplexServices = async (query) => {
  try {
    const res = await API.get(`/custom/media/topComplexServices/filter?${query}&limit=5`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getMoreComplexServices = async (query) => {
  try {
    const res = await API.get(`/custom/media/topComplexServices/filter?${query}&limit=20`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getServicesByType = async (query) => {
  try {
    const res = await API.get(`/custom/media/servicesByType/filter?${query}&limit=150`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getOutletsChartData = async (query) => {
  try {
    const res = await API.get(`/custom/media/outlets/filter?${query}&limit=20`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getServicesByUnit = async (query) => {
  try {
    const res = await API.get(`/custom/media/servicesByUnit/filter?${query}&limit=150`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};

export const getInteractionsByUnit = async (query) => {
  try {
    const res = await API.get(`/custom/media/interactionsByUnit/filter?${query}&limit=150`);
    if (res.status === 204) {
      return 'noData';
    }
    return res.data;
  } catch (e) {
    return { error: 1, message: e.message };
  }
};
