import Plot from 'react-plotly.js';
import { months } from '../../shared/constants/constants';

const pieLayout = (title) => {
  return {
    title: title,
    margin: {
      l: 15,
      r: 0,
      b: 15,
      t: 25,
    },
    autosize: true,
    yaxis: {
      rangemode: 'tozero',
      automargin: true,
      tickfont: {
        size: 9,
      },
    },
    xaxis: {
      automargin: true,
      tickfont: {
        size: 9,
      },
    },
    font: {
      size: 10,
    },
    legend: {
      font: {
        size: 10,
      },
    },
  };
};
const barLayout = (title) => {
  return {
    title: title,
    margin: {
      l: 15,
      r: 0,
      b: 15,
      t: 25,
    },
    autosize: true,
    yaxis: {
      rangemode: 'tozero',
      automargin: true,
      tickfont: {
        size: 9,
      },
    },
    xaxis: {
      automargin: true,
      tickfont: {
        size: 9,
      },
      dtick: 1,
    },
    font: {
      size: 10,
    },
    showlegend: false,

    legend: {
      x: 0,
      xanchor: 'left',
      y: 1,
      font: {
        size: 12,
      },
    },
  };
};
const lineLayout = (title) => {
  return {
    title: title,
    margin: {
      l: 15,
      r: 0,
      b: 15,
      t: 25,
    },
    autosize: true,
    yaxis: {
      rangemode: 'tozero',
      automargin: true,
      tickfont: {
        size: 9,
      },
    },
    xaxis: {
      automargin: true,
      tickfont: {
        size: 9,
      },
    },
    font: {
      size: 10,
    },
    showlegend: true,

    legend: {
      x: 1,
      y: 0.5,
      font: {
        size: 12,
      },
    },
    barmode: 'stack',
  };
};
function LinePlot({ chartData, title, type }) {
  const layout = lineLayout(title);
  layout.title = title;
  return <Plot data={chartData} useResizeHandler={true} layout={layout} style={{ width: '100%', height: '100%' }} />;
}

function MonthLinePlot({ chartData, title, type }) {
  const layout = lineLayout(title);
  layout.title = title;
  layout.xaxis.categoryarray = months.filter((month) => {
    return chartData.findIndex((line) => line.x.includes(month)) >= 0;
  });
  layout.xaxis.categoryorder = 'array';
  return <Plot data={chartData} useResizeHandler={true} layout={layout} style={{ width: '100%', height: '100%' }} />;
}

function BarPlot({ chartData, title, type }) {
  const layout = barLayout(title);
  layout.title = title;
  return <Plot data={chartData} layout={layout} useResizeHandler={true} style={{ width: '100%', height: '100%' }} />;
}

function PieChart({ chartData, title, type }) {
  const layout = pieLayout(title);
  layout.title = title;
  return <Plot data={chartData} layout={layout} useResizeHandler={true} style={{ width: '100%', height: '100%' }} />;
}

export { LinePlot, BarPlot, PieChart, MonthLinePlot };
