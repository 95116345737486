import { Spinner } from 'react-bootstrap';
import BroadcastPopupDialog from './BroadcastPopupDialog';

const BroadcastPopupPage: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner animation="border"></Spinner>
      <BroadcastPopupDialog />
    </div>
  );
};

export default BroadcastPopupPage;
