import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { HexColorPicker } from 'react-colorful';
import { Box } from '@mui/material';

import {
  postNewDepartmentEnum,
  postNewUnitEnum,
  postNewCampaignEnum,
  updateDepartment,
  updateUnit,
  updateCampaign,
} from './adminPageRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { useDispatch } from 'react-redux';
import { Tooltip, Zoom } from '@mui/material';
import { isValidHexColorString } from '../../shared/functions/misc';
import { IssueAndServiceSharedValues } from '../../shared/constants/IssueAndServiceKeys';

const useStyles = makeStyles({
  modalCard: {
    padding: '30px',
    width: '30%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '1vw',
    marginTop: '3vh',
  },
  textField: {
    width: '100%',
  },
  fieldDiv: {
    paddingTop: '2vh',
    paddingBottom: '2vh',
    borderTop: 'solid gray 1px',
    borderBottom: 'solid gray 1px',
    marginTop: '2vh',
    marginBottom: '2vh',
  },
  fieldDivTop: {
    paddingTop: '2vh',
    paddingBottom: '1vh',
    borderTop: 'solid gray 1px',
    marginTop: '2vh',
    marginBottom: '1vh',
  },
  fieldDivBottom: {
    paddingTop: '1vh',
    paddingBottom: '2vh',
    borderBottom: 'solid gray 1px',
    marginTop: '1vh',
    marginBottom: '2vh',
  },
  fieldDivMiddle: {
    paddingTop: '1vh',
    paddingBottom: '1vh',
    marginTop: '1vh',
    marginBottom: '1vh',
  },
  addButton: {
    width: '6vw',
    height: '5vh',
  },
  cancelButton: {
    width: '6vw',
    height: '5vh',
    color: 'white',
    backgroundColor: 'red',
  },
  hexColorPickerTextFieldAndButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  hexColorPickerEnableDisableColorButton: {
    width: '6vw',
    height: '55px',
    borderRadius: '0 4px 4px 0 !important',
  },
  charCountText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

function AddEnumModal({ open, setOpen, itemType, existingItems, editMode, setEditMode, selectedItem }) {
  let postItemMethod;
  let updateItemMethod;
  let queryKeys;
  let initialFormStatusObject;

  // Object with field as keys and the char limit of that field as value. If char limit does not exist the set value to -1.
  let itemFieldToCharLimit;

  let itemFieldToTextfieldLabel;
  let itemFieldToTooltipDescription;
  let itemFields;

  // component types: textField, colorPicker
  let itemFieldToComponentType;

  switch (itemType) {
    case IssueAndServiceSharedValues.department:
      postItemMethod = postNewDepartmentEnum;
      updateItemMethod = updateDepartment;
      itemFields = ['name'];
      itemFieldToCharLimit = { name: 35 };
      itemFieldToTooltipDescription = { name: `Name of ${itemType}` };
      itemFieldToTextfieldLabel = { name: `Name` };
      itemFieldToComponentType = { name: 'textField' };
      queryKeys = ['depts', 'adminPage'];
      initialFormStatusObject = {};

      itemFields.forEach((field) => {
        initialFormStatusObject[field] = { error: false, message: '' };
      });
      break;
    case IssueAndServiceSharedValues.unit:
      postItemMethod = postNewUnitEnum;
      updateItemMethod = updateUnit;
      itemFields = ['name'];
      itemFieldToCharLimit = { name: 35 };
      itemFieldToTooltipDescription = { name: `Name of ${itemType}/Faculty` };
      itemFieldToTextfieldLabel = { name: `Name` };
      itemFieldToComponentType = { name: 'textField' };
      queryKeys = ['units', 'adminPage'];
      initialFormStatusObject = {};

      itemFields.forEach((field) => {
        initialFormStatusObject[field] = { error: false, message: '' };
      });
      break;
    case IssueAndServiceSharedValues.campaign:
      postItemMethod = postNewCampaignEnum;
      updateItemMethod = updateCampaign;
      itemFields = ['name', 'backgroundColor'];
      itemFieldToCharLimit = { name: 35, backgroundColor: -1 };
      itemFieldToTextfieldLabel = { name: `Name`, backgroundColor: 'Background Color' };
      itemFieldToComponentType = { name: 'textField', backgroundColor: 'colorPicker' };
      itemFieldToTooltipDescription = {
        name: `Name of ${itemType}`,
        backgroundColor:
          'Background color of the Media Interaction/Service Log table cells and Analytics chart sections that show this campaign. You can set the color using the slider below or by copy-pasting the full hex value of a color into the text field. It is recommended that you pick a lighter colour to go with black text. Note that the topmost campaign will have priority in determining the campaign cell color in Media Interaction/Service Log tables',
      };
      queryKeys = ['campaigns', 'adminPage'];
      initialFormStatusObject = {};

      itemFields.forEach((field) => {
        initialFormStatusObject[field] = { error: false, message: '' };
      });
      break;
    default:
      console.error('unhandled item type encountered;');
      break;
  }

  const classes = useStyles();
  // holds the field values of the enum object that is being added/edited
  const [itemObject, setItemObject] = useState({});
  const [formStatus, setFormStatus] = useState(initialFormStatusObject);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editMode) {
      setItemObject(selectedItem);
    } else {
      setItemObject({});
    }
  }, [editMode, selectedItem]);

  const checkEmptyFieldsAndCharacterLength = () => {
    let pass = true;
    let newFormStatus = { ...formStatus };

    if (!itemObject.name) {
      newFormStatus.name = { error: true, message: '' };
      pass = false;
    } else {
      newFormStatus.name = { error: false, message: '' };
    }
    setFormStatus(newFormStatus);
    return pass;
  };

  const checkDuplicates = () => {
    let numOfDuplicates = 0;

    // We check for duplicates by checking name only. We want to also allow people to change capitalization of names in updates.
    if (!(editMode && selectedItem.name.toLowerCase() === itemObject.name.toLowerCase())) {
      existingItems.forEach((item) => {
        if (item.name.toLowerCase() === itemObject.name.toLowerCase()) {
          numOfDuplicates += 1;
        }
      });
    }

    if (numOfDuplicates > 0) {
      dispatch(
        openSnackbar({
          severity: 'warning',
          message: `${numOfDuplicates} ${itemType.toLowerCase()}(s) with the same name already exists. Please check if you are trying to add a type that already exists.`,
        })
      );
      return false;
    }
    return true;
  };

  const getClassNameForParentDivOfTextField = (itemFields, index) => {
    const arrayOfItemFields = itemFields;
    if (arrayOfItemFields.length === 1) {
      return classes.fieldDiv;
    } else if (index === 0) {
      return classes.fieldDivTop;
    } else if (index === arrayOfItemFields.length - 1) {
      return classes.fieldDivBottom;
    } else {
      return classes.fieldDivMiddle;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!checkEmptyFieldsAndCharacterLength()) return;

    if (!checkDuplicates()) return;

    if (editMode) {
      await updateItemMethod(selectedItem._id, itemObject)
        .then((res) => {
          setItemObject({});
          setOpen(false);
          setEditMode(false);
          dispatch(openSnackbar({ message: `${itemType} updated successfully`, severity: 'success' }));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(openSnackbar({ message: `${itemType} failed to be updated`, severity: 'error' }));
        });
    } else {
      await postItemMethod(itemObject)
        .then((res) => {
          setItemObject({});
          setOpen(false);
          dispatch(openSnackbar({ message: `${itemType} added successfully`, severity: 'success' }));
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(openSnackbar({ message: `${itemType} failed to be added`, severity: 'error' }));
        });
    }
  };

  const queryClient = useQueryClient();
  const itemMutation = useMutation({
    mutationFn: (e) => onSubmit(e),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys);
    },
  });

  const getComponentGivenFieldComponentType = (fieldComponentType, field) => {
    switch (fieldComponentType) {
      case 'textField':
        return textInputForEnumField(field);
      case 'colorPicker':
        return colorPickerForEnumField(field);
      default:
        console.error('unhandled field type encountered');
        return textInputForEnumField(field);
    }
  };

  const colorPickerForEnumField = (field) => {
    return (
      <>
        <div className={classes.hexColorPickerTextFieldAndButtonContainer}>
          <Tooltip
            title={<Typography variant={'body2'}>{itemFieldToTooltipDescription[field]}</Typography>}
            TransitionComponent={Zoom}
            enterDelay={200}
            placement={'right'}
          >
            <TextField
              id={`${itemType}-${field}-colorPicker-textField`}
              required
              InputLabelProps={{ shrink: true }}
              value={itemObject[field] ?? ''}
              disabled={!itemObject[field]}
              onChange={(e) => {
                const newHexColorString = e.target.value;
                if (isValidHexColorString(newHexColorString)) {
                  const newItemObject = { ...itemObject };
                  newItemObject[field] = newHexColorString;
                  setItemObject(newItemObject);
                }
              }}
              className={classes.textField}
              label={itemFieldToTextfieldLabel[field]}
              variant="outlined"
              error={formStatus[field].error}
              helperText={formStatus[field].message}
            ></TextField>
          </Tooltip>
          <Button
            variant="contained"
            className={classes.hexColorPickerEnableDisableColorButton}
            onClick={() => {
              const newItemObject = { ...itemObject };
              newItemObject[field] = itemObject[field] ? '' : '#000000';
              setItemObject(newItemObject);
            }}
          >
            {itemObject[field] ? 'Disable Colors' : 'Enable Colors'}
          </Button>
        </div>
        {itemObject[field] && (
          <HexColorPicker
            id={`${itemType}-${field}-colorPicker-hexColorSlider`}
            style={{ width: 'auto', height: '120px', marginTop: '0.5vh' }}
            color={itemObject[field] ?? '#000000'}
            onChange={(e) => {
              const newItemObject = { ...itemObject };
              newItemObject[field] = e;
              setItemObject(newItemObject);
            }}
          ></HexColorPicker>
        )}
      </>
    );
  };

  const textInputForEnumField = (field) => {
    return (
      <Tooltip
        title={<Typography variant={'body2'}>{itemFieldToTooltipDescription[field]}</Typography>}
        TransitionComponent={Zoom}
        enterDelay={200}
        placement={'right'}
      >
        <TextField
          required
          value={itemObject[field]}
          onChange={(e) => {
            if (e.target.value.length > itemFieldToCharLimit[field]) {
              const newItemObject = { ...itemObject };
              newItemObject[field] = e.target.value.slice(0, itemFieldToCharLimit[field]);
              setItemObject(newItemObject);
            } else {
              const newItemObject = { ...itemObject };
              newItemObject[field] = e.target.value;
              setItemObject(newItemObject);
            }
          }}
          className={classes.textField}
          id={`${itemType}-${field}-textInput-textfield`}
          label={itemFieldToTextfieldLabel[field]}
          variant="outlined"
          error={formStatus[field].error}
          helperText={
            <Box>
              {formStatus[field].message ? `${formStatus[field].message}` : ''}
              <Box className={classes.charCountText}>{`${itemObject[field]?.length ?? 0}/${
                itemFieldToCharLimit[field]
              } character(s)`}</Box>
            </Box>
          }
        ></TextField>
      </Tooltip>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setEditMode(false);
        setOpen(false);
      }}
    >
      <Card className={classes.modalCard}>
        <Typography align="left" variant="h5">
          {editMode ? `Edit ${itemType}` : `Add ${itemType}`}
        </Typography>
        <form>
          {itemFields.map((field, index) => (
            <div className={getClassNameForParentDivOfTextField(itemFields, index)}>
              {getComponentGivenFieldComponentType(itemFieldToComponentType[field], field)}
            </div>
          ))}

          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.cancelButton} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              // type="submit"
              className={classes.addButton}
              disabled={formStatus.disabled}
              onClick={(e) => itemMutation.mutate(e)}
              variant="contained"
              color="primary"
              data-cy="submit-button-AddEnumModal"
            >
              {editMode ? 'Edit' : 'Add'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}

export default AddEnumModal;
