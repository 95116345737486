import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface EmailInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Box component="form" className="box" noValidate sx={{ mt: 1 }}>
      <TextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        type="email"
        value={email}
        onChange={handleChange}
      />
    </Box>
  );
};

export default EmailInput;
