import { Box, Card, List, ListItem, ListSubheader } from '@mui/material';
import AdminListFuzzySearch from './AdminListFuzzySearch';
import EnumListItem from './EnumListItem';
import { useState } from 'react';

function EnumListComponent({
  enumItems,
  itemType,
  addButton,
  targetMergingItem,
  setEnumModalOpen,
  setIsEditing,
  setSelectedEnum,
  isMerging,
  setIsMerging,
  setMergingItems,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Set());

  const handleCheckboxChange = (event, enumItem) => {
    const updatedSelectedItems = new Set(selectedItems);
    if (event.target.checked) {
      updatedSelectedItems.add(enumItem);
    } else {
      updatedSelectedItems.delete(enumItem);
    }
    setSelectedItems(updatedSelectedItems);
    setMergingItems([...updatedSelectedItems]);
  };

  const activeItems = searchResults.filter((item) => !item.archived);
  const archivedItems = searchResults.filter((item) => item.archived);

  return (
    <Box display="inline-block">
      <Card>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '0',
            margin: '0',
            maxHeight: '100%',
            overflow: 'auto',
            width: '30rem',
          }}
          disablePadding={true}
          padding={0}
        >
          <ListItem
            key={'searchbar'}
            sx={{
              justifyContent: 'space-between',
              marginTop: '1rem',
              paddingTop: '10',
            }}
          >
            <AdminListFuzzySearch items={enumItems} setSearchResults={setSearchResults} />
            {!isMerging && addButton()}
          </ListItem>

          {activeItems &&
            activeItems.map((enumItem, i) => (
              <EnumListItem
                targetMergingItem={targetMergingItem}
                key={enumItem._id || `activeItem-${i}`}
                enumItem={enumItem}
                itemType={itemType}
                isMerging={isMerging}
                selectedItems={selectedItems}
                handleCheckboxChange={handleCheckboxChange}
                setIsEditing={setIsEditing}
                setSelectedEnum={setSelectedEnum}
                setEnumModalOpen={setEnumModalOpen}
                setIsMerging={setIsMerging}
              />
            ))}

          {archivedItems.length > 0 && (
            <ListSubheader
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                width: '93%',
                borderRadius: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem 1rem 1rem 1rem',
                padding: '0',
              }}
            >
              Archived
            </ListSubheader>
          )}

          {archivedItems &&
            archivedItems.map((enumItem, i) => (
              <EnumListItem
                targetMergingItem={targetMergingItem}
                key={enumItem._id || `archivedItem-${i}`}
                enumItem={enumItem}
                itemType={itemType}
                isMerging={isMerging}
                selectedItems={selectedItems}
                handleCheckboxChange={handleCheckboxChange}
                setIsEditing={setIsEditing}
                setSelectedEnum={setSelectedEnum}
                setEnumModalOpen={setEnumModalOpen}
                setIsMerging={setIsMerging}
                isArchivedItem={true}
              />
            ))}
        </List>
      </Card>
    </Box>
  );
}

export default EnumListComponent;
