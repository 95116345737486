import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { StoreState, UserState } from '../../redux-types';
import { PrivilegeLevels } from '../constants/PrivilegeLevels';

const AdminPrivilegeProtectedWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector<StoreState, UserState>((state) => state.user);
  const isAdminPrivilegeProtected = user.userInfo.privilege > PrivilegeLevels.manager.level;

  if (!isAdminPrivilegeProtected) {
    return null;
  }

  return children;
};

export default AdminPrivilegeProtectedWrapper;
