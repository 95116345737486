import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getAdminBroadcastPopup,
  publishBroadcastPopup,
  saveBroadcastPopupDraft,
} from '../../shared/requests/broadcastPopupRequests';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../AppSnackbar/snackbarSlice';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';

const BroadcastPopupSetting: React.FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { isFetching, data } = useQuery({
    queryKey: ['tenants', 'tenantsPage', 'BroadcastPopupSetting'],
    queryFn: () => getAdminBroadcastPopup(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const saveBroadcastPopupDraftMutation = useMutation({
    mutationFn: saveBroadcastPopupDraft,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tenants', 'tenantsPage', 'BroadcastPopupSetting'],
      });
      dispatch(
        openSnackbar({
          message: 'Broadcast Popup Draft saved successfully',
          severity: 'success',
        })
      );
    },
    onError: (err) => {
      dispatch(
        openSnackbar({
          message: 'Failed to save Broadcast Popup Draft',
          severity: 'error',
        })
      );
    },
  });

  const publishBroadcastPopupMutation = useMutation({
    mutationFn: publishBroadcastPopup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tenants', 'tenantsPage', 'BroadcastPopupSetting'],
      });
      dispatch(
        openSnackbar({
          message: 'Broadcast Popup published successfully',
          severity: 'success',
        })
      );
    },
    onError: (err) => {
      dispatch(
        openSnackbar({
          message: 'Failed to publish Broadcast Popup, save your draft before publish',
          severity: 'error',
        })
      );
    },
  });

  const [draftContent, setDraftContent] = useState('');
  const [publishedContent, setPublishedContent] = useState('');
  const [seenCount, setSeenCount] = useState(0);

  useEffect(() => {
    if (!isFetching && data) {
      setDraftContent(data.draftContent);
      setPublishedContent(data.publishedContent);
      setSeenCount(data.seenCount);
    }
  }, [isFetching, data]);

  const handleDraftContentChange = (value: string) => {
    setDraftContent(value);
  };

  const handleSaveDraft = () => {
    saveBroadcastPopupDraftMutation.mutateAsync({
      text: draftContent,
    });
  };

  const handlePublish = () => {
    publishBroadcastPopupMutation.mutateAsync();
  };

  if (isFetching) {
    return null;
  }

  return (
    <Box sx={{ marginTop: '3vh', marginBottom: '3vh' }}>
      <Box>
        <Typography variant="h3">Broadcast Popup</Typography>
        <Typography variant="h6">
          Please avoid having multiple people saving drafts or publishing simultaneously
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center', width: '100%', marginTop: '1rem' }}>
          <LoadingButton
            loading={saveBroadcastPopupDraftMutation.isPending}
            variant="outlined"
            onClick={() => handleSaveDraft()}
          >
            Save Draft
          </LoadingButton>
          <LoadingButton
            loading={publishBroadcastPopupMutation.isPending}
            variant="outlined"
            onClick={() => handlePublish()}
          >
            Publish Broadcast Popup
          </LoadingButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '3rem', minHeight: '30vh', marginTop: '1rem' }}>
        <Box sx={{ minWidth: '30%', height: '100%' }}>
          <RichTextEditor
            defaultValue={''}
            value={draftContent}
            onChange={handleDraftContentChange}
            readOnly={false}
            label="Draft Broadcast Popup"
            quillStyle={{ resize: 'vertical' }}
          />
        </Box>
        <Box sx={{ minWidth: '30%', height: '100%' }}>
          <RichTextEditor
            defaultValue={''}
            value={data ? data.draftContent : ''}
            readOnly={true}
            label="Saved Broadcast Popup Draft"
            quillStyle={{ resize: 'vertical' }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', minWidth: '30%', height: '100%' }}>
          <div>
            This published Broadcast popup has been seen by <strong>{seenCount}</strong> users
          </div>
          <RichTextEditor
            defaultValue={''}
            value={publishedContent}
            readOnly={true}
            quillStyle={{ resize: 'vertical' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BroadcastPopupSetting;
