import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Button, LinearProgress } from '@mui/material';
import ContactFuzzySearch from './ContactFuzzySearch';
import CombinedContactPersonnelModal from './Modal/CombinedContactPersonnelModal';
import AddIcon from '@mui/icons-material/Add';
import { capitalize } from '../../shared/functions/misc';
import ContactsVirtualList from './ContactsVirtualList';
import CotactsExportPreview from './ContactsExportPreview';

// generate a random color for each contact
function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name[0]}`,
  };
}

const lookupUnit = (contact, unitId, units) => {
  if (contact.unitName) return contact.unitName;

  const unit = units.find((unit) => unit._id === unitId);

  contact.unitName = unit ? unit.name : '❗Outdated';
  return contact.unitName;
};

// check if the contact has a org and return the org's name and its role
function getOrgRole(contact, category, units) {
  if (contact.Org) {
    return capitalize(`${contact.Org.name} - ${category.slice(0, -1)}`);
  } else if (contact.unit) {
    return capitalize(`${lookupUnit(contact, contact.unit, units)} - ${category.slice(0, -1)}`);
  } else {
    return '';
  }
}

const ContactPersonnelsList = (props) => {
  const contacts = props.data;
  const { orgs, handleContactClick, isLoadingList, setIsLoadingList, units } = props;
  const { category } = props;
  // Props from MergeContactPersonnelModal
  const { isMerging, mergingContacts, setMergingContacts, selectedContact } = props;

  const [searchResults, setSearchResults] = useState(contacts);
  const [query, setQuery] = useState('');
  const [openAddContact, setOpenAddContact] = useState(false);

  useEffect(() => {
    setSearchResults(contacts);
    contacts.length > 0 && setIsLoadingList && setIsLoadingList(false);
  }, [contacts, setIsLoadingList]);

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '0',
          margin: '0',
          maxHeight: '100%',
          overflow: 'auto',
          width: '35rem',
        }}
      >
        <ListItem
          sx={{
            justifyContent: 'space-between',
            marginTop: '1rem',
            paddingTop: '10',
          }}
        >
          <ContactFuzzySearch
            contacts={contacts}
            setSearchResults={setSearchResults}
            query={query}
            setQuery={setQuery}
            category={category}
          />
          <CotactsExportPreview category={category} previewData={searchResults} />
          {!isMerging && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddContact(true);
              }}
              data-cy="addContact-button-ContactPersonnelsList"
            >
              <AddIcon />
              Add {category.slice(0, -1)}
            </Button>
          )}
        </ListItem>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {isLoadingList ? <LinearProgress /> : null}
          <ContactsVirtualList
            contactsSize={contacts ? contacts.length : 0}
            searchResultItems={searchResults}
            isMerging={isMerging}
            setMergingContacts={setMergingContacts}
            mergingContacts={mergingContacts}
            selectedContact={selectedContact}
            handleContactClick={handleContactClick}
            getListItemAvatarProps={(name) => stringAvatar(name)}
            getListItemSecondaryText={(contact) => getOrgRole(contact, category, units)}
          />
        </Box>
      </List>
      <CombinedContactPersonnelModal
        openAddContact={openAddContact}
        setOpenAddContact={setOpenAddContact}
        orgs={orgs}
        units={units}
        setRefreshContacts={props.setRefreshContacts}
        category={category}
        isInContactPage={true}
        contacts={contacts}
      />
    </>
  );
};

export default ContactPersonnelsList;
